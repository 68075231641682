import React, { useState, useEffect } from "react";
import verifyImage from "images/verify_account_illustration.png";

const ConfirmationModal = (props) => {
  const [submitting, setSubmitting] = useState(false);

  const handleSubmitting = () => {
    setSubmitting(true);

    if (submitting === false) {
      props.action();
    }
  };

  const renderTitle = () => {
    if (props.title) {
      return props.title;
    } else {
      return "Confirmation needed";
    }
  };

  const renderMessage = () => {
    if (props.message) {
      return props.message;
    } else {
      return "Please confirm that you have verified the attached files and that your column mappings are correct before submitting.";
    }
  };

  const renderLoader = () => {
    if (submitting) {
      return <div className="loading-indicator"></div>;
    }
  };

  const renderModal = () => {
    if (props.displayModal) {
      return (
        <div className="modal__background">
          <div className="modal__body modal__body--no-side-padding" style={{ paddingTop: 30, paddingBottom: 30 }}>
            <div className="modal__body__title">Are you sure?</div>
            <div className="modal__body__subtitle" style={{ marginBottom: 30 }}>
              please confirm before continuing
            </div>

            <div className="modal__body__spotlight">
              <img src={verifyImage} width={220} />
            </div>
            <div className="modal__body__container">
              <div className="modal__body__container__note__title">{renderTitle()}</div>
              <div className="modal__body__container__note">{renderMessage()}</div>
            </div>
            <div className="modal__body__footer">
              <div
                className="btn btn--white btn--outline"
                onClick={() => {
                  props.setModalState(false);
                }}
              >
                Cancel
              </div>
              <div
                className="btn"
                id="confirmButton"
                style={{ flexGrow: 1, marginLeft: 30 }}
                onClick={() => {
                  handleSubmitting();
                }}
              >
                {renderLoader()}
                Confirm
              </div>
            </div>
          </div>
        </div>
      );
    } else {
      return null;
    }
  };

  return renderModal();
};
export default ConfirmationModal;
