import React, { useState, useRef, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import CheckIcon from "@mui/icons-material/Check";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { mapManufacturersForSelect } from './manufacturerSelectUtils';

const useStyles = makeStyles(() => ({
  customCheckIcon: {
    color: "#3246d3"
  },
}));

const ManufacturerSelect = ({ manufacturers, selectedManufacturer, setSelectedManufacturer  }) => {
  const [isOpen, setIsOpen] = useState(false);
  const selectRef = useRef(null);

  const classes = useStyles();
  
  const options = mapManufacturersForSelect(manufacturers);

  const allManufacturersAreSelected = selectedManufacturer.value === manufacturers[0].value;

  const toggleSelect = () => {
    setIsOpen(!isOpen);
  };

  const handleManufacturerSelect = (manufacturer) => {
    setSelectedManufacturer(manufacturer);
    setIsOpen(false);
  };

  const handleClickOutside = (event) => {
    if (selectRef.current && !selectRef.current.contains(event.target)) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    if (isOpen) {
      document.addEventListener('click', handleClickOutside, false);
    } else {
      document.removeEventListener('click', handleClickOutside, false);
    }

    return () => {
      document.removeEventListener('click', handleClickOutside, false);
    };
  }, [isOpen]);

  const renderGroup = (group) => (
    group.map(({ label, options }) => (
      <div key={label} >
        <div className="manufacturer-select__group__item__label">{label}</div>
        <div className="manufacturer-select__group__item__options">
          {options.map((option) => {
            const optionIsSelected = selectedManufacturer.value === option.value;
            return (
              <button
                type="button"
                className={`manufacturer-select__group__item__option ${optionIsSelected ? "manufacturer-select__group__item__option--selected" : ""}`}
                key={option.value}
                onClick={() => handleManufacturerSelect(option)}
              >
                <span>{option.label}</span>
                {optionIsSelected && (
                  <CheckIcon style={{ fontSize: "14px" }} className={classes.customCheckIcon} />
                )}
              </button>
            )
          })}
        </div>
      </div>
    ))
  );

  return (
    <div ref={selectRef} className="manufacturer-select">
      <label htmlFor="manufacturer" className="manufacturer-select__label">Manufacturer</label>
      <button id="manufacturer" type="button" className="manufacturer-select__button" onClick={toggleSelect}>
        <span>{selectedManufacturer.label}</span>
        <ExpandMoreIcon fontSize="small" sx={{ color: "#adbbc8" }} />
      </button>
      {isOpen && (
          <div className="manufacturer-select__options">
            <button
              type="button"
              onClick={() => setSelectedManufacturer(manufacturers[0])}
              className="manufacturer-select__options__all"
            >
              <span
                className={`manufacturer-select__options__all__text ${allManufacturersAreSelected ? "manufacturer-select__options__all__text--selected" : ""}`}
              >
                {manufacturers[0].label}
              </span>
              {allManufacturersAreSelected && (
                <CheckIcon style={{ fontSize: "14px" }} className={classes.customCheckIcon} />
              )}
            </button>
            <div className="manufacturer-select__group">
              {options.map((group, index) => (
                  <div key={index} className="manufacturer-select__group__item">
                    {renderGroup(group)}
                  </div>
              ))}
            </div>
          </div>
      )}
    </div>
  );
};

export default ManufacturerSelect;