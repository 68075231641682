import React, { useState, useEffect, useMemo, useRef } from "react";

import useMultiPageNavigation from "../../Hooks/useMultiPageNavigation";
import Http from "../http";

import CoveredEntitiesList from "../main_slide_components/CoveredEntitiesList";
import FirstPage from "../main_slide_components/entity_component/FirstPage";
import PharmacyFileList from "../main_slide_components/entity_component/PharmacyFileList";
import SubmissionPage from "../main_slide_components/entity_component/SubmitCertifications";
import StatsBar from "../main_slide_components/StatsBar";

const MainSlide = ({
  organizationId,
  entities,
  stats,
  setStats,
  attestations,
  setAttestations,
  hasCompletedAllAttestations,
  showSuccessModal,
  handleCloseModal,
  setShouldConfirmRef,
  getConfirmation,
  getConfirmationIfNeeded,
  setNotice,
}) => {
  const [selectedNpi, setSelectedNpi] = useState();
  const [activeId340B, setActiveId340B] = useState(null);
  const allAttestationsInitiallyCompleted = useRef();

  const allAttestationsCompleted = useMemo(() => attestations.every((a) => a.status == "complete"), [attestations]);
  const firstIncompleteAttestation = useMemo(() => {
    if (allAttestationsCompleted) {
      return null;
    }
    return attestations.find((a) => a.status !== "complete");
  }, [attestations]);

  useEffect(() => {
    setActiveId340B(firstIncompleteAttestation?.id_340b || attestations[0].id_340b);
    allAttestationsInitiallyCompleted.current = allAttestationsCompleted;
  }, []);

  const selectedAttestationIndex = useMemo(
    () => attestations?.findIndex((attestation) => attestation.id_340b === activeId340B),
    [attestations, activeId340B]
  );
  const selectedAttestation = useMemo(
    () => attestations?.find((ce) => ce.id_340b === activeId340B) || {},
    [activeId340B, attestations]
  );
  const activePharmacy = useMemo(
    () => selectedAttestation.in_house_certifications?.find((p) => p.npi === selectedNpi) || {},
    [selectedAttestation, selectedNpi]
  );
  const entityId = useMemo(
    () => entities.find((entity) => entity.id_340b === selectedAttestation.id_340b)?.id,
    [activeId340B]
  );

  function getIsSubmitDisabled() {
    const firstPage = currPageIndex === 0;
    const submissionPage = currPageIndex === 2;

    if (firstPage) {
      return selectedAttestation.in_house === null || !selectedAttestation.signed_by;
    } else if (submissionPage) {
      const attestationComplete =
        selectedAttestation.status === "in_review" || selectedAttestation.status === "complete";

      return !attestation.signed_by || attestationComplete;
    }
  }

  const isSubmitDisabled = getIsSubmitDisabled();

  var { nextPage, goToPage, currPage, currPageIndex } = useMultiPageNavigation([
    <FirstPage
      pharmacies={selectedAttestation.in_house_certifications}
      activeId340B={activeId340B}
      attestation={selectedAttestation}
      setAttestation={setAttestation}
      showUploadPage={showUploadPage}
      showSubmissionPage={() => goToPage(2)}
      allAttestationsCompleted={allAttestationsCompleted}
      allAttestationsInitiallyCompleted={allAttestationsInitiallyCompleted.current}
      handleNextAttestation={handleNextAttestation}
      handleSubmitAttestation={handleSubmitAttestation}
      hasCompletedAllAttestations={hasCompletedAllAttestations}
      handleCloseModal={handleCloseModal}
      getConfirmation={getConfirmation}
      isSubmitDisabled={isSubmitDisabled}
      showSuccessModal={showSuccessModal}
    />,
    <PharmacyFileList
      organizationId={organizationId}
      entityId={entityId}
      pharmacy={activePharmacy}
      entity={selectedAttestation}
      attestations={attestations}
      setAttestations={setAttestations}
      setAttestation={setAttestation}
      showFirstPage={showFirstPage}
      setShouldConfirmRef={setShouldConfirmRef}
      getConfirmationIfNeeded={getConfirmationIfNeeded}
      setNotice={setNotice}
    />,
    <SubmissionPage
      attestation={selectedAttestation}
      setAttestation={setAttestation}
      showFirstPage={showFirstPage}
      handleSubmitAttestation={handleSubmitAttestation}
      isSubmitDisabled={isSubmitDisabled}
    />,
  ]);

  function setAttestation(newValues) {
    setAttestations((prevAttestations) => {
      return prevAttestations.map((attestation) => {
        if (attestation.id_340b == activeId340B) {
          return { ...attestation, ...newValues };
        }

        return attestation;
      });
    });
  }

  function handleNextAttestation() {
    if (!allAttestationsCompleted) {
      function selectNextIncompleteAttestation() {
        const reorderedAttestations = [
          ...attestations?.slice(selectedAttestationIndex + 1),
          ...attestations?.slice(0, selectedAttestationIndex + 1),
        ];
        const nextIncompleteAttestation = reorderedAttestations.find((attestation) => {
          return attestation.status !== "complete" && attestation.id_340b !== activeId340B;
        });

        if (nextIncompleteAttestation) {
          setActiveId340B(nextIncompleteAttestation?.id_340b);
        }
      }

      selectNextIncompleteAttestation();
      return;
    }

    if (selectedAttestationIndex < attestations.length - 1) {
      setActiveId340B(attestations[selectedAttestationIndex + 1]);
    }
  }

  async function handleSubmitAttestation() {
    const { in_house, in_house_certifications, signed_by } = selectedAttestation;
    const isSubmitSuccessful = await Http.submitAttestation(
      organizationId,
      entityId,
      selectedAttestation.id_340b,
      in_house,
      signed_by,
      attestations,
      setAttestations,
      setStats,
      setNotice
    );

    showFirstPage();
  }

  function showUploadPage(npi) {
    setSelectedNpi(npi);
    nextPage();
  }

  function showFirstPage() {
    setSelectedNpi(null);
    goToPage(0);
  }

  return (
    <div className="flex--col flex-grow gap--24 overflow--hidden">
      <StatsBar stats={stats} />
      <div className="flex flex-grow border br--8 overflow--y-hidden">
        <div className="flex flex-grow" style={{ minWidth: 880 }}>
          <CoveredEntitiesList
            attestations={attestations}
            activeId340B={activeId340B}
            setActiveId340B={setActiveId340B}
            showFirstPage={showFirstPage}
            getConfirmationIfNeeded={getConfirmationIfNeeded}
          />
          <div className="flex--col flex-grow">{currPage}</div>
        </div>
      </div>
    </div>
  );
};

export default MainSlide;
