import React, { useState, useCallback, useMemo } from "react";
import _ from "lodash";
import EntityAttestations from "./EntityAttestations";
import { withStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";

const CustomTextField = withStyles({
  root: {
    backgroundColor: "transparent",
    borderRadius: 8,
    "& label.Mui-focused": {
      color: "#3D71FF",
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: "green",
    },
    "& .MuiOutlinedInput-root": {
      borderRadius: 8,
      backgroundColor: "#ffffff",
      "& fieldset": {
        borderColor: "#DEE7EE",
      },
      "&:hover fieldset": {
        borderColor: "#DEE7EE",
      },
      "&.Mui-focused fieldset": {
        borderColor: "#3D71FF",
      },
    },
  },
})(TextField);

const ActionRequired = (props) => {
  const [userEmail, setUserEmail] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [isFocused, setIsFocused] = useState(false);
  const validEmailDomains = /^(?!.*@(gmail\.com|outlook\.com|yahoo\.com|hotmail\.com)$).+$/
  const validEmailAddressRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i
  const statsComplete = props.stats.percent_complete == 100;

  const isValidEmail = useMemo(() => {
    if (userEmail) {
      return validEmailAddressRegex.test(userEmail) && validEmailDomains.test(userEmail)
    }

    return false;
  }, [userEmail]);

  function renderEntityAttestations() {
    const attestations = props.attestations?.[props.policy] || [];

    function setEntityAttestations(newAttestations) {
      props.setAttestations({
        [props.policy]: newAttestations,
      });
    }

    return (
      <EntityAttestations
        page={props.page}
        stats={props.stats}
        attestations={attestations}
        setAttestations={setEntityAttestations}
        id_340b={props.id_340b}
        setNotice={props.setNotice}
        userEmail={userEmail}
        isValidEmail={isValidEmail}
        firstName={firstName}
        lastName={lastName}
      />
    );
  }

  const renderEmailTextField = () => {
    return (
      <div>
        <div className="register__form__dual-row register__form__dual-row--pad-right">
          <CustomTextField
            label="First Name"
            margin="normal"
            variant="outlined"
            id="firstName"
            name="first_name"
            autoFocus
            fullWidth
            required
            value={firstName}
            onChange={handleFirstName}
          />
        </div>
        <div className="register__form__dual-row register__form__dual-row--pad-left">
          <CustomTextField
            label="Last Name"
            margin="normal"
            variant="outlined"
            id="lastName"
            name="last_name"
            fullWidth
            required
            value={lastName}
            onChange={handleLastName}
          />
        </div>
        <CustomTextField
          label="Work Email Address"
          margin="normal"
          variant="outlined"
          id="email"
          type="email"
          fullWidth
          required
          error={isFocused && !isValidEmail}
          helperText={
            isValidEmail
              ? null
              : "Please provide a valid Work Email Address for this covered entity."
          }
          value={userEmail}
          onChange={handleEmailChange}
          onFocus={handleFocus}
          onBlur={handleBlur}
        />
      </div>
    )
  }

  const handleEmailChange = (event) => {
    setUserEmail(event.target.value);
  }

  const handleFirstName = (event) => {
    setFirstName(event.target.value);
  }

  const handleLastName = (event) => {
    setLastName(event.target.value);
  }

  const handleFocus = useCallback(() => {
    setIsFocused(true);
  }, []);

  const handleBlur = useCallback(() => {
    setIsFocused(false);
  }, []);

  return (
    <>
      <div className="action_required__container">
        <div className="action_required__contents">
          <div className="flex flex-column gap--8">
            {props.id_340b}
            <div className="t--md t--500">Sanofi Contract Pharmacy Anti-Diversion Policy</div>

            <div className="t--sm t--subtitle">
              Please provide your contract pharmacy agreement(s) evidencing that you retain legal title to Sanofi
              340B-priced drugs at your Arkansas-based contract pharmacies or, alternatively, an attestation that you
              retain legal title to Sanofi 340B-priced drugs at your Arkansas-based contract pharmacies. Please refer to
              Sanofi's Policy Document at{" "}
              <a className="t--anchor t--blue" href="/resources" target="_blank">
                340Besp.com/resources
              </a>{" "}
              for additional information.
            </div>
          </div>

          {!statsComplete && renderEmailTextField()}
          {renderEntityAttestations()}
        </div>
      </div>
    </>
  );
};

export default ActionRequired;
