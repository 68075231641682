export const fetchWrapper = {
  vault: GetVault,
  createFieldMapping: createFieldMapping,
  updateFieldMapping: updateFieldMapping,
  deleteAllFieldMappings: deleteAllFieldMappings,
  deleteFieldMapping: deleteFieldMapping
};

function GetVault(organizationID) {
  const url = `/api/organization/${organizationID}/vault`

  const requestOptions = {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      "X-CSRF-Token": document.querySelector('meta[name="csrf-token"]').getAttribute("content"),
    },
  }

  return fetch(url, requestOptions).then(handleResponse);
};

function createFieldMapping(organizationID, name) {
  const url = `/organization/${organizationID}/field_mappings`

  const requestOptions = {
    method: 'POST',
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      "X-CSRF-Token": document.querySelector('meta[name="csrf-token"]').getAttribute("content"),
    },
    body: JSON.stringify({
      field_mappings: {
        mapping_name: name
      }
    })
  }

  return fetch(url, requestOptions).then(handleResponse);
}

function updateFieldMapping(organizationID, id, fieldName, mappingName) {
  const url = `/organization/${organizationID}/field_mappings/${id}`

  const requestOptions = {
    method: 'PUT',
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      "X-CSRF-Token": document.querySelector('meta[name="csrf-token"]').getAttribute("content"),
    },
    body: JSON.stringify({
      field_mappings: {
        field_name: fieldName,
        mapping_name: mappingName
      }
    })
  }

  return fetch(url, requestOptions).then(handleResponse);
}

function deleteAllFieldMappings(organizationID, id) {
  const url = `/organization/${organizationID}/field_mappings/${id}`

  const requestOptions = {
    method: 'DELETE',
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      "X-CSRF-Token": document.querySelector('meta[name="csrf-token"]').getAttribute("content"),
    }
  }

  return fetch(url, requestOptions).then(handleResponse);
}

function deleteFieldMapping(organizationID, id, fieldName, mappingName) {
  const url = `/organization/${organizationID}/field_mappings`

  const requestOptions = {
    method: 'DELETE',
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      "X-CSRF-Token": document.querySelector('meta[name="csrf-token"]').getAttribute("content"),
    },
    body: JSON.stringify({
      field_mappings: {
        id: id,
        field_name: fieldName,
        mapping_name: mappingName
      }
    })
  }

  return fetch(url, requestOptions).then(handleResponse);
}

function handleResponse(response) {
  return response.json().then(resObj => {

    if (!response.ok) {
      const error = (resObj && resObj.message) || response.statusText;
      return Promise.reject(error);
    }

    return resObj;
  });
}