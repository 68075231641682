import React, { useRef, useState, useEffect } from "react";
import _ from "lodash";
import XLSX from "xlsx";
import ConfirmationModal from "../ConfirmationModal";
import Notice from "../Notice";
import { schema } from "./Fields";

const Post = (props) => {
  const [displayConfirmation, setDisplayConfirmation] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [isValidSubmission, setIsValidSubmission] = useState(props.filesWithErrors.length == 0);
  const [notice, setNotice] = React.useState({
    kind: "error",
    open: false,
    message: "",
  });

  var workbookSample = XLSX.utils.book_new();
  const workbookSheet = XLSX.utils.aoa_to_sheet([_.keys(schema())]);

  useEffect(() => {
    setIsValidSubmission(props.filesWithErrors.length == 0);
  }, [props.filesWithErrors]);

  const handleFiles = () => {
    _.forEach(props.data, (file, index) => {
      XLSX.utils.sheet_add_json(workbookSheet, file.processedData, {
        skipHeader: true,
        blankrows: false,
        origin: index == 0 ? "A2" : -1,
      });
    });
  };

  // This is temp used for dev
  const downloadWorkbook = () => {
    handleFiles();
    XLSX.utils.book_append_sheet(workbookSample, workbookSheet, "340B ESP™");
    XLSX.writeFile(workbookSample, "SecondSightSolutions.csv");
  };

  const writeWorkbook = (_callback) => {
    handleFiles();
    XLSX.utils.book_append_sheet(workbookSample, workbookSheet, "340B ESP™");

    _callback(XLSX.write(workbookSample, { bookType: "csv", type: "array" }));
  };

  const handleSubmission = () => {
    setDisplayConfirmation(false);
    if (props.data.length < 1 || submitting) return;
    setSubmitting(true);

    // downloadWorkbook()

    // Callback function passed to writeWorkbook which passes csv
    writeWorkbook(postSubmission);
  };

  const postSubmission = (csv) => {
    // TODO
    // ensure data submitting is validated before post
    var formData = new FormData();
    var date = new Date();

    formData.append("submission[uploads]", new File([csv], `340B_ESP_${date.toISOString().split("T")[0]}.csv`));

    fetch(props.submissionPath, {
      method: "POST",
      headers: {
        "X-CSRF-Token": document.querySelector('meta[name="csrf-token"]').getAttribute("content"),
      },
      body: formData,
    }).then((res) => {
      if (res.status >= 200 && res.status <= 299) {
        return res.json().then((data) => {
          location.href = data.path;
        });
      } else {
        res.json().then((data) => {
          location.href = data.path;
        });

        setNotice({
          kind: "error",
          open: true,
          message: "Oh no - it looks like something went wrong, please try again.",
        });
      }
    });
  };

  const renderLoader = () => {
    if (submitting) {
      return <div className="loading-indicator"></div>;
    }
  };

  return (
    <div>
      <Notice details={notice} />
      <div style={{ display: "flex" }}>
        <a href={`/organization/${props.organization}/submissions`}>
          <div
            className="btn btn--outline btn--white btn--sq"
            style={{ width: 46, height: 46, lineHeight: "44px", fontSize: 16 }}
          >
            <div className="solid solid-budicon-trash-cancel" />
          </div>
        </a>
        <div
          className={props.data.length > 0 && isValidSubmission ? "btn" : "btn btn--disabled"}
          style={{ marginLeft: 20, marginBottom: 30, flexGrow: 1 }}
          id={props.data.length > 0 && isValidSubmission ? "saveAndSubmit" : null}
          disabled={props.data.length > 0 && isValidSubmission ? false : true}
          onClick={() => {
            props.data.length > 0 && isValidSubmission ? setDisplayConfirmation(true) : null;
          }}
        >
          {renderLoader()}
          Save and Submit
        </div>
      </div>
      <ConfirmationModal
        displayModal={displayConfirmation}
        setModalState={setDisplayConfirmation}
        action={handleSubmission}
      />
    </div>
  );
};

export default Post;
