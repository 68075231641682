import _ from "lodash";
import moment from "moment";
import axios from "axios";

export default class Http {
  getQueries = (searchQuery) => {
    var queries = {};

    _.each(searchQuery, (value, key) => {
      if (value && value.length) _.assign(queries, { [key]: value });
    });

    return queries;
  };

  static fetchAccounts = (path, searchQuery) => {
    return axios
      .get(path, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "X-CSRF-Token": document.querySelector('meta[name="csrf-token"]').getAttribute("content"),
        },
        params: {
          ...searchQuery
        },
      })
      .then(function (response) {
        return response.data;
      })
      .catch(function (error) {});
  };

  static fetchLedger = (path, searchQuery) => {
    return axios
      .get(path, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "X-CSRF-Token": document.querySelector('meta[name="csrf-token"]').getAttribute("content"),
        },
        params: {
          ...searchQuery,
        },
      })
      .then(function (response) {
        return response.data;
      })
      .catch(function (error) {});
  };

  static fetchStatusHistory = (path, searchQuery) => {
    return axios
      .get(path, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "X-CSRF-Token": document.querySelector('meta[name="csrf-token"]').getAttribute("content"),
        },
        params: {
          ...searchQuery,
        },
      })
      .then(function (response) {
        return response.data;
      })
      .catch(function (error) {});
  };

  static fetchSubmissionDetails = (path, searchQuery) => {
    return axios
      .get(path, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "X-CSRF-Token": document.querySelector('meta[name="csrf-token"]').getAttribute("content"),
        },
        params: {
          ...searchQuery,
        },
      })
      .then(function (response) {
        return response.data;
      })
      .catch(function (error) {});
  };

  static fetchBalanceByMonth = (path, searchQuery) => {
    return axios
      .get(path, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "X-CSRF-Token": document.querySelector('meta[name="csrf-token"]').getAttribute("content"),
        },
        params: {
          ...searchQuery,
        },
      })
      .then(function (response) {
        return response.data;
      })
      .catch(function (error) {});
  };
}
