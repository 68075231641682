import React, { useState, useEffect, useRef } from "react";

export default function DesignationNotices(props) {
  const renderHinNotice = () => {
    if (props.disableHinNotice || !props.hasHin) return;

    return (
      <div className="stat-cards__notice" style={{ lineHeight: 1.5 }}>
        <span style={{ fontWeight: 500 }}>You can still save designations </span>
        but our records indicate the selected pharmacy does not have a HIN. You must register a HIN through
        <a href="https://www.hibcc.org/" target="_blank">
          <span style={{ fontWeight: 500, textDecoration: "underline" }}> HIBCC </span>
        </a>
        in order to ship 340B purchased drugs to this pharmacy.
        <a href="http://help.340besp.com/en/articles/4816982-hin-registration-overview" target="_blank">
          <span style={{ fontWeight: 500, textDecoration: "underline" }}> Learn more.</span>
        </a>
      </div>
    );
  };

  const renderGranteeExemptionNotice = () => {
    if (props.disableGranteeNotice || !props.isGrantee) return;

    return (
      <div className="stat-cards__notice stat-cards__notice--red stat-cards__notice--mb" style={{ lineHeight: 1.5 }}>
        The selected entity is exempted from this policy and is not required to make a single contract pharmacy
        designation. Any designation you make will not impact your access to 340B pricing.
        <a href={props.policy_doc_url} download={props.policy_doc_name}>
          <span style={{ fontWeight: 500, textDecoration: "underline", margin: "0px 5px" }}>
            Download Policy document
          </span>
        </a>
        Please reach out to
        <span style={{ fontWeight: 500 }}> support@340besp.com </span>
        if you have any additional questions or if you do not have access to 340B pricing for your contract pharmacies.
      </div>
    );
  };

  const renderEmptyCpsNotice = () => {
    if (!props.emptyCps) return;

    // var kind = props.hasMap ? "within 40 miles" : "within this limited distribution network";
    var kind = "within this limited distribution network";

    return (
      <div className="stat-cards__notice stat-cards__notice--blue stat-cards__notice--mb" style={{ lineHeight: 1.5 }}>
        {`Our records indicate the selected entity does not have any active contract pharmacy arrangements ${kind}.
          The entity must establish a contract pharmacy arrangement with a pharmacy ${kind} to access 340B pricing.
          Please reach out to support@340besp.com for further assistance on this.`}
      </div>
    );
  };

  const renderInHousePharmacyNotice = () => {
    if (props.disableInHouseNotice || !props.hasInHouseCp) return;

    return (
      <div className="stat-cards__notice stat-cards__notice--red stat-cards__notice--mb" style={{ lineHeight: 1.5 }}>
        Our records indicate the selected entity has a pharmacy capable of purchasing and dispensing 340B drugs to its
        patients. As a result, the entity is not eligible to designate a single contract pharmacy arrangement and any
        designation you make will not impact access to 340B pricing.
        <a href={props.policy_doc_url} download={props.policy_doc_name}>
          <span style={{ fontWeight: 500, textDecoration: "underline", margin: "0px 5px" }}>
            Download Policy document
          </span>
        </a>
        Please reach out to
        <span style={{ fontWeight: 500 }}> support@340besp.com </span>
        for more information or to clarify the entity's ability to dispense 340B purchased drugs to patients.
      </div>
    );
  };

  const renderNoticeHasWhollyOwnedExemption = () => {
    if (props.disableWhollyOwnedNotice || !props.hasWoDesignation) return;

    return (
      <div className="stat-cards__notice stat-cards__notice--red stat-cards__notice--mb" style={{ lineHeight: 1.5 }}>
        Our records indicate the selected entity has already been granted a wholly owned contract pharmacy exemption. As
        a result of this exemption, the entity is not eligible to designate a single contract pharmacy arrangement and
        any designation you make will not impact access to 340B pricing.
        <a href={props.policy_doc_url} download={props.policy_doc_name}>
          <span style={{ fontWeight: 500, textDecoration: "underline", margin: "0px 5px" }}>
            Download Policy document
          </span>
        </a>
        Please reach out to
        <span style={{ fontWeight: 500 }}> support@340besp.com </span>
        if you have any additional questions or if you do not have access to 340B pricing for your contract pharmacies.
      </div>
    );
  };

  return (
    <>
      {renderNoticeHasWhollyOwnedExemption()}
      {renderGranteeExemptionNotice()}
      {renderEmptyCpsNotice()}
      {renderInHousePharmacyNotice()}
      {renderHinNotice()}
    </>
  );
}
