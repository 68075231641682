import React, { useState, useRef, useEffect } from "react";
import _ from "lodash";

const DocumentBillboard = (props) => {
  const [submitting, setSubmitting] = useState(false);

  const renderLoader = () => {
    if (submitting) {
      return <div className="loading-indicator" style={{ margin: "0 10px 0 -28px" }}></div>;
    }
  };

  const canSubmit = () => {
    if (props.enableSubmit() && !submitting) {
      return false
    } else {
      return true
    }
  };

  const handleSubmit = () => {
    setSubmitting(true)
    document.getElementById("wholly_owned_application_form").submit();
  }

  return (
    <div className="register__billboard">
      <div className="register__billboard__title">Wholly Owned Exemptions</div>
      <div className="register__billboard__subtitle">All steps and fields are required *</div>
      <div className="register__billboard__step__container">
        <div className={props.completedAttachedDocuments ? "register__billboard__step register__billboard__step--complete" : "register__billboard__step"}>1</div>
        <div className="register__billboard__step__details">
          <div className="register__billboard__step__details__title">Documents</div>
          <div className="register__billboard__step__details__subtitle">Proof of ownership</div>
        </div>
      </div>
      <button className={props.enableSubmit() ? "btn" : "btn btn--disabled"} disabled={canSubmit()} type="submit" onClick={() => handleSubmit()}>
        {renderLoader()}
        Submit
      </button>
      <div className="register__billboard__footernote">
        Need Help? Contact Us
        <div className="register__billboard__footernote__contact">support@340besp.com</div>
      </div>
    </div>
  )

};

export default DocumentBillboard;
