import React, { Fragment, useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import ButtonGroup from "../../ButtonGroup";
import { Close, CalendarMonth } from "@mui/icons-material";
import moment from "moment";

import 'react-datepicker/dist/react-datepicker.css'

const DateSelector = (props) => {
  const dateMap = {
    "This Month": {
      start: moment().startOf("month").toDate(),
      end: moment().toDate(),
    },
    "Last 3 Months": {
      start: moment().startOf("month").subtract(3, "months").toDate(),
      end: moment().toDate(),
    },
    "Last 12 Months": {
      start: moment().startOf("month").subtract(12, "months").toDate(),
      end: moment().toDate(),
    },
  };

  const buttons = ["This Month", "Last 3 Months", "Last 12 Months"];
  const [activeButton, setActiveButton] = useState("This Month");
  const [startDate, setStartDate] = useState(dateMap["Last 12 Months"].start);
  const [endDate, setEndDate] = useState(dateMap["Last 12 Months"].end);

  useEffect(() => {
    setStartDate(dateMap[activeButton].start);
    setEndDate(dateMap[activeButton].end);

    props.setDateRange({
      start: moment(dateMap[activeButton].start).format("YYYY-MM-DD"),
      end: moment(dateMap[activeButton].end).format("YYYY-MM-DD"),
    });
  }, [activeButton]);

  useEffect(() => {
    props.setDateRange({
      start: moment(startDate).format("YYYY-MM-DD"),
      end: moment(endDate).format("YYYY-MM-DD"),
    });
  }, [startDate, endDate]);

  return (
    <Fragment>
      <ButtonGroup buttons={buttons} activeButton={activeButton} setActiveButton={setActiveButton} />
      <div className="flex__container flex__container--space-between">
        <div className="label label--grey">
          <div className="label__icon">
            <CalendarMonth fontSize="inherit" />
          </div>
          <div className="label__value">
            <DatePicker
              dateFormat="yyyy-MM-dd"
              selected={startDate}
              onChange={(date) => setStartDate(date)}
              selectsStart
            />
          </div>
        </div>
        <div className="label label--grey">
          <div className="label__icon">
            <CalendarMonth fontSize="inherit" />
          </div>
          <div className="label__value">
            <DatePicker dateFormat="yyyy-MM-dd" selected={endDate} onChange={(date) => setEndDate(date)} selectsStart />
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default DateSelector;
