import React, { useRef, useState, useEffect } from "react";
import ConfirmationModal from "./ConfirmationModal";
import Notice from "./Notice";

const TableItemDelete = (props) => {
  const [displayConfirmation, setDisplayConfirmation] = useState(false);
  const [notice, setNotice] = React.useState({
    kind: "error",
    open: false,
    message: "",
  });

  useEffect(() => {
    setDisplayConfirmation(false);
  }, [notice]);

  const deleteItem = () => {
    fetch(props.path, {
      method: "DELETE",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "X-CSRF-Token": document.querySelector('meta[name="csrf-token"]').getAttribute("content"),
      },
    })
      .then((res) => {
        if (res.status >= 200 && res.status <= 299) {
          return res.json();
        } else {
          throw res;
        }
      })
      .then((data) => {
        setNotice({
          kind: "success",
          open: true,
          message: data.notice,
        });

        setTimeout(() => {
          document.querySelector(`#itemID_${props.itemID}`).remove();
        }, 1000);
      })
      .catch((response) => {
        response.json().then((res) => {
          setNotice({
            kind: "error",
            open: true,
            message: res.error,
          });
        });
      });
  };

  return (
    <div>
      <div
        className="btn btn--sq"
        onClick={() => {
          setDisplayConfirmation(true);
        }}
      >
        <div className="solid solid-budicon-trash"></div>
      </div>
      <Notice details={notice} />
      <ConfirmationModal title={props.confirmationTitle} message={props.confirmationMessage} displayModal={displayConfirmation} setModalState={setDisplayConfirmation} action={deleteItem} />
    </div>
  );
};

export default TableItemDelete;
