import React, { useRef, useState, useEffect } from "react";
import _, { last } from "lodash";
import Post from "./Post";
import DayJS from "react-dayjs";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import moment from "moment";

const SheetJSFT = [".xlsx", ".xls", ".csv"];

const DataInput = (props) => {
  const [files, setFiles] = useState({ attached: [], selected: 0 });
  const [displayFileHeaders, setDisplayFileHeaders] = useState(true);

  const getFirstPosition = (attachments) => {
    const firstPos = _.minBy(attachments, "position");
    return firstPos.position;
  };

  const getNextPosition = () => {
    const lastPos = _.maxBy(files.attached, "position");
    return lastPos ? lastPos.position + 1 : 0;
  };

  const handleChange = (e) => {
    const attachments = e.target.files;
    const position = getNextPosition();
    props.handleFile(attachments[0], position);

    setFiles({ attached: [...files.attached, { position: position, file: attachments[0] }], selected: position });
  };

  const handleSelectedFile = (position) => {
    props.handleSelectedFile(position);

    setFiles({ attached: [...files.attached], selected: position });
  };

  // Should move this to parent
  // Parent component should hold attachments only
  const handleRemovefile = (event, position) => {
    event.stopPropagation();

    var cloneFiles = _.cloneDeep(files.attached);
    var attachments = _.reject(cloneFiles, ["position", position]);

    document.getElementById("submissionAttachments").value = "";

    props.removeSubmissionError(position);

    if (attachments.length) {
      var firstPos = getFirstPosition(attachments);
      setFiles({ attached: attachments, selected: firstPos });
      props.removeSelectedFile(position, firstPos);
    } else {
      setFiles({ attached: [], selected: 0 });
      props.reset();
    }
  };

  const renderfileHeaders = (position) => {
    if (props.fileHeaders && displayFileHeaders && position == files.selected) {
      const orderedfileHeaders = props.fileHeaders.sort((a, b) => a.localeCompare(b));

      return (
        <Droppable droppableId="attachedFile">
          {(provided, snapshot) => (
            <div ref={provided.innerRef} className="attachments__info__container">
              {orderedfileHeaders.map((title, i) => (
                <Draggable key={title} draggableId={title} index={i}>
                  {(provided, snapshot) => (
                    <div
                      key={i}
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                      className="tag tag--outline-bg-white tag--square tag--font-md tag--lowercase"
                    >
                      {title}
                    </div>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      );
    }
  };

  const renderAttachmentHighlight = (position) => {
    if (position == files.selected && props.filesWithErrors.includes(position)) {
      return "attachments__tile attachments__tile--space attachments__tile--selected--errors";
    } else if (position == files.selected) {
      return "attachments__tile attachments__tile--space attachments__tile--selected";
    } else {
      return "attachments__tile attachments__tile--space";
    }
  };

  const renderAttachmentStatus = (position) => {
    if (props.filesWithErrors.includes(position)) {
      return <div className="attachments__tile__status-tag attachments__tile__status-tag--errors">Errors</div>;
    } else {
      return <div className="attachments__tile__status-tag">Good</div>;
    }
  };

  // should rename position everywhere to ID - ID alias for position
  const getAttachmentByID = (id) => {
    return _.find(props.attachments, (data) => data.position == id);
  };

  const getMappingDetails = (id) => {
    var attachment = getAttachmentByID(id);

    if (attachment) {
      return attachment.mappingName;
    }
  };

  const renderAttachments = () => {
    return files.attached.map((attached, i) => (
      <div
        key={i}
        className={renderAttachmentHighlight(attached.position)}
        onClick={() => handleSelectedFile(attached.position)}
      >
        {renderAttachmentStatus(attached.position)}
        <div className="attachments__tile__icon attachments__tile__icon--push-bottom">
          <svg xmlns="http://www.w3.org/2000/svg" width="26.339" height="33.63" viewBox="0 0 26.339 33.63">
            <g transform="translate(-0.223)">
              <path
                d="M1.068,0H16.646l7.375,7.481V32.562a1.068,1.068,0,0,1-1.068,1.068H1.068A1.068,1.068,0,0,1,0,32.562V1.068A1.068,1.068,0,0,1,1.068,0Z"
                transform="translate(2.541)"
                fill="#def8ea"
              />
              <path d="M0,0,7.473,7.473h-5.9A1.573,1.573,0,0,1,0,5.9Z" transform="translate(19.089)" fill="#2bd67b" />
              <g transform="translate(0 14.401)">
                <rect width="23" height="10" rx="2" transform="translate(0.223 0.344)" fill="#24b668" />
                <text
                  transform="translate(6.223 7.344)"
                  fill="#fff"
                  fontSize="6"
                  fontFamily="Roboto-Bold, Roboto"
                  fontWeight="700"
                  letterSpacing="0.007em"
                >
                  <tspan x="0" y="0">
                    XLS
                  </tspan>
                </text>
              </g>
            </g>
          </svg>
        </div>
        <div className="attachments__tile__remove__container">
          <div
            className="attachments__tile__remove"
            onClick={(event) => {
              handleRemovefile(event, attached.position);
            }}
          >
            Remove
          </div>
        </div>
        <div className="attachments__tile__details attachments__tile__details--block">
          <div className="attachments__tile__details__filename">{attached.file.name}</div>
          <div className="attachments__tile__details__mapping-name">
            <span style={{ color: "#718291" }}>Selected mapping - </span>
            <span style={{ fontWeight: 700 }}>{getMappingDetails(attached.position)}</span>
          </div>
        </div>

        <div className="attachments__tile__actions__container">
          <div
            className="attachments__tile__actions"
            onClick={() => {
              setDisplayFileHeaders(!displayFileHeaders);
            }}
          >
            {displayFileHeaders ? "Hide Details" : "Show Details"}
          </div>
        </div>
        {renderfileHeaders(attached.position)}
      </div>
    ));
  };

  return (
    <div>
      <Post
        organization={props.organization}
        data={props.attachments}
        ndcList={props.ndcList}
        submissionPath={props.submissionPath}
        fieldMappingGroups={props.fieldMappingGroups}
        filesWithErrors={props.filesWithErrors}
      />

      <div className="draw__item">
        <div className="draw__item__title">Submission Details</div>
        <div className="task__container task__container--outline">
          <div className="task__item task__item--small">
            <div className="task__date-icon task__date-icon--navy">
              <div className="task__date-icon__date">{moment().format("DD")}</div>
              <div className="task__date-icon__month">{moment().format("MMM")}</div>
            </div>
            <div className="task__details">
              <div className="task__details__title">{moment().format("MMMM D")}</div>
              <div className="task__details__subtitle">Submission date</div>
            </div>

            <div className="task__item__footer">
              <span style={{ fontWeight: 700 }}>{files.attached.length}</span>
              <span>{files.attached.length == 1 ? " file attached" : " files attached"}</span>
            </div>
          </div>
        </div>
      </div>

      <div className="draw__section__header">
        <div className="draw__section__title">Attach files to Submit</div>
        <div className="draw__section__caddy">
          <div
            className={files.attached.length ? "btn btn--small" : "btn btn--small btn--pulse--loud"}
            onClick={() => {
              document.getElementById("submissionAttachments").click();
            }}
          >
            Attach
          </div>
          <input
            type="file"
            style={{ display: "none" }}
            className="form-control"
            id="submissionAttachments"
            accept={SheetJSFT}
            multiple={true}
            onChange={handleChange}
          />
        </div>
      </div>

      <div className="draw__section__container">{renderAttachments()}</div>
    </div>
  );
};

export default DataInput;
