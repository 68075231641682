import React from "react";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import verifyImage from "images/verify_account_illustration.png";
import Notice from "../Notice";

const CustomTextField = withStyles({
  root: {
    backgroundColor: "#ffffff",
    borderRadius: 8,
    "& label.Mui-focused": {
      color: "#3D71FF",
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: "green",
    },
    "& .MuiOutlinedInput-root": {
      borderRadius: 8,
      "& fieldset": {
        borderColor: "#DEE7EE",
      },
      "&:hover fieldset": {
        borderColor: "#DEE7EE",
      },
      "&.Mui-focused fieldset": {
        borderColor: "#3D71FF",
      },
    },
  },
})(TextField);

const EmailVerificationForm = (props) => {
  const [notice, setNotice] = React.useState({
    kind: "error",
    open: false,
    message: "",
  });

  const sendRegistrationToken = () => {
    fetch(props.path, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "X-CSRF-Token": document.querySelector('meta[name="csrf-token"]').getAttribute("content"),
      },
    })
      .then((res) => {
        if (res.status >= 200 && res.status <= 299) {
          return res.json();
        } else {
          throw Error(res.statusText);
        }
      })
      .then((data) => {
        setNotice({
          kind: "success",
          open: true,
          message: data.notice,
        });
      })
      .catch((error) => {
        setNotice({
          kind: "error",
          open: true,
          message: "Oh no - it looks like something went wrong, please try again.",
        });
      });
  };

  return (
    <form method="POST" action={props.submission_path}>
      <input type="hidden" name="authenticity_token" value={document.querySelector('meta[name="csrf-token"]').getAttribute("content")} />
      <div className="onboarding__content__form">
        <div className="onboarding__content__form__image">
          <img src={verifyImage} />
        </div>
        <div className="onboarding__content__form__title">We need to verify your registration</div>
        <div className="onboarding__content__form__actionbar" style={{ margin: "30px 0 0 0" }}>
          <p style={{ fontSize: 14 }}>
            <span style={{ color: "#0A162F", fontWeight: 500 }}>Don't have a registration code?</span>
            <br />
            <br />
            Generate and email a registration code to the Email Address you provided.
          </p>
          <div className="btn btn--outline btn--white btn--hover-blue" style={{ flexGrow: 1, marginLeft: 30 }} onClick={sendRegistrationToken}>
            Generate and email registration code
          </div>
        </div>
        <p style={{ fontSize: 12, color: "#0A162F", marginTop: 10 }}>Remember to check your spam folder if you do not see the email.</p>
        <div className="register__divider" style={{ margin: "20px 0" }} />
        <p style={{ color: "#3360D9" }}>
          <span style={{ color: "#3360D9", fontWeight: 500 }}>Already have a registration code?</span>
          <br />
          <br />
          Please enter your registration code in the form below to authenticate and verify your account.
        </p>

        <CustomTextField variant="outlined" margin="normal" required fullWidth id="twofa" label="Registration Code" name="registration_code" autoFocus />
        <div className="onboarding__content__form__footnote">Didn't recieve an email? Re-send a registration email or get in contact with us.</div>
        <div className="onboarding__content__form__actions onboarding__content__form__actions--margin-sm">
          <a href="/logout">
            <div className="btn btn--outline btn--white">Cancel and Logout</div>
          </a>
          <button className="btn" style={{ flexGrow: 1, marginLeft: 30 }} type="submit">
            Verify and Continue
          </button>
        </div>
      </div>
      <Notice details={notice} />
    </form>
  );
};

export default EmailVerificationForm;
