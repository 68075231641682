import React from "react";
import ArrowRight from "../../../Icons/ArrowRight";
import ArrowLeft from "../../../Icons/ArrowLeft";
import SignAndAttest from "./SignAndAttest";

const SubmissionPage = ({ attestation, setAttestation, showFirstPage, handleSubmitAttestation }) => {
  const isStatusComplete = attestation.status === "in_review" || attestation.status === "complete";

  if (!attestation.id_340b) return;

  return (
    <>
      <div className="pa--24 flex-grow overflow--y-auto">
        <div className="flex--col gap--24">
          <button className="pharm_cert_modal__back_btn flex align-center color--blue gap--4" onClick={showFirstPage}>
            <ArrowLeft size="large" />
            <span className="t--md t--500">Back to pharmacy list</span>
          </button>
          <div className="t--title">
            <div className="t--700 mb--8">Review</div>
            <div className="t--md">
              Please review the provided documentation prior to submitting. Once you are ready to submit the Pharmacy
              Certification for the covered entity listed, sign and attest below.
            </div>
          </div>
          <div>
            <div className="t--700 t--title mb--16">Submission Summary</div>
            <div className="flex border bg br--8 pa--8 gap--8">
              <div className="flex--col bg--white br--8 pa--16 gap--8" style={{ flex: 1 }}>
                <div className="t--sm t--700 t--subtitle t--upcase">340B ID</div>
                <div className="t--700 t--title">{attestation.id_340b}</div>
              </div>
              <div className="flex--col bg--white br--8 pa--16 gap--12" style={{ flex: 1 }}>
                <div className="t--sm t--700 t--subtitle t--upcase">Flagged Pharmacies</div>
                <div className="flex align-center" style={{ gap: 6 }}>
                  <span className="t--700 t--title">{attestation.in_house_certifications?.length}</span>
                  <span className="t--sm t--500 t--subtitle">Total</span>
                </div>
              </div>
            </div>
          </div>
          <div className="pt--24 border--top">
            <SignAndAttest attestation={attestation} setAttestation={setAttestation} readOnly={isStatusComplete} />
          </div>
        </div>
      </div>
      <div className="pharm_cert_modal__action_bar">
        <button
          className="btn btn--lg flex align-center gap--8"
          onClick={handleSubmitAttestation}
          disabled={!attestation.signed_by}
        >
          <span>Submit</span>
          <ArrowRight />
        </button>
      </div>
    </>
  );
};

export default SubmissionPage;
