import React from "react";
import ProgressBar from "../components/ProgressBar";

const StatsBar = ({ stats }) => {
  return (
    <div className="border br--8 pa--24">
      <div className="t--700 t--navy mb--16">Progress Summary</div>

      <div className="flex border br--8 pa--8 gap--8 bg--gray2">
        <div className="border br--8 pa--16 bg--white" style={{ flex: 1 }}>
          <div className="t--xsm t--700 t--subtitle t--upcase mb--2">Status</div>
          <div className="t--sm t--500 t--navyblue mb--8">Covered Entities Remaining</div>
          <div className="t--700 t--navyblue" style={{ fontSize: 18 }}>
            {stats.entities_remaining}
          </div>
        </div>

        <div className="border br--8 pa--16 bg--white" style={{ flex: 1 }}>
          <div className="t--xsm t--700 t--subtitle t--upcase mb--2">Progress</div>
          <div className="t--sm t--500 t--navyblue mb--8">Covered Entities Submitted</div>
          <div className="t--700 t--navyblue" style={{ fontSize: 18 }}>
            <div className="flex align-center" style={{ gap: 6 }}>
              <span>{stats.percentage_completed}%</span>
              <ProgressBar percent={`${stats.percentage_completed}%`} color="green" />
            </div>
          </div>
        </div>

        <div className="border br--8 pa--16 bg--white" style={{ flex: 1 }}>
          <div className="t--xsm t--700 t--subtitle t--upcase mb--2">Deadline</div>
          <div className="t--sm t--500 t--navyblue mb--8">Certification Due By</div>
          <div className="t--700 t--navyblue" style={{ fontSize: 18 }}>
            {stats.due_at}
          </div>
        </div>
      </div>
    </div>
  );
};

export default StatsBar;
