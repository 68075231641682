import React, { useState, useEffect, useRef } from "react";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";
import Notice from "../Notice";
import GrantTpaAccessForm from "./GrantTpaAccessForm";

const GrantTpaAccess = (props) => {
  const [entities, setEntities] = React.useState(props.entities.map((ce) => ({ ...ce, edit: false })));
  const [tpas, setTpas] = React.useState(props.entity_tpas);

  const [notice, setNotice] = React.useState({
    kind: "error",
    open: false,
    message: "",
  });

  const submitForm = (id, token) => {
    fetch(props.path, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "X-CSRF-Token": document.querySelector('meta[name="csrf-token"]').getAttribute("content"),
      },
      body: JSON.stringify({
        entity_id: id,
        access_key: token,
      }),
    })
      .then((res) => {
        if (res.status >= 200 && res.status <= 299) {
          res.json().then((json) => {
            setTpas(json);
            setNotice({
              kind: "success",
              open: true,
              message: "Success - access has been granted",
            });
          });
        } else {
          throw Error(res.statusText);
        }
      })
      .catch((error) => {
        setNotice({
          kind: "error",
          open: true,
          message: "Oh no - it looks like something went wrong, please try again.",
        });
      });
  };

  const deleteForm = (id, token) => {
    fetch(props.path, {
      method: "DELETE",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "X-CSRF-Token": document.querySelector('meta[name="csrf-token"]').getAttribute("content"),
      },
      body: JSON.stringify({
        entity_id: id,
        access_key: token,
      }),
    })
      .then((res) => {
        if (res.status >= 200 && res.status <= 299) {
          res.json().then((json) => {
            setTpas(json);
            setNotice({
              kind: "success",
              open: true,
              message: "Success - access has been revoked",
            });
          });
        } else {
          throw Error(res.statusText);
        }
      })
      .catch((error) => {
        setNotice({
          kind: "error",
          open: true,
          message: "Oh no - it looks like something went wrong, please try again.",
        });
      });
  };

  const renderForm = () => {
    return props.entities.map((entity) => {
      return (
        <div>
          <div className="line-divider" style={{ margin: "20px 0" }}></div>
          <div
            className="profile__container profile__container--justify-start"
            style={{ marginTop: 20, marginBottom: 20 }}
          >
            <div className="profile__avatar profile__avatar--space-right">
              <div className="profile__avatar__initials">{props.user_initials}</div>
            </div>
            <div className="profile__details">
              <div className="profile__details__name profile__details__name profile__details__name--bold">
                {entity.id_340b}
              </div>
              <div className="profile__details__email">{entity.entity_name}</div>
            </div>
          </div>
          <GrantTpaAccessForm
            entity={entity}
            key={entity.id_340b}
            submit={submitForm}
            delete={deleteForm}
            tpas={tpas.length ? tpas.filter((tpa) => tpa.id_340b === entity.id_340b) : []}
          />
        </div>
      );
    });
  };

  return (
    <div>
      <div className="card__details__section__title">TPA access tokens</div>
      <div className="card__details__section__note" style={{ marginBottom: 30 }}>
        Provide TPAs access to your <strong>Covered Entity's Data</strong>. Your TPA will need to provide you their 340B
        ESP Access Token. Add an Access Token below to grant access.
      </div>
      {renderForm()}
      <Notice details={notice} />
    </div>
  );
};

export default GrantTpaAccess;
