import React, { useState, useRef, useEffect } from "react";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import _ from "lodash";

const CustomTextField = withStyles({
  root: {
    backgroundColor: "transparent",
    borderRadius: 8,
    "& label.Mui-focused": {
      color: "#3D71FF",
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: "green",
    },
    "& .MuiOutlinedInput-root": {
      borderRadius: 8,
      backgroundColor: "#ffffff",
      "& fieldset": {
        borderColor: "#DEE7EE",
      },
      "&:hover fieldset": {
        borderColor: "#DEE7EE",
      },
      "&.Mui-focused fieldset": {
        borderColor: "#3D71FF",
      },
    },
  },
})(TextField);

const ContactDetails = (props) => {
  const invalidEmailDomains = ["gmail", "outlook", "yahoo", "hotmail"];
  const [hasError, setHasError] = useState(false);

  const isValidEmail = (val) => {
    if (val.includes("@") && !_.map(invalidEmailDomains, (domain) => val.includes(domain)).includes(true)) {
      setHasError(false);
      return true;
    } else {
      setHasError(true);
      return false;
    }
  };

  const renderForm = () => {
    return (
      <div>
        <div className="register__form__dual-row register__form__dual-row--pad-right">
          <CustomTextField
            label="First Name"
            margin="normal"
            variant="outlined"
            id="firstName"
            name="application[contact_first_name]"
            fullWidth
            required
            onChange={(event) => {
              if (event.target.value && event.target.value.length >= 3) {
                props.setCompletedFirstName(true);
              } else {
                props.setCompletedFirstName(false);
              }
            }}
          />
        </div>
        <div className="register__form__dual-row register__form__dual-row--pad-left">
          <CustomTextField
            label="Last Name"
            margin="normal"
            variant="outlined"
            id="lastName"
            name="application[contact_last_name]"
            fullWidth
            required
            onChange={(event) => {
              if (event.target.value && event.target.value.length >= 3) {
                props.setCompletedLastName(true);
              } else {
                props.setCompletedLastName(false);
              }
            }}
          />
        </div>
        <div className="register__form__requirements">
          <p>
            <span className="bold">Note: </span>
            Please provide your
            <span className="bold"> Work Email Address.</span>
            <br />
            {/* Designations are verified by authenticating the 340B covered entity domain with the email address provided. */}
          </p>
        </div>
        <CustomTextField
          label="Work Email Address"
          margin="normal"
          variant="outlined"
          id="email"
          name="application[contact_email]"
          type="email"
          fullWidth
          required
          error={hasError}
          helperText={hasError ? "Email is not valid - Please provide a valid Work Email Address for this covered entity." : null}
          onChange={(event) => {
            if (event.target.value && isValidEmail(event.target.value)) {
              props.setCompletedContactEmail(true);
            } else {
              props.setCompletedContactEmail(false);
            }
          }}
        />
      </div>
    )
  };

  return (
    renderForm()
  )

};

export default ContactDetails;
