import React, { useState, useRef, useEffect } from "react";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Checkbox from "@material-ui/core/Checkbox";
import Attachments from "../Attachments";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import BoehringerIngelheimDesignations from "../../../boehringer_ingelheim/designations/BoehringerIngelheimDesignations";
import AsyncSelect from "react-select/async";
import debounce from "debounce-promise";
import _ from "lodash";

const CustomTextField = withStyles({
  root: {
    backgroundColor: "transparent",
    borderRadius: 8,
    "& label.Mui-focused": {
      color: "#3D71FF",
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: "green",
    },
    "& .MuiOutlinedInput-root": {
      borderRadius: 8,
      backgroundColor: "#ffffff",
      "& fieldset": {
        borderColor: "#DEE7EE",
      },
      "&:hover fieldset": {
        borderColor: "#DEE7EE",
      },
      "&.Mui-focused fieldset": {
        borderColor: "#3D71FF",
      },
    },
  },
})(TextField);

const PublicDesignationsForm = (props) => {
  const [completedUserDetails, setCompletedUserDetails] = useState(false);
  const [authorized, setAuthorized] = React.useState(false);
  const [completedAttachedDocuments, setCompletedAttachedDocuments] = useState(false);
  const [userDetails, setUserDetails] = React.useState({
    firstName: null,
    lastName: null,
    email: null,
  });
  const [designations, setDesignations] = useState(props.designations.map((obj) => ({ ...obj, selected: true })));
  const [attachments, setAttachments] = useState([{ id: 1, filename: null, filesize: null, file: null }]);

  const invalidEmailDomains = ["gmail", "outlook", "yahoo", "hotmail"];

  useEffect(() => {
    userDetailsCompleted();
  }, [userDetails]);

  useEffect(() => {
    if (hasEmptyValues(attachments)) {
      setCompletedAttachedDocuments(false);
    } else {
      setCompletedAttachedDocuments(true);
    }
  }, [attachments]);

  const updateDesignationStatus = (designation, value) => {
    var clone_designations = _.cloneDeep(designations);

    _.find(clone_designations, (d) => d.id == designation.id).selected = value;

    setDesignations(clone_designations);
  };

  const userDetailsCompleted = () => {
    if (!isInvalidEmail() && userDetails.email && userDetails.firstName && userDetails.lastName) {
      setCompletedUserDetails(true);
    } else {
      if (completedUserDetails) setCompletedUserDetails(false);
    }
  };

  const isInvalidEmail = () => {
    if (userDetails.email) {
      if (
        userDetails.email.includes("@") &&
        !_.map(invalidEmailDomains, (domain) => userDetails.email.includes(domain)).includes(true)
      ) {
        return false;
      } else {
        return true;
      }
    }
  };

  const hasEmptyValues = (data) => {
    let values;

    values = _.flatten(data.map((obj) => _.values(obj)));

    return values.includes(null) || values.includes("");
  };

  const enableSubmit = () => {
    return completedUserDetails && authorized && completedAttachedDocuments;
  };

  const renderBillboard = () => {
    return (
      <div className="register__billboard">
        <div className="register__billboard__title">Designations</div>
        <div className="register__billboard__subtitle">All steps and fields are required *</div>
        <div className="register__billboard__step__container">
          <div className="register__billboard__step register__billboard__step--complete">1</div>
          <div className="register__billboard__step__details">
            <div className="register__billboard__step__details__title">Attest</div>
            <div className="register__billboard__step__details__subtitle">Confirm Policy</div>
          </div>
        </div>
        <div className="register__billboard__step__container">
          <div
            className={
              completedUserDetails
                ? "register__billboard__step register__billboard__step--complete"
                : "register__billboard__step"
            }
          >
            2
          </div>
          <div className="register__billboard__step__details">
            <div className="register__billboard__step__details__title">Contact Details</div>
            <div className="register__billboard__step__details__subtitle">Confirm details</div>
          </div>
        </div>
        <div className="register__billboard__step__container">
          <div
            className={
              authorized ? "register__billboard__step register__billboard__step--complete" : "register__billboard__step"
            }
          >
            3
          </div>
          <div className="register__billboard__step__details">
            <div className="register__billboard__step__details__title">Authorization</div>
            <div className="register__billboard__step__details__subtitle">Confirm authorization</div>
          </div>
        </div>
        <div className="register__billboard__step__container">
          <div
            className={
              completedAttachedDocuments
                ? "register__billboard__step register__billboard__step--complete"
                : "register__billboard__step"
            }
          >
            4
          </div>
          <div className="register__billboard__step__details">
            <div className="register__billboard__step__details__title">Proof of Ownership</div>
            <div className="register__billboard__step__details__subtitle">Upload documents</div>
          </div>
        </div>
        <button
          className={enableSubmit() ? "btn" : "btn btn--disabled"}
          disabled={enableSubmit() ? false : true}
          type="submit"
        >
          Submit
        </button>
        <div className="register__billboard__footernote">
          Need Help? Contact Us
          <div className="register__billboard__footernote__contact">support@340besp.com</div>
        </div>
      </div>
    );
  };

  const renderDesignations = () => {
    return (
      <>
        <div className="register__step__title">1. Attest</div>
        <div className="register__form register__form--large">
          <div className="register__form__requirements" style={{ marginTop: 0 }}>
            <p>
              <span className="bold">Attest: </span>
              To keep your exemptions in place please verify your entity remains eligible for this exemption and has the
              same corporate ownership, OR (ii) the covered entity wholly owns the identified contract pharmacy(ies).
            </p>
          </div>

          <table className="pages__data_table">
            <tbody>
              {designations.map((d, i) => (
                <tr key={i} className="sm">
                  <td className="t--md">
                    <div
                      className="t--sm t--500"
                      style={{ marginBottom: 5, textDecoration: d.selected ? "" : "line-through" }}
                    >
                      {d.name}
                    </div>
                    <div className="t--sm t--subtitle" style={{ textDecoration: d.selected ? "" : "line-through" }}>
                      {d.address}
                    </div>
                  </td>
                  <td className="t--md" style={{ textDecoration: d.selected ? "" : "line-through" }}>
                    {d.kind}
                  </td>
                  <td>
                    <div className="designation_form__section__group__value">
                      <input type="hidden" name={`existing_designations[${d.id}]`} value={d.selected} />
                      <div className="btn__option__group">
                        <div
                          className={!d.selected ? "btn btn--small btn--selected" : "btn btn--small"}
                          onClick={() => updateDesignationStatus(d, false)}
                        >
                          Reject
                        </div>
                        <div
                          className={d.selected ? "btn btn--small btn--selected" : "btn btn--small"}
                          onClick={() => updateDesignationStatus(d, true)}
                        >
                          Attest
                        </div>
                      </div>
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </>
    );
  };

  const renderAttachments = () => {
    return attachments.map((item, i) => {
      return (
        <div key={item.id}>
          <Attachments
            id={item.id}
            filename={item.filename}
            filesize={item.filesize}
            file={item.file}
            attachments={attachments}
            setAttachments={setAttachments}
          />
        </div>
      );
    });
  };

  const renderNote = () => {
    return (
      <p>
        <span className="bold">Note: </span>
        Please provide any updates to expired ownership documentation. Examples of proof of ownership documents include:
        <span className="bold"> Certificate of liability insurance </span>
        or
        <span className="bold"> State business licenses</span>
      </p>
    );
  };

  return (
    <div className="register__wrapper register__wrapper--large">
      <form
        method="POST"
        action={`/wholly_owned_applications/${props.token}/recertification`}
        id="designations_form"
        encType="multipart/form-data"
      >
        <div className="register__form__container register__form__container--large">
          <input type="hidden" name="entity_id" value={props.entity.id_340b} />
          {renderDesignations()}
          <div className="register__step__title">2. Enter Contact Details</div>
          <div className="register__form register__form--large">
            <div className="register__form__dual-row register__form__dual-row--pad-right">
              <CustomTextField
                label="First Name"
                margin="normal"
                variant="outlined"
                id="firstName"
                name="user[first_name]"
                fullWidth
                required
                onChange={(event) => {
                  setUserDetails({
                    ...userDetails,
                    firstName: event.target.value,
                  });
                }}
              />
            </div>
            <div className="register__form__dual-row register__form__dual-row--pad-left">
              <CustomTextField
                label="Last Name"
                margin="normal"
                variant="outlined"
                id="lastName"
                name="user[last_name]"
                fullWidth
                required
                onChange={(event) => {
                  setUserDetails({
                    ...userDetails,
                    lastName: event.target.value,
                  });
                }}
              />
            </div>
            <div className="register__form__requirements">
              <p>
                <span className="bold">Note: </span>
                Please provide your
                <span className="bold"> Work Email Address.</span>
                <br />
                Designations are verified by authenticating the 340B covered entity domain with the email address
                provided.
              </p>
            </div>
            <CustomTextField
              label="Work Email Address"
              margin="normal"
              variant="outlined"
              id="email"
              name="user[email]"
              type="email"
              fullWidth
              required
              error={isInvalidEmail()}
              helperText={
                userDetails.email && isInvalidEmail()
                  ? "Email is not valid - Please provide a valid Work Email Address for this covered entity."
                  : null
              }
              onChange={(event) => {
                setUserDetails({
                  ...userDetails,
                  email: event.target.value,
                });
              }}
            />
          </div>
          <div className="register__step__title">3. Authorize Your Designation</div>
          <div className="register__form register__form--large">
            <p>
              Are you authorized on behalf of the selected covered entity(s) to designate a single contract pharmacy
              with 340B ESP™?
            </p>
            <FormControlLabel
              control={
                <Checkbox
                  checked={authorized}
                  onChange={() => {
                    setAuthorized(!authorized);
                  }}
                  color="primary"
                />
              }
              label="I am authorized"
            />
          </div>
          <div className="register__step__title">4. Upload Proof of Ownership</div>
          <div className="register__form register__form--large register__form--no-mb">
            <div className="register__form__requirements" style={{ marginTop: 0 }}>
              {renderNote()}
            </div>
            {renderAttachments()}
            <div
              style={{
                margin: "15px 0",
                textDecoration: "underline",
                color: "#3246D3",
                cursor: "pointer",
                textAlign: "right",
              }}
              onClick={() => {
                setAttachments([
                  ...attachments,
                  { id: _.maxBy(attachments, "id").id + 1, filename: null, filesize: null, file: null },
                ]);
              }}
            >
              + Add Another File
            </div>
          </div>
        </div>
        {renderBillboard()}
      </form>
    </div>
  );
};

export default PublicDesignationsForm;
