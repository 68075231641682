import { useState } from "react";

const useMultiPageNavigation = (pages = []) => {
  const [currentPageIndex, setCurrentPageIndex] = useState(0);

  function nextPage() {
    setCurrentPageIndex((currIndex) => {
      if (currIndex >= pages.length - 1) return currIndex;
      return currIndex + 1;
    });
  }

  function prevPage() {
    setCurrentPageIndex((currIndex) => {
      if (currIndex <= 0) return currIndex;
      return currIndex - 1;
    });
  }

  function goToPage(index) {
    setCurrentPageIndex(index);
  }

  return {
    nextPage,
    prevPage,
    goToPage,
    currPage: pages[currentPageIndex],
    currPageIndex: currentPageIndex,
  };
};

export default useMultiPageNavigation;
