import React, { useState, useRef, useEffect } from "react";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import _ from "lodash";

const CustomTextField = withStyles({
  root: {
    backgroundColor: "transparent",
    borderRadius: 8,
    "& label.Mui-focused": {
      color: "#3D71FF",
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: "green",
    },
    "& .MuiOutlinedInput-root": {
      borderRadius: 8,
      backgroundColor: "#ffffff",
      "& fieldset": {
        borderColor: "#DEE7EE",
      },
      "&:hover fieldset": {
        borderColor: "#DEE7EE",
      },
      "&.Mui-focused fieldset": {
        borderColor: "#3D71FF",
      },
    },
  },
})(TextField);

const PharmacyInputs = (props) => {

  const findPharmacy = (obj) => {
    return _.find(obj, ['id', props.id])
  }

  const renderHinInput = () => {
    if (props.selectedManufacturer == "pfizer") return
    
    return (
      <CustomTextField
        label="HIN"
        margin="normal"
        variant="outlined"
        name={`application[wholly_owned_contract_pharmacies_attributes][][health_industry_number]`}
        fullWidth
        required
        helperText={"Must be 9 characters in length"}
        value={props.hin ? props.hin : ""}
        onChange={(event) => {
          let dataClone = _.cloneDeep(props.pharmacies);

          findPharmacy(dataClone).hin = event.target.value;
          props.setPharmacies(dataClone);
        }}
      />
    )
  }

  const renderInputs = () => {
    return (
      <div>
        <CustomTextField
          label="Pharmacy Name"
          margin="normal"
          variant="outlined"
          name={`application[wholly_owned_contract_pharmacies_attributes][][name]`}
          fullWidth
          required
          value={props.name ? props.name : ""}
          onChange={(event) => {
            let dataClone = _.cloneDeep(props.pharmacies);

            findPharmacy(dataClone).name = event.target.value;
            props.setPharmacies(dataClone);
          }}
        />
        <CustomTextField
          label="Pharmacy Address"
          margin="normal"
          variant="outlined"
          name={`application[wholly_owned_contract_pharmacies_attributes][][address]`}
          fullWidth
          required
          value={props.address ? props.address : ""}
          onChange={(event) => {
            let dataClone = _.cloneDeep(props.pharmacies);

            findPharmacy(dataClone).address = event.target.value;
            props.setPharmacies(dataClone);
          }}
        />
        <div className="register__form__triple-row register__form__triple-row--pad-right">
          <CustomTextField
            label="Pharmacy State"
            margin="normal"
            variant="outlined"
            name={`application[wholly_owned_contract_pharmacies_attributes][][state]]`}
            fullWidth
            required
            value={props.state ? props.state : ""}
            onChange={(event) => {
              let dataClone = _.cloneDeep(props.pharmacies);

              findPharmacy(dataClone).state = event.target.value;
              props.setPharmacies(dataClone);
            }}
          />
        </div>
        <div className="register__form__triple-row register__form__triple-row--pad-middle">
          <CustomTextField
            label="Pharmacy City"
            margin="normal"
            variant="outlined"
            name={`application[wholly_owned_contract_pharmacies_attributes][][city]`}
            fullWidth
            required
            value={props.city ? props.city : ""}
            onChange={(event) => {
              let dataClone = _.cloneDeep(props.pharmacies);

              findPharmacy(dataClone).city = event.target.value;
              props.setPharmacies(dataClone);
            }}
          />
        </div>
        <div className="register__form__triple-row register__form__triple-row--pad-left">
          <CustomTextField
            label="Pharmacy Zip"
            margin="normal"
            variant="outlined"
            name={`application[wholly_owned_contract_pharmacies_attributes][][zip_code]`}
            fullWidth
            required
            value={props.zip ? props.zip : ""}
            onChange={(event) => {
              let dataClone = _.cloneDeep(props.pharmacies);

              findPharmacy(dataClone).zip = event.target.value;
              props.setPharmacies(dataClone);
            }}
          />
        </div>
        <div className="register__form__triple-row register__form__triple-row--pad-right">
          <CustomTextField
            label="HRSA Pharmacy ID"
            margin="normal"
            variant="outlined"
            name={`application[wholly_owned_contract_pharmacies_attributes][][hrsa_id]`}
            fullWidth
            required
            value={props.hrsaId ? props.hrsaId : ""}
            onChange={(event) => {
              let dataClone = _.cloneDeep(props.pharmacies);

              findPharmacy(dataClone).hrsaId = event.target.value;
              props.setPharmacies(dataClone);
            }}
          />
        </div>
        <div className="register__form__triple-row register__form__triple-row--pad-middle">
          <CustomTextField
            label="DEA"
            margin="normal"
            variant="outlined"
            name={`application[wholly_owned_contract_pharmacies_attributes][][drug_enforcement_administration]`}
            fullWidth
            required
            value={props.dea ? props.dea : ""}
            onChange={(event) => {
              let dataClone = _.cloneDeep(props.pharmacies);

              findPharmacy(dataClone).dea = event.target.value;
              props.setPharmacies(dataClone);
            }}
          />
        </div>
        <div className="register__form__triple-row register__form__triple-row--pad-left">
          {renderHinInput()}
        </div>
        {props.pharmacies.length > 1 ? (<div
          style={{
            margin: "15px 0",
            textDecoration: "underline",
            color: "#718291",
            cursor: "pointer",
          }}
          onClick={() => {
            let dataClone = _.cloneDeep(props.pharmacies);
            _.remove(dataClone, (obj) => obj === findPharmacy(dataClone))

            props.setPharmacies(dataClone);
          }}
        >
          remove
        </div>) : (null)}

        <div className="register__divider" />
      </div>
    );
  };

  return (
    renderInputs()
  )

};

export default PharmacyInputs;
