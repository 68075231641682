import React, { useRef, useState, Fragment, useEffect } from "react";
import ProductBalanceHistoryChart from "./Charts/ProductBalanceHistoryChart";
import _ from "lodash";
import { Pie } from "@nivo/pie";
import CreditsDebitsChart from "./Charts/CreditsDebitsChart";
import moment from "moment";
import { Close } from "@mui/icons-material";
import Http from "./http";
import { numberWithCommas, wholeNumberWithCommas } from "../utils/NumberFormatter";

const AccountsModal = (props) => {
  const [balanceHistory, setBalanceHistory] = useState([]);
  const [productHistory, setProductHistory] = useState([]);
  const [submissions, setSubmissions] = useState();
  const [ledger, setLedger] = useState([]);

  useEffect(() => {
    if (props.searchQuery.grouping == "product_name") {
      var query = {
        id_340b: props.selectedAccount.id_340b,
        pid:
          props.searchQuery.pharmacy_type == "contract"
            ? props.selectedAccount.pharmacy_id
            : props.selectedAccount.in_house_pharmacy_id,
        product_name: props.selectedAccount.product_name,
        grouping: props.searchQuery.grouping,
        date_range: { start: props.searchQuery.date_range.start, end: props.searchQuery.date_range.end },
        manufacturer: props.selectedAccount.manufacturer,
        pharmacy_type: props.searchQuery.pharmacy_type,
      };
    }

    if (props.searchQuery.grouping == "chain_name" && props.selectedAccount.chain_name) {
      var query = {
        id_340b: props.selectedAccount.id_340b,
        chain_name: props.selectedAccount.chain_name,
        product_name: props.selectedAccount.product_name,
        grouping: props.searchQuery.grouping,
        date_range: { start: props.searchQuery.date_range.start, end: props.searchQuery.date_range.end },
        manufacturer: props.selectedAccount.manufacturer,
        pharmacy_type: props.searchQuery.pharmacy_type,
      };
    }

    if (props.searchQuery.grouping == "chain_name" && !props.selectedAccount.chain_name) {
      var query = {
        id_340b: props.selectedAccount.id_340b,
        pid:
          props.searchQuery.pharmacy_type == "contract"
            ? props.selectedAccount.pharmacy_id
            : props.selectedAccount.in_house_pharmacy_id,
        product_name: props.selectedAccount.product_name,
        grouping: props.searchQuery.grouping,
        date_range: { start: props.searchQuery.date_range.start, end: props.searchQuery.date_range.end },
        manufacturer: props.selectedAccount.manufacturer,
        pharmacy_type: props.searchQuery.pharmacy_type,
      };
    }

    var ledgerPath = props.searchQuery.pharmacy_type === "inhouse" ? props.inhouseLedgerPath : props.ledgerPath;
    var balancePath =
      props.searchQuery.pharmacy_type === "inhouse" ? props.inhouseBalanceHistoryPath : props.balanceHistoryPath;

    Http.fetchSubmissionDetails(props.submissionDetailsPath, query).then((res) => setSubmissions(res));
    Http.fetchBalanceByMonth(balancePath, query).then((res) => setBalanceHistory(res));
    Http.fetchLedger(ledgerPath, query).then((res) => setLedger(res));
  }, []);

  const renderOverDrawnBadge = () => {
    if (props.selectedAccount.status == "OVERDRAWN") {
      return (
        <div className="badge">
          <div className="badge__indicator badge__indicator--orange" />
          Negative Balance
        </div>
      );
    }
  };

  const renderCurrentBalance = () => {
    return (
      <div className="tile">
        <div className="flex__container flex__container--gutter">
          <div style={{ flex: 0.3 }}>
            <div className="tile__header">
              <div className="tile__header__details">
                <div className="tile__header__details__title t--lg">Current Unit Balance</div>
                <div className="tile__header__details__subtitle">
                  Updated - {moment().startOf("month").format("YYYY-MM-DD")}
                </div>
              </div>
            </div>
            <div className="t--xl">{numberWithCommas(props.selectedAccount.balance)}</div>
            <div className="tile__footer tile__footer--spread">{renderOverDrawnBadge()}</div>
          </div>
          <div style={{ height: 200, flex: 0.7 }}>
            <ProductBalanceHistoryChart data={balanceHistory.data} />
          </div>
        </div>
      </div>
    );
  };

  const renderSubmissions = () => {
    return (
      <div className="tile" style={{ flex: 1 }}>
        <div className="flex__container flex__container--gutter">
          <div style={{ flex: 0.4 }}>
            <div className="tile__header">
              <div className="tile__header__details">
                <div className="tile__header__details__title t--lg">Submitted Claims</div>
                <div className="tile__header__details__subtitle">Updated - {moment().format("YYYY-MM-DD")}</div>
              </div>
            </div>
            <div className="flex__container flex__container__col flex__container__col--gap-sm">
              <div className="t--xl">{submissions ? wholeNumberWithCommas(submissions?.total_conforming) : 0}</div>
              <div className="t--sm">Conforming</div>
              <div className="t--xl">{submissions ? wholeNumberWithCommas(submissions?.total_non_conforming) : 0}</div>
              <div className="t--sm">Non-Conforming</div>
            </div>
            <div className="tile__footer tile__footer--spread"></div>
          </div>
          <div style={{ display: "flex", justifyContent: "center", alignItems: "center", height: 200, flex: 0.7 }}>
            <Pie
              data={submissions ? submissions.data : []}
              width={200}
              height={180}
              margin={{ top: 20, right: 30, bottom: 0, left: 30 }}
              innerRadius={0.8}
              activeInnerRadiusOffset={4}
              activeOuterRadiusOffset={4}
              enableArcLabels={false}
              enableArcLinkLabels={false}
              colors={["#3246D3", "#0A162F"]}
              legends={[
                {
                  anchor: "top-left",
                  direction: "row",
                  justify: false,
                  translateX: -30,
                  translateY: -20,
                  itemWidth: 95,
                  itemHeight: 10,
                  symbolSpacing: 4,
                  symbolSize: 6,
                  symbolShape: "circle",
                },
              ]}
            />
          </div>
        </div>
      </div>
    );
  };

  const renderCreditsDebitsData = () => {
    if (ledger && ledger.transactions) {
      var data = [
        {
          id: "340B Purchases",
          data: _.map(ledger.transactions.debits, (value, key) => ({ x: key, y: Math.abs(value) })).slice(-6),
        },
        {
          id: "Submitted Units",
          data: _.map(ledger.transactions.credits, (value, key) => ({ x: key, y: Math.abs(value) })).slice(-6),
        },
      ];
    } else {
      var data = [];
    }

    return data;
  };

  const renderCreditsDebits = () => {
    return (
      <div className="tile" style={{ flex: 1 }}>
        <div className="flex__container flex__container--gutter">
          <div style={{ flex: 0.3 }}>
            <div className="tile__header">
              <div className="tile__header__details">
                <div className="tile__header__details__title t--lg">Submission Balance</div>
                <div className="tile__header__details__subtitle">
                  Update - {moment().startOf("month").format("YYYY-MM-DD")}
                </div>
              </div>
            </div>
          </div>
          <div style={{ height: 200, flex: 0.7 }}>
            <CreditsDebitsChart data={renderCreditsDebitsData()} />
          </div>
        </div>
      </div>
    );
  };

  const renderRows = () => {
    if (ledger && ledger.transactions) {
      var balance = 0;

      return _.map(ledger.transactions.credits, (value, key) => {
        balance += value + ledger.transactions.debits[key];

        return (
          <tr key={key} className="sm">
            <td className="t--md">{key}</td>
            <td className="t--sm t--500">{props.selectedAccount.product_name}</td>
            <td className="t--md t--500">{numberWithCommas(ledger.transactions.debits[key])}</td>
            <td className="t--md t--500">{numberWithCommas(value)}</td>
            <td className="t--md t--500">{numberWithCommas(value + ledger.transactions.debits[key])}</td>
            <td className="t--md t--500">{numberWithCommas(balance)}</td>
          </tr>
        );
      });
    }
  };

  const renderBalanceHistoryTable = () => {
    return (
      <div style={{ marginTop: 20 }}>
        <table className="primary">
          <thead>
            <tr className="sm">
              <th>Date</th>
              <th>Product Name</th>
              <th>340B Purchases</th>
              <th>Submitted Units</th>
              <th>Subtotal</th>
              <th>Balance</th>
            </tr>
          </thead>
          <tbody>{renderRows()}</tbody>
        </table>
      </div>
    );
  };

  const renderBadgePharmacyName = () => {
    if (props.selectedAccount.pharmacy_name) {
      return (
        <div className="badge badge--md color--blue" style={{ marginRight: 10 }}>
          {props.selectedAccount.pharmacy_name}
        </div>
      );
    }
  };

  const renderBadgePharmacyAddress = () => {
    if (props.selectedAccount.address) {
      return (
        <div className="badge badge--md color--blue" style={{ marginRight: 10 }}>
          {props.selectedAccount.address}
        </div>
      );
    }
  };

  const renderModal = () => {
    if (props.displayModal) {
      return (
        <div
          className="modal__background"
          style={{ alignItems: "flex-end" }}
          onClick={() => props.setDisplayModal(false)}
        >
          <div className="modal__close" onClick={() => props.setDisplayModal(false)}>
            <Close />
          </div>
          <div className="modal__panel animated fadeInUpScale" onClick={(e) => e.stopPropagation()}>
            <div className="container" style={{ maxWidth: 1080 }}>
              <div className="page-details__container">
                <div className="page-details__container__info">
                  <div className="flex__container flex__container--space-between">
                    <span>
                      <div className="page-details__title">Account Details</div>
                      <div className="t--lg mb-3">{props.entity}</div>
                      <div className="page-details__subtitle">
                        <div className="breadcrumbs__container">
                          <div className="breadcrumbs__crumb breadcrumbs__crumb__active">
                            <div className="badge badge--md color--blue" style={{ marginRight: 10 }}>
                              {props.selectedAccount.product_name}
                            </div>
                            <div className="badge badge--md color--blue" style={{ marginRight: 10 }}>
                              {props.selectedAccount.id_340b}
                            </div>
                            {renderBadgePharmacyName()}
                            {renderBadgePharmacyAddress()}
                          </div>
                        </div>
                      </div>
                    </span>
                  </div>
                </div>
              </div>
              <div
                className="content__container"
                style={{ width: "100%", minHeight: "100vh", height: "auto", overflow: "auto" }}
              >
                {renderCurrentBalance()}
                <div className="flex__container flex__container--gutter" style={{ marginTop: 20 }}>
                  {renderSubmissions()}
                  {renderCreditsDebits()}
                </div>
                {renderBalanceHistoryTable()}
              </div>
            </div>
          </div>
        </div>
      );
    }
  };

  return renderModal();
};

export default AccountsModal;
