import axios from "axios";
import React, { useMemo, useState, useEffect, Fragment } from "react";
import { ExpandMore, ExpandLess, MoreHoriz, ChevronRight } from "@material-ui/icons";
import { numberWithCommas } from "../utils/NumberFormatter";
import Notice from "../Notice";

import _ from "lodash";
import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  useReactTable,
  // ExpandedState,
  getExpandedRowModel,
  getPaginationRowModel,
} from "@tanstack/react-table";
import TableLoadingSkeleton from "../Reports/TableLoadingSkeleton";

export default function AccountsTable(props) {
  const [submitting, setSubmitting] = useState(false);
  const columnHelper = createColumnHelper();
  const [expanded, setExpanded] = useState({});
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [notice, setNotice] = React.useState({
    kind: "error",
    open: false,
    message: "",
  });

  const columns = useMemo(() => [
    columnHelper.accessor("id_340b", {
      header: "Covered Entity",
      cell: ({ row, getValue }) => {
        return (
          <>
            <div
              className="btn btn--sqr"
              style={{ marginRight: 10, verticalAlign: "middle" }}
              onClick={() => row.toggleExpanded()}
            >
              {row.getIsExpanded() ? <ExpandLess fontSize="small" /> : <ExpandMore fontSize="small" />}
            </div>
            <div className="table__text__stack">
              <div className="t--sm t--500">{getValue().toUpperCase()}</div>
              <div className="t--sm t--subtitle">{row.original.entity_name}</div>
            </div>
          </>
        );
      },
    }),
    columnHelper.display({
      header: "Products",
      cell: ({ row }) => {
        return (
          <div className="table__text__stack">
            <div className="t--sm t--500">{_(row.original.accounts).countBy("product_name").values().sum()}</div>
          </div>
        );
      },
    }),
    columnHelper.accessor("pharmacy_name", {
      header: "Pharmacy",
      cell: ({ row, getValue }) => {
        return (
          <div className="table__text__stack">
            <div className="t--sm t--500">{getValue().toUpperCase()}</div>
            <div className="t--sm t--subtitle">{row.original.address}</div>
          </div>
        );
      },
    }),
    columnHelper.accessor("Status", {
      header: "Status",
      cell: ({ row, getValue }) => {
        const status_counts = _.countBy(row.original.accounts, "status");

        return (
          <div className="badge">
            <div className="badge__indicator" />
            <div className="badge__item badge__item--t-lg">
              {status_counts["POSITIVE"] ? status_counts["POSITIVE"] : 0}
            </div>
            <div className="badge__indicator badge__indicator--orange" />
            <div className="badge__item badge__item--t-lg badge__item--last">
              {status_counts["NEGATIVE"] ? status_counts["NEGATIVE"] : 0}
            </div>
          </div>
        );
      },
    }),
    columnHelper.accessor("links", {
      header: "actions",
      cell: ({ row, getValue }) => {
        return (
          props.isContractPharmacy && (
            <div
              className="btn btn--sqr"
              style={{ position: "relative" }}
              onClick={(e) => setDropdownOpen(row.original.id)}
            >
              <MoreHoriz fontSize="small" />
              {renderDropdownMenu(row.original.id, row.original)}
            </div>
          )
        );
      },
    }),
  ]);

  const handleDropDownClose = (e) => {
    e.stopPropagation();
    setDropdownOpen(false);
  };

  const renderLoader = () => {
    return <div className="loading-indicator"></div>;
  };

  const handleDropDownSubmit = (e, row) => {
    e.stopPropagation();

    if (!submitting) {
      setSubmitting(true);

      axios
        .post(
          props.reviews_path,
          { id_340b: row.id_340b, pid: row.id },
          {
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
              "X-CSRF-Token": document.querySelector('meta[name="csrf-token"]').getAttribute("content"),
            },
          }
        )
        .then(() => {
          setNotice({
            kind: "success",
            open: true,
            message: "Successfully submitted for review",
          });

          setSubmitting(false);
        })
        .catch(() => {
          setNotice({
            kind: "error",
            open: true,
            message: "Error - please try again",
          });

          setSubmitting(false);
        });

      setDropdownOpen(false);
    }
  };

  const renderDropdownMenu = (pid, row) => {
    if (dropdownOpen && dropdownOpen == pid) {
      return (
        <div className="dropdown__container" style={{ top: 30, right: 0, left: "auto" }}>
          <div className="dropdown__header">
            <div className="dropdown__header__title">Options</div>
            <div className="dropdown__header__close" onClick={(e) => handleDropDownClose(e)}>
              <div className="solid solid-budicon-cross-ui" />
            </div>
          </div>
          <div style={{ marginBottom: 10, whiteSpace: "normal", textAlign: "left" }}>
            Is this an in-house pharmacy? Submit for review.
          </div>
          <div style={{ display: "flex" }}>
            <div
              className="btn btn--tiny btn--outline btn--white"
              style={{ marginRight: 10, flex: 0.8 }}
              onClick={(e) => handleDropDownClose(e)}
            >
              Cancel
            </div>
            <div
              className="btn btn--tiny"
              style={{ marginRight: 10, flex: 0.2 }}
              onClick={(e) => handleDropDownSubmit(e, row)}
            >
              {submitting ? renderLoader() : "Submit"}
            </div>
          </div>
        </div>
      );
    }
  };

  const table = useReactTable({
    data: props.accounts,
    columns,
    state: {
      expanded,
    },
    onExpandedChange: setExpanded,
    getExpandedRowModel: getExpandedRowModel(),
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    initialState: { pagination: { pageSize: 50 } },
  });

  const renderStatusBadge = (status) => {
    if (status == "NEGATIVE") {
      return (
        <div className="badge">
          <div className="badge__indicator badge__indicator--orange" />
          Negative Balance
        </div>
      );
    } else {
      return (
        <div className="badge">
          <div className="badge__indicator" />
          Positive Balance
        </div>
      );
    }
  };

  const renderAccountsTableSubcomponent = (account_rows) => {
    return (
      <table className="secondary" style={{ textAlign: "left" }}>
        <thead>
          <tr className="sm">
            <th className="rnd-l">Pharmacy</th>
            <th>Product Name</th>
            <th>Status</th>
            <th>Unit Balance</th>
            <th className="rnd-r">Actions</th>
          </tr>
        </thead>
        <tbody>{renderAccountRowsSubcomponent(account_rows)}</tbody>
      </table>
    );
  };

  const renderAccountRowsSubcomponent = (account_rows) => {
    return _.map(account_rows, (acc, i) => {
      return (
        <tr className="sm" key={i}>
          <td className="t--sm">
            <div className="table__text__stack">
              <div className="t--sm t--500">{acc.pharmacy_name}</div>
              <div className="t--sm t--subtitle">{acc.address}</div>
            </div>
          </td>
          <td className="t--sm t--500">{acc.product_name}</td>
          <td className="t--sm t--500">{renderStatusBadge(acc.status)}</td>
          <td className="t--500">{numberWithCommas(acc.balance)}</td>
          <td style={{ paddingRight: 0 }}>
            <div
              className="btn btn--light"
              onClick={() => {
                props.setSelectedAccount(acc);
                props.setDisplayModal(true);
              }}
            >
              View Details
            </div>
          </td>
        </tr>
      );
    });
  };

  const renderSubComponent = (row) => {
    if (row.original.accounts) {
      return renderAccountsTableSubcomponent(row.original.accounts);
    }
  };

  const renderRows = () => {
    if (props.isLoading) return <TableLoadingSkeleton rows={20} />;

    return table.getRowModel().rows.map((row) => {
      return (
        <Fragment key={row.id}>
          <tr className="sm">
            {row.getVisibleCells().map((cell) => (
              <td key={cell.id} className="t--md">
                {flexRender(cell.column.columnDef.cell, cell.getContext())}
              </td>
            ))}
          </tr>
          {row.getIsExpanded() && (
            <tr className="sm">
              <td colSpan={row.getVisibleCells().length}>{renderSubComponent(row)}</td>
            </tr>
          )}
        </Fragment>
      );
    });
  };

  const renderPaginationControls = () => {
    if (props.accounts?.length > 50) {
      return (
        <div className="flex__container" style={{ justifyContent: "flex-end", marginTop: 10 }}>
          <button
            className="btn btn--small"
            onClick={() => table.previousPage()}
            disabled={!table.getCanPreviousPage()}
          >
            Prev
          </button>
          <button
            className="btn btn--small"
            style={{ marginLeft: 10 }}
            onClick={() => table.nextPage()}
            disabled={!table.getCanNextPage()}
          >
            Next
          </button>
        </div>
      );
    }
  };

  return (
    <div>
      <table className="primary">
        <thead>
          {table.getHeaderGroups().map((headerGroup) => (
            <tr key={headerGroup.id} className="sm">
              {headerGroup.headers.map((header) => (
                <th key={header.id}>
                  {header.isPlaceholder ? null : flexRender(header.column.columnDef.header, header.getContext())}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody>{renderRows()}</tbody>
      </table>
      {renderPaginationControls()}
      <Notice details={notice} />
    </div>
  );
}
