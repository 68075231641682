import React from 'react';
import Tooltip from '@material-ui/core/Tooltip';
import Fade from '@material-ui/core/Fade';
import { withStyles } from '@material-ui/core/styles';

const CustomTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: "#fff",
    color: "#25265e",
    maxWidth: "225px",
    fontSize: "12px",
    border: '1px solid #dadde9',
    boxShadow: "0 6px 14px 0 rgba(76, 84, 153, 0.2)",
    borderRadius: "8px"
  },
}))(Tooltip);

const badges = {
  lookback: {
    description: "45 Day Look Back Period",
    icon: "preview",
    label: "45 Day"
  },
  lookback_14: {
    description: "14 Day Look Back Period",
    icon: "preview",
    label: "14 Day"
  },
  cpDesignations: {
    description: "1 CP Designation",
    icon: "counter_1",
    label: "1 CP"
  },
  claimsCollection: {
    description: "Claims Required",
    icon: "data_check",
    label: "Claims"
  },
  geofence: {
    description: "40 Mile Radius Geofencing",
    icon: "share_location",
    label: "Geofence"
  },
  exemptions: {
    description: "Grantee Exemption",
    icon: "apartment",
    label: "Exemption"
  },
  whollyOwned: {
    description: "Wholly Owned",
    icon: "local_pharmacy",
    label: "W.O."
  }
};

const Badge = ({ tooltipHeader, tooltipDescription, tooltipBody, children }) => {
  return (
    <CustomTooltip
      TransitionComponent={Fade}
      TransitionProps={{ timeout: 350 }}
      placement="top-start"
      title={
        <div className="policy-badges__badge__content">
          <div className="policy-badges__badge__content__tooltip-header">{tooltipHeader}</div>
          <div className="policy-badges__badge__content__tooltip-description">{tooltipDescription}</div>
          <div className="policy-badges__badge__content__tooltip-body">{tooltipBody}</div>
        </div>
      }
    >
      <div className="policy-badges__badge">
        {children}
      </div>
    </CustomTooltip>
  );
};

const PolicyBadges = ({ manufacturer }) => {
  return (
    <div className="policy-badges">
      {Object.entries(manufacturer?.policies).map(([key, value]) => {
        const { description, icon, label } = badges[key];

        return (
          <Badge
            key={key}
            tooltipHeader={manufacturer.label}
            tooltipDescription={description}
            tooltipBody={value}
          >
            <span className="material-symbols-rounded size-22">{icon}</span>
            <span className="policy-badges__badge__label">{label}</span>
          </Badge>
        );
      })}
    </div>
  );
};

export default PolicyBadges;