import { SHA3 } from "sha3";
import _ from "lodash";

self.onmessage = (e) => {
  console.log("starting worker");

  const hashedData = hashData(
    e.data.requiredShape,
    e.data.fieldMapping,
    e.data.csv,
    e.data.salt
  );

  self.postMessage(hashedData);
};

const hashData = (requiredShape, fieldMapping, data, salt) => {
  const hash = new SHA3(256);

  const filterData = (data) => {
    console.log("starting data");
    var schemaContent = [];
    for (let i = 0, l = data.length; i < l; i++) {
      // const schema = _.cloneDeep(requiredShape);
      const schema = Object.assign({}, requiredShape);

      if (i % 100000 == 0) {
        console.log(i);
      }

      _.map(requiredShape, (value, key) => {
        schema[key] = formatValue(key, data[i][fieldMapping[key]]);
      });

      schemaContent.push(schema);
    }

    return schemaContent;
  };

  const formatValue = (schemaColumnName, value) => {
    if (["date_of_service", "date_prescribed"].includes(schemaColumnName)) {
      value = formatDate(value);
    }

    if (
      ["date_of_service", "date_prescribed", "rx_number"].includes(
        schemaColumnName
      )
    ) {
      return hashValue(value);
    } else {
      return value;
    }
  };

  const hashValue = (value) => {
    return generateSecureHash(value);
  };

  const formatDate = (value) => {
    // return early if not a date
    if (isNaN(Date.parse(value))) return;

    var date = new Date(value);

    return date.toISOString().split("T")[0];
  };

  const generateSecureHash = (value) => {
    hash.reset();

    if (!value) return;
    const saltedValue = value + salt;

    hash.update(saltedValue);

    return hash.digest("hex");
  };

  return filterData(data);
};
