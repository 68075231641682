import React, { useState, useEffect } from "react";
import ArrowLeft from "../../../Icons/ArrowLeft";
import CheckCircleOutline from "../../../Icons/CheckCircleOutline";
import CheckCircle from "../../../Icons/CheckCircle";
import Http from "../../http";
import FileRow from "../../components/FileRow";

const CheckCircleIcon = ({ isSubmitted }) => {
  const [icon, colorClass] = isSubmitted
    ? [<CheckCircle size="large" />, "color--blue"]
    : [<CheckCircleOutline size="large" />, "color--gray4"];

  return <div className={colorClass}>{icon}</div>;
};

const PharmacyFileList = ({
  organizationId,
  entityId,
  pharmacy,
  entity,
  showFirstPage,
  getConfirmationIfNeeded,
  setNotice,
  attestations,
  setAttestations,
}) => {
  const [attachments, setAttachments] = useState({
    pharmacy_license: null,
    additional_documents: [],
  });

  const [submitDisabled, setSubmitDisabled] = useState(!attachments.pharmacy_license);
  const [buttonText, setButtonText] = useState("Upload");

  useEffect(() => {
    setSubmitDisabled(!attachments.pharmacy_license);
  }, [attachments.pharmacy_license]);

  function handleFileUpload(type, file) {
    setAttachments((prevAttachments) => {
      const newAttachedFiles = { ...prevAttachments };

      if (type === "required") {
        newAttachedFiles.pharmacy_license = file;
      } else {
        newAttachedFiles.additional_documents = [...prevAttachments.additional_documents, file];
      }

      return newAttachedFiles;
    });
  }

  function handleFileDelete(type, index) {
    setAttachments((prevAttachments) => {
      const newAttachedFiles = { ...prevAttachments };

      if (type === "required") {
        newAttachedFiles.pharmacy_license = null;
      } else {
        newAttachedFiles.additional_documents = newAttachedFiles.additional_documents.filter((_, i) => i !== index);
      }

      return newAttachedFiles;
    });
  }

  function onUploadComplete() {
    setSubmitDisabled(false);
    setButtonText("Upload");
    showFirstPage();
  }

  async function handleSubmit() {
    if (submitDisabled) return;

    setSubmitDisabled(true);
    setButtonText("Uploading...");

    Http.upload(
      organizationId,
      entityId,
      entity.id_340b,
      pharmacy.npi,
      attachments,
      attestations,
      setAttestations,
      setNotice,
      onUploadComplete
    );
  }

  return (
    <>
      <div className="pa--24 flex-grow overflow--y-auto">
        <div className="flex--col gap--24">
          <button
            className="pharm_cert_modal__back_btn flex align-center color--blue gap--4"
            onClick={() => getConfirmationIfNeeded(showFirstPage)}
          >
            <ArrowLeft size="large" />
            <span className="t--md t--500">Back to pharmacy list</span>
          </button>
          <div className="t--title">
            <div className="t--700 mb--8">Upload Documentation</div>
            <div className="t--md">
              Please attach the pharmacy license for the pharmacy listed below. Additional documents are also supported,
              but not required. Note that any attached documents will not be saved until you click Upload.
            </div>
          </div>
          <div className="bg--gray2 border br--8 pa--8">
            <div className="flex bg--white br--8 pa--12 gap--8">
              <CheckCircleIcon isSubmitted={!!attachments.pharmacy_license} />
              <div className="flex--col flex-grow">
                <div className="t--md t--700 t--title mb--4">{pharmacy.npi}</div>
                <div className="t--sm t--subtitle mb--8">{pharmacy.pharmacy_address}</div>
                <div className="border br--8 pl--12 pr--12">
                  <FileRow
                    id={`required-file-${pharmacy.id}`}
                    hasFileAttached={Boolean(attachments.pharmacy_license)}
                    fileName={attachments.pharmacy_license?.name}
                    type="required"
                    handleFileUpload={handleFileUpload}
                    handleFileDelete={handleFileDelete}
                  />
                  {attachments.additional_documents?.map((file, i) => (
                    <FileRow
                      key={`${file}-${pharmacy.id}-${i}`}
                      id={`${file}-${pharmacy.id}-${i}`}
                      hasFileAttached={true}
                      fileName={file.name}
                      type="additional"
                      index={i}
                      handleFileUpload={handleFileUpload}
                      handleFileDelete={handleFileDelete}
                    />
                  ))}
                  <FileRow
                    id={`additional-file-${pharmacy.id}`}
                    type="additional"
                    handleFileUpload={handleFileUpload}
                    handleFileDelete={handleFileDelete}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="pharm_cert_modal__action_bar">
        <button className="btn btn--lg flex align-center gap--8" onClick={handleSubmit} disabled={submitDisabled}>
          <span>{buttonText}</span>
        </button>
      </div>
    </>
  );
};

export default PharmacyFileList;
