import React, { useState, useRef, useEffect, Fragment, useMemo } from "react";
import { ExpandMore, GetApp, ChevronRight, UnfoldMore } from "@material-ui/icons";
import _ from "lodash";
import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  useReactTable,
  getExpandedRowModel,
  getSortedRowModel,
} from "@tanstack/react-table";
import { useVirtual } from "react-virtual";
import TableLoadingSkeleton from "../TableLoadingSkeleton";

export default function AtRiskTable(props) {
  const tableContainerRef = useRef();

  const [sorting, setSorting] = useState([]);

  const columnHelper = createColumnHelper();

  const columns = [
    columnHelper.accessor("manufacturer", {
      header: "Mfr",
      enableSorting: false,
      cell: ({ renderValue }) => <div className="t--sm t--500">{renderValue()}</div>,
    }),
    columnHelper.accessor((row) => row.entity, {
      header: "Entity",
      enableSorting: false,
      cell: ({ row, getValue }) => {
        return <div className="t--sm t--500">{getValue().id_340b}</div>;
      },
    }),
    columnHelper.accessor((row) => row.pharmacy, {
      header: "Pharmacy",
      enableSorting: false,
      cell: ({ row, getValue }) => {
        return (
          <div>
            <div className="t--sm t--500">{getValue().pharmacy_name}</div>
            <div className="t--sm t--subtitle">{getValue().address}</div>
          </div>
        );
      },
    }),
    columnHelper.accessor("cp_balance", {
      header: "CP Balance",
      enableSorting: false,
      cell: ({ renderValue }) => <div className="t--sm t--500">{renderValue()}</div>,
    }),
    columnHelper.accessor("ih_balance", {
      header: "Inhouse Balance",
      enableSorting: false,
      cell: ({ renderValue }) => {
        return (
          <div
            className="t--sm t--500"
            style={{ display: "inline-block", verticalAlign: "middle", position: "relative" }}
          >
            {renderValue()}
          </div>
        );
      },
    }),
    columnHelper.accessor("type", {
      header: "Type",
      enableSorting: false,
      cell: ({ renderValue }) => <div className="t--sm t--500">{renderValue()}</div>,
    }),
    columnHelper.accessor((row) => row.status, {
      header: "Status",
      enableSorting: false,
      cell: ({ row, getValue }) => {
        return (
          <div className="badge">
            <div className="badge__indicator badge__indicator--orange" />
            {getValue()}
          </div>
        );
      },
    }),
  ];

  const table = useReactTable({
    data: props.pharmacies,
    columns,
    state: {
      sorting,
    },
    onSortingChange: setSorting,
    getExpandedRowModel: getExpandedRowModel(),
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
  });

  const { rows } = table.getRowModel();

  const rowVirtualizer = useVirtual({
    parentRef: tableContainerRef,
    size: props.pharmacies.length,
    overscan: 10,
  });

  const { virtualItems: virtualRows, totalSize } = rowVirtualizer;

  const renderVirtualRows = () => {
    return virtualRows.map((virtualRow) => {
      const row = rows[virtualRow.index];

      return (
        <tr key={row.id} className="sm">
          {row.getVisibleCells().map((cell) => {
            return (
              <td key={cell.id} className="t--sm">
                {flexRender(cell.column.columnDef.cell, cell.getContext())}
              </td>
            );
          })}
        </tr>
      );
    });
  };

  const paddingTop = virtualRows.length > 0 ? virtualRows?.[0]?.start || 0 : 0;
  const paddingBottom = virtualRows.length > 0 ? totalSize - (virtualRows?.[virtualRows.length - 1]?.end || 0) : 0;

  return (
    <>
      <div ref={tableContainerRef} className="virtual__table__reports__container">
        <table className="primary">
          <thead>
            {table.getHeaderGroups().map((headerGroup) => (
              <tr key={headerGroup.id} className="sm">
                {headerGroup.headers.map((header) => (
                  <th key={header.id}>
                    {header.isPlaceholder ? null : flexRender(header.column.columnDef.header, header.getContext())}
                    {header.column.getCanSort() && (
                      <span
                        style={{ fontSize: 12, display: "inline-flex", verticalAlign: "top" }}
                        onClick={() => header.column.toggleSorting()}
                      >
                        <UnfoldMore fontSize="inherit" color="inherit" />
                      </span>
                    )}
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody>
            {paddingTop > 0 && (
              <tr>
                <td style={{ height: `${paddingTop}px` }} />
              </tr>
            )}
            {props.isLoading ? <TableLoadingSkeleton rows={10} columns={7} /> : renderVirtualRows()}
            {paddingBottom > 0 && (
              <tr>
                <td style={{ height: `${paddingBottom}px` }} />
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </>
  );
}
