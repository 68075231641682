import React, { useState, useRef, useEffect } from "react";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";

const CustomTextField = withStyles({
  root: {
    backgroundColor: "transparent",
    borderRadius: 8,
    "& label.Mui-focused": {
      color: "#3D71FF",
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: "green",
    },
    "& .MuiOutlinedInput-root": {
      borderRadius: 8,
      backgroundColor: "#ffffff",
      "& fieldset": {
        borderColor: "#DEE7EE",
      },
      "&:hover fieldset": {
        borderColor: "#DEE7EE",
      },
      "&.Mui-focused fieldset": {
        borderColor: "#3D71FF",
      },
    },
  },
})(TextField);

const ResetPasswordPath = (props) => {
  const [passwordIsMasked, setPasswordIsMasked] = React.useState(true);
  const [completedUserDetails, setCompletedUserDetails] = useState(false);
  const [userDetails, setUserDetails] = React.useState({
    password: "",
  });

  useEffect(() => {
    userDetailsCompleted();
  }, [userDetails]);

  const userDetailsCompleted = () => {
    if (isValidPassword(userDetails.password)) {
      setCompletedUserDetails(true);
    } else {
      if (completedUserDetails) setCompletedUserDetails(false);
    }
  };

  const hasAtLeaseOneLower = (val) => {
    return val.match(/.*[a-z].*/) != null;
  };

  const hasAtLeaseOneUpper = (val) => {
    return val.match(/.*[A-Z].*/) != null;
  };

  const hasAtLeaseOneNumber = (val) => {
    return val.match(/.*[0-9].*/) != null;
  };

  const hasAtLeaseOneSpecial = (val) => {
    return val.match(/.*[\W_].*/) != null;
  };

  const hasAtLeaseEightChars = (val) => {
    return val.length >= 8;
  };

  const isValidPassword = (val) => {
    if (val) {
      return (
        hasAtLeaseOneLower(val) &&
        hasAtLeaseOneUpper(val) &&
        hasAtLeaseOneNumber(val) &&
        hasAtLeaseOneSpecial(val) &&
        hasAtLeaseEightChars(val)
      );
    }
  };

  const togglePasswordMask = () => {
    setPasswordIsMasked(!passwordIsMasked);
  };

  const enableSubmit = () => {
    return completedUserDetails;
  };

  return (
    <div>
      <form method="POST" action={props.path}>
        <input name="_method" type="hidden" value="put" />
        <CustomTextField
          label="New Password"
          margin="normal"
          variant="outlined"
          id="password"
          name="user[password]"
          type={passwordIsMasked ? "password" : "text"}
          error={
            userDetails.password
              ? !isValidPassword(userDetails.password)
              : false
          }
          fullWidth
          required
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <div style={{ cursor: "pointer" }} onClick={togglePasswordMask}>
                  <span
                    style={{
                      color: "#718291",
                      fontSize: 14,
                      marginRight: 10,
                      verticalAlign: "top",
                      lineHeight: "20px",
                    }}
                  >
                    {passwordIsMasked ? "show" : "hide"}
                  </span>
                  <i
                    style={{ fontSize: 20 }}
                    className="solid solid-budicon-eye-sight"
                  />
                </div>
              </InputAdornment>
            ),
          }}
          onChange={(event) => {
            setUserDetails({
              ...userDetails,
              password: event.target.value,
            });
          }}
        />
        <div className="register__form__requirements">
          <ul>
            <li
              className={
                hasAtLeaseOneLower(userDetails.password) ? "success" : null
              }
            >
              One lowercase character
            </li>
            <li
              className={
                hasAtLeaseOneUpper(userDetails.password) ? "success" : null
              }
            >
              One uppercase character
            </li>
            <li
              className={
                hasAtLeaseOneNumber(userDetails.password) ? "success" : null
              }
            >
              One number
            </li>
          </ul>
        </div>
        <div className="register__form__requirements">
          <ul>
            <li
              className={
                hasAtLeaseOneSpecial(userDetails.password) ? "success" : null
              }
            >
              One special character
            </li>
            <li
              className={
                hasAtLeaseEightChars(userDetails.password) ? "success" : null
              }
            >
              8 characters minimum
            </li>
          </ul>
        </div>

        <div className="register__form__actions">
          <a href="/">
            <div className="btn btn--outline btn--white">Cancel</div>
          </a>
          <button
            className={enableSubmit() ? "btn" : "btn btn--disabled"}
            disabled={enableSubmit() ? false : true}
            style={{ flexGrow: 1, marginLeft: 30 }}
            type="submit"
          >
            Reset Password
          </button>
        </div>
      </form>
    </div>
  );
};

export default ResetPasswordPath;
