import React, { useState, useRef, useEffect } from "react";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import BoehringerIngelheimDesignations from "../../../boehringer_ingelheim/designations/BoehringerIngelheimDesignations";
import AsyncSelect from "react-select/async";
import debounce from "debounce-promise";
import _ from "lodash";

const CustomTextField = withStyles({
  root: {
    backgroundColor: "transparent",
    borderRadius: 8,
    "& label.Mui-focused": {
      color: "#3D71FF",
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: "green",
    },
    "& .MuiOutlinedInput-root": {
      borderRadius: 8,
      backgroundColor: "#ffffff",
      "& fieldset": {
        borderColor: "#DEE7EE",
      },
      "&:hover fieldset": {
        borderColor: "#DEE7EE",
      },
      "&.Mui-focused fieldset": {
        borderColor: "#3D71FF",
      },
    },
  },
})(TextField);

const PublicDesignationsForm = (props) => {
  const [hasInhousePharmacy, setHasInhousePharmacy] = useState();
  const [attested, setAttested] = useState(false);
  const [designations, setDesignations] = useState(props.designations.map((obj) => ({ ...obj, selected: true })));

  const [completedUserDetails, setCompletedUserDetails] = useState(false);
  const [authorized, setAuthorized] = React.useState(false);
  const [completedDesignations, setCompletedDesignations] = useState(true);
  const [userDetails, setUserDetails] = React.useState({
    firstName: null,
    lastName: null,
    email: null,
  });

  const invalidEmailDomains = ["gmail", "outlook", "yahoo", "hotmail"];

  useEffect(() => {
    userDetailsCompleted();
  }, [userDetails]);

  const hasRemovedRetail = () => {
    return _.filter(designations, (des) => des.selected === false && des.kind === "retail").length > 0;
  };

  const hasRemovedSpecialty = () => {
    return _.filter(designations, (des) => des.selected === false && des.kind === "ldn").length > 0;
  };

  const handleAttested = (value) => {
    setAttested(true);
    setHasInhousePharmacy(value);
  };

  const updateDesignationStatus = (designation, value) => {
    var clone_designations = _.cloneDeep(designations);

    _.find(clone_designations, (d) => d.id == designation.id).selected = value;

    setDesignations(clone_designations);
  };

  const userDetailsCompleted = () => {
    if (!isInvalidEmail() && userDetails.email && userDetails.firstName && userDetails.lastName) {
      setCompletedUserDetails(true);
    } else {
      if (completedUserDetails) setCompletedUserDetails(false);
    }
  };

  const isInvalidEmail = () => {
    if (userDetails.email) {
      if (
        userDetails.email.includes("@") &&
        !_.map(invalidEmailDomains, (domain) => userDetails.email.includes(domain)).includes(true)
      ) {
        return false;
      } else {
        return true;
      }
    }
  };

  const enableSubmit = () => {
    return attested && completedUserDetails && authorized && completedDesignations;
  };

  const renderDesignationStep = () => {
    if (hasRemovedRetail() || hasRemovedSpecialty()) {
      return (
        <div className="register__billboard__step__container">
          <div
            className={
              completedDesignations
                ? "register__billboard__step register__billboard__step--complete"
                : "register__billboard__step"
            }
          >
            5
          </div>
          <div className="register__billboard__step__details">
            <div className="register__billboard__step__details__title">Pharmacy Designations</div>
            <div className="register__billboard__step__details__subtitle">Make designations</div>
          </div>
        </div>
      );
    }
  };

  const renderBillboard = () => {
    return (
      <div className="register__billboard">
        <div className="register__billboard__title">Designations</div>
        <div className="register__billboard__subtitle">All steps and fields are required *</div>
        <div className="register__billboard__step__container">
          <div
            className={
              attested ? "register__billboard__step register__billboard__step--complete" : "register__billboard__step"
            }
          >
            1
          </div>
          <div className="register__billboard__step__details">
            <div className="register__billboard__step__details__title">Attest</div>
            <div className="register__billboard__step__details__subtitle">Confirm policy</div>
          </div>
        </div>
        <div className="register__billboard__step__container">
          <div
            className={
              attested ? "register__billboard__step register__billboard__step--complete" : "register__billboard__step"
            }
          >
            2
          </div>
          <div className="register__billboard__step__details">
            <div className="register__billboard__step__details__title">Confirm Designations</div>
            <div className="register__billboard__step__details__subtitle">Confirm designations</div>
          </div>
        </div>
        <div className="register__billboard__step__container">
          <div
            className={
              completedUserDetails
                ? "register__billboard__step register__billboard__step--complete"
                : "register__billboard__step"
            }
          >
            3
          </div>
          <div className="register__billboard__step__details">
            <div className="register__billboard__step__details__title">Contact Details</div>
            <div className="register__billboard__step__details__subtitle">Confirm details</div>
          </div>
        </div>
        <div className="register__billboard__step__container">
          <div
            className={
              authorized ? "register__billboard__step register__billboard__step--complete" : "register__billboard__step"
            }
          >
            4
          </div>
          <div className="register__billboard__step__details">
            <div className="register__billboard__step__details__title">Authorization</div>
            <div className="register__billboard__step__details__subtitle">Confirm authorization</div>
          </div>
        </div>
        {renderDesignationStep()}
        <button
          className={enableSubmit() ? "btn" : "btn btn--disabled"}
          disabled={enableSubmit() ? false : true}
          type="submit"
        >
          Submit
        </button>
        <div className="register__billboard__footernote">
          Need Help? Contact Us
          <div className="register__billboard__footernote__contact">support@340besp.com</div>
        </div>
      </div>
    );
  };

  const renderConfirmation = () => {
    return (
      <>
        <div className="register__step__title">1. Attest</div>
        <div className="register__form register__form--large">
          <p>Confirm that your 340B covered entity does not have an in-house pharmacy</p>
          <p style={{ fontStyle: "italic", fontSize: 14 }}>
            Boehringer Ingelheim's (BI) contract pharmacy policy permits any 340B covered entity that does not have the
            ability to dispense 340B purchased drugs to its patients to designate a single contract pharmacy for this
            purpose. By designating a contract pharmacy in this web form you are acknowledging that your 340B covered
            entity does not have an in-house pharmacy that is capable of dispensing 340B purchased medicines to eligible
            patients.
            <br />
            <br />
            Your contract pharmacy designation will be valid for 12 months from the date of your first designation. You
            must resubmit your designation after the 12 - month period has expired for your designation to remain in
            effect.
          </p>
          <div style={{ display: "flex", justifyContent: "flex-end" }}>
            <div
              className={
                hasInhousePharmacy === false ? "btn btn--small mr-3" : "btn btn--small btn--outline btn--white mr-3"
              }
              onClick={() => handleAttested(false)}
            >
              Accept
            </div>
            <div
              className={hasInhousePharmacy === true ? "btn btn--small" : "btn btn--small btn--outline btn--white"}
              onClick={() => handleAttested(true)}
            >
              Reject
            </div>
          </div>
        </div>
      </>
    );
  };

  const renderActionButtons = (designation) => {
    if (hasInhousePharmacy === false) {
      return (
        <div className="designation_form__section__group__value">
          <div className="btn__option__group">
            <input type="hidden" name={`existing_designations[${designation.id}]`} value={designation.selected} />
            <div
              className={!designation.selected ? "btn btn--small btn--selected" : "btn btn--small"}
              onClick={() => updateDesignationStatus(designation, false)}
            >
              Remove
            </div>
            <div
              className={designation.selected ? "btn btn--small btn--selected" : "btn btn--small"}
              onClick={() => updateDesignationStatus(designation, true)}
            >
              Keep
            </div>
          </div>
        </div>
      );
    }
  };

  const renderDesignations = () => {
    return (
      <>
        <div className="register__step__title">2. Confirm Designations</div>
        <div className="register__form register__form--large">
          <table className="pages__data_table">
            <tbody>
              {designations.map((d, i) => (
                <tr key={i} className="sm">
                  <td className="t--md">
                    <div
                      className="t--sm t--500"
                      style={{ marginBottom: 5, textDecoration: d.selected ? "" : "line-through" }}
                    >
                      {d.name}
                    </div>
                    <div className="t--sm t--subtitle" style={{ textDecoration: d.selected ? "" : "line-through" }}>
                      {d.address}
                    </div>
                  </td>
                  <td className="t--md" style={{ textDecoration: d.selected ? "" : "line-through" }}>
                    {d.kind.toUpperCase()}
                  </td>
                  <td>{renderActionButtons(d)}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </>
    );
  };

  const renderDesignationForm = () => {
    if (hasRemovedRetail() || hasRemovedSpecialty()) {
      return (
        <>
          <div
            className={
              hasInhousePharmacy === false
                ? "register__step__title"
                : "register__step__title register__step__title--disabled"
            }
          >
            5. Designate a Single Contract Pharmacy
          </div>
          <div
            className={
              hasInhousePharmacy === false
                ? "register__form register__form--large register__form--no-mb"
                : "register__form register__form--large register__form--no-mb register__form--disabled"
            }
          >
            <div className="designation_form__wrapper">
              <BoehringerIngelheimDesignations
                entity={props.entity}
                setCompletedDesignations={setCompletedDesignations}
                renderRetail={hasRemovedRetail()}
                renderSpecialty={hasRemovedSpecialty()}
              />
            </div>
          </div>
        </>
      );
    }
  };

  return (
    <div className="register__wrapper register__wrapper--large">
      <form method="POST" action={`/designations/${props.token}/recertification`} id="designations_form">
        <div className="register__form__container register__form__container--large">
          <input type="hidden" name="has_inhouse_pharmacy" value={hasInhousePharmacy} />
          <input type="hidden" name="entity_id" value={props.entity.id_340b} />
          {renderConfirmation()}
          {renderDesignations()}
          <div className="register__step__title">3. Enter Contact Details</div>
          <div className="register__form register__form--large">
            <div className="register__form__dual-row register__form__dual-row--pad-right">
              <CustomTextField
                label="First Name"
                margin="normal"
                variant="outlined"
                id="firstName"
                name="user[first_name]"
                fullWidth
                required
                onChange={(event) => {
                  setUserDetails({
                    ...userDetails,
                    firstName: event.target.value,
                  });
                }}
              />
            </div>
            <div className="register__form__dual-row register__form__dual-row--pad-left">
              <CustomTextField
                label="Last Name"
                margin="normal"
                variant="outlined"
                id="lastName"
                name="user[last_name]"
                fullWidth
                required
                onChange={(event) => {
                  setUserDetails({
                    ...userDetails,
                    lastName: event.target.value,
                  });
                }}
              />
            </div>
            <div className="register__form__requirements">
              <p>
                <span className="bold">Note: </span>
                Please provide your
                <span className="bold"> Work Email Address.</span>
                <br />
                Designations are verified by authenticating the 340B covered entity domain with the email address
                provided.
              </p>
            </div>
            <CustomTextField
              label="Work Email Address"
              margin="normal"
              variant="outlined"
              id="email"
              name="user[email]"
              type="email"
              fullWidth
              required
              error={isInvalidEmail()}
              helperText={
                userDetails.email && isInvalidEmail()
                  ? "Email is not valid - Please provide a valid Work Email Address for this covered entity."
                  : null
              }
              onChange={(event) => {
                setUserDetails({
                  ...userDetails,
                  email: event.target.value,
                });
              }}
            />
          </div>
          <div className="register__step__title">4. Authorize Your Designation</div>
          <div className="register__form register__form--large">
            <p>
              Are you authorized on behalf of the selected covered entity(s) to designate a single contract pharmacy
              with 340B ESP™?
            </p>
            <FormControlLabel
              control={
                <Checkbox
                  checked={authorized}
                  onChange={() => {
                    setAuthorized(!authorized);
                  }}
                  color="primary"
                />
              }
              label="I am authorized"
            />
          </div>

          {renderDesignationForm()}
        </div>
        {renderBillboard()}
      </form>
    </div>
  );
};

export default PublicDesignationsForm;
