import React, { useEffect, useState } from "react";
import { Pie } from "@nivo/pie";
import moment from "moment";
import { KeyboardArrowRight } from "@mui/icons-material";

export default function ClaimsWidget(props) {
  const [activeChunk, setActiveChunk] = useState("Conforming");

  const totalClaims = props.data.reduce((acc, claimType) => {
    return acc + claimType.value;
  }, 0);

  const chunk =
    (chunkId) =>
    ({ dataWithArc, centerX, centerY, arcGenerator }) => {
      const [data] = dataWithArc.filter(({ id }) => id === chunkId);
      const pathData = arcGenerator({
        ...data.arc,
        innerRadius: data.arc.innerRadius - 4,
        outerRadius: data.arc.outerRadius + 4,
        cornerRadius: 10,
      });

      const claimsQuantity = data.value.toLocaleString();

      return (
        <g transform={`translate(${centerX},${centerY})`}>
          <path d={pathData} fill={data.color} />
          <text transform="translate(0,-10)" fill={"currentColor"} fontSize="18" fontWeight={"500"} textAnchor="middle">
            {claimsQuantity}
          </text>
        </g>
      );
    };

  const renderPieChart = () => {
    return (
      <div className={"d-none d-xl-block"} style={{ width: 170 }}>
        {
          <Pie
            data={props.data}
            width={170}
            height={120}
            margin={{ top: 16, right: 20, bottom: 0, left: 20 }}
            innerRadius={0.75}
            startAngle={-90}
            endAngle={90}
            activeInnerRadiusOffset={4}
            activeOuterRadiusOffset={4}
            enableArcLabels={false}
            enableArcLinkLabels={false}
            colors={["#3246D3", "#0A162F", "#718291", "#FF681F"]}
            onClick={(data) => setActiveChunk(data.id)}
            layers={["arcs", chunk(activeChunk)]}
          />
        }
        <div className="t--500 t--subtitle t--sm" style={{ textAlign: "center" }}>
          Claims by Category
        </div>
      </div>
    );
  };

  return (
    <div className="div6">
      <div className="tile" style={{ height: "100%" }}>
        <div className="tile__header tile__header--spread">
          <div className="tile__header__details">
            <div className="tile__header__details__title">Claim Submissions</div>
            <div className="tile__header__details__subtitle">
              Last 30 days - {moment().subtract(30, "days").format("MM-DD-YYYY")}
              <span style={{ fontSize: 18, margin: "0 2px" }}>
                <KeyboardArrowRight fontSize={"inherit"} color={"inherit"} />
              </span>
              {moment().format("MM-DD-YYYY")}
            </div>
          </div>
          <a href={`/organization/${props.organization}/reports`}>
            <div className="btn btn--light">View Report</div>
          </a>
        </div>

        <div className="flex__container">
          {renderPieChart()}

          <div className="list__container">
            {props.data.map((data) => {
              const percentage = Math.round((data.value / totalClaims) * 100);
              const claimColor = (claimType) => {
                switch (claimType) {
                  case "Conforming":
                    return "#3246D3";
                  case "Not A Contract Pharmacy":
                    return "#0A162F";
                  case "Invalid 340B ID":
                    return "#718291";
                  case "Non-Conforming":
                    return "#FF681F";
                }
              };
              return (
                <div
                  onClick={() => setActiveChunk(data.id)}
                  key={data.id}
                  className={
                    activeChunk == data.id
                      ? "list__item list__item--rad list__item--spread list__item--active"
                      : "list__item list__item--rad list__item--spread"
                  }
                >
                  <div className="flex__container flex__container--align-center">
                    <div className="indicator" style={{ backgroundColor: claimColor(data.id) }}></div>
                    <div className="t--sm">{data.label}</div>
                  </div>
                  <div className="progress_bar__wrapper" style={{ marginLeft: 10 }}>
                    <div className="progress_bar__container progress_bar__container--sm" style={{ overflow: "hidden" }}>
                      <div
                        className="progress_bar__fill"
                        style={{ width: `${percentage}%`, backgroundColor: claimColor(data.id) }}
                      ></div>
                    </div>
                    <div className="progress_bar__value progress_bar__value--min-w">{percentage ? percentage : 0}%</div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
}
