import React, { useState, useRef, useEffect } from "react";
import _ from "lodash";
import Sumitomo from "images/Sumitomo.png";

const AgreementSumitomo = (props) => {
  const renderAgreement = () => {
    if (props.selectedManufacturer == "sumitomo") {
      return (
        <div>
          <div className="logo__container">
            <div className="logo">
              <img src={Sumitomo} />
            </div>
            <div className="logo__info">
              <div className="logo__info__title">Sumitomo</div>
              <div className="logo__info__subtitle">Wholly Owned Contract Pharmacy Agreement</div>
            </div>
          </div>
          <p>
            For orders made on or after May 1, 2024, wholesalers on behalf of Sumitomo Pharma America, INC will ship
            certain products purchased at the 340B price to locations registered within the Health Resources and
            Services Administration (HRSA) database as a 340B covered entity or child site location.
          </p>
          <p>
            A covered entity that selects a contract pharmacy that is wholly owned by a 340B hospital or has common
            ownership with a health system may remain eligible to receive Bill-to/Ship-to replenishment orders of
            Orgovyx® at the 340B price upon registering on the ESP Platform and receiving approval for a wholly owned
            exemption. Wholly owned pharmacies are not exempt for replenishment orders at the 340B price for APTIOM,
            GEMTESA, and MYFEMBREE.
          </p>
          <p>
            To apply for this exemption, wholly owned pharmacies must be registered within the HRSA contract pharmacy
            database as a contract pharmacy location of their related 340B covered entity. Covered entities then must
            complete this online form and submit proof of ownership documents online. Examples of proof of ownership
            documents include:
          </p>
          <ul>
            <li>Certificate of liability insurance</li>
            <li>State business licenses</li>
          </ul>
          <p>
            Wholly owned contract pharmacy application requests will be processed within five business days of receipt.
            Exemptions will be valid for twelve (12) months from approval date. After the initial 12-month period,
            Sumitomo Pharma America, INC may require covered entities to resubmit proof of ownership documents to
            validate the continued eligibility of existing exemptions.
          </p>
          <p>
            By signing this form, the authorized representative of the covered entity certifies that the covered entity
            and contract pharmacy(ies) included in this Exemption request: (i) have the same corporate ownership, OR
            (ii) that the covered entity wholly owns the identified contract pharmacy(ies); AND (iii) the covered entity
            qualifies for the exemption as described in this form. If there are any changes to the ownership structure
            of any of the contract pharmacies identified in this form, the covered entity agrees to notify Sumitomo
            Pharma America, INC at support@340Besp.com within five (5) business days of such change of ownership.
          </p>

          <div className="register__divider" />
        </div>
      );
    } else {
      return null;
    }
  };

  return renderAgreement();
};

export default AgreementSumitomo;
