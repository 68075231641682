import React, { useState, useRef, useEffect } from "react";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Attachments from "../Attachments";
import _ from "lodash";

const CustomTextField = withStyles({
  root: {
    backgroundColor: "transparent",
    borderRadius: 8,
    "& label.Mui-focused": {
      color: "#3D71FF",
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: "green",
    },
    "& .MuiOutlinedInput-root": {
      borderRadius: 8,
      backgroundColor: "#ffffff",
      "& fieldset": {
        borderColor: "#DEE7EE",
      },
      "&:hover fieldset": {
        borderColor: "#DEE7EE",
      },
      "&.Mui-focused fieldset": {
        borderColor: "#3D71FF",
      },
    },
  },
})(TextField);

const PublicDesignationsForm = (props) => {
  const [hasInhousePharmacy, setHasInhousePharmacy] = useState();
  const [attested, setAttested] = useState(false);
  const [attachments, setAttachments] = useState([{ id: 1, filename: null, filesize: null, file: null }]);
  const [completedAttachedDocuments, setCompletedAttachedDocuments] = useState(false);

  const [completedUserDetails, setCompletedUserDetails] = useState(false);
  const [authorized, setAuthorized] = React.useState(false);
  const [completedDesignations, setCompletedDesignations] = useState(true);
  const [userDetails, setUserDetails] = React.useState({
    firstName: null,
    lastName: null,
    email: null,
  });

  const invalidEmailDomains = ["gmail", "outlook", "yahoo", "hotmail"];

  useEffect(() => {
    userDetailsCompleted();
  }, [userDetails]);

  useEffect(() => {
    if (hasEmptyValues(attachments)) {
      setCompletedAttachedDocuments(false);
    } else {
      setCompletedAttachedDocuments(true);
    }
  }, [attachments]);

  const hasEmptyValues = (data) => {
    let values = _.flatten(data.map((obj) => _.values(obj)));

    return values.includes(null) || values.includes("");
  };

  const handleAttested = (value) => {
    setAttested(true);
    setHasInhousePharmacy(value);
  };

  const userDetailsCompleted = () => {
    if (!isInvalidEmail() && userDetails.email && userDetails.firstName && userDetails.lastName) {
      setCompletedUserDetails(true);
    } else {
      if (completedUserDetails) setCompletedUserDetails(false);
    }
  };

  const isInvalidEmail = () => {
    if (userDetails.email) {
      if (
        userDetails.email.includes("@") &&
        !_.map(invalidEmailDomains, (domain) => userDetails.email.includes(domain)).includes(true)
      ) {
        return false;
      } else {
        return true;
      }
    }
  };

  const enableSubmit = () => {
    return attested && completedUserDetails && authorized && completedDesignations;
  };

  const renderUploadStep = () => {
    if (hasInhousePharmacy === false) {
      return (
        <div className="register__billboard__step__container">
          <div
            className={
              completedAttachedDocuments
                ? "register__billboard__step register__billboard__step--complete"
                : "register__billboard__step"
            }
          >
            1b
          </div>
          <div className="register__billboard__step__details">
            <div className="register__billboard__step__details__title">Upload</div>
            <div className="register__billboard__step__details__subtitle">Upload Documents</div>
          </div>
        </div>
      );
    }
  };

  const renderBillboard = () => {
    return (
      <div className="register__billboard">
        <div className="register__billboard__title">Designations</div>
        <div className="register__billboard__subtitle">All steps and fields are required *</div>
        <div className="register__billboard__step__container">
          <div
            className={
              attested ? "register__billboard__step register__billboard__step--complete" : "register__billboard__step"
            }
          >
            {hasInhousePharmacy === false ? "1a" : "1"}
          </div>
          <div className="register__billboard__step__details">
            <div className="register__billboard__step__details__title">Attest</div>
            <div className="register__billboard__step__details__subtitle">Confirm policy</div>
          </div>
        </div>
        {renderUploadStep()}
        <div className="register__billboard__step__container">
          <div
            className={
              completedUserDetails
                ? "register__billboard__step register__billboard__step--complete"
                : "register__billboard__step"
            }
          >
            2
          </div>
          <div className="register__billboard__step__details">
            <div className="register__billboard__step__details__title">Contact Details</div>
            <div className="register__billboard__step__details__subtitle">Confirm details</div>
          </div>
        </div>
        <div className="register__billboard__step__container">
          <div
            className={
              authorized ? "register__billboard__step register__billboard__step--complete" : "register__billboard__step"
            }
          >
            3
          </div>
          <div className="register__billboard__step__details">
            <div className="register__billboard__step__details__title">Authorization</div>
            <div className="register__billboard__step__details__subtitle">Confirm authorization</div>
          </div>
        </div>
        {/* {renderDesignationStep()} */}
        <button
          className={enableSubmit() ? "btn" : "btn btn--disabled"}
          disabled={enableSubmit() ? false : true}
          type="submit"
        >
          Submit
        </button>
        <div className="register__billboard__footernote">
          Need Help? Contact Us
          <div className="register__billboard__footernote__contact">support@340besp.com</div>
        </div>
      </div>
    );
  };

  const renderConfirmation = () => {
    return (
      <>
        <div className="register__step__title">{hasInhousePharmacy === false ? "1a. Attest" : "1. Attest"}</div>
        <div className="register__form register__form--large">
          <p>Confirm that your 340B covered entity does not have an in-house pharmacy</p>
          <p style={{ fontStyle: "italic", fontSize: 14 }}>
            BMS will allow a covered entity to designate a single contract pharmacy location for non-IMiD products upon
            attestation and availability of auditable records sufficient to support that the covered entity does not
            have an “entity-owned pharmacy” capable of purchasing and dispensing the applicable BMS 340B drugs. The
            ability to designate a contract pharmacy does not in any way extend this pricing to entities or individuals
            that do not meet 340B program eligibility. Covered entities are responsible for ensuring that their
            particular contracting arrangements and operations conform to the requirements of all applicable laws and
            regulations. Upon request, participating covered entities will provide copies of their contract pharmacy
            service agreements to BMS. You may only change your designation within a 12-month period if the designated
            contract pharmacy relationship is terminated from the HRSA OPAIS database.
            <br />
            <br />
            By designating a contract pharmacy, you are acknowledging that your 340B covered entity does not have an
            “entity-owned pharmacy” capable of purchasing and dispensing the non-IMiD products.
          </p>
          <div style={{ display: "flex", justifyContent: "flex-end" }}>
            <div
              className={
                hasInhousePharmacy === false ? "btn btn--small mr-3" : "btn btn--small btn--outline btn--white mr-3"
              }
              onClick={() => handleAttested(false)}
            >
              Accept
            </div>
            <div
              className={hasInhousePharmacy === true ? "btn btn--small" : "btn btn--small btn--outline btn--white"}
              onClick={() => handleAttested(true)}
            >
              Reject
            </div>
          </div>
        </div>
      </>
    );
  };

  const renderAttachments = () => {
    return attachments.map((item, i) => {
      return (
        <div key={item.id}>
          <Attachments
            id={item.id}
            filename={item.filename}
            filesize={item.filesize}
            file={item.file}
            attachments={attachments}
            setAttachments={setAttachments}
          />
        </div>
      );
    });
  };

  const renderAttachmentNote = () => {
    return (
      <p>
        <span className="bold">Note: </span>
        Please provide documents that confirm your 340B covered entity does not have an in-house pharmacy.
        {/* <span className="bold"> Certificate of liability insurance </span>
        or
        <span className="bold"> State business licenses</span> */}
      </p>
    );
  };

  const renderUpload = () => {
    if (hasInhousePharmacy === false) {
      return (
        <>
          <div className="register__step__title">1b. Upload Documents</div>
          <div className="register__form register__form--large" data-testid="document-upload">
            <div className="register__form__requirements" style={{ marginTop: 0 }}>
              {renderAttachmentNote()}
            </div>
            {renderAttachments()}
            <div
              style={{
                margin: "15px 0",
                textDecoration: "underline",
                color: "#3246D3",
                cursor: "pointer",
                textAlign: "right",
              }}
              onClick={() => {
                setAttachments([
                  ...attachments,
                  {
                    id: _.maxBy(attachments, "id").id + 1,
                    filename: null,
                    filesize: null,
                    file: null,
                  },
                ]);
              }}
            >
              + Add Another File
            </div>
          </div>
        </>
      );
    }
  };

  return (
    <div className="register__wrapper register__wrapper--large">
      <form
        method="POST"
        action={`/bms_recertification/${props.token}`}
        id="recertification_form"
        encType="multipart/form-data"
      >
        <div className="register__form__container register__form__container--large">
          <input type="hidden" name="_method" value="put" />
          <input type="hidden" name="has_inhouse_pharmacy" value={hasInhousePharmacy} />
          <input type="hidden" name="entity_id" value={props.entity.id_340b} />
          {renderConfirmation()}
          {renderUpload()}
          {/* {renderDesignations()} */}
          <div className="register__step__title">2. Enter Contact Details</div>
          <div className="register__form register__form--large">
            <div className="register__form__dual-row register__form__dual-row--pad-right">
              <CustomTextField
                label="First Name"
                margin="normal"
                variant="outlined"
                id="firstName"
                name="user[first_name]"
                fullWidth
                required
                onChange={(event) => {
                  setUserDetails({
                    ...userDetails,
                    firstName: event.target.value,
                  });
                }}
              />
            </div>
            <div className="register__form__dual-row register__form__dual-row--pad-left">
              <CustomTextField
                label="Last Name"
                margin="normal"
                variant="outlined"
                id="lastName"
                name="user[last_name]"
                fullWidth
                required
                onChange={(event) => {
                  setUserDetails({
                    ...userDetails,
                    lastName: event.target.value,
                  });
                }}
              />
            </div>
            <div className="register__form__requirements">
              <p>
                <span className="bold">Note: </span>
                Please provide your
                <span className="bold"> Work Email Address.</span>
                <br />
                Designations are verified by authenticating the 340B covered entity domain with the email address
                provided.
              </p>
            </div>
            <CustomTextField
              label="Work Email Address"
              margin="normal"
              variant="outlined"
              id="email"
              name="user[email]"
              type="email"
              fullWidth
              required
              error={isInvalidEmail()}
              helperText={
                userDetails.email && isInvalidEmail()
                  ? "Email is not valid - Please provide a valid Work Email Address for this covered entity."
                  : null
              }
              onChange={(event) => {
                setUserDetails({
                  ...userDetails,
                  email: event.target.value,
                });
              }}
            />
          </div>
          <div className="register__step__title">3. Authorize Your Designation</div>
          <div className="register__form register__form--large">
            <p>
              Are you authorized on behalf of the selected covered entity(s) to designate a single contract pharmacy
              with 340B ESP™?
            </p>
            <FormControlLabel
              control={
                <Checkbox
                  checked={authorized}
                  onChange={() => {
                    setAuthorized(!authorized);
                  }}
                  color="primary"
                />
              }
              label="I am authorized"
            />
          </div>

          {/* {renderDesignationForm()} */}
        </div>
        {renderBillboard()}
      </form>
    </div>
  );
};

export default PublicDesignationsForm;
