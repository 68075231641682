import React, { useState, useEffect, Fragment } from "react";
import { ExpandMore, GetApp, ChevronRight } from "@material-ui/icons";
import SubmissionSummaryChart from "./SubmissionSummaryChart";
import _ from "lodash";
import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  useReactTable,
  getExpandedRowModel,
} from "@tanstack/react-table";

const columnHelper = createColumnHelper();

const columns = [
  columnHelper.accessor("file_name", {
    header: "Submission",
    cell: ({ row, getValue }) => {
      return (
        <>
          <div
            className="btn btn--sqr"
            style={{ marginRight: 18, verticalAlign: "middle" }}
            onClick={() => row.toggleExpanded()}
          >
            <ExpandMore fontSize="small" />
          </div>
          <span className="t--500">{getValue()}</span>
        </>
      );
    },
  }),
  columnHelper.accessor((row) => row.status, {
    header: "Status",
    cell: ({ row, getValue }) => {
      return (
        <div className="badge">
          <div className="badge__indicator" />
          {getValue()}
        </div>
      );
    },
  }),
  columnHelper.accessor("row_count", {
    header: "Claims",
    cell: (info) => <div className="t--500">{info.renderValue()}</div>,
  }),
  columnHelper.accessor("conforming_percent", {
    header: "Conforming",
    cell: ({ row, getValue }) => {
      return (
        <div className="progress_bar__wrapper">
          <div className="progress_bar__container progress_bar__container--t-fixed">
            <div className="progress_bar__fill" style={{ width: `${getValue()}%` }} />
          </div>
          <div className="progress_bar__value">{`${getValue()}%`}</div>
        </div>
      );
    },
  }),
  columnHelper.accessor("date", {
    header: "Date",
  }),
  columnHelper.accessor("links", {
    header: "actions",
    cell: ({ row, getValue }) => {
      return (
        <>
          {/* <a href={getValue().report} disposition="attachment">
            <div className="btn btn--light" style={{ marginRight: 10 }}>
              View Report
            </div>
          </a> */}
          <div className="btn btn--sqr">
            <a href={getValue().download} disposition="attachment">
              <GetApp style={{ fontSize: 14 }} />
            </a>
          </div>
        </>
      );
    },
  }),
];

export default function ClaimsTable(props) {
  const [data, setData] = React.useState(() => props.submissions);
  const parentRef = React.useRef(null);

  const table = useReactTable({
    data,
    columns,
    getExpandedRowModel: getExpandedRowModel(),
    getCoreRowModel: getCoreRowModel(),
  });

  const renderSubComponent = (row) => {
    return <SubmissionSummaryChart row={row} />;
  };

  const renderRows = () => {
    return table.getRowModel().rows.map((row) => {
      return (
        <Fragment key={row.id}>
          <tr className="sm">
            {row.getVisibleCells().map((cell) => (
              <td key={cell.id} className="t--md">
                {flexRender(cell.column.columnDef.cell, cell.getContext())}
              </td>
            ))}
          </tr>
          {row.getIsExpanded() && (
            <tr>
              <td colSpan={row.getVisibleCells().length}>{renderSubComponent(row)}</td>
            </tr>
          )}
        </Fragment>
      );
    });
  };

  return (
    <div>
      <table className="primary">
        <thead>
          {table.getHeaderGroups().map((headerGroup) => (
            <tr key={headerGroup.id} className="sm">
              {headerGroup.headers.map((header) => (
                <th key={header.id}>
                  {header.isPlaceholder ? null : flexRender(header.column.columnDef.header, header.getContext())}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody>{renderRows()}</tbody>
      </table>
    </div>
  );
}
