import React, { useState, useRef, useEffect } from "react";
import _ from "lodash";

const Billboard = (props) => {
  const [submitting, setSubmitting] = useState(false);

  const renderLoader = () => {
    if (submitting) {
      return <div className="loading-indicator" style={{ margin: "0 10px 0 -28px" }}></div>;
    }
  };

  const canSubmit = () => {
    if (props.enableSubmit() && !submitting) {
      return false;
    } else {
      return true;
    }
  };

  const handleSubmit = () => {
    setSubmitting(true);
    document.getElementById("wholly_owned_application_form").submit();
  };

  const renderStepSixSubtitle = () => {
    if (props.selectedManufacturer == "johnson_n_johnson") {
      return "Proof of ownership & of Pharmacy(s) 501(c)(3) Status";
    } else {
      return "Proof of ownership";
    }
  };

  return (
    <div className="register__billboard">
      <div className="register__billboard__title">Wholly Owned Exemptions</div>
      <div className="register__billboard__subtitle">All steps and fields are required *</div>
      <div className="register__billboard__step__container">
        <div
          className={
            props.completedSelectManufacturers
              ? "register__billboard__step register__billboard__step--complete"
              : "register__billboard__step"
          }
        >
          1
        </div>
        <div className="register__billboard__step__details">
          <div className="register__billboard__step__details__title">Select Manufacturer</div>
          <div className="register__billboard__step__details__subtitle">Select one manufacturer</div>
        </div>
      </div>
      <div className="register__billboard__step__container">
        <div
          className={
            props.completedFirstName && props.completedLastName && props.completedContactEmail
              ? "register__billboard__step register__billboard__step--complete"
              : "register__billboard__step"
          }
        >
          2
        </div>
        <div className="register__billboard__step__details">
          <div className="register__billboard__step__details__title">Contact Details</div>
          <div className="register__billboard__step__details__subtitle">Provide your details</div>
        </div>
      </div>
      <div className="register__billboard__step__container">
        <div
          className={
            props.completedSelectedEntity
              ? "register__billboard__step register__billboard__step--complete"
              : "register__billboard__step"
          }
        >
          3
        </div>
        <div className="register__billboard__step__details">
          <div className="register__billboard__step__details__title">Covered Entities</div>
          <div className="register__billboard__step__details__subtitle">Select covered entity</div>
        </div>
      </div>
      <div className="register__billboard__step__container">
        <div
          className={
            props.completedWholesaler
              ? "register__billboard__step register__billboard__step--complete"
              : "register__billboard__step"
          }
        >
          4
        </div>
        <div className="register__billboard__step__details">
          <div className="register__billboard__step__details__title">Wholesaler</div>
          <div className="register__billboard__step__details__subtitle">Add wholesaler(s) details</div>
        </div>
      </div>
      <div className="register__billboard__step__container">
        <div
          className={
            props.completedPharmacyDetails
              ? "register__billboard__step register__billboard__step--complete"
              : "register__billboard__step"
          }
        >
          5
        </div>
        <div className="register__billboard__step__details">
          <div className="register__billboard__step__details__title">Pharmacy Details</div>
          <div className="register__billboard__step__details__subtitle">Add pharmacy(s) details</div>
        </div>
      </div>
      <div className="register__billboard__step__container">
        <div
          className={
            props.completedAttachedDocuments
              ? "register__billboard__step register__billboard__step--complete"
              : "register__billboard__step"
          }
        >
          6
        </div>
        <div className="register__billboard__step__details">
          <div className="register__billboard__step__details__title">Documents</div>
          <div className="register__billboard__step__details__subtitle">{renderStepSixSubtitle()}</div>
        </div>
      </div>
      <div className="register__billboard__step__container">
        <div
          className={
            props.completedSignedAgreement
              ? "register__billboard__step register__billboard__step--complete"
              : "register__billboard__step"
          }
        >
          7
        </div>
        <div className="register__billboard__step__details">
          <div className="register__billboard__step__details__title">Signature</div>
          <div className="register__billboard__step__details__subtitle">Sign agreement</div>
        </div>
      </div>
      <button
        className={props.enableSubmit() ? "btn" : "btn btn--disabled"}
        disabled={canSubmit()}
        type="submit"
        onClick={() => handleSubmit()}
      >
        {renderLoader()}
        Submit
      </button>
      <div className="register__billboard__footernote">
        Need Help? Contact Us
        <div className="register__billboard__footernote__contact">support@340besp.com</div>
      </div>
    </div>
  );
};

export default Billboard;
