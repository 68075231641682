import React, { useState, useEffect } from "react";
import Switch from "@material-ui/core/Switch";
import { withStyles } from "@material-ui/core/styles";
import Notice from "./Notice";

const Toggle = withStyles((theme) => ({
  root: {
    width: 40,
    height: 20,
    padding: 0,
    marginLeft: 10,
  },
  switchBase: {
    padding: 4,
    "&$checked": {
      transform: "translateX(20px)",
      color: theme.palette.common.white,
      "& + $track": {
        backgroundColor: "#3D71FF",
        opacity: 1,
        border: "none",
      },
    },
    "&$focusVisible $thumb": {
      color: "#3D71FF",
      border: "6px solid #fff",
    },
  },
  thumb: {
    width: 12,
    height: 12,
    boxShadow: "none",
  },
  track: {
    borderRadius: 20,
    backgroundColor: "#B7C0C8",
    opacity: 1,
    transition: theme.transitions.create(["background-color", "border"]),
  },
  checked: {},
  focusVisible: {},
}))(({ classes, ...props }) => {
  return (
    <Switch
      focusVisibleClassName={classes.focusVisible}
      disableRipple
      classes={{
        root: classes.root,
        switchBase: classes.switchBase,
        thumb: classes.thumb,
        track: classes.track,
        checked: classes.checked,
      }}
      {...props}
    />
  );
});

export default function CustomToggle(props) {
  const [notice, setNotice] = React.useState({
    kind: "error",
    open: false,
    message: "",
  });

  const submit = (value, ids) => {
    fetch(props.submitPath, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        "X-CSRF-Token": document
          .querySelector('meta[name="csrf-token"]')
          .getAttribute("content"),
      },
      body: JSON.stringify({ pharmacy: { [props.name]: value }, id: ids }),
    })
      .then((res) => {
        if (res.status >= 200 && res.status <= 299) {
          return res.json();
        } else {
          throw res;
        }
      })
      .catch((response) => {
        response.json().then((res) => {
          setNotice({
            kind: "error",
            open: true,
            message: "error please refresh and try again",
          });
        });
      });
  };

  return (
    <div>
      <Notice details={notice} />
      <Toggle
        checked={props.checked || false}
        onChange={(event) => {
          props.setState(event.target.checked, props.pharmacyIDs);
          submit(event.target.checked, props.pharmacyIDs);
        }}
      />
    </div>
  );
}
