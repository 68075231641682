import React, { useState, useEffect, useRef } from "react";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";
import Notice from "./Notice";
import { AsYouType, parsePhoneNumberFromString } from "libphonenumber-js";

const CustomTextField = withStyles({
  root: {
    backgroundColor: "#ffffff",
    borderRadius: 8,
    "& label.Mui-focused": {
      color: "#3D71FF",
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: "green",
    },
    "& .MuiOutlinedInput-root": {
      borderRadius: 8,
      "& fieldset": {
        borderColor: "#DEE7EE",
      },
      "&:hover fieldset": {
        borderColor: "#DEE7EE",
      },
      "&.Mui-focused fieldset": {
        borderColor: "#3D71FF",
      },
    },
  },
})(TextField);

const EditPhoneInputForm = (props) => {
  const [validPhoneType, setValidPhoneType] = React.useState();
  const [userDetails, setUserDetails] = React.useState({
    phoneNumber: props.value,
  });
  const [state, setState] = React.useState({
    edit: false,
    path: props.path,
    name: props.name,
    phone: props.formatedValue,
  });
  const [notice, setNotice] = React.useState({
    kind: "error",
    open: false,
    message: "",
  });

  var phoneformater = new AsYouType("US");

  const fetchPhoneType = () => {
    fetch(`/api/phone/details?number=${userDetails.phoneNumber}`, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "X-CSRF-Token": document
          .querySelector('meta[name="csrf-token"]')
          .getAttribute("content"),
      },
    })
      .then((res) => res.json())
      .then((data) => {
        setValidPhoneType(data.value);
      });
  };

  const isValidPhoneNumber = (val) => {
    if (!val) return true;

    if (val) {
      var number = parsePhoneNumberFromString("+1" + val);

      if (number && number.isValid()) {
        fetchPhoneType();
      }

      if (number && number.isValid() && validPhoneType) {
        return true;
      } else {
        return false;
      }
    }
  };

  const formatPhoneNumber = (val) => {
    if (val) {
      var number = parsePhoneNumberFromString("+1" + val);
      return number && number.nationalNumber;
    }
  };

  const submitForm = () => {
    fetch(props.path, {
      method: "PUT",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "X-CSRF-Token": document
          .querySelector('meta[name="csrf-token"]')
          .getAttribute("content"),
      },
      body: JSON.stringify({
        user: { phone: formatPhoneNumber(userDetails.phoneNumber) || null },
      }),
    })
      .then((res) => {
        if (res.status >= 200 && res.status <= 299) {
          setState({ ...state, edit: false, phone: userDetails.phoneNumber });

          return res.json();
        } else {
          throw Error(res.statusText);
        }
      })
      .then((data) => {
        setNotice({
          kind: "success",
          open: true,
          message: data.notice,
        });
      })
      .catch((error) => {
        setNotice({
          kind: "error",
          open: true,
          message:
            "Oh no - it looks like something went wrong, please try again.",
        });
      });
  };

  const enableSubmit = () => {
    return isValidPhoneNumber(userDetails.phoneNumber);
  };

  const renderForm = () => {
    if (state.edit) {
      return (
        <div
          className="card__item card__item--small card__item--light"
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <div style={{ flexGrow: 1 }}>
            <CustomTextField
              label="Cell Number"
              margin="none"
              fullWidth
              variant="outlined"
              id="phoneNumber"
              name="phoneNumber"
              required
              error={validPhoneType == false}
              helperText={
                validPhoneType == false
                  ? "Please provide your cell number"
                  : null
              }
              value={userDetails.phoneNumber}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">+1</InputAdornment>
                ),
              }}
              onChange={(event) => {
                setUserDetails({
                  ...userDetails,
                  phoneNumber: phoneformater.input(event.target.value),
                });
              }}
            />
          </div>

          <div
            className={
              enableSubmit()
                ? "btn btn--small btn--blue btn--ib"
                : "btn btn--small btn--ib btn--disabled"
            }
            style={{
              alignSelf: "center",
              marginLeft: 30,
            }}
            onClick={() => {
              enableSubmit() ? submitForm() : null;
            }}
          >
            Save
          </div>
          <div
            className={"btn btn--small btn--navy btn--ib"}
            style={{
              alignSelf: "center",
              marginLeft: 10,
            }}
            onClick={() => {
              setState({ ...state, edit: false });
            }}
          >
            Cancel
          </div>
        </div>
      );
    } else {
      return (
        <div
          className="card__item card__item--small card__item--light"
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <div>
            <div className="card__item__value__icon">
              <i className="solid solid-budicon-iphone" />
            </div>
            <div className="card__item__value">{state.phone}</div>
          </div>
          <div
            className="btn btn--blue btn--sq btn--ib"
            onClick={() => {
              setState({ ...state, edit: true });
            }}
          >
            <div className="solid solid-budicon-pen-writing"></div>
          </div>
        </div>
      );
    }
  };

  return (
    <div>
      {renderForm()}
      <Notice details={notice} />
    </div>
  );
};

export default EditPhoneInputForm;
