import React, { useState, useRef, useEffect } from "react";
import _ from "lodash";
import PharmacySelect from "./PharmacySelect";
import TextField from "@material-ui/core/TextField";
import { withStyles, makeStyles } from "@material-ui/core/styles";

const CabometyxDesignationForm = (props) => {
  const [specialtyDesignation, setSpecialtyDesignation] = useState(false);
  const [confirmedPolicy, setConfirmedPolicy] = useState(false);
  const [hasSelectedPharmacy, setHasSelectedPharmacy] = useState(false);
  const [selectedPharmacy, setSelectedPharmacy] = useState(false);

  useEffect(() => {
    var formDetails = _.cloneDeep(props.manufacturerFormDetails);

    // set form details for completed status - obj name as class
    formDetails[props.manufacturer].specialtyDesignationForm = {
      open: specialtyDesignation,
      completed: hasSelectedPharmacy,
    };
    props.setManufacturerFormDetails(formDetails);
  }, [specialtyDesignation, hasSelectedPharmacy]);

  const reset = () => {
    if (hasSelectedPharmacy) {
      setHasSelectedPharmacy(false);
    }
  };

  const notPermitted = () => {
    switch (props.manufacturer) {
      case "exelixis":
        return (
          <div
            className={
              confirmedPolicy
                ? "designation_form__section__group__step__toggle_container designation_form__section__group__step__toggle_container--closed"
                : "designation_form__section__group__step__toggle_container"
            }
          >
            <div className="stat-cards__confirmation stat-cards__confirmation--disabled">
              <span>Please reach out to support at support@340besp.com for assistance.</span>
              {renderNotPermitted()}
            </div>
          </div>
        );
    }
  };

  const renderNotPermitted = () => {
    return (
      <div className="stat-cards__confirmation__footer">
        <span>
          <div className="indicator indicator--red indicator--pulse" style={{ marginTop: 3 }}></div>
          Contact support
        </span>
      </div>
    );
  };

  const policies = () => {
    if (props.isBlocked) {
      reset();
      return notPermitted();
    }

    return (
      <div
        className={
          confirmedPolicy
            ? "designation_form__section__group__step__toggle_container designation_form__section__group__step__toggle_container--closed"
            : "designation_form__section__group__step__toggle_container"
        }
      >
        <div className="stat-cards__confirmation stat-cards__confirmation--disabled">
          Exelixis' 340B contract pharmacy integrity initiative permits a covered entity that does not provide claims
          data and does not have an in-house pharmacy to designate a single contract pharmacy location within each
          product's authorized specialty pharmacy network for the shipment of CABOMETYX®. The covered entity cannot
          designate multiple locations of the same pharmacy chain. By designating a contract pharmacy, you are attesting
          to the fact that your covered entity does not have an in-house pharmacy.
          {renderConfirmation()}
        </div>
      </div>
    );
  };

  const renderConfirmation = () => {
    return (
      <div className="stat-cards__confirmation__footer">
        <span>
          <div className="indicator indicator--red indicator--pulse" style={{ marginTop: 3 }}></div>
          Please confirm policy before assigning designation
        </span>
        <span>
          <div className="btn btn--ib btn--tiny" onClick={() => setConfirmedPolicy(true)}>
            Confirm
          </div>
        </span>
      </div>
    );
  };

  const renderDesignationQuestion = () => {
    switch (props.manufacturer) {
      case "exelixis":
        return `Would you like to make a contract pharmacy designation for ${props.manufacturerName}' specialty limited distribution product, Cabometyx®?`;
    }
  };

  const renderSpecialtyDesignationForm = () => {
    return (
      <div className="designation_form__section__group">
        <div className="designation_form__section__group__row">
          <div className="designation_form__section__group__question">
            <div className="designation_form__section__group__question__subtitle">Designation for Cabometyx®</div>
            {renderDesignationQuestion()}
          </div>
          <div className="designation_form__section__group__value">
            <div className="btn__option__group">
              <div
                className={specialtyDesignation ? "btn btn--small btn--selected" : "btn btn--small"}
                onClick={() => setSpecialtyDesignation(true)}
              >
                yes
              </div>
              <div
                className={specialtyDesignation ? "btn btn--small" : "btn btn--small btn--selected"}
                onClick={() => setSpecialtyDesignation(false)}
              >
                no
              </div>
            </div>
          </div>
        </div>
        {renderPolicy()}
        {renderStep()}
      </div>
    );
  };

  const renderStepIndicator = (step) => {
    if (step) {
      return (
        <div className="designation_form__section__group__step__indicator designation_form__section__group__step__indicator--complete">
          <i className="solid solid-budicon-check-ui" />
        </div>
      );
    } else {
      return <div className="designation_form__section__group__step__indicator"></div>;
    }
  };

  const renderPolicy = () => {
    if (specialtyDesignation) {
      return (
        <div className="designation_form__section__group__step">
          {renderStepIndicator(confirmedPolicy)}
          <div className="designation_form__section__group__step__title">Policy Confirmation</div>
          <div className="designation_form__section__group__step__subtitle">
            Please confirm manufacturers policy to continue.
          </div>
          {policies()}
        </div>
      );
    }
  };

  const renderNoticeHasExistingDesignation = (entity) => {
    var designation = entity.designations_count[props.manufacturer];

    if (designation && designation[props.specialtyKeyName] > 0) {
      return (
        <div className="stat-cards__notice stat-cards__notice--blue stat-cards__notice--mb" style={{ lineHeight: 1.5 }}>
          The selected covered entity already made a contract pharmacy designation, please login to or register an
          account with 340B ESP to view the details of this designation. If you have questions, please reach out to
          support@340besp.com
        </div>
      );
    }
  };

  const filterPharmacies = (entity) => {
    return _.filter(entity.pharmacies.details, { [props.manufacturer]: [props.specialtyKeyName] });
  };

  const renderPharmacySelect = () => {
    if (props.isBlocked) {
      reset();
      return false;
    }

    // hasSelectedSpecialtyPharmacy is passed in to render notice
    // You can't select two specialty pharmacies across designation kinds per manufacturer

    // retailSelectedSpecialtyPharmacy for duplicate input values if single designation has selected specialty CP

    return _.map(props.entities, (entity, index) => (
      <div key={index}>
        {renderNoticeHasExistingDesignation(entity)}
        <PharmacySelect
          kind={props.specialtyKeyName}
          entity={entity}
          pharmacies={filterPharmacies(entity)}
          manufacturer={props.manufacturer}
          disableGranteeNotice={false}
          disableWhollyOwnedNotice={false}
          disableInHouseSpecialtyNotice={false}
          disableInHouseNotice={true}
          selectedPharmacy={selectedPharmacy}
          setSelectedPharmacy={setSelectedPharmacy}
          setHasSelectedPharmacy={setHasSelectedPharmacy}
          retailSelectedSpecialtyPharmacy={props.retailSelectedSpecialtyPharmacy}
          retailHasSelectedSpecialtyPharmacy={props.retailHasSelectedSpecialtyPharmacy}
        />
      </div>
    ));
  };

  const renderStep = () => {
    if (specialtyDesignation && confirmedPolicy) {
      return (
        <div className="designation_form__section__group__step">
          {renderStepIndicator(hasSelectedPharmacy)}
          <div className="designation_form__section__group__step__title">Select Contract Pharmacy</div>
          <div className="designation_form__section__group__step__subtitle">
            Please select a contract pharmacy to designate.
          </div>
          {renderPharmacySelect()}
        </div>
      );
    }
  };

  return renderSpecialtyDesignationForm();
};

export default CabometyxDesignationForm;
