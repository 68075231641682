import React, { useState } from "react";
import dataImage from "images/data_illustration.png";

const AcceptTransfer = (props) => {
  const listEntities = () => {
    return props.entities.map((entity, i) => (
      <div
        key={entity.id}
        className={"onboarding__content__form__scrollbody__item"}
      >
        <div className={"onboarding__content__form__scrollbody__item__info"}>
          <div
            className={
              "onboarding__content__form__scrollbody__item__info__title"
            }
          >
            {entity.id_340b}
          </div>

          <div
            className={
              "onboarding__content__form__scrollbody__item__info__subtitle"
            }
          >
            {entity.entity_name}
          </div>
        </div>
      </div>
    ));
  };

  return (
    <form method="POST" action={props.submission_path}>
      <input
        type="hidden"
        name="authenticity_token"
        value={document
          .querySelector('meta[name="csrf-token"]')
          .getAttribute("content")}
      />
      <input type="hidden" name="_method" value="patch" />
      <input
        type="hidden"
        name="initiating_user"
        value={props.initiating_user.id}
      />
      <div className="onboarding__content__form">
        <div className="onboarding__content__form__image">
          <img src={dataImage} />
        </div>
        <div className="onboarding__content__form__title">
          Merge Covered Entities
        </div>
        <p style={{ marginBottom: 20 }}>
          <strong>{`${props.initiating_user.first_name} ${props.initiating_user.last_name} `}</strong>
          has requested to merge entites for you to submit claims data on their
          behalf - please accept or reject these entities.
        </p>
        <div className="onboarding__content__form__scrollbody">
          <div className={"onboarding__content__form__scrollbody__header"}>
            <div
              className={"onboarding__content__form__scrollbody__header__info"}
            >
              Entities to be merged
            </div>
          </div>
          {listEntities()}
        </div>

        <div className="onboarding__content__form__actions onboarding__content__form__actions--margin-sm">
          <button
            className={"btn"}
            name="status"
            value="accept"
            style={{ marginRight: 10, flexGrow: 1 }}
            type="submit"
          >
            Accept Merge
          </button>
          <button
            className="btn btn--red"
            name="status"
            value="reject"
            style={{ marginLeft: 10, flexGrow: 1 }}
            type="submit"
          >
            Reject Merge
          </button>
        </div>
      </div>
    </form>
  );
};

export default AcceptTransfer;
