import React, { useState, useEffect } from "react";
import _ from "lodash";
import StateAttestationActionRequired from "./ActionRequired";
import Notice from "../../Notice";
import Http from "./http";

const PublicDesignationsForm = (props) => {
  // TODO: initially set this via prop inheritance from rails controller or async update?
  const [attestationStats, setAttestationStats] = useState({});
  const [attestations, setAttestations] = useState([]);
  const [notice, setNotice] = useState({
    kind: "error",
    open: false,
    message: "",
  });

  const urlParams = new URLSearchParams(window.location.search);
  const token = urlParams.get('token');

  useEffect(() => {
    Http.fetchEntityAttestations(token, setAttestations, setNotice);
  }, []);

  useEffect(() => {
    Http.getEntityStats(token, setAttestationStats, setNotice);
  }, [attestations]);


  const renderActionRequired = () => {
    if (!attestationStats || Object.keys(attestationStats).length == 0) {
      return null;
    }

    return _.map(attestationStats, (policyStats, policy) => {
      if (policy == "sanofi_anti-diversion_attestations") {
        return (
          <div key={policy} className="draw__item__container">
            <StateAttestationActionRequired
              page={"entities"}
              policy={policy}
              id_340b={props.id_340b}
              setNotice={setNotice}
              stats={policyStats}
              attestations={attestations}
              setAttestations={setAttestations}
            />
          </div>
        );
      }
    });
  };

  return (
    <div>
      {renderActionRequired()}

      <Notice details={notice} />
    </div>
  );
};

export default PublicDesignationsForm;
