import React, { useState, useEffect } from "react";
import _ from "lodash";
import SubmissionActionDetails from "./SubmissionDetailsCard";
import StateAttestationActionRequired from "../StateAttestationsModal/Components/ActionRequired";
import Notice from "../Notice";
import Http from "../StateAttestationsModal/http";
import PharmCertDrawComponent from "../PharmacyCertification/Index";

const Draw = (props) => {
  const [attestationStats, setAttestationStats] = useState({});
  const [attestations, setAttestations] = useState([]);
  const [notice, setNotice] = useState({
    kind: "error",
    open: false,
    message: "",
  });

  useEffect(() => {
    if (props.controller == "entities") {
      Http.fetchEntityAttestations(props.organizationID, props.entity.id, setAttestations, setNotice);
    }
    if (props.controller == "dashboard") {
      Http.fetchOrgAttestations(props.organizationID, setAttestations, setNotice);
    }
  }, []);

  useEffect(() => {
    if (props.controller == "entities") {
      Http.getEntityStats(props.organizationID, props.entity.id, setAttestationStats, setNotice);
    }
    if (props.controller == "dashboard") {
      Http.getOrgStats(props.organizationID, setAttestationStats, setNotice);
    }
  }, [attestations]);

  const renderActionRequired = () => {
    if (!attestationStats || Object.keys(attestationStats).length == 0) {
      return null;
    }

    return _.map(attestationStats, (policyStats, policy) => {
      if (policy == "sanofi_anti-diversion_attestations") {
        return (
          <div key={policy} className="draw__item__container">
            <StateAttestationActionRequired
              page={props.controller}
              policy={policy}
              organizationID={props.organizationID}
              entity={props.entity}
              entities={props.entities}
              userName={props.userName}
              setNotice={setNotice}
              stats={policyStats}
              setStats={setAttestationStats}
              attestations={attestations}
              setAttestations={setAttestations}
            />
          </div>
        );
      }
    });
  };

  function renderPharmCertDrawComponent() {
    // Pharm Cert Component is scoped to org – don't show on entity profile which is scoped to entity.
    if (props.controller !== "entities") {
      return (
        <PharmCertDrawComponent organizationId={props.organizationID} entities={props.entities} setNotice={setNotice} />
      );
    }

    return null;
  }

  return (
    <div>
      <div className="draw__item__container">
        <SubmissionActionDetails
          organization={props.organizationID}
          atRisk={props.atRisk}
          pendingRegistrations={props.pendingRegistrations}
          pendingRegistrationIDs={JSON.parse(props.pendingRegistrationIDs)}
          entity={props.entity}
          transferDetails={props.transfer_details}
        />
      </div>

      {renderPharmCertDrawComponent()}

      {renderActionRequired()}

      <Notice details={notice} />
    </div>
  );
};

export default Draw;
