import React, { useState, useEffect } from "react";
import ClipboardJS from "clipboard";
import Notice from "../Notice";

export default function ClipboardInviteLink(props) {
  const [notice, setNotice] = React.useState({
    kind: "error",
    open: false,
    message: "",
  });

  useEffect(() => {
    new ClipboardJS('.btn__item');
  }, []);

  return (
    <div>
      <Notice details={notice} />
      <div className="btn__item" data-clipboard-text={props.value} onClick={() => setNotice({
        kind: "success",
        open: true,
        message: "Link copied to clipboard"
      })}>
        <div className="btn__item__title">
          <i className="solid solid-budicon-task" />
          Copy invitation link
        </div>
        <div className="btn__item__link">{props.prettyLink}</div>
      </div>
    </div>
  );
}
