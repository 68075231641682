import React, { useState, useEffect, useRef } from "react";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import inviteImage from "images/invite_or_find_illustration.png";
import InputAdornment from "@material-ui/core/InputAdornment";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Notice from "./Notice";

const AttestationForm = (props) => {
  const completedMount = useRef(true);
  const shouldSubmit = useRef(true);
  const [state, setState] = React.useState({
    insured: !props.checked,
    checked: props.checked,
    path: props.path,
    name: props.name,
  });
  const [notice, setNotice] = React.useState({
    kind: "error",
    open: false,
    message: "",
  });

  useEffect(() => {
    if (completedMount.current || !shouldSubmit.current) {
      completedMount.current = false;
      shouldSubmit.current = true;

      return;
    }

    submitForm();
  }, [state]);

  const submitForm = () => {
    fetch(props.path, {
      method: "PUT",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "X-CSRF-Token": document
          .querySelector('meta[name="csrf-token"]')
          .getAttribute("content"),
      },
      body: JSON.stringify({
        organization: { exempt: state.checked },
      }),
    })
      .then((res) => {
        if (res.status >= 200 && res.status <= 299) {
          return res.json();
        } else {
          throw Error(res.statusText);
        }
      })
      .then((data) => {
        setNotice({
          kind: "success",
          open: true,
          message: data.notice,
        });
      })
      .catch((error) => {
        setNotice({
          kind: "error",
          open: true,
          message:
            "Oh no - it looks like something went wrong, please try again.",
        });
      });
  };

  const renderForm = () => {
    if (!state.insured || state.checked) {
      return (
        <div>
          <div className="register__divider" style={{ margin: "20px 0" }} />
          <div className="card__details__section__note">
            Covered entities may complete the attestation below to indicate that
            340B contract pharmacy utilization is exclusively for uninsured
            patients. Pharmaceutical manufacturers may elect to exempt covered
            entities from use of 340B ESP™ based on this attestation.
            <br />
            <br />
            Each attestation will be validated through analysis of rebate
            utilization data originating from each 340B covered entities own
            pharmacies. Following validation, manufacturers will notify covered
            entities of their exemption status.
          </div>
          <div className="card__item card__item--small card__item--light">
            <div className="card__item__label">
              By checking the ‘Attest’ box below, I
              <strong> {props.user} </strong>
              certify that the covered entity for which I am registering does
              not fill prescriptions for patients with any form of Medicaid,
              Medicare Part D or commercial insurance through any of its 340B
              contract pharmacies.
            </div>
            <FormControlLabel
              control={
                <Checkbox
                  checked={state.checked}
                  onChange={() => {
                    setState({ ...state, checked: event.target.checked });
                  }}
                  color="primary"
                />
              }
              label="I Attest"
            />
          </div>
        </div>
      );
    }
  };

  return (
    <div>
      <div className="btn__option__group__container">
        <div className="btn__option__group">
          <div
            onClick={() => {
              shouldSubmit.current = state.checked ? true : false;
              setState({ ...state, checked: false, insured: true });
            }}
            className={
              state.insured
                ? "btn btn--tiny btn__option__group--pad-child-r btn--selected"
                : "btn btn--tiny btn__option__group--pad-child-r"
            }
          >
            yes
          </div>
          <div
            onClick={() => {
              shouldSubmit.current = false;
              setState({ ...state, insured: false });
            }}
            className={
              state.insured
                ? "btn btn--tiny btn__option__group--pad-child-r"
                : "btn btn--tiny btn__option__group--pad-child-r btn--selected"
            }
          >
            no
          </div>
        </div>
      </div>
      {renderForm()}
      <Notice details={notice} />
    </div>
  );
};

export default AttestationForm;
