import React, { useEffect, useState } from "react";
import _ from "lodash";
import { numberWithCommas } from "../utils/NumberFormatter";
import moment from "moment";
import { KeyboardArrowRight } from "@mui/icons-material";

export default function AccountStatusWidget(props) {
  const [activity, setActivity] = useState([]);

  useEffect(() => {
    fetchLedgerActivity();
  }, []);

  const fetchLedgerActivity = () => {
    fetch(props.ledger_activity_path, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "X-CSRF-Token": document.querySelector('meta[name="csrf-token"]').getAttribute("content"),
      },
    })
      .then((res) => {
        if (res.status >= 200 && res.status <= 299) {
          return res.json();
        } else {
          throw Error(res.statusText);
        }
      })
      .then((response) => {
        setActivity(response.data);
      })
      .catch((error) => {});
  };

  const renderTable = () => {
    return (
      <table className="secondary secondary--light">
        <thead>
          <tr className="sm">
            <th className="rnd-l t--sm">Manufacturer</th>
            <th className="t--sm">Product</th>
            <th className="t--sm">Type</th>
            <th className="rnd-r t--sm">Units</th>
          </tr>
        </thead>
        <tbody>{renderActivityDetails()}</tbody>
      </table>
    );
  };

  const renderActivityDetails = () => {
    return _.map(activity, (act, i) => {
      return (
        <tr key={i} className="sm">
          <td className="t--sm">{act.manufacturer_name}</td>
          <td className="t--sm">{act.product_name}</td>
          <td className="t--sm">{act.kind}</td>
          <td className="t--sm">{numberWithCommas(act.amount)}</td>
        </tr>
      );
    });
  };

  return (
    <div className="div7">
      <div className="tile" style={{ height: "100%" }}>
        <div className="tile__header tile__header--spread">
          <div className="tile__header__details">
            <div className="tile__header__details__title">Account Activity</div>
            <div className="tile__header__details__subtitle">
              <div className="tile__header__details__subtitle">
                Last 30 days - {moment().subtract(30, "days").format("MM-DD-YYYY")}
                <span style={{ fontSize: 18, margin: "0 2px" }}>
                  <KeyboardArrowRight fontSize={"inherit"} color={"inherit"} />
                </span>
                {moment().format("MM-DD-YYYY")}
              </div>
            </div>
          </div>
          <a href={`/organization/${props.organization}/accounts/`}>
            <div className="btn btn--light">View Details</div>
          </a>
        </div>
        <div style={{ overflow: "scroll" }}>{renderTable()}</div>
      </div>
    </div>
  );
}
