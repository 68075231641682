import React, { useState, useEffect, useRef } from "react";
import _ from "lodash";
import Select from "react-select";
import { manufacturers } from "/app/javascript/manufacturers/details";

const customSelectStyles = {
  container: (base) => ({
    ...base,
    margin: "0 0 0 0",
  }),
  input: (base) => ({
    ...base,
    fontSize: 14,
  }),
  singleValue: (base) => ({
    ...base,
    fontSize: 12,
  }),
  menu: (base) => ({
    ...base,
    fontSize: 12,
  }),
  menuList: (base) => ({
    ...base,
    maxHeight: 138,
  }),
  placeholder: (base) => ({
    ...base,
    fontSize: 12,
    fontStyle: "italic",
    color: "#718291",
  }),
  control: (base) => ({
    ...base,
    minHeight: 36,
    borderColor: "#718291",
  }),
};

const PharmacySelect = (props) => {
  const [selectedPharmacy, setSelectedPharmacy] = useState();
  const [modalOpened, setModalOpened] = useState(false);

  useEffect(() => {
    if (selectedPharmacy || props.retailHasSelectedSpecialtyPharmacy) {
      props.setHasSelectedPharmacy(true);
    } else {
      props.setHasSelectedPharmacy(false);
    }
  }, [selectedPharmacy, props.retailHasSelectedSpecialtyPharmacy]);

  const handleCloseModal = () => {
    setModalOpened(false);
  };

  const resetState = () => {
    if (selectedPharmacy) {
      setSelectedPharmacy();
    }
  };

  const setDesignations = (selection) => {
    if (props.kind == "retail") {
      handleRetailDesignation(selection);
    } else {
      handleSpecialtyDesignation(selection);
    }
  };

  const handleRetailDesignation = (selection) => {
    // reset if selections are cleared
    if (!selection) {
      props.setRetailHasSelectedSpecialtyPharmacy(false);
      props.setRetailSelectedSpecialtyPharmacy();
      setSelectedPharmacy();

      return;
    }

    // TODO Update function to allow entities to designate two specialty pharmacies if provided by manufacturer policy

    // Check if the selected pharmacy is defined as a specialty kind for current manufacturer
    // You can't select two specialty pharmacies across designation kinds per manufacturer
    if (
      selection[props.manufacturer] &&
      selection[props.manufacturer].length &&
      !["bristol_myers_squibb", "abbvie", "pfizer", "pharmacyclics", "johnson_n_johnson"].includes(props.manufacturer)
    ) {
      props.setRetailHasSelectedSpecialtyPharmacy(true);
      props.setRetailSelectedSpecialtyPharmacy(selection);
    } else {
      props.setRetailHasSelectedSpecialtyPharmacy(false);
      props.setRetailSelectedSpecialtyPharmacy();
    }

    // used to inform parent that selection has been completed
    setSelectedPharmacy(selection);
  };

  const handleSpecialtyDesignation = (selection) => {
    // reset if selections are cleared
    if (!selection) {
      setSelectedPharmacy();

      return;
    }

    // used to inform parent that selection has been completed
    setSelectedPharmacy(selection);
  };

  const customOption = (props) => {
    return (
      <div
        {...props.innerProps}
        style={{ margin: "5px 10px", paddingBottom: 5, borderBottom: "1px solid #F2F5F8", cursor: "pointer" }}
      >
        <div style={{ fontSize: 11, fontWeight: 500 }}>{props.data.label}</div>
        <div style={{ color: "#718291", fontSize: 11 }}>{props.data.address ? props.data.address : "--"}</div>
      </div>
    );
  };

  const renderSelectInput = (pharmacies) => {
    return (
      <Select
        isClearable
        placeholder={"Search Pharmacies"}
        styles={customSelectStyles}
        options={pharmacies}
        components={{ Option: customOption }}
        onChange={(selection) => {
          setDesignations(selection);
        }}
      />
    );
  };

  const renderEditDesignationWithHin = (pharmacies) => {
    return (
      <div>
        <div className="stat-cards__form__fieldset__details">
          <div className="stat-cards__form__fieldset__details__title stat-cards__form__fieldset__details__title--mt">
            Designated pharmacy
          </div>
          {renderSelectInput(pharmacies)}
        </div>
        <div className="stat-cards__form__fieldset__values">
          <div
            className="stat-cards__form__fieldset__details__title stat-cards__form__fieldset__details__title--mt"
            style={{ textAlign: "right" }}
          >
            Health Industry Number (HIN)
          </div>
          <div className="stat-cards__form__fieldset__values__subtitle" style={{ textAlign: "right" }}>
            {selectedPharmacy ? selectedPharmacy.hin : "--"}
          </div>
        </div>
      </div>
    );
  };

  const renderEditDesignationWithOutHin = (pharmacies) => {
    return (
      <div className="stat-cards__form__fieldset__details stat-cards__form__fieldset__details--no-br">
        <div className="stat-cards__form__fieldset__details__title stat-cards__form__fieldset__details__title--mt">
          Designated pharmacy
        </div>
        {renderSelectInput(pharmacies)}
      </div>
    );
  };

  const filterPharmacies = () => {
    if (props.kind == "retail") {
      return props.entity.pharmacies;
    } else {
      return _.filter(props.entity.pharmacies, { [props.manufacturer]: [props.kind] });
    }
  };

  const renderForm = () => {
    var manufacturerDetails = _.find(manufacturers, ["name", props.manufacturer]);
    var pharmacies = filterPharmacies();

    if (pharmacies.length) {
      return manufacturerDetails.hin
        ? renderEditDesignationWithHin(pharmacies)
        : renderEditDesignationWithOutHin(pharmacies);
    } else {
      return renderNoticeEmptyPharmacies();
    }
  };

  const renderHinNotice = (entity) => {
    if (selectedPharmacy) {
      if (selectedPharmacy.hin) return;

      var manufacturerDetails = _.find(manufacturers, ["name", props.manufacturer]);

      if (manufacturerDetails.hin) {
        return (
          <div className="stat-cards__notice" style={{ lineHeight: 1.5 }}>
            <span style={{ fontWeight: 500 }}>You can still save designations </span>
            but our records indicate the selected pharmacy does not have a HIN. You must register a HIN through
            <a href="https://www.hibcc.org/" target="_blank">
              <span style={{ fontWeight: 500, textDecoration: "underline" }}> HIBCC </span>
            </a>
            in order to ship 340B purchased drugs to this pharmacy.
            <a href="http://help.340besp.com/en/articles/4816982-hin-registration-overview" target="_blank">
              <span style={{ fontWeight: 500, textDecoration: "underline" }}> Learn more.</span>
            </a>
          </div>
        );
      }
    }
  };

  // TODO make updates to optional displayed notices
  const renderEntityFlagNotice = () => {
    if (props.entity.designation_permitted_status[props.manufacturer] == "has_grantee_exemption") {
      if (
        ["gilead", "bausch"].includes(props.manufacturer) ||
        (props.manufacturer == "sanofi" && props.entity.entity_type_code == "CH") ||
        (props.manufacturer == "merck" && props.entity.entity_type_code == "CH") ||
        props.disableGranteeNotice
      ) {
        return;
      }

      return renderNoticeHasGranteeExemption();
    }

    if (
      props.entity.designation_permitted_status[props.manufacturer] == "has_wholly_owned_exemption" &&
      !props.disableWhollyOwnedNotice
    ) {
      return renderNoticeHasWhollyOwnedExemption();
    }

    if (
      props.kind != "retail" &&
      props.entity.has_specialty_in_house_pharmacy &&
      !props.disableInHouseSpecialtyNotice
    ) {
      return renderNoticeHasSpecialtyInHousePharmacy();
    }

    if (
      props.entity.designation_permitted_status[props.manufacturer] == "has_inhouse_pharmacy" &&
      !props.disableInHouseNotice
    ) {
      return renderNoticeHasInHousePharmacy();
    }
  };

  const displayWarning = (entity) => {
    var hinRequired = _.find(manufacturers, ["name", props.manufacturer]).hin == true;

    if (hinRequired && selectedPharmacy) {
      return !selectedPharmacy.hin;
    } else {
      return false;
    }
  };

  const renderRemainingDesignationCount = () => {
    var designation_count = props.entity.designations_count[props.manufacturer];
    if (designation_count && designation_count[props.kind]) {
      var count = designation_count[props.kind];
    } else {
      var count = 0;
    }

    var total = 2;
    var remaining = total - count;

    if (remaining < 0) {
      return 0;
    } else {
      return remaining;
    }
  };

  const renderNoticePharmacyKindSelected = () => {
    return (
      <div className="stat-cards__notice stat-cards__notice--blue stat-cards__notice--mb" style={{ lineHeight: 1.5 }}>
        The pharmacy selected in the contract pharmacy designation for retail products is also in the manufacturers
        limited distribution network therefore another specialty pharmacy designation is not permitted.
      </div>
    );
  };

  const renderNoticeEmptyPharmacies = () => {
    if (props.displayImidPharmacyList || props.displayCamzyosPharmacyList) {
      return (
        <div>
          <div
            className="stat-cards__notice stat-cards__notice--blue stat-cards__notice--mb"
            style={{ lineHeight: 1.5 }}
          >
            Our records indicate the selected entity does not have any active contract pharmacy arrangements within this
            limited distribution network. The entity must establish a contract pharmacy arrangement with a pharmacy
            within the limited distribution network to access 340B pricing. Please refer to
            <span
              style={{ fontWeight: 500, textDecoration: "underline", cursor: "pointer", marginLeft: 3, marginRight: 3 }}
              onClick={() => setModalOpened(true)}
            >
              this list
            </span>
            for eligible contract pharmacies within this limited distribution network.
          </div>
        </div>
      );
    } else if (props.manufacturer == "pfizer") {
      return (
        <div className="stat-cards__notice stat-cards__notice--blue stat-cards__notice--mb" style={{ lineHeight: 1.5 }}>
          Our records indicate the selected entity does not have any active contract pharmacy arrangements within
          Pfizer's defined distribution oncology network. The entity must establish a contract pharmacy arrangement with
          a pharmacy within Pfizer's defined distribution oncology network to access 340B pricing. Please reach out to
          340BCP@Pfizer.com for further assistance on this.
        </div>
      );
    } else {
      return (
        <div className="stat-cards__notice stat-cards__notice--blue stat-cards__notice--mb" style={{ lineHeight: 1.5 }}>
          Our records indicate the selected entity does not have any active contract pharmacy arrangements within this
          limited distribution network. The entity must establish a contract pharmacy arrangement with a pharmacy within
          the limited distribution network to access 340B pricing. Please reach out to support@340besp.com for further
          assistance on this.
        </div>
      );
    }
  };

  const renderNoticeHasSpecialtyInHousePharmacy = () => {
    return (
      <div className="stat-cards__notice stat-cards__notice--red stat-cards__notice--mb" style={{ lineHeight: 1.5 }}>
        Our records indicate the selected entity has a pharmacy capable of dispensing 340B purchased specialty drugs to
        its patients. As a result, the entity is not eligible to designate a single contract pharmacy arrangement and
        any designation you make will not impact access to 340B pricing.
        <a
          href={_.find(manufacturers, { name: props.manufacturer }).policy_doc_url}
          download={_.find(manufacturers, { name: props.manufacturer }).policy_doc_name}
        >
          <span style={{ fontWeight: 500, textDecoration: "underline", margin: "0px 5px" }}>
            Download Policy document
          </span>
        </a>
        Please reach out to
        <span style={{ fontWeight: 500 }}> support@340besp.com </span>
        for more information or to clarify the entity's ability to dispense 340B purchased specialty drugs to patients.
      </div>
    );
  };

  const renderNoticeHasGranteeExemption = () => {
    return (
      <div className="stat-cards__notice stat-cards__notice--red stat-cards__notice--mb" style={{ lineHeight: 1.5 }}>
        The selected entity is exempted from this policy and is not required to make a single contract pharmacy
        designation. Any designation you make will not impact your access to 340B pricing.
        <a
          href={_.find(manufacturers, { name: props.manufacturer }).policy_doc_url}
          download={_.find(manufacturers, { name: props.manufacturer }).policy_doc_name}
        >
          <span style={{ fontWeight: 500, textDecoration: "underline", margin: "0px 5px" }}>
            Download Policy document
          </span>
        </a>
        Please reach out to
        <span style={{ fontWeight: 500 }}> support@340besp.com </span>
        if you have any additional questions or if you do not have access to 340B pricing for your contract pharmacies.
      </div>
    );
  };

  const renderNoticeHasWhollyOwnedExemption = () => {
    return (
      <div className="stat-cards__notice stat-cards__notice--red stat-cards__notice--mb" style={{ lineHeight: 1.5 }}>
        Our records indicate the selected entity has already been granted a wholly owned contract pharmacy exemption. As
        a result of this exemption, the entity is not eligible to designate a single contract pharmacy arrangement and
        any designation you make will not impact access to 340B pricing.
        <a
          href={_.find(manufacturers, { name: props.manufacturer }).policy_doc_url}
          download={_.find(manufacturers, { name: props.manufacturer }).policy_doc_name}
        >
          <span style={{ fontWeight: 500, textDecoration: "underline", margin: "0px 5px" }}>
            Download Policy document
          </span>
        </a>
        Please reach out to
        <span style={{ fontWeight: 500 }}> support@340besp.com </span>
        if you have any additional questions or if you do not have access to 340B pricing for your contract pharmacies.
      </div>
    );
  };

  const renderNoticeHasInHousePharmacy = () => {
    return (
      <div className="stat-cards__notice stat-cards__notice--red stat-cards__notice--mb" style={{ lineHeight: 1.5 }}>
        Our records indicate the selected entity has a pharmacy capable of purchasing and dispensing 340B drugs to its
        patients. As a result, the entity is not eligible to designate a single contract pharmacy arrangement and any
        designation you make will not impact access to 340B pricing.
        <a
          href={_.find(manufacturers, { name: props.manufacturer }).policy_doc_url}
          download={_.find(manufacturers, { name: props.manufacturer }).policy_doc_name}
        >
          <span style={{ fontWeight: 500, textDecoration: "underline", margin: "0px 5px" }}>
            Download Policy document
          </span>
        </a>
        Please reach out to
        <span style={{ fontWeight: 500 }}> support@340besp.com </span>
        for more information or to clarify the entity's ability to dispense 340B purchased drugs to patients.
      </div>
    );
  };

  const renderHiddenInputs = () => {
    if (selectedPharmacy) {
      return (
        <div>
          <input type="hidden" name={`designations[][external_contact_email]`} value={props.externalEmailContact} />
          <input type="hidden" name={`designations[][secondary_contact_email]`} value={props.secondaryContactEmail} />
          <input type="hidden" name={`designations[][manufacturer]`} value={props.manufacturer} />
          <input type="hidden" name={`designations[][manufacturer_name]`} value={props.manufacturer} />
          <input type="hidden" name={`designations[][kind]`} value={props.kind} />
          <input type="hidden" name={`designations[][id340B]`} value={props.entity.id_340b} />
          <input type="hidden" name={`designations[][pharmacy_id]`} value={selectedPharmacy.value} />
        </div>
      );
    }
  };

  const renderHiddenInputsForRetailSelectedSpecialtyPharmacy = () => {
    // If retail designation has selected specialty CP create duplicate specialty designation for same manufacturer

    // reset components selected CP
    resetState();

    return (
      <div>
        <input type="hidden" name={`designations[][external_contact_email]`} value={props.externalEmailContact} />
        <input type="hidden" name={`designations[][secondary_contact_email]`} value={props.secondaryContactEmail} />
        <input type="hidden" name={`designations[][manufacturer]`} value={props.manufacturer} />
        <input type="hidden" name={`designations[][manufacturer_name]`} value={props.manufacturer} />
        <input
          type="hidden"
          name={`designations[][kind]`}
          value={props.retailSelectedSpecialtyPharmacy[props.manufacturer]}
        />
        <input type="hidden" name={`designations[][id340B]`} value={props.entity.id_340b} />
        <input type="hidden" name={`designations[][pharmacy_id]`} value={props.retailSelectedSpecialtyPharmacy.value} />
      </div>
    );
  };

  const renderEntityForm = () => {
    if (filterPharmacies().length == 0) {
      return (
        <div
          className={
            displayWarning(props.entity)
              ? "stat-cards__form__fieldset stat-cards__form__fieldset--warning"
              : "stat-cards__form__fieldset"
          }
        >
          <div className="stat-cards__form__fieldset__details stat-cards__form__fieldset__details--no-br">
            <div className="stat-cards__form__fieldset__details__title">{props.entity.entity_name}</div>
            <div className="stat-cards__form__fieldset__details__subtitle">{props.entity.id_340b}</div>
          </div>
          <div className="stat-cards__form__fieldset__values">
            <div className="stat-cards__form__fieldset__values__note">Available Updates</div>
            <div className="stat-cards__form__fieldset__values__subtitle" style={{ textAlign: "right" }}>
              0 remaining
            </div>
          </div>
          {renderNoticeEmptyPharmacies()}
        </div>
      );
    } else if (props.retailHasSelectedSpecialtyPharmacy) {
      // if retail designation has selected a specialty pharmacy, don't allow selections and display notice
      // You can't select two specialty pharmacies across designation kinds per manufacturer

      return (
        <div
          className={
            displayWarning(props.entity)
              ? "stat-cards__form__fieldset stat-cards__form__fieldset--warning"
              : "stat-cards__form__fieldset"
          }
        >
          <div className="stat-cards__form__fieldset__details stat-cards__form__fieldset__details--no-br">
            <div className="stat-cards__form__fieldset__details__title">{props.entity.entity_name}</div>
            <div className="stat-cards__form__fieldset__details__subtitle">{props.entity.id_340b}</div>
          </div>
          <div className="stat-cards__form__fieldset__values">
            <div className="stat-cards__form__fieldset__values__note">Available Updates</div>
            <div className="stat-cards__form__fieldset__values__subtitle" style={{ textAlign: "right" }}>
              0 remaining
            </div>
          </div>
          {renderNoticePharmacyKindSelected()}
          {renderHiddenInputsForRetailSelectedSpecialtyPharmacy()}
        </div>
      );
    } else {
      return (
        <div
          className={
            displayWarning(props.entity)
              ? "stat-cards__form__fieldset stat-cards__form__fieldset--warning"
              : "stat-cards__form__fieldset"
          }
        >
          <div className="stat-cards__form__fieldset__details stat-cards__form__fieldset__details--no-br">
            <div className="stat-cards__form__fieldset__details__title">{props.entity.entity_name}</div>
            <div className="stat-cards__form__fieldset__details__subtitle">{props.entity.id_340b}</div>
          </div>
          <div className="stat-cards__form__fieldset__values">
            <div className="stat-cards__form__fieldset__values__note">Available Updates</div>
            <div className="stat-cards__form__fieldset__values__subtitle" style={{ textAlign: "right" }}>
              {renderRemainingDesignationCount()} remaining
            </div>
          </div>
          {renderEntityFlagNotice()}
          {renderForm()}
          {renderHinNotice(props.entity)}
          {renderHiddenInputs()}
        </div>
      );
    }
  };

  return renderEntityForm();
};

export default PharmacySelect;
