import React, { useState, Fragment } from "react";
import _ from "lodash";
import StyledCheckbox from "./../StyledCheckbox";
import StatusTag from "./../StatusTag";

// TODO: GileadPharmacyTable provides good structure for attestation table

const AttestationList = ({ attestations, attestingPharmacies, setAttestingPharmacies }) => {
  const attestablePharmacies = attestations.filter((attestation) => ["not_started", null].includes(attestation.status));
  const allPharmaciesAttesting =
    attestablePharmacies.length > 0 && Object.keys(attestingPharmacies).length == attestablePharmacies.length;

  const setChecked = (pharmacy_id) => {
    if (!attestingPharmacies[pharmacy_id]) {
      setAttestingPharmacies({ ...attestingPharmacies, [pharmacy_id]: true });
    } else {
      setAttestingPharmacies(_.omit(attestingPharmacies, pharmacy_id));
    }
  };

  const setAllChecked = () => {
    if (allPharmaciesAttesting) {
      setAttestingPharmacies({});
      return;
    }

    const uncheckedPharmacies = {};

    _.each(attestablePharmacies, (cp) => {
      if (!attestingPharmacies[cp.pharmacy_id]) {
        uncheckedPharmacies[cp.pharmacy_id] = true;
      }
    });

    setAttestingPharmacies({ ...attestingPharmacies, ...uncheckedPharmacies });
  };

  const renderPharmacies = () => {
    return attestations.map((pharmacy, i) => {
      const disabled = ["complete", "in_review", "denied"].includes(pharmacy.status);
      const selected = !!attestingPharmacies[pharmacy.pharmacy_id];

      function renderCheckbox() {
        if (disabled) return null;

        return (
          <StyledCheckbox
            disableRipple
            name={`${pharmacy.pharmacy_id}-${pharmacy.id_340b}`}
            color="primary"
            checked={!!attestingPharmacies[pharmacy.pharmacy_id]}
            onChange={() => setChecked(pharmacy.pharmacy_id)}
          />
        );
      }

      function getClassName() {
        if (selected) {
          return "selected";
        }
        return "";
      }

      return (
        <tr key={pharmacy.pharmacy_id} className={`sm ${getClassName()}`}>
          <td className="t--md">{renderCheckbox()}</td>
          <td className="t--md">
            <div className="t--sm t--500">{pharmacy.pharmacy_name}</div>
            <div className="t--sm t--subtitle">{pharmacy.pharmacy_address}</div>
          </td>
          <td className="t--md">{pharmacy.npi || "--"}</td>
          <td className="t--md">
            <StatusTag status={pharmacy.status} />
          </td>
        </tr>
      );
    });
  };

  const renderTable = () => {
    return (
      <div className="attestation_modal__table-container attestation_modal--width">
        <table className="primary">
          <thead>
            <tr className="sm">
              <th>
                <StyledCheckbox
                  disableRipple
                  name="gilead"
                  color="primary"
                  checked={allPharmaciesAttesting}
                  onChange={(e) => setAllChecked(e.target.checked)}
                />
              </th>
              <th>Pharmacy Name</th>
              <th>NPI</th>
              <th>Status</th>
            </tr>
          </thead>

          <tbody>{renderPharmacies()}</tbody>
        </table>
      </div>
    );
  };

  return (
    <>
      <div>
        <div className="t--500 mb--8">
          Please select from the list below all contract pharmacies for which you intend to attest. You may return to
          this process to update your selection at any time.
        </div>
        <div className="t--md">
          If you do not select all, you may return to complete this process for the remaining unattested pharmacies at a
          later date.
          <br />
          <br />
          Need to change or make an update to an attestation or document submission? Please{" "}
          <a
            href="https://help.340besp.com/en/articles/8808065-frequently-asked-questions-faqs"
            target="_blank"
            className="t--blue t--anchor"
          >
            click here
          </a>{" "}
          to chat with our support team or email us at{" "}
          <a href="mailto:support@340besp.com" className="t--blue t--anchor">
            support@340Besp.com
          </a>
          .
        </div>
      </div>
      {renderTable()}
    </>
  );
};

export default AttestationList;
