import React, { useState, useRef, useEffect } from "react";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import SelectCoveredEntity from "./SelectCoveredEntity";
import TermsOfService from "./TermsOfService";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import InputAdornment from "@material-ui/core/InputAdornment";
import { AsYouType, parsePhoneNumberFromString } from "libphonenumber-js";
import Notice from "./Notice";

const CustomTextField = withStyles({
  root: {
    backgroundColor: "transparent",
    borderRadius: 8,
    "& label.Mui-focused": {
      color: "#3D71FF",
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: "green",
    },
    "& .MuiOutlinedInput-root": {
      borderRadius: 8,
      backgroundColor: "#ffffff",
      "& fieldset": {
        borderColor: "#DEE7EE",
      },
      "&:hover fieldset": {
        borderColor: "#DEE7EE",
      },
      "&.Mui-focused fieldset": {
        borderColor: "#3D71FF",
      },
    },
  },
})(TextField);

const RegisterForm = (props) => {
  const [acceptedTerms, setAcceptedTerms] = useState(false);
  const [completedUserDetails, setCompletedUserDetails] = useState(false);
  const [selectedEntity, setSelectedEntity] = useState(false);
  const [authorized, setAuthorized] = React.useState(false);
  const [enableMFA, setEnableMFA] = React.useState(true);
  const [passwordIsMasked, setPasswordIsMasked] = React.useState(true);
  const [validPhoneType, setValidPhoneType] = React.useState();
  const [userDetails, setUserDetails] = React.useState({
    firstName: null,
    lastName: null,
    email: null,
    verifiedEmail: "",
    phoneNumber: "",
    password: "",
  });
  const [notice, setNotice] = React.useState({
    kind: "error",
    open: false,
    message: "",
  });

  var phoneformater = new AsYouType("US");
  const invalidEmailDomains = ["gmail", "outlook", "yahoo", "hotmail"];

  useEffect(() => {
    userDetailsCompleted();
  }, [userDetails, enableMFA, validPhoneType]);

  const fetchPhoneType = () => {
    fetch(`/api/phone/details?number=${userDetails.phoneNumber}`, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "X-CSRF-Token": document.querySelector('meta[name="csrf-token"]').getAttribute("content"),
      },
    })
      .then((res) => res.json())
      .then((data) => {
        setValidPhoneType(data.value);
      });
  };

  const userDetailsCompleted = () => {
    if (
      !isInvalidEmail() &&
      hasVerifiedEmail() &&
      isValidPhoneNumber(userDetails.phoneNumber) &&
      isValidPassword(userDetails.password)
    ) {
      setCompletedUserDetails(true);
    } else {
      if (completedUserDetails) setCompletedUserDetails(false);
    }
  };

  const hasAtLeaseOneLower = (val) => {
    return val.match(/.*[a-z].*/) != null;
  };

  const hasAtLeaseOneUpper = (val) => {
    return val.match(/.*[A-Z].*/) != null;
  };

  const hasAtLeaseOneNumber = (val) => {
    return val.match(/.*[0-9].*/) != null;
  };

  const hasAtLeaseOneSpecial = (val) => {
    return val.match(/.*[\W_].*/) != null;
  };

  const hasAtLeaseEightChars = (val) => {
    return val.length >= 8;
  };

  const isValidPassword = (val) => {
    if (val) {
      return (
        hasAtLeaseOneLower(val) &&
        hasAtLeaseOneUpper(val) &&
        hasAtLeaseOneNumber(val) &&
        hasAtLeaseOneSpecial(val) &&
        hasAtLeaseEightChars(val)
      );
    }
  };

  const isValidPhoneNumber = (val) => {
    if (!enableMFA) return true;

    if (val) {
      var number = parsePhoneNumberFromString("+1" + val);

      if (number && number.isValid()) {
        fetchPhoneType();
      }

      if (number && number.isValid() && validPhoneType) {
        return true;
      } else {
        return false;
      }
    }
  };

  const hasVerifiedEmail = () => {
    return (
      /@/.test(userDetails.email) &&
      /@/.test(userDetails.verifiedEmail) &&
      userDetails.email === userDetails.verifiedEmail
    );
  };

  const isInvalidEmail = () => {
    if (userDetails.email) {
      if (
        userDetails.email.includes("@") &&
        !_.map(invalidEmailDomains, (domain) => userDetails.email.includes(domain)).includes(true)
      ) {
        return false;
      } else {
        return true;
      }
    }
  };

  const togglePasswordMask = () => {
    setPasswordIsMasked(!passwordIsMasked);
  };

  const formatPhoneNumber = (val) => {
    if (val) {
      var number = parsePhoneNumberFromString("+1" + val);

      return number && number.nationalNumber;
    }
  };

  const renderAcceptTermsBtn = () => {
    if (acceptedTerms) {
      return (
        <div className="btn btn--ib btn--wide btn--pull-right" style={{ marginRight: 30 }}>
          <i className="solid solid-budicon-file-tick" />
          Accepted
        </div>
      );
    } else {
      return (
        <div
          className="btn btn--ib btn--wide btn--pull-right"
          style={{ marginRight: 30 }}
          onClick={() => {
            setAcceptedTerms(true);
          }}
        >
          Accept
        </div>
      );
    }
  };

  const enableSubmit = () => {
    return completedUserDetails && selectedEntity && authorized && acceptedTerms;
  };

  const renderPhoneInput = () => {
    if (enableMFA) {
      return (
        <div>
          <CustomTextField
            label="Cell Number"
            margin="normal"
            variant="outlined"
            id="phoneNumber"
            name="phoneNumber"
            fullWidth
            required
            error={validPhoneType == false}
            helperText={validPhoneType == false ? "Please provide your cell number" : null}
            value={userDetails.phoneNumber}
            InputProps={{
              startAdornment: <InputAdornment position="start">+1</InputAdornment>,
            }}
            onChange={(event) => {
              setUserDetails({
                ...userDetails,
                phoneNumber: phoneformater.input(event.target.value),
              });
            }}
          />
          <input type="hidden" value={formatPhoneNumber(userDetails.phoneNumber) || ""} name="user[phone]" />
        </div>
      );
    }
  };

  return (
    <div className="register__wrapper">
      <form method="POST" action="/register" id="register_form">
        <div className="register__form__container">
          <div className="register__step__title">1. Enter your user details.</div>
          <div className="register__form">
            <div className="register__form__dual-row register__form__dual-row--pad-right">
              <CustomTextField
                label="First Name"
                margin="normal"
                variant="outlined"
                id="firstName"
                name="user[first_name]"
                autoFocus
                fullWidth
                required
                onChange={(event) => {
                  setUserDetails({
                    ...userDetails,
                    firstName: event.target.value,
                  });
                }}
              />
            </div>
            <div className="register__form__dual-row register__form__dual-row--pad-left">
              <CustomTextField
                label="Last Name"
                margin="normal"
                variant="outlined"
                id="lastName"
                name="user[last_name]"
                fullWidth
                required
                onChange={(event) => {
                  setUserDetails({
                    ...userDetails,
                    lastName: event.target.value,
                  });
                }}
              />
            </div>
            <div className="register__form__requirements">
              <p>
                <span className="bold">Note: </span>
                Please provide your
                <span className="bold"> Work Email Address.</span>
                <br />
                Accounts are verified by authenticating the 340B covered entity domain with the email address provided.
              </p>
            </div>
            <CustomTextField
              label="Work Email Address"
              margin="normal"
              variant="outlined"
              id="email"
              name="user[email]"
              type="email"
              fullWidth
              required
              error={isInvalidEmail()}
              helperText={
                userDetails.email && isInvalidEmail()
                  ? "Email is not valid - Please provide a valid Work Email Address for this covered entity."
                  : null
              }
              onChange={(event) => {
                setUserDetails({
                  ...userDetails,
                  email: event.target.value,
                });
              }}
            />
            <CustomTextField
              label="Confirm Email Address"
              margin="normal"
              variant="outlined"
              id="email_verify"
              name="email_verify"
              fullWidth
              required
              error={userDetails.verifiedEmail ? !hasVerifiedEmail(userDetails.verifiedEmail) : false}
              helperText={
                userDetails.verifiedEmail && !hasVerifiedEmail(userDetails.verifiedEmail) ? "Emails do not match" : null
              }
              onChange={(event) => {
                setUserDetails({
                  ...userDetails,
                  verifiedEmail: event.target.value,
                });
              }}
            />
            <div className="register__form__requirements">
              <FormControlLabel
                control={
                  <Checkbox
                    checked={enableMFA}
                    onChange={() => {
                      setEnableMFA(!enableMFA);
                    }}
                    color="primary"
                  />
                }
                label="Two-Factor Authentication"
              />
              <input type="hidden" value={!enableMFA} name="user[disabled_2fa]" />
              <p>
                <span className="bold">Enable Two-Factor Authentication </span>
                to add an extra layer of security. If enabled you will be required to enter an authorization code that
                will be sent to your
                <span className="bold"> Cell Number </span>
                upon login - this option can be updated in your account settings.
              </p>
            </div>
            {renderPhoneInput()}
            <CustomTextField
              label="Password"
              margin="normal"
              variant="outlined"
              id="password"
              name="user[password]"
              type={passwordIsMasked ? "password" : "text"}
              error={userDetails.password ? !isValidPassword(userDetails.password) : false}
              fullWidth
              required
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <div style={{ cursor: "pointer" }} onClick={togglePasswordMask}>
                      <span
                        style={{
                          color: "#718291",
                          fontSize: 14,
                          marginRight: 10,
                          verticalAlign: "top",
                          lineHeight: "20px",
                        }}
                      >
                        {passwordIsMasked ? "show" : "hide"}
                      </span>
                      <i style={{ fontSize: 20 }} className="solid solid-budicon-eye-sight" />
                    </div>
                  </InputAdornment>
                ),
              }}
              onChange={(event) => {
                setUserDetails({
                  ...userDetails,
                  password: event.target.value,
                });
              }}
            />
            <div className="register__form__requirements">
              <ul>
                <li className={hasAtLeaseOneLower(userDetails.password) ? "success" : null}>One lowercase character</li>
                <li className={hasAtLeaseOneUpper(userDetails.password) ? "success" : null}>One uppercase character</li>
                <li className={hasAtLeaseOneNumber(userDetails.password) ? "success" : null}>One number</li>
              </ul>
            </div>
            <div className="register__form__requirements">
              <ul>
                <li className={hasAtLeaseOneSpecial(userDetails.password) ? "success" : null}>One special character</li>
                <li className={hasAtLeaseEightChars(userDetails.password) ? "success" : null}>8 characters minimum</li>
              </ul>
            </div>
          </div>

          <SelectCoveredEntity coveredEntities={props.coveredEntities} completeSelection={setSelectedEntity} />

          <div className="register__step__title">4. Authorize your registration.</div>
          <div className="register__form">
            <p>
              Are you authorized on behalf of the selected Covered Entity(s) to register an account with 340B ESP? You
              are required to accept our terms and conditions in order to continue the registration process.
            </p>

            <FormControlLabel
              control={
                <Checkbox
                  checked={authorized}
                  onChange={() => {
                    setAuthorized(!authorized);
                  }}
                  color="primary"
                />
              }
              label="I am authorized"
            />
          </div>

          <div className="register__step__title">5. Agree to our Terms of Use and Privacy Policy.</div>
          <div className="register__form register__form--scroll">{<TermsOfService />}</div>
          <a
            href="/Terms of Use_340BESP_October 2024.pdf"
            download="Terms of Use_340BESP_October 2024.pdf"
          >
            <div className="btn btn--ib btn--white btn--outline btn--wide">Download</div>
          </a>
          <a href="/">
            <div className="btn btn--ib btn--white btn--outline btn--wide btn--pull-right">Decline</div>
          </a>
          {renderAcceptTermsBtn()}
        </div>

        <div className="register__billboard">
          <div className="register__billboard__title">Register with 340B ESP</div>
          <div className="register__billboard__subtitle">All steps and fields are required *</div>
          <div className="register__billboard__step__container">
            <div
              className={
                completedUserDetails
                  ? "register__billboard__step register__billboard__step--complete"
                  : "register__billboard__step"
              }
            >
              1
            </div>
            <div className="register__billboard__step__details">
              <div className="register__billboard__step__details__title">Provide your details</div>
              <div className="register__billboard__step__details__subtitle">Create your account</div>
            </div>
          </div>

          <div className="register__billboard__step__container">
            <div
              className={
                selectedEntity
                  ? "register__billboard__step register__billboard__step--complete"
                  : "register__billboard__step"
              }
            >
              2
            </div>
            <div className="register__billboard__step__details">
              <div className="register__billboard__step__details__title">Covered Entity</div>
              <div className="register__billboard__step__details__subtitle">Select your covered entity</div>
            </div>
          </div>

          <div className="register__billboard__step__container">
            <div
              className={
                authorized
                  ? "register__billboard__step register__billboard__step--complete"
                  : "register__billboard__step"
              }
            >
              3
            </div>
            <div className="register__billboard__step__details">
              <div className="register__billboard__step__details__title">Authorized Signatory</div>
              <div className="register__billboard__step__details__subtitle">Confirm authorization</div>
            </div>
          </div>

          <div className="register__billboard__step__container">
            <div
              className={
                acceptedTerms
                  ? "register__billboard__step register__billboard__step--complete"
                  : "register__billboard__step"
              }
            >
              4
            </div>
            <div className="register__billboard__step__details">
              <div className="register__billboard__step__details__title">Terms & Privacy</div>
              <div className="register__billboard__step__details__subtitle">Review and accept terms</div>
            </div>
          </div>

          <button
            className={enableSubmit() ? "btn" : "btn btn--disabled"}
            disabled={enableSubmit() ? false : true}
            type="submit"
          >
            Register
          </button>

          <div className="register__billboard__footernote">
            Need Help? Contact Us
            <div className="register__billboard__footernote__contact">support@340besp.com</div>
          </div>
        </div>
      </form>
    </div>
  );
};

export default RegisterForm;
