export default class Http {
  static applicationJsonHeader = {
    Accept: "application/json",
    "Content-Type": "application/json",
    "X-CSRF-Token": document.querySelector('meta[name="csrf-token"]').getAttribute("content"),
  };

  static errorNotice = {
    kind: "error",
    open: true,
    message: "An Error occurred - please try again or contact support.",
  };

  static async getData(organizationId, setPharmCertData, setPharmCertStats, setNotice) {
    fetch(`/organization/${organizationId}/in_house_attestations`, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "X-CSRF-Token": document.querySelector('meta[name="csrf-token"]').getAttribute("content"),
      },
    })
      .then((res) => {
        if (res.ok) {
          return res.json();
        } else {
          throw Error(res.statusText);
        }
      })
      .then((data) => {
        const { stats, attestations } = data;

        setPharmCertData(attestations);
        setPharmCertStats(stats);
      })
      .catch((error) => {
        setNotice({
          kind: "error",
          open: true,
          message: "An Error occurred - please try again or contact support.",
        });
      });
  }

  static upload(organizationId, entityId, id_340b, npi, attachments, attestations, setAttestations, setNotice, onUploadComplete) {
    const endpoint = `/organization/${organizationId}/entities/${entityId}/in_house_attestations/uploads`;

    const upload_body = {
      pharmacy_licenses: {
        id_340b: {
          npi: attachments,
        },
      },
    };

    const form = new FormData();

    if (attachments.pharmacy_license) {
      form.append(`pharmacy_licenses[${id_340b}][${npi}]`, attachments.pharmacy_license);
    }

    if (attachments.additional_documents.length > 0) {
      attachments.additional_documents.forEach((doc, index) => {
        form.append(`additional_documents[${id_340b}][${npi}][${index}]`, doc);
      });
    }

    fetch(endpoint, {
      method: "POST",
      headers: {
        "X-CSRF-Token": document.querySelector('meta[name="csrf-token"]').getAttribute("content"),
      },
      body: form,
    })
      .then((res) => {
        onUploadComplete();

        if (res.ok) {
          return res.json();
        } else {
          throw Error(res.statusText);
        }
      })
      .then((data) => {
        this.#updateCertifications(data.in_house_attestations, attestations, setAttestations);
        setNotice({ kind: "success", open: true, message: data.notice });
      })
      .catch((error) => {
        setNotice({
          kind: "error",
          open: true,
          message: "An Error occurred - please try again or contact support.",
        });
      });
  }

  static async submitAttestation(
    organizationId,
    entityId,
    id_340b,
    in_house,
    signed_by,
    attestations,
    setAttestations,
    setStats,
    setNotice
  ) {
    const endpoint = `/organization/${organizationId}/entities/${entityId}/in_house_attestations/attest`;

    try {
      const res = await fetch(endpoint, {
        method: "POST",
        headers: this.applicationJsonHeader,
        body: JSON.stringify({
          in_house_attestation: {
            signed_by,
            in_house,
            id_340b,
          },
        }),
      });
      if (!res.ok) throw Error(res.statusText);

      const data = await res.json();

      this.#updateAttestation(data.in_house_attestation, attestations, setAttestations);
      setStats(data.stats);
      setNotice({ kind: "success", open: true, message: data.notice });

      return true;
    } catch (error) {
      setNotice(this.errorNotice);

      return false;
    }
  }

  static #updateAttestation(updatedAttestation, attestations, setAttestations) {
    const newAttestations = attestations.map((attestation) => {
      if (attestation.id_340b == updatedAttestation.id_340b) {
        return updatedAttestation;
      }
      return attestation;
    });

    setAttestations(newAttestations);
  }

  static #updateAttestations(updatedAttestations, attestations, setAttestations) {
    const updatedAttestationsMap = new Map(
      updatedAttestations.map((attestation) => [attestation.id_340b, attestation])
    );

    const newAttestations = attestations.map(
      (attestation) => updatedAttestationsMap.get(attestation.id_340b) || attestation
    );

    setAttestations(newAttestations);
  }

  static #updateCertifications(updatedAttestations, attestations, setAttestations) {
    const updatedAttestationsMap = new Map(
      updatedAttestations.map((attestation) => [attestation.id_340b, attestation])
    );

    const newAttestations = attestations.map((attestation) => {
      const updatedAttestation = updatedAttestationsMap.get(attestation.id_340b);

      if (updatedAttestation) {
        return {
          ...attestation,
          in_house_certifications: updatedAttestation.in_house_certifications,
        };
      }

      return attestation;
    });

    setAttestations(newAttestations);
  }
}
