import React, { useState } from "react";
import _ from "lodash";
import Tooltip from "@material-ui/core/Tooltip";
import Fade from "@material-ui/core/Fade";
import { withStyles } from "@material-ui/core/styles";
import {
  AbbviePharmacyTable,
  AlkermesPharmacyTable,
  AmgenPharmacyTable,
  AstellasPharmacyTable,
  AstrazenecaPharmacyTable,
  BauschPharmacyTable,
  BauschNLombPharmacyTable,
  BayerPharmacyTable,
  BiPharmacyTable,
  BiogenPharmacyTable,
  BmsPharmacyTable,
  EisaiPharmacyTable,
  EliLillyPharmacyTable,
  EmdSeronoPharmacyTable,
  ExelixisPharmacyTable,
  GenentechPharmacyTable,
  GileadPharmacyTable,
  GskPharmacyTable,
  IncytePharmacyTable,
  JazzPharmacyTable,
  JnJPharmacyTable,
  LiquidiaPharmacyTable,
  MerckPharmacyTable,
  NovartisPharmacyTable,
  NovoNordiskPharmacyTable,
  OrganonPharmacyTable,
  PfizerPharmacyTable,
  SandozPharmacyTable,
  SanofiPharmacyTable,
  SobiPharmacyTable,
  SumitomoPharmacyTable,
  TakedaPharmacyTable,
  TevaPharmacyTable,
  UcbPharmacyTable,
  VertexPharmacyTable,
  ViatrisPharmacyTable,
  AllPharmaciesTable as AllPharmacyTable,
} from "./PharmacyTables";
import {
  AbbvieDesignationsForm,
  AlkermesDesignationsForm,
  AmgenDesignationsForm,
  AstellasDesignationsForm,
  AstrazenecaDesignationsForm,
  BauschDesignationsForm,
  BauschNLombDesignationsForm,
  BayerDesignationsForm,
  BiDesignationsForm,
  BiogenDesignationsForm,
  BmsDesignationsForm,
  EisaiDesignationsForm,
  EliLillyDesignationsForm,
  EmdSeronoDesignationsForm,
  ExelixisDesignationsForm,
  GenentechDesignationsForm,
  GileadDesignationsForm,
  GskDesignationsForm,
  IncyteDesignationsForm,
  JazzDesignationsForm,
  JnjDesignationsForm,
  LiquidiaDesignationsForm,
  MerckDesignationsForm,
  NovartisDesignationsForm,
  NovoNordiskDesignationsForm,
  OrganonDesignationsForm,
  PfizerDesignationsForm,
  SandozDesignationsForm,
  SanofiDesignationsForm,
  SobiDesignationsForm,
  SumitomoDesignationsForm,
  TakedaDesignationsForm,
  TevaDesignationsForm,
  UcbDesignationsForm,
  VertexDesignationsForm,
  ViatrisDesignationsForm,
} from "./DesignationForms";
import EntityDropDownSelector from "./entityDropDownSelector";
import ManufacturerSelect from "./ManufacturerSelect";
import manufacturers from "./manufacturers";
import PolicyBadges from "./PolicyBadges";

const EntityDetails = (props) => {
  const [selectedManufacturer, setSelectedManufacturer] = useState(manufacturers[0]);
  const [isDesignationsModalOpen, setIsDesignationsModalOpen] = useState(false);

  const componentMapping = {
    all: {
      PharmacyTable: AllPharmacyTable,
      DesignationsForm: null,
      additionalProps: {
        claims_count: props.claims_count,
        mfr_designations: props.mfr_designations,
        eligibility: props.eligibility,
      },
    },
    abbvie: {
      PharmacyTable: AbbviePharmacyTable,
      DesignationsForm: AbbvieDesignationsForm,
      additionalProps: {
        eligibility: props.eligibility.abbvie,
      },
    },
    alkermes: {
      PharmacyTable: AlkermesPharmacyTable,
      DesignationsForm: AlkermesDesignationsForm,
      additionalProps: {
        eligibility: props.eligibility.alkermes,
      },
    },
    amgen: {
      PharmacyTable: AmgenPharmacyTable,
      DesignationsForm: AmgenDesignationsForm,
      additionalProps: {
        eligibility: props.eligibility.amgen,
      },
    },
    astellas: {
      PharmacyTable: AstellasPharmacyTable,
      DesignationsForm: AstellasDesignationsForm,
      additionalProps: {
        eligibility: props.eligibility.astellas,
      },
    },
    astrazeneca: {
      PharmacyTable: AstrazenecaPharmacyTable,
      DesignationsForm: AstrazenecaDesignationsForm,
      additionalProps: {
        eligibility: props.eligibility.astrazeneca,
      },
    },
    bausch: {
      PharmacyTable: BauschPharmacyTable,
      DesignationsForm: BauschDesignationsForm,
      additionalProps: {
        eligibility: props.eligibility.bausch,
        submitPath: props.attestationSubmitPath,
      },
    },
    bausch_n_lomb: {
      PharmacyTable: BauschNLombPharmacyTable,
      DesignationsForm: BauschNLombDesignationsForm,
      additionalProps: {
        eligibility: props.eligibility.bausch_n_lomb,
        submitPath: props.attestationSubmitPath,
      },
    },
    bayer: {
      PharmacyTable: BayerPharmacyTable,
      DesignationsForm: BayerDesignationsForm,
      additionalProps: {
        eligibility: props.eligibility.bayer,
      },
    },
    biogen: {
      PharmacyTable: BiogenPharmacyTable,
      DesignationsForm: BiogenDesignationsForm,
      additionalProps: {
        eligibility: props.eligibility.biogen,
        submitPath: props.attestationSubmitPath,
      },
    },
    bi: {
      PharmacyTable: BiPharmacyTable,
      DesignationsForm: BiDesignationsForm,
      additionalProps: {
        eligibility: props.eligibility.boehringer_ingelheim,
      },
    },
    bms: {
      PharmacyTable: BmsPharmacyTable,
      DesignationsForm: BmsDesignationsForm,
      additionalProps: {
        eligibility: props.eligibility.bristol_myers_squibb,
      },
    },
    eisai: {
      PharmacyTable: EisaiPharmacyTable,
      DesignationsForm: EisaiDesignationsForm,
      additionalProps: {
        eligibility: props.eligibility.eisai,
        submitPath: props.attestationSubmitPath,
      },
    },
    "eli lilly": {
      PharmacyTable: EliLillyPharmacyTable,
      DesignationsForm: EliLillyDesignationsForm,
      additionalProps: {
        eligibility: props.eligibility.eli_lilly,
      },
    },
    "emd serono": {
      PharmacyTable: EmdSeronoPharmacyTable,
      DesignationsForm: EmdSeronoDesignationsForm,
      additionalProps: {
        eligibility: props.eligibility.emd_serono,
      },
    },
    exelixis: {
      PharmacyTable: ExelixisPharmacyTable,
      DesignationsForm: ExelixisDesignationsForm,
      additionalProps: {
        eligibility: props.eligibility.exelixis,
      },
    },
    genentech: {
      PharmacyTable: GenentechPharmacyTable,
      DesignationsForm: GenentechDesignationsForm,
      additionalProps: {
        eligibility: props.eligibility.genentech,
      },
    },
    gilead: {
      PharmacyTable: GileadPharmacyTable,
      DesignationsForm: GileadDesignationsForm,
      additionalProps: {
        eligibility: props.eligibility.gilead,
        submitPath: props.attestationSubmitPath,
      },
    },
    gsk: {
      PharmacyTable: GskPharmacyTable,
      DesignationsForm: GskDesignationsForm,
      additionalProps: {
        eligibility: props.eligibility.glaxosmithkline,
        submitPath: props.attestationSubmitPath,
      },
    },
    incyte: {
      PharmacyTable: IncytePharmacyTable,
      DesignationsForm: IncyteDesignationsForm,
      additionalProps: {
        eligibility: props.eligibility.incyte,
        submitPath: props.attestationSubmitPath,
      },
    },
    jazz: {
      PharmacyTable: JazzPharmacyTable,
      DesignationsForm: JazzDesignationsForm,
      additionalProps: {
        eligibility: props.eligibility.jazz,
        submitPath: props.attestationSubmitPath,
      },
    },
    jnj: {
      PharmacyTable: JnJPharmacyTable,
      DesignationsForm: JnjDesignationsForm,
      additionalProps: {
        eligibility: props.eligibility.johnson_n_johnson,
        geoJson: props.geo_json,
      },
    },
    liquidia: {
      PharmacyTable: LiquidiaPharmacyTable,
      DesignationsForm: LiquidiaDesignationsForm,
      additionalProps: {
        eligibility: props.eligibility.liquidia,
      },
    },
    merck: {
      PharmacyTable: MerckPharmacyTable,
      DesignationsForm: MerckDesignationsForm,
      additionalProps: {
        eligibility: props.eligibility.merck,
      },
    },
    novartis: {
      PharmacyTable: NovartisPharmacyTable,
      DesignationsForm: NovartisDesignationsForm,
      additionalProps: {
        eligibility: props.eligibility.novartis,
        submitPath: props.attestationSubmitPath,
      },
    },
    "novo nordisk": {
      PharmacyTable: NovoNordiskPharmacyTable,
      DesignationsForm: NovoNordiskDesignationsForm,
      additionalProps: {
        eligibility: props.eligibility.novo_nordisk,
        submitPath: props.attestationSubmitPath,
      },
    },
    organon: {
      PharmacyTable: OrganonPharmacyTable,
      DesignationsForm: OrganonDesignationsForm,
      additionalProps: {
        eligibility: props.eligibility.organon,
        submitPath: props.attestationSubmitPath,
      },
    },
    pfizer: {
      PharmacyTable: PfizerPharmacyTable,
      DesignationsForm: PfizerDesignationsForm,
      additionalProps: {
        eligibility: props.eligibility.pfizer,
      },
    },
    sanofi: {
      PharmacyTable: SanofiPharmacyTable,
      DesignationsForm: SanofiDesignationsForm,
      additionalProps: {
        eligibility: props.eligibility.sanofi,
        submitPath: props.attestationSubmitPath,
      },
    },
    sandoz: {
      PharmacyTable: SandozPharmacyTable,
      DesignationsForm: SandozDesignationsForm,
      additionalProps: {
        eligibility: props.eligibility.sandoz,
        submitPath: props.attestationSubmitPath,
      },
    },
    sobi: {
      PharmacyTable: SobiPharmacyTable,
      DesignationsForm: SobiDesignationsForm,
      additionalProps: {
        eligibility: props.eligibility.sobi,
      },
    },
    sumitomo: {
      PharmacyTable: SumitomoPharmacyTable,
      DesignationsForm: SumitomoDesignationsForm,
      additionalProps: {
        eligibility: props.eligibility.sumitomo,
      },
    },
    takeda: {
      PharmacyTable: TakedaPharmacyTable,
      DesignationsForm: TakedaDesignationsForm,
      additionalProps: {
        eligibility: props.eligibility.takeda,
        submitPath: props.attestationSubmitPath,
      },
    },
    teva: {
      PharmacyTable: TevaPharmacyTable,
      DesignationsForm: TevaDesignationsForm,
      additionalProps: {
        eligibility: props.eligibility.teva,
        submitPath: props.attestationSubmitPath,
      },
    },
    ucb: {
      PharmacyTable: UcbPharmacyTable,
      DesignationsForm: UcbDesignationsForm,
      additionalProps: {
        eligibility: props.eligibility.ucb,
      },
    },
    vertex: {
      PharmacyTable: VertexPharmacyTable,
      DesignationsForm: VertexDesignationsForm,
      additionalProps: {
        eligibility: props.eligibility.vertex,
      },
    },
    viatris: {
      PharmacyTable: ViatrisPharmacyTable,
      DesignationsForm: ViatrisDesignationsForm,
      additionalProps: {
        eligibility: props.eligibility.viatris,
      },
    },
  };

  const getManufacturerComponents = () => {
    const selectedManufacturerKey = selectedManufacturer.value.toLowerCase();
    const manufacturerComponents = componentMapping[selectedManufacturerKey];

    const { PharmacyTable, DesignationsForm, additionalProps } = manufacturerComponents;
    const pharmacyTableProps = {
      entity: props.entity,
      pharmacies: props.entity.pharmacies,
      entity_designations_status: props.entity_designations_status,
      designations_path: props.designations_path,
      ...additionalProps,
    };

    return (
      <>
        <PharmacyTable {...pharmacyTableProps} />
        {DesignationsForm && (
          <DesignationsForm {...props} isOpen={isDesignationsModalOpen} setIsOpen={setIsDesignationsModalOpen} />
        )}
      </>
    );
  };

  const renderDesignationsButton = () => (
    <button
      className={`btn btn--sm details__header__designations-button ${allManufacturersSelected ? "btn--disabled" : ""}`}
      onClick={() => {
        setIsDesignationsModalOpen(!isDesignationsModalOpen);
      }}
      disabled={allManufacturersSelected}
    >
      <span className="material-symbols-rounded size-15">file_present</span>
      <span className="details__header__designations-button__label">Make Designations</span>
    </button>
  );

  const allManufacturersSelected = selectedManufacturer.value === "all";

  const CustomTooltip = withStyles((theme) => ({
    tooltip: {
      backgroundColor: "#fff",
      color: "#25265e",
      maxWidth: "150px",
      fontSize: "12px",
      border: "1px solid #dadde9",
      boxShadow: "0 6px 14px 0 rgba(76, 84, 153, 0.2)",
      borderRadius: "8px",
    },
  }))(Tooltip);

  const manufacturer = manufacturers.find(({ value }) => value === selectedManufacturer.value);

  const entityDetails = () => {
    return (
      <div className="details__header">
        <div className="details__header__partition">
          <EntityDropDownSelector
            entity={props.entity}
            entities={props.entities}
            path={props.path}
            organization={props.organization}
          />
        </div>
        <div className="details__header__partition">
          <div className="details__header__partition__item">
            <div className="details__header__partition__item__title">Administrator</div>
            <div className="details__header__partition__item__content">
              <div className="profile__avatar">
                <div className="profile__avatar__initials">{props.admin_initials}</div>
              </div>
              <div className="details__header__partition__item__content__details">
                <div className="details__header__partition__item__content__details__title">{props.admin_name}</div>
                <div className="details__header__partition__item__content__details__subtitle">{props.admin_email}</div>
              </div>
            </div>
          </div>
          <div className="details__header__partition__item">
            <div className="details__header__partition__item__title">Eligibility</div>
            <a href={props.eligibility_file_url}>
              <div className="details__header__partition__item__content">
                <div className="details__header__partition__item__content__action-icon">
                  <span className="material-symbols-rounded size-18">download</span>
                </div>
                <div className="details__header__partition__item__content__details">
                  <div className="details__header__partition__item__content__details__title">Download File</div>
                  <div className="details__header__partition__item__content__details__subtitle">
                    340B ESP Eligibility
                  </div>
                </div>
              </div>
            </a>
          </div>
        </div>
        <div className="details__header__section__info__container details__header__section__info__container--mt">
          <div className="details__header__section__info__item">
            <div className="details__header__section__info__item__title">340B ID</div>
            <div className="details__header__section__info__item__value">{props.entity.id_340b}</div>
          </div>
          <div className="details__header__section__info__item">
            <div className="details__header__section__info__item__title">Entity Type</div>
            <div className="details__header__section__info__item__value">{props.entity.entity_type_code}</div>
          </div>
          <div className="details__header__section__info__item">
            <div className="details__header__section__info__item__title">Primary Contact</div>
            <div className="details__header__section__info__item__value">{props.entity.primary_contact_name}</div>
          </div>
          <div className="details__header__section__info__item">
            <div className="details__header__section__info__item__title">Authorizing Official</div>
            <div className="details__header__section__info__item__value">{props.entity.authorizing_official_name}</div>
          </div>
        </div>
        <div className="details__header__section__info__container details__header__section__info__container--bb">
          <div className="details__header__section__info__item">
            <div className="details__header__section__info__item__title">340B ESP Registration</div>
            <div className="details__header__section__info__item__value">{props.entity.registered_at}</div>
          </div>
          <div className="details__header__section__info__item">
            <div className="details__header__section__info__item__title">Designations</div>
            <div className="details__header__section__info__item__value">{props.entity.designations_count}</div>
          </div>
          <div className="details__header__section__info__item">
            <div className="details__header__section__info__item__title">Contracted Pharmacies</div>
            <div className="details__header__section__info__item__value">{props.entity.pharmacies.length}</div>
          </div>
          <div className="details__header__section__info__item">
            <div className="details__header__section__info__item__title">Users</div>
            <div className="details__header__section__info__item__value">{props.user_count}</div>
          </div>
        </div>
        <div className="details__header__tab_bar">
          <ManufacturerSelect
            manufacturers={manufacturers}
            selectedManufacturer={selectedManufacturer}
            setSelectedManufacturer={setSelectedManufacturer}
          />
          <div className="details__header__tab_bar__badges">
            <div className="details__header__tab_bar__badges__label">Policies</div>
            <div className="details__header__tab_bar__badges__content">
              {allManufacturersSelected ? (
                <p className="details__header__tab_bar__badges__instruction">
                  Select a Manufacturer to view their policies
                </p>
              ) : (
                <PolicyBadges manufacturer={manufacturer} />
              )}
            </div>
          </div>
          <div className="details__header__tab_bar__button">
            {allManufacturersSelected ? (
              <CustomTooltip
                TransitionComponent={Fade}
                TransitionProps={{ timeout: 350 }}
                placement="top"
                title="Select a manufacturer to make designations."
              >
                <span>{renderDesignationsButton()}</span>
              </CustomTooltip>
            ) : (
              renderDesignationsButton()
            )}
          </div>
        </div>
      </div>
    );
  };

  return (
    <>
      {entityDetails()}
      <div className="content__caddy">{getManufacturerComponents()}</div>
    </>
  );
};

export default EntityDetails;
