import React from "react";
import { Check } from "@material-ui/icons";
import { CheckBox, CheckBoxOutlineBlank } from "@mui/icons-material";

const MultiChipSelector = ({ options, selected, handleSelection }) => {
  const isChipSelected = (chip) => selected.includes(chip);

  const renderChipContent = (value, label) => {
    if (options.length === 1) {
      return (
        <>
          {isChipSelected(value) && (
            <span style={{ display: "inline-flex", marginRight: 5, fontSize: 14 }}>
              <Check fontSize="inherit" />
            </span>
          )}
          <span className="chip__content__label--no-relative-position">
            {label}
          </span>
        </>
      );
    } else {
      return (
        <span className="chip__content">
          {isChipSelected(value)
            ? <CheckBox className="chip__content__checkbox" sx={{ fontSize: "13px" }} />
            : <CheckBoxOutlineBlank className="chip__content__checkbox" sx={{ fontSize: "13px" }} />
          }
          <span className="chip__content__label">{label}</span>
        </span>
      );
    }
  };

  return options.map(({ label, value }) => {
    return (
      <button
        className={isChipSelected(value) ? "chip chip--selected" : "chip"}
        key={value}
        onClick={() => handleSelection(value)}
      >
        {renderChipContent(value, label)}
      </button>
    );
  });
};

export default MultiChipSelector;