import React, { useState, useEffect, useRef } from "react";
import _ from "lodash";

const Menu = (props) => {
  const [displayFormMenu, setDisplayFormMenu] = useState(false);
  const mouseHasEnteredMenu = useRef(false);
  const mouseOnTrigger = useRef(false);

  useEffect(() => {
    handleMenuClose()
  }, [displayFormMenu]);

  // check if mouse has entered menu, otherwise close menu after 1 sec
  const handleMenuClose = () => {
    setTimeout(() => {
      if (!mouseHasEnteredMenu.current && !mouseOnTrigger.current) {
        setDisplayFormMenu(false)
      }
    }, 1000);
  }

  const handleMenuMouseEvents = (event) => {
    if (event == "enter") {
      mouseHasEnteredMenu.current = true;
    } else {
      handleMenuClose();
      mouseHasEnteredMenu.current = false
    }
  }

  const handleTriggerMouseEvents = (event) => {
    if (event == "enter") {
      setDisplayFormMenu(true);
      mouseOnTrigger.current = true;
    } else {
      handleMenuClose();
      mouseOnTrigger.current = false;
    }
  }
  const renderFormDropdown = () => {
    if (displayFormMenu) {

      return (
        <div className="menu__dropdown" onMouseEnter={() => { handleMenuMouseEvents("enter") }} onMouseLeave={() => { handleMenuMouseEvents("leave") }}>
          <div className="menu__dropdown__title">
            Exemptions
          </div>
          <a href="/designations">
            <div className="menu__dropdown__item">
              <div className="menu__dropdown__item__logo">
                <i className="solid solid-budicon-doc-marker"></i>
              </div>
              <div className="menu__dropdown__item__container">
                <div className="menu__dropdown__item__title">
                  Designations
                </div>
                <div className="menu__dropdown__item__subtitle">
                  Single Contract Pharmacy
                </div>
              </div>
            </div>
          </a>
          <a href="/wholly_owned_application">
            <div className="menu__dropdown__item">
              <div className="menu__dropdown__item__logo">
                <i className="solid solid-budicon-notepad"></i>
              </div>
              <div className="menu__dropdown__item__container">
                <div className="menu__dropdown__item__title">
                  Wholly Owned Exemption
                </div>
                <div className="menu__dropdown__item__subtitle">
                  Wholly Owned Contract Pharmacy
                </div>
              </div>
            </div>
          </a>
        </div>
      )
    }
  }

  return (
    <div className="row no-gutters">
      <div className="col-12 col-md-3">
        <a href="/">
          <div className="menu__logo" />
        </a>
      </div>
      <div className="col-12 col-md-9">
        <div className="menu__item__container">
          <a href="/about">
            <div className="menu__item">
              About Us
            </div>
          </a>
          <a href="https://help.340besp.com/en/articles/8808065-frequently-asked-questions-faqs" target="_blank">
            <div className="menu__item">
              FAQs
            </div>
          </a>
          <a href="/contacts">
            <div className="menu__item">
              Contact
            </div>
          </a>
          <a href="/resources">
            <div className="menu__item">
              Resources
            </div>
          </a>
          <div className="menu__item" onMouseEnter={() => { handleTriggerMouseEvents('enter') }} onMouseLeave={() => { handleTriggerMouseEvents('leave') }}>
            Forms
            {renderFormDropdown()}
          </div>
          <a href="/register">
            <div className="menu__item">
              SignUp
            </div>
          </a>
          <div className="span d-none d-md-block">
            <a href="/login">
              <div className="btn btn--navy btn--ib"> Login
              </div>
            </a>
          </div>
        </div>
      </div>
    </div>
  )
};
export default Menu;
