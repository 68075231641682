import React from "react";
import { CheckCircle, CheckCircleOutline } from "@mui/icons-material";
import StatusTag from "./../StatusTag";

const UploadList = ({
  attestations,
  setAttestations,
  pharmacyAgreements,
  setPharmacyAgreements,
  pharmacyUploads,
  setPharmacyUploads,
}) => {
  const handleFileRemoval = (pharmacyId) => {
    setPharmacyUploads((prevState) => {
      const newPharmacyUploads = { ...prevState };
      delete newPharmacyUploads[pharmacyId];
      return newPharmacyUploads;
    });
  };

  const handleFileAttachment = (event, pharmacyId) => {
    const file = event.target.files[0];
    setPharmacyUploads((prevState) => ({
      ...prevState,
      [pharmacyId]: file,
    }));
  };

  const renderCheckIcon = (attestation) => {
    if (["in_review", "complete", "denied"].includes(attestation.status)) {
      return <CheckCircle style={{ color: "#3246d3" }} />;
    }

    if (pharmacyUploads[attestation.pharmacy_id]) {
      return <CheckCircleOutline style={{ color: "#3246d3" }} />;
    }

    return <CheckCircleOutline style={{ color: "#adbbc8" }} />;
  };

  const renderUploadButton = (attestation) => {
    if (["in_review", "complete", "denied"].includes(attestation.status)) {
      return null;
    }

    if (pharmacyUploads[attestation.pharmacy_id]) {
      return (
        <button className="btn btn--light btn--lg" onClick={() => handleFileRemoval(attestation.pharmacy_id)}>
          Delete
        </button>
      );
    }

    return (
      <div>
        <button
          className="btn btn--light btn--lg"
          onClick={() => document.getElementById(`pharmacy-agreement-${attestation.pharmacy_id}`).click()}
        >
          Upload
        </button>
        <input
          type="file"
          style={{ display: "none" }}
          className="form-control"
          name={`attestation-${attestation.pharmacy_id}[pharmacy-agreement]`}
          id={`pharmacy-agreement-${attestation.pharmacy_id}`}
          multiple={false}
          onChange={(e) => handleFileAttachment(e, attestation.pharmacy_id)}
        />
      </div>
    );
  };

  const renderPharmacies = () => {
    return attestations.map((pharmacy) => {
      return (
        <tr key={pharmacy.pharmacy_id} className="sm">
          <td className="t--md">
            <div className="flex align-items-center justify-content-center">{renderCheckIcon(pharmacy)}</div>
          </td>
          <td className="t--md">
            <div className="t--sm t--500">{pharmacy.pharmacy_name}</div>
            <div className="t--sm t--subtitle">{pharmacy.pharmacy_address}</div>
          </td>
          <td className="t--md">{pharmacy.npi || "--"}</td>
          <td className="t--md">
            <StatusTag status={pharmacy.status} />
          </td>
          <td>{renderUploadButton(pharmacy)}</td>
        </tr>
      );
    });
  };

  const renderTable = () => {
    return (
      <div className="attestation_modal__table-container">
        <table className="primary ">
          <thead>
            <tr className="sm">
              <th></th>
              <th>Pharmacy Name</th>
              <th>NPI</th>
              <th>Status</th>
              <th></th>
            </tr>
          </thead>

          <tbody>{renderPharmacies()}</tbody>
        </table>
      </div>
    );
  };

  return (
    <>
      <div>
        <div className="t--500 mb--8">
          Please select from the list of contract pharmacies below all contract pharmacies for which you intend to
          submit contract pharmacy agreements.
        </div>
        <div className="t--md">
          Please upload a current, true and correct contract pharmacy agreement for each selected contract pharmacy. The
          contract pharmacy agreement must demonstrate that you retain legal title to Sanofi 340B-priced drugs at the
          contract pharmacy. Documentation standards and applicable definitions are listed in Sanofi’s Policy Document
          at{" "}
          <a className="t--anchor t--blue" href="/resources" target="_blank">
            340Besp.com/resources
          </a>
          . To upload multiple documents, please merge all documents into a single PDF. This can be done using a PDF
          editor to combine the files. You may return to this process to update your submissions at any time.
          <br />
          <br />
          Need to change or make an update to an attestation or document submission? Please{" "}
          <a
            href="https://help.340besp.com/en/articles/8808065-frequently-asked-questions-faqs"
            target="_blank"
            className="t--blue t--anchor"
          >
            click here
          </a>{" "}
          to chat with our support team or email us at{" "}
          <a href="mailto:support@340besp.com" className="t--blue t--anchor">
            support@340Besp.com
          </a>
          .
        </div>
      </div>
      {renderTable()}
    </>
  );
};

export default UploadList;
