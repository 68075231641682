import React, { useState, useEffect } from "react";
import verifyImage from "images/verify_account_illustration.png";

const ListPharmaciesModal = (props) => {
  const flolan_pharmacies = [
    {
      name: "PROCARE PHARMACY, L.L.C.",
      suite: "",
      street: "979 EAST THIRD STREET",
      city: "CHATTANOOGA",
      state: "TN",
      postcode: "37403",
    },
    {
      name: "CVS/SPECIALTY OR CARELONRX SPECIALTY PHA",
      suite: "",
      street: "1001 SPINKS ROAD, STE 280",
      city: "FLOWER MOUND",
      state: "TX",
      postcode: "75028",
    },
    {
      name: "WALGREEN CO.",
      suite: "",
      street: "601 E KING ST",
      city: "KINGS MOUNTAIN",
      state: "NC",
      postcode: "28086",
    },
    {
      name: "PROCARE PHARMACY, L.L.C.",
      suite: "",
      street: "10160 INTERNATIONAL BLVD.",
      city: "WEST CHESTER",
      state: "OH",
      postcode: "45246",
    },
    {
      name: "ACCREDO HEALTH GROUP, INC.",
      suite: "",
      street: "677 ALA MOANA BLVD STE 404",
      city: "HONOLULU",
      state: "HI",
      postcode: "96813",
    },
    {
      name: "CAREMARK, L.L.C.",
      suite: "",
      street: "7251 S. EASTERN AVE.",
      city: "LAS VEGAS",
      state: "NV",
      postcode: "89119",
    },
    {
      name: "PROCARE PHARMACY, L.L.C.",
      suite: "500 ALA MOANA BLVD., BLDG 1",
      street: "ONE WATERFRONT PLAZA",
      city: "HONOLULU",
      state: "HI",
      postcode: "96813",
    },
    {
      name: "CAREMARK PUERTO RICO SPECIALTY PHARMACY,",
      suite: "SUITE 2",
      street: "280 AVE JESUS T PINERO",
      city: "SAN JUAN",
      state: "PR",
      postcode: "927",
    },
    {
      name: "ACCREDO HEALTH GROUP INC",
      suite: "",
      street: "261 CEDAR HILL ST",
      city: "MARLBOROUGH",
      state: "MA",
      postcode: "1752",
    },
    {
      name: "CAREMARK MASSACHUSETTS SPECIALTY PHARMAC",
      suite: "",
      street: "25 BIRCH STREET, BLDG B, SUITE 100",
      city: "MILFORD",
      state: "MA",
      postcode: "1757",
    },
    {
      name: "LYNNFIELD DRUG INC",
      suite: "",
      street: "12 KENT WAY STE 120F",
      city: "BYFIELD",
      state: "MA",
      postcode: "1922",
    },
    {
      name: "PROCARE PHARMACY, L.L.C.",
      suite: "",
      street: "35 KNEELAND STREET",
      city: "BOSTON",
      state: "MA",
      postcode: "2111",
    },
    {
      name: "CVS PHARMACY, INC.",
      suite: "",
      street: "350 LONGWOOD AVENUE",
      city: "BOSTON",
      state: "MA",
      postcode: "2115",
    },
    {
      name: "PROCARE PHARMACY, L.L.C.",
      suite: "",
      street: "350 LONGWOOD AVE., STE B",
      city: "BOSTON",
      state: "MA",
      postcode: "2115",
    },
    {
      name: "CAREMARK NEW JERSEY SPECIALTY PHCY, LLC",
      suite: "",
      street: "180 PASSAIC AVE UNIT B-5",
      city: "FAIRFIELD",
      state: "NJ",
      postcode: "7004",
    },
    {
      name: "ACCREDO HEALTH GROUP, INC.",
      suite: "",
      street: "45 ROUTE 46 EAST",
      city: "PINE BROOK",
      state: "NJ",
      postcode: "7058",
    },
    {
      name: "PROCARE PHARMACY, L.L.C.",
      suite: "",
      street: "126 EIGHTH AVE.",
      city: "NEW YORK",
      state: "NY",
      postcode: "10011",
    },
    {
      name: "AHG OF NEW YORK, INC",
      suite: "",
      street: "125 CLEARBROOK RD STE 122",
      city: "ELMSFORD",
      state: "NY",
      postcode: "10523",
    },
    {
      name: "PROCARE PHARMACY DIRECT, L.L.C.",
      suite: "",
      street: "105 MALL BOULEVARD",
      city: "MONROEVILLE",
      state: "PA",
      postcode: "15146",
    },
    {
      name: "ACCREDO HEALTH GROUP INC",
      suite: "",
      street: "3000 ERICSSON DRIVE, SUITE 100",
      city: "WARRENDALE",
      state: "PA",
      postcode: "15086",
    },
    {
      name: "BIOPARTNERS IN CARE INC",
      suite: "",
      street: "11411 STRANG LINE RD",
      city: "LENEXA",
      state: "KS",
      postcode: "66215",
    },
    {
      name: "PROCARE PHARMACY, L.L.C.",
      suite: "",
      street: "1117 WALNUT ST.",
      city: "PHILADELPHIA",
      state: "PA",
      postcode: "19107",
    },
    {
      name: "PROCARE PHARMACY, L.L.C.",
      suite: "",
      street: "1601 17TH STREET NW",
      city: "WASHINGTON",
      state: "DC",
      postcode: "20009",
    },
    {
      name: "ACCREDO HEALTH GROUP INC",
      suite: "",
      street: "422 GALLIMORE DAIRY RD",
      city: "GREENSBORO",
      state: "NC",
      postcode: "27409",
    },
    {
      name: "CAREMARK NORTH CAROLINA SPECIALTY PHARMA",
      suite: "",
      street: "10700 WORLD TRADE BLVD STE 110",
      city: "RALEIGH",
      state: "NC",
      postcode: "27617",
    },
    {
      name: "PROCARE PHARMACY, LLC",
      suite: "",
      street: "601 S. KINGS DR.",
      city: "CHARLOTTE",
      state: "NC",
      postcode: "28204",
    },
    {
      name: "PROCARE PHARMACY, L.L.C.",
      suite: "",
      street: "3250 HARDEN ST. EXT. SUITE #300",
      city: "COLUMBIA",
      state: "SC",
      postcode: "29203",
    },
    {
      name: "ACCREDO HEALTH GROUP INC",
      suite: "",
      street: "190 TECHNOLOGY PKWY STE 120",
      city: "NORCROSS",
      state: "GA",
      postcode: "30092",
    },
    {
      name: "ENCOMPASS RX, LLC",
      suite: "",
      street: "2700 NORTHEAST EXPRESSWAY",
      city: "ATLANTA",
      state: "GA",
      postcode: "30345",
    },
    {
      name: "PROCARE PHARMACY, L.L.C.",
      suite: "",
      street: "560 AMSTERDAM AVE, STE #E",
      city: "ATLANTA",
      state: "GA",
      postcode: "30306",
    },
    {
      name: "ADVANCED CARE SCRIPTS, INC",
      suite: "SUITE 101",
      street: "6251 CHANCELLOR DRIVE",
      city: "ORLANDO",
      state: "FL",
      postcode: "32809",
    },
    {
      name: "ACCREDO HEALTH GROUP, INC.",
      suite: "",
      street: "6272 LEE VISTA BLVD STE 100",
      city: "ORLANDO",
      state: "FL",
      postcode: "32822",
    },
    {
      name: "CAREMARK FLORIDA SPECIALTY PHARMACY LLC",
      suite: "",
      street: "7930 WOODLAND CENTER BLVD STE 500",
      city: "TAMPA",
      state: "FL",
      postcode: "33614",
    },
    {
      name: "OPTION CARE ENTERPRISES, INC.",
      suite: "SUITE 430",
      street: "2100 RIVERCHASE CENTER",
      city: "HOOVER",
      state: "AL",
      postcode: "35244",
    },
    {
      name: "PROCARE PHARMACY, L.L.C.",
      suite: "",
      street: "1521 4TH AVE., SOUTH",
      city: "BIRMINGHAM",
      state: "AL",
      postcode: "35233",
    },
    {
      name: "ACCREDO HEALTH GROUP INC",
      suite: "",
      street: "2100 RIVERCHASE CTR STE 405",
      city: "HOOVER",
      state: "AL",
      postcode: "35244",
    },
    {
      name: "HOMECHOICE PARTNERS, INC.",
      suite: "",
      street: "2100 RIVERCHASE CENTER STE 314",
      city: "BIRMINGHAM",
      state: "AL",
      postcode: "35244",
    },
    {
      name: "ERLANGER PHARMACIES",
      suite: "",
      street: "979 EAST THIRD ST. SUITE 120",
      city: "CHATTANOOGA",
      state: "TN",
      postcode: "37403",
    },
    {
      name: "CAREMARK TENNESSEE SPECIALTY PHARMACY, L",
      suite: "SUITE 107",
      street: "8370 WOLF LAKE DRIVE",
      city: "BARTLETT",
      state: "TN",
      postcode: "38133",
    },
    {
      name: "ACCREDO HEALTH GROUP INC",
      suite: "",
      street: "1620 CENTURY CENTER PKWY",
      city: "MEMPHIS",
      state: "TN",
      postcode: "38134",
    },
    {
      name: "MEDCO HEALTH SOLUTIONS OF INDIANA, LLC",
      suite: "",
      street: "4750 E. 450 S.",
      city: "WHITESTOWN",
      state: "IN",
      postcode: "46075",
    },
    {
      name: "ACCREDO HEALTH GROUP, INC.",
      suite: "",
      street: "2825 W PERIMETER RD STE 112",
      city: "INDIANAPOLIS",
      state: "IN",
      postcode: "46241",
    },
    {
      name: "CAREMARK MICHIGAN SPECIALTY PHARMACY, LL",
      suite: "",
      street: "1307 ALLEN DR STE H",
      city: "TROY",
      state: "MI",
      postcode: "48083",
    },
    {
      name: "ACCREDO HEALTH GROUP INC",
      suite: "",
      street: "39625 LEWIS DR",
      city: "NOVI",
      state: "MI",
      postcode: "48377",
    },
    {
      name: "ACCREDO HEALTH GROUP INC",
      suite: "",
      street: "2915 WATERS RD STE 109",
      city: "EAGAN",
      state: "MN",
      postcode: "55121",
    },
    {
      name: "CAREMARK ILLINOIS MAIL PHARMACY, LLC",
      suite: "SUITE A",
      street: "800 BIERMANN COURT",
      city: "MOUNT PROSPECT",
      state: "IL",
      postcode: "60056",
    },
    {
      name: "CAREMARK ILLINOIS SPECIALTY PHARMACY, LL",
      suite: "",
      street: "800 BIERMANN CT STE B",
      city: "MOUNT PROSPECT",
      state: "IL",
      postcode: "60056",
    },
    {
      name: "CAREMARK,  L.L.C.",
      suite: "SUITE A",
      street: "1127 BRYN MAWR AVE",
      city: "REDLANDS",
      state: "CA",
      postcode: "92374",
    },
    {
      name: "ACCREDO HEALTH GROUP, INC.",
      suite: "",
      street: "11411 STRANG LINE RD",
      city: "LENEXA",
      state: "KS",
      postcode: "66215",
    },
    {
      name: "CAREMARK KANSAS SPECIALTY PHARMACY",
      suite: "",
      street: "11162 RENNER BLVD",
      city: "LENEXA",
      state: "KS",
      postcode: "66219",
    },
    {
      name: "ACCREDO HEALTH GROUP INC",
      suite: "",
      street: "19060 Q ST STE 101",
      city: "OMAHA",
      state: "NE",
      postcode: "68135",
    },
    {
      name: "ACCREDO HEALTH GROUP INC",
      suite: "",
      street: "520 ELMWOOD PARK BLVD STE 100",
      city: "NEW ORLEANS",
      state: "LA",
      postcode: "70123",
    },
    {
      name: "ACCREDO HEALTH GROUP INC",
      suite: "",
      street: "4901 W RENO AVE STE 950",
      city: "OKLAHOMA CITY",
      state: "OK",
      postcode: "73127",
    },
    {
      name: "ACCREDO HEALTH GROUP INC",
      suite: "",
      street: "4343 W ROYAL LN STE 124",
      city: "IRVING",
      state: "TX",
      postcode: "75063",
    },
    {
      name: "PROCARE PHARMACY, L.L.C.",
      suite: "",
      street: "426 S. HENDERSON STREET",
      city: "FORT WORTH",
      state: "TX",
      postcode: "76104",
    },
    {
      name: "ACCREDO HEALTH GROUP INC",
      suite: "",
      street: "9307 KIRBY DR",
      city: "HOUSTON",
      state: "TX",
      postcode: "77054",
    },
    {
      name: "PROCARE PHARMACY, L.L.C.",
      suite: "",
      street: "7950 FLOYD CURL DR. STE #106",
      city: "SAN ANTONIO",
      state: "TX",
      postcode: "78229",
    },
    {
      name: "ACCREDO HEALTH GROUP INC",
      suite: "",
      street: "650 W GRAND AVE STE 102",
      city: "ELMHURST",
      state: "IL",
      postcode: "60126",
    },
    {
      name: "ACCREDO HEALTH GROUP INC",
      suite: "",
      street: "361 INVERNESS DR S STE F",
      city: "ENGLEWOOD",
      state: "CO",
      postcode: "80112",
    },
    {
      name: "PROCARE PHARMACY, L.L.C.",
      suite: "",
      street: "2101 N URSULA STREET UNIT 35",
      city: "AURORA",
      state: "CO",
      postcode: "80045",
    },
    {
      name: "ACCREDO HEALTH GROUP INC",
      suite: "",
      street: "3488 S MAIN ST",
      city: "SALT LAKE CITY",
      state: "UT",
      postcode: "84115",
    },
    {
      name: "PROCARE PHARMACY, L.L.C.",
      suite: "",
      street: "1002 EAST MCDOWELL ROAD",
      city: "PHOENIX",
      state: "AZ",
      postcode: "85006",
    },
    {
      name: "ACCREDO HEALTH GROUP INC",
      suite: "",
      street: "2040 W RIO SALADO PKWY STE 101B",
      city: "TEMPE",
      state: "AZ",
      postcode: "85281",
    },
    {
      name: "ACCREDO HEALTH GROUP INC",
      suite: "",
      street: "212 CARPENTERS UNION WAY",
      city: "LAS VEGAS",
      state: "NV",
      postcode: "89119",
    },
    {
      name: "PROCARE PHARMACY, L.L.C.",
      suite: "",
      street: "8607 SANTA MONICA BOULEVARD",
      city: "WEST HOLLYWOOD",
      state: "CA",
      postcode: "90069",
    },
    {
      name: "PROCARE PHARMACY, L.L.C.",
      suite: "",
      street: "2 DUDLEY STREET SUITE 170",
      city: "PROVIDENCE",
      state: "RI",
      postcode: "2905",
    },
    {
      name: "PROCARE PHARMACY, L.L.C.",
      suite: "",
      street: "1010 UNIVERSITY AVENUE",
      city: "SAN DIEGO",
      state: "CA",
      postcode: "92103",
    },
    {
      name: "ACCREDO HEALTH GROUP INC",
      suite: "",
      street: "2410 WARDLOW RD",
      city: "CORONA",
      state: "CA",
      postcode: "92878",
    },
    {
      name: "PROCARE PHARMACY, L.L.C.",
      suite: "",
      street: "445 CASTRO STREET",
      city: "SAN FRANCISCO",
      state: "CA",
      postcode: "94114",
    },
    {
      name: "PROCARE PHARMACY, L.L.C.",
      suite: "",
      street: "1309 NW 23RD AVE.",
      city: "PORTLAND",
      state: "OR",
      postcode: "97210",
    },
    {
      name: "ACCREDO HEALTH GROUP INC",
      suite: "",
      street: "22623 68TH AVE S",
      city: "KENT",
      state: "WA",
      postcode: "98032",
    },
    {
      name: "PROCARE PHARMACY, L.L.C.",
      suite: "",
      street: "1001 MADISON ST.",
      city: "SEATTLE",
      state: "WA",
      postcode: "98104",
    },
    {
      name: "EXPRESS SCRIPTS PHARMACY, INC.",
      suite: "",
      street: "4750 E. 450 S.",
      city: "WHITESTOWN",
      state: "IN",
      postcode: "46075",
    },
    {
      name: "PROCARE PHARMACY, L.L.C.",
      suite: "SUITE 104",
      street: "7205 CORPORATE CENTER DRIVE",
      city: "MIAMI",
      state: "FL",
      postcode: "33126",
    },
    {
      name: "ACCREDO HEALTH GROUP, INC.",
      suite: "",
      street: "2 BOULDEN CIR STE 1",
      city: "NEW CASTLE",
      state: "DE",
      postcode: "19720",
    },
  ];

  const zejula_pharmacies = [
    {
      name: "ACCREDO HEALTH GROUP, INC.",
      suite: "",
      street: "2 BOULDEN CIR STE 1",
      city: "NEW CASTLE",
      state: "DE",
      postcode: "19720",
    },
    {
      name: "OPTUM PHARMACY 707, INC",
      suite: "",
      street: "4900 RIVERGRADE RD STE E110",
      city: "IRWINDALE",
      state: "CA",
      postcode: "91706",
    },
    {
      name: "AVELLA OF DEER VALLEY, INC. #38",
      suite: "",
      street: "24416 N. 19TH AVE",
      city: "PHOENIX",
      state: "AZ",
      postcode: "85085",
    },
    {
      name: "AVELLA OF DEER VALLEY, INC. #38",
      suite: "",
      street: "24416 N. 19TH AVE",
      city: "PHOENIX",
      state: "AZ",
      postcode: "85085",
    },
    {
      name: "PROCARE PHARMACY, L.L.C.",
      suite: "",
      street: "979 EAST THIRD STREET",
      city: "CHATTANOOGA",
      state: "TN",
      postcode: "37403",
    },
    {
      name: "CVS/SPECIALTY OR CARELONRX SPECIALTY PHA",
      suite: "",
      street: "1001 SPINKS ROAD, STE 280",
      city: "FLOWER MOUND",
      state: "TX",
      postcode: "75028",
    },
    {
      name: "WALGREEN CO.",
      suite: "",
      street: "601 E KING ST",
      city: "KINGS MOUNTAIN",
      state: "NC",
      postcode: "28086",
    },
    {
      name: "PROCARE PHARMACY, L.L.C.",
      suite: "",
      street: "10160 INTERNATIONAL BLVD.",
      city: "WEST CHESTER",
      state: "OH",
      postcode: "45246",
    },
    {
      name: "OPTUM PHARMACY 801, INC.",
      suite: "",
      street: "24416 N 19TH AVE",
      city: "PHOENIX",
      state: "AZ",
      postcode: "85085",
    },
    {
      name: "OPTUM PHARMACY 700, LLC",
      suite: "",
      street: "19 CHAPIN RD, BLDG D, STE D8",
      city: "PINE BROOK",
      state: "NJ",
      postcode: "7058",
    },
    {
      name: "ACCREDO HEALTH GROUP, INC.",
      suite: "",
      street: "677 ALA MOANA BLVD STE 404",
      city: "HONOLULU",
      state: "HI",
      postcode: "96813",
    },
    {
      name: "OPTUM PHARMACY 700, LLC",
      suite: "",
      street: "5700 DOT COM CT, STE 1000",
      city: "OVIEDO",
      state: "FL",
      postcode: "32765",
    },
    {
      name: "AVELLA OF DEER VALLEY, INC. #38",
      suite: "",
      street: "24416 N. 19TH AVE.",
      city: "PHOENIX",
      state: "AZ",
      postcode: "85085",
    },
    {
      name: "OPTUM PHARMACY 701, LLC",
      suite: "",
      street: "8131 W BOSTIAN RD STE A350",
      city: "WOODINVILLE",
      state: "WA",
      postcode: "98072",
    },
    {
      name: "CAREMARK, L.L.C.",
      suite: "",
      street: "7251 S. EASTERN AVE.",
      city: "LAS VEGAS",
      state: "NV",
      postcode: "89119",
    },
    {
      name: "BIOLOGICS, INC.",
      suite: "",
      street: "11800 WESTON PARKWAY",
      city: "CARY",
      state: "NC",
      postcode: "27513",
    },
    {
      name: "PROCARE PHARMACY, L.L.C.",
      suite: "500 ALA MOANA BLVD., BLDG 1",
      street: "ONE WATERFRONT PLAZA",
      city: "HONOLULU",
      state: "HI",
      postcode: "96813",
    },
    {
      name: "OPTUM INFUSION SERVICES 501, INC.",
      suite: "",
      street: "8131 W BOSTIAN RD",
      city: "WOODINVILLE",
      state: "WA",
      postcode: "98072",
    },
    {
      name: "OPTUM PHARMACY 701, LLC",
      suite: "",
      street: "4015 SHOPTON RD STE 600",
      city: "CHARLOTTE",
      state: "NC",
      postcode: "28217",
    },
    {
      name: "CAREMARK PUERTO RICO SPECIALTY PHARMACY,",
      suite: "SUITE 2",
      street: "280 AVE JESUS T PINERO",
      city: "SAN JUAN",
      state: "PR",
      postcode: "927",
    },
    {
      name: "OPTUM PHARMACY 701, LLC",
      suite: "",
      street: "4100 S SAGINAW ST STE D",
      city: "FLINT",
      state: "MI",
      postcode: "48507",
    },
    {
      name: "ACCREDO HEALTH GROUP INC",
      suite: "",
      street: "261 CEDAR HILL ST",
      city: "MARLBOROUGH",
      state: "MA",
      postcode: "1752",
    },
    {
      name: "CAREMARK MASSACHUSETTS SPECIALTY PHARMAC",
      suite: "",
      street: "25 BIRCH STREET, BLDG B, SUITE 100",
      city: "MILFORD",
      state: "MA",
      postcode: "1757",
    },
    {
      name: "OPTUM INFUSION SERVICES 550, LLC",
      suite: "",
      street: "20 COMMERCE WAY",
      city: "WOBURN",
      state: "MA",
      postcode: "1801",
    },
    {
      name: "LYNNFIELD DRUG INC",
      suite: "",
      street: "12 KENT WAY STE 120F",
      city: "BYFIELD",
      state: "MA",
      postcode: "1922",
    },
    {
      name: "PROCARE PHARMACY, L.L.C.",
      suite: "",
      street: "35 KNEELAND STREET",
      city: "BOSTON",
      state: "MA",
      postcode: "2111",
    },
    {
      name: "CVS PHARMACY, INC.",
      suite: "",
      street: "350 LONGWOOD AVENUE",
      city: "BOSTON",
      state: "MA",
      postcode: "2115",
    },
    {
      name: "PROCARE PHARMACY, L.L.C.",
      suite: "",
      street: "350 LONGWOOD AVE., STE B",
      city: "BOSTON",
      state: "MA",
      postcode: "2115",
    },
    {
      name: "CAREMARK NEW JERSEY SPECIALTY PHCY, LLC",
      suite: "",
      street: "180 PASSAIC AVE UNIT B-5",
      city: "FAIRFIELD",
      state: "NJ",
      postcode: "7004",
    },
    {
      name: "ACCREDO HEALTH GROUP, INC.",
      suite: "",
      street: "45 ROUTE 46 EAST",
      city: "PINE BROOK",
      state: "NJ",
      postcode: "7058",
    },
    {
      name: "PROCARE PHARMACY, L.L.C.",
      suite: "",
      street: "126 EIGHTH AVE.",
      city: "NEW YORK",
      state: "NY",
      postcode: "10011",
    },
    {
      name: "AHG OF NEW YORK, INC",
      suite: "",
      street: "125 CLEARBROOK RD STE 122",
      city: "ELMSFORD",
      state: "NY",
      postcode: "10523",
    },
    {
      name: "OPTUM PHARMACY 706, INC.",
      suite: "",
      street: "30-30   47TH AVENUE",
      city: "LONG ISLAND CITY",
      state: "NY",
      postcode: "11101",
    },
    {
      name: "PROCARE PHARMACY DIRECT, L.L.C.",
      suite: "",
      street: "105 MALL BOULEVARD",
      city: "MONROEVILLE",
      state: "PA",
      postcode: "15146",
    },
    {
      name: "ACCREDO HEALTH GROUP INC",
      suite: "",
      street: "3000 ERICSSON DRIVE, SUITE 100",
      city: "WARRENDALE",
      state: "PA",
      postcode: "15086",
    },
    {
      name: "BIOPARTNERS IN CARE INC",
      suite: "",
      street: "11411 STRANG LINE RD",
      city: "LENEXA",
      state: "KS",
      postcode: "66215",
    },
    {
      name: "PROCARE PHARMACY, L.L.C.",
      suite: "",
      street: "1117 WALNUT ST.",
      city: "PHILADELPHIA",
      state: "PA",
      postcode: "19107",
    },
    {
      name: "PROCARE PHARMACY, L.L.C.",
      suite: "",
      street: "1601 17TH STREET NW",
      city: "WASHINGTON",
      state: "DC",
      postcode: "20009",
    },
    {
      name: "ACCREDO HEALTH GROUP INC",
      suite: "",
      street: "422 GALLIMORE DAIRY RD",
      city: "GREENSBORO",
      state: "NC",
      postcode: "27409",
    },
    {
      name: "CAREMARK NORTH CAROLINA SPECIALTY PHARMA",
      suite: "",
      street: "10700 WORLD TRADE BLVD STE 110",
      city: "RALEIGH",
      state: "NC",
      postcode: "27617",
    },
    {
      name: "PROCARE PHARMACY, LLC",
      suite: "",
      street: "601 S. KINGS DR.",
      city: "CHARLOTTE",
      state: "NC",
      postcode: "28204",
    },
    {
      name: "PROCARE PHARMACY, L.L.C.",
      suite: "",
      street: "3250 HARDEN ST. EXT. SUITE #300",
      city: "COLUMBIA",
      state: "SC",
      postcode: "29203",
    },
    {
      name: "ACCREDO HEALTH GROUP INC",
      suite: "",
      street: "190 TECHNOLOGY PKWY STE 120",
      city: "NORCROSS",
      state: "GA",
      postcode: "30092",
    },
    {
      name: "ENCOMPASS RX, LLC",
      suite: "",
      street: "2700 NORTHEAST EXPRESSWAY",
      city: "ATLANTA",
      state: "GA",
      postcode: "30345",
    },
    {
      name: "PROCARE PHARMACY, L.L.C.",
      suite: "",
      street: "560 AMSTERDAM AVE, STE #E",
      city: "ATLANTA",
      state: "GA",
      postcode: "30306",
    },
    {
      name: "DIPLOMAT SPECIALTY PHARMACY",
      suite: "",
      street: "4100 S SAGINAW ST",
      city: "FLINT",
      state: "MI",
      postcode: "48507",
    },
    {
      name: "ADVANCED CARE SCRIPTS, INC",
      suite: "SUITE 101",
      street: "6251 CHANCELLOR DRIVE",
      city: "ORLANDO",
      state: "FL",
      postcode: "32809",
    },
    {
      name: "ACCREDO HEALTH GROUP, INC.",
      suite: "",
      street: "6272 LEE VISTA BLVD STE 100",
      city: "ORLANDO",
      state: "FL",
      postcode: "32822",
    },
    {
      name: "OPTUM INFUSION SERVICES 201, INC.",
      suite: "",
      street: "5700 DOT COM CT STE 1030",
      city: "OVIEDO",
      state: "FL",
      postcode: "32765",
    },
    {
      name: "CAREMARK FLORIDA SPECIALTY PHARMACY LLC",
      suite: "",
      street: "7930 WOODLAND CENTER BLVD STE 500",
      city: "TAMPA",
      state: "FL",
      postcode: "33614",
    },
    {
      name: "OPTION CARE ENTERPRISES, INC.",
      suite: "SUITE 430",
      street: "2100 RIVERCHASE CENTER",
      city: "HOOVER",
      state: "AL",
      postcode: "35244",
    },
    {
      name: "PROCARE PHARMACY, L.L.C.",
      suite: "",
      street: "1521 4TH AVE., SOUTH",
      city: "BIRMINGHAM",
      state: "AL",
      postcode: "35233",
    },
    {
      name: "OPTUM PHARMACY 705, LLC",
      suite: "",
      street: "1100 LEE BRANCH LN",
      city: "BIRMINGHAM",
      state: "AL",
      postcode: "35242",
    },
    {
      name: "ACCREDO HEALTH GROUP INC",
      suite: "",
      street: "2100 RIVERCHASE CTR STE 405",
      city: "HOOVER",
      state: "AL",
      postcode: "35244",
    },
    {
      name: "HOMECHOICE PARTNERS, INC.",
      suite: "",
      street: "2100 RIVERCHASE CENTER STE 314",
      city: "BIRMINGHAM",
      state: "AL",
      postcode: "35244",
    },
    {
      name: "OPTUM PHARMACY 705, LLC",
      suite: "",
      street: "1893 GENERAL GEORGE PATTON DR",
      city: "FRANKLIN",
      state: "TN",
      postcode: "37067",
    },
    {
      name: "ERLANGER PHARMACIES",
      suite: "",
      street: "979 EAST THIRD ST. SUITE 120",
      city: "CHATTANOOGA",
      state: "TN",
      postcode: "37403",
    },
    {
      name: "CAREMARK TENNESSEE SPECIALTY PHARMACY, L",
      suite: "SUITE 107",
      street: "8370 WOLF LAKE DRIVE",
      city: "BARTLETT",
      state: "TN",
      postcode: "38133",
    },
    {
      name: "ACCREDO HEALTH GROUP INC",
      suite: "",
      street: "1620 CENTURY CENTER PKWY",
      city: "MEMPHIS",
      state: "TN",
      postcode: "38134",
    },
    {
      name: "OPTUM PHARMACY 705, LLC",
      suite: "",
      street: "3539 BLUECUTT RD",
      city: "COLUMBUS",
      state: "MS",
      postcode: "39705",
    },
    {
      name: "MEDCO HEALTH SOLUTIONS OF INDIANA, LLC",
      suite: "",
      street: "4750 E. 450 S.",
      city: "WHITESTOWN",
      state: "IN",
      postcode: "46075",
    },
    {
      name: "ACCREDO HEALTH GROUP, INC.",
      suite: "",
      street: "2825 W PERIMETER RD STE 112",
      city: "INDIANAPOLIS",
      state: "IN",
      postcode: "46241",
    },
    {
      name: "CAREMARK MICHIGAN SPECIALTY PHARMACY, LL",
      suite: "",
      street: "1307 ALLEN DR STE H",
      city: "TROY",
      state: "MI",
      postcode: "48083",
    },
    {
      name: "ACCREDO HEALTH GROUP INC",
      suite: "",
      street: "39625 LEWIS DR",
      city: "NOVI",
      state: "MI",
      postcode: "48377",
    },
    {
      name: "ACCREDO HEALTH GROUP INC",
      suite: "",
      street: "2915 WATERS RD STE 109",
      city: "EAGAN",
      state: "MN",
      postcode: "55121",
    },
    {
      name: "CAREMARK ILLINOIS MAIL PHARMACY, LLC",
      suite: "SUITE A",
      street: "800 BIERMANN COURT",
      city: "MOUNT PROSPECT",
      state: "IL",
      postcode: "60056",
    },
    {
      name: "CAREMARK ILLINOIS SPECIALTY PHARMACY, LL",
      suite: "",
      street: "800 BIERMANN CT STE B",
      city: "MOUNT PROSPECT",
      state: "IL",
      postcode: "60056",
    },
    {
      name: "CAREMARK,  L.L.C.",
      suite: "SUITE A",
      street: "1127 BRYN MAWR AVE",
      city: "REDLANDS",
      state: "CA",
      postcode: "92374",
    },
    {
      name: "OPTUMRX",
      suite: "",
      street: "6800 W 115TH ST STE 600",
      city: "OVERLAND PARK",
      state: "KS",
      postcode: "66211",
    },
    {
      name: "ACCREDO HEALTH GROUP, INC.",
      suite: "",
      street: "11411 STRANG LINE RD",
      city: "LENEXA",
      state: "KS",
      postcode: "66215",
    },
    {
      name: "CAREMARK KANSAS SPECIALTY PHARMACY",
      suite: "",
      street: "11162 RENNER BLVD",
      city: "LENEXA",
      state: "KS",
      postcode: "66219",
    },
    {
      name: "ACCREDO HEALTH GROUP INC",
      suite: "",
      street: "19060 Q ST STE 101",
      city: "OMAHA",
      state: "NE",
      postcode: "68135",
    },
    {
      name: "ACCREDO HEALTH GROUP INC",
      suite: "",
      street: "520 ELMWOOD PARK BLVD STE 100",
      city: "NEW ORLEANS",
      state: "LA",
      postcode: "70123",
    },
    {
      name: "ACCREDO HEALTH GROUP INC",
      suite: "",
      street: "4901 W RENO AVE STE 950",
      city: "OKLAHOMA CITY",
      state: "OK",
      postcode: "73127",
    },
    {
      name: "ACCREDO HEALTH GROUP INC",
      suite: "",
      street: "4343 W ROYAL LN STE 124",
      city: "IRVING",
      state: "TX",
      postcode: "75063",
    },
    {
      name: "PROCARE PHARMACY, L.L.C.",
      suite: "",
      street: "426 S. HENDERSON STREET",
      city: "FORT WORTH",
      state: "TX",
      postcode: "76104",
    },
    {
      name: "ACCREDO HEALTH GROUP INC",
      suite: "",
      street: "9307 KIRBY DR",
      city: "HOUSTON",
      state: "TX",
      postcode: "77054",
    },
    {
      name: "PROCARE PHARMACY, L.L.C.",
      suite: "",
      street: "7950 FLOYD CURL DR. STE #106",
      city: "SAN ANTONIO",
      state: "TX",
      postcode: "78229",
    },
    {
      name: "OPTUM PHARMACY 704, INC.",
      suite: "",
      street: "5627 UNIVERSITY HEIGHTS STE 108",
      city: "SAN ANTONIO",
      state: "TX",
      postcode: "78249",
    },
    {
      name: "ACCREDO HEALTH GROUP INC",
      suite: "",
      street: "650 W GRAND AVE STE 102",
      city: "ELMHURST",
      state: "IL",
      postcode: "60126",
    },
    {
      name: "ACCREDO HEALTH GROUP INC",
      suite: "",
      street: "361 INVERNESS DR S STE F",
      city: "ENGLEWOOD",
      state: "CO",
      postcode: "80112",
    },
    {
      name: "PROCARE PHARMACY, L.L.C.",
      suite: "",
      street: "2101 N URSULA STREET UNIT 35",
      city: "AURORA",
      state: "CO",
      postcode: "80045",
    },
    {
      name: "ACCREDO HEALTH GROUP INC",
      suite: "",
      street: "3488 S MAIN ST",
      city: "SALT LAKE CITY",
      state: "UT",
      postcode: "84115",
    },
    {
      name: "PROCARE PHARMACY, L.L.C.",
      suite: "",
      street: "1002 EAST MCDOWELL ROAD",
      city: "PHOENIX",
      state: "AZ",
      postcode: "85006",
    },
    {
      name: "ACCREDO HEALTH GROUP INC",
      suite: "",
      street: "2040 W RIO SALADO PKWY STE 101B",
      city: "TEMPE",
      state: "AZ",
      postcode: "85281",
    },
    {
      name: "ACCREDO HEALTH GROUP INC",
      suite: "",
      street: "212 CARPENTERS UNION WAY",
      city: "LAS VEGAS",
      state: "NV",
      postcode: "89119",
    },
    {
      name: "PROCARE PHARMACY, L.L.C.",
      suite: "",
      street: "8607 SANTA MONICA BOULEVARD",
      city: "WEST HOLLYWOOD",
      state: "CA",
      postcode: "90069",
    },
    {
      name: "PROCARE PHARMACY, L.L.C.",
      suite: "",
      street: "2 DUDLEY STREET SUITE 170",
      city: "PROVIDENCE",
      state: "RI",
      postcode: "2905",
    },
    {
      name: "PROCARE PHARMACY, L.L.C.",
      suite: "",
      street: "1010 UNIVERSITY AVENUE",
      city: "SAN DIEGO",
      state: "CA",
      postcode: "92103",
    },
    {
      name: "ACCREDO HEALTH GROUP INC",
      suite: "",
      street: "2410 WARDLOW RD",
      city: "CORONA",
      state: "CA",
      postcode: "92878",
    },
    {
      name: "PROCARE PHARMACY, L.L.C.",
      suite: "",
      street: "445 CASTRO STREET",
      city: "SAN FRANCISCO",
      state: "CA",
      postcode: "94114",
    },
    {
      name: "PROCARE PHARMACY, L.L.C.",
      suite: "",
      street: "1309 NW 23RD AVE.",
      city: "PORTLAND",
      state: "OR",
      postcode: "97210",
    },
    {
      name: "ACCREDO HEALTH GROUP INC",
      suite: "",
      street: "22623 68TH AVE S",
      city: "KENT",
      state: "WA",
      postcode: "98032",
    },
    {
      name: "PROCARE PHARMACY, L.L.C.",
      suite: "",
      street: "1001 MADISON ST.",
      city: "SEATTLE",
      state: "WA",
      postcode: "98104",
    },
    {
      name: "OPTUM PHARMACY 702, LLC",
      suite: "",
      street: "1050 PATROL RD",
      city: "JEFFERSONVILLE",
      state: "IN",
      postcode: "47130",
    },
    {
      name: "EXPRESS SCRIPTS PHARMACY, INC.",
      suite: "",
      street: "4750 E. 450 S.",
      city: "WHITESTOWN",
      state: "IN",
      postcode: "46075",
    },
    {
      name: "PROCARE PHARMACY, L.L.C.",
      suite: "SUITE 104",
      street: "7205 CORPORATE CENTER DRIVE",
      city: "MIAMI",
      state: "FL",
      postcode: "33126",
    },
  ];

  const nucala_pharmacies = [
    {
      name: "WALGREEN CO.",
      suite: "",
      street: "1020 29TH ST STE 140",
      city: "SACRAMENTO",
      state: "CA",
      postcode: "95816",
    },
    {
      name: "WALGREEN CO.",
      suite: "",
      street: "2100 WEBSTER STREET SUITE 105",
      city: "SAN FRANCISCO",
      state: "CA",
      postcode: "94115",
    },
    {
      name: "MIAMI COAST PHARMACY LLC",
      suite: "",
      street: "1250 NW 7TH ST UNIT 101-102",
      city: "MIAMI",
      state: "FL",
      postcode: "33125",
    },
    {
      name: "WALGREEN CO.",
      suite: "",
      street: "500 PARNASSUS, I LEVEL, RM MU-005",
      city: "SAN FRANCISCO",
      state: "CA",
      postcode: "94143",
    },
    {
      name: "WALGREEN CO",
      suite: "",
      street: "640 UNIVERSITY AVE",
      city: "SAN DIEGO",
      state: "CA",
      postcode: "92103",
    },
    {
      name: "WALGREEN ARIZONA DRUG CO",
      suite: "",
      street: "5149 W THUNDERBIRD RD.",
      city: "GLENDALE",
      state: "AZ",
      postcode: "85306",
    },
    {
      name: "WALGREEN CO.",
      suite: "",
      street: "747 N RUTLEDGE ST STE 100",
      city: "SPRINGFIELD",
      state: "IL",
      postcode: "62702",
    },
    {
      name: "WALGREEN ARIZONA DRUG CO.",
      suite: "SUITE 7",
      street: "2302 N. CENTRAL AVE.",
      city: "PHOENIX",
      state: "AZ",
      postcode: "85004",
    },
    {
      name: "WALGREEN CO.",
      suite: "SUITE 114",
      street: "114 W. 3RD AVE.",
      city: "COLUMBUS",
      state: "OH",
      postcode: "43201",
    },
    {
      name: "AVELLA OF DEER VALLEY, INC. #38",
      suite: "",
      street: "24416 N. 19TH AVE",
      city: "PHOENIX",
      state: "AZ",
      postcode: "85085",
    },
    {
      name: "WALGREEN CO.",
      suite: "",
      street: "335 MASSACHUSETTS AVENUE",
      city: "INDIANAPOLIS",
      state: "IN",
      postcode: "46204",
    },
    {
      name: "WAL-MART PHARMACY 10-3039",
      suite: "",
      street: "225 W CHICAGO AVE",
      city: "CHICAGO",
      state: "IL",
      postcode: "60654",
    },
    {
      name: "CYSTIC FIBROSIS SERVICES, LLC",
      suite: "SUITE 200",
      street: "10530 JOHN W ELLIOTT DRIVE",
      city: "FRISCO",
      state: "TX",
      postcode: "75033",
    },
    {
      name: "AVELLA OF DEER VALLEY, INC. #38",
      suite: "",
      street: "24416 N. 19TH AVE",
      city: "PHOENIX",
      state: "AZ",
      postcode: "85085",
    },
    {
      name: "WALGREEN OF PUERTO RICO, INC.",
      suite: "",
      street: "576 CALLE CESAR GONZALEZ STE 206",
      city: "SAN JUAN",
      state: "PR",
      postcode: "918",
    },
    {
      name: "PROCARE PHARMACY, L.L.C.",
      suite: "",
      street: "979 EAST THIRD STREET",
      city: "CHATTANOOGA",
      state: "TN",
      postcode: "37403",
    },
    {
      name: "CVS/SPECIALTY OR CARELONRX SPECIALTY PHA",
      suite: "",
      street: "1001 SPINKS ROAD, STE 280",
      city: "FLOWER MOUND",
      state: "TX",
      postcode: "75028",
    },
    {
      name: "WALGREEN CO.",
      suite: "SUITE 101",
      street: "1 N. TACOMA AVE.",
      city: "TACOMA",
      state: "WA",
      postcode: "98403",
    },
    {
      name: "WALGREEN CO.",
      suite: "",
      street: "601 E KING ST",
      city: "KINGS MOUNTAIN",
      state: "NC",
      postcode: "28086",
    },
    {
      name: "WALGREEN CO.",
      suite: "SPC B",
      street: "565 PEACHTREE STREET NE",
      city: "ATLANTA",
      state: "GA",
      postcode: "30308",
    },
    {
      name: "WALGREEN CO.",
      suite: "STE 112",
      street: "7000 INDIANA AVE.",
      city: "RIVERSIDE",
      state: "CA",
      postcode: "92506",
    },
    {
      name: "WALGREEN CO.",
      suite: "",
      street: "2262 MARKET STREET",
      city: "SAN FRANCISCO",
      state: "CA",
      postcode: "94114",
    },
    {
      name: "PROCARE PHARMACY, L.L.C.",
      suite: "",
      street: "10160 INTERNATIONAL BLVD.",
      city: "WEST CHESTER",
      state: "OH",
      postcode: "45246",
    },
    {
      name: "OPTUM PHARMACY 801, INC.",
      suite: "",
      street: "24416 N 19TH AVE",
      city: "PHOENIX",
      state: "AZ",
      postcode: "85085",
    },
    {
      name: "OPTUM PHARMACY 700, LLC",
      suite: "",
      street: "19 CHAPIN RD, BLDG D, STE D8",
      city: "PINE BROOK",
      state: "NJ",
      postcode: "7058",
    },
    {
      name: "ACCREDO HEALTH GROUP, INC.",
      suite: "",
      street: "677 ALA MOANA BLVD STE 404",
      city: "HONOLULU",
      state: "HI",
      postcode: "96813",
    },
    {
      name: "WALGREEN CO.",
      suite: "",
      street: "28311 MARGUERITE PKWY STE B-1",
      city: "MISSION VIEJO",
      state: "CA",
      postcode: "92692",
    },
    {
      name: "WALGREEN CO.",
      suite: "STE 120",
      street: "3217 MONTROSE BLVD.",
      city: "HOUSTON",
      state: "TX",
      postcode: "77006",
    },
    {
      name: "OPTUM PHARMACY 700, LLC",
      suite: "",
      street: "5700 DOT COM CT, STE 1000",
      city: "OVIEDO",
      state: "FL",
      postcode: "32765",
    },
    {
      name: "AVELLA OF DEER VALLEY, INC. #38",
      suite: "",
      street: "24416 N. 19TH AVE.",
      city: "PHOENIX",
      state: "AZ",
      postcode: "85085",
    },
    {
      name: "OPTUM PHARMACY 701, LLC",
      suite: "",
      street: "8131 W BOSTIAN RD STE A350",
      city: "WOODINVILLE",
      state: "WA",
      postcode: "98072",
    },
    {
      name: "CAREMARK, L.L.C.",
      suite: "",
      street: "7251 S. EASTERN AVE.",
      city: "LAS VEGAS",
      state: "NV",
      postcode: "89119",
    },
    {
      name: "PROCARE PHARMACY, L.L.C.",
      suite: "500 ALA MOANA BLVD., BLDG 1",
      street: "ONE WATERFRONT PLAZA",
      city: "HONOLULU",
      state: "HI",
      postcode: "96813",
    },
    {
      name: "OPTUM INFUSION SERVICES 501, INC.",
      suite: "",
      street: "8131 W BOSTIAN RD",
      city: "WOODINVILLE",
      state: "WA",
      postcode: "98072",
    },
    {
      name: "OPTUM PHARMACY 701, LLC",
      suite: "",
      street: "4015 SHOPTON RD STE 600",
      city: "CHARLOTTE",
      state: "NC",
      postcode: "28217",
    },
    {
      name: "CAREMARK PUERTO RICO SPECIALTY PHARMACY,",
      suite: "SUITE 2",
      street: "280 AVE JESUS T PINERO",
      city: "SAN JUAN",
      state: "PR",
      postcode: "927",
    },
    {
      name: "OPTUM PHARMACY 701, LLC",
      suite: "",
      street: "4100 S SAGINAW ST STE D",
      city: "FLINT",
      state: "MI",
      postcode: "48507",
    },
    {
      name: "ACCREDO HEALTH GROUP INC",
      suite: "",
      street: "261 CEDAR HILL ST",
      city: "MARLBOROUGH",
      state: "MA",
      postcode: "1752",
    },
    {
      name: "CAREMARK MASSACHUSETTS SPECIALTY PHARMAC",
      suite: "",
      street: "25 BIRCH STREET, BLDG B, SUITE 100",
      city: "MILFORD",
      state: "MA",
      postcode: "1757",
    },
    {
      name: "OPTUM INFUSION SERVICES 550, LLC",
      suite: "",
      street: "20 COMMERCE WAY",
      city: "WOBURN",
      state: "MA",
      postcode: "1801",
    },
    {
      name: "LYNNFIELD DRUG INC",
      suite: "",
      street: "12 KENT WAY STE 120F",
      city: "BYFIELD",
      state: "MA",
      postcode: "1922",
    },
    {
      name: "PROCARE PHARMACY, L.L.C.",
      suite: "",
      street: "35 KNEELAND STREET",
      city: "BOSTON",
      state: "MA",
      postcode: "2111",
    },
    {
      name: "CVS PHARMACY, INC.",
      suite: "",
      street: "350 LONGWOOD AVENUE",
      city: "BOSTON",
      state: "MA",
      postcode: "2115",
    },
    {
      name: "PROCARE PHARMACY, L.L.C.",
      suite: "",
      street: "350 LONGWOOD AVE., STE B",
      city: "BOSTON",
      state: "MA",
      postcode: "2115",
    },
    {
      name: "CAREMARK NEW JERSEY SPECIALTY PHCY, LLC",
      suite: "",
      street: "180 PASSAIC AVE UNIT B-5",
      city: "FAIRFIELD",
      state: "NJ",
      postcode: "7004",
    },
    {
      name: "ACCREDO HEALTH GROUP, INC.",
      suite: "",
      street: "45 ROUTE 46 EAST",
      city: "PINE BROOK",
      state: "NJ",
      postcode: "7058",
    },
    {
      name: "WALGREEN EASTERN CO., INC.",
      suite: "",
      street: "1 DIAMOND HILL RD.",
      city: "BERKELEY HEIGHTS",
      state: "NJ",
      postcode: "7922",
    },
    {
      name: "PROCARE PHARMACY, L.L.C.",
      suite: "",
      street: "126 EIGHTH AVE.",
      city: "NEW YORK",
      state: "NY",
      postcode: "10011",
    },
    {
      name: "WALGREEN EASTERN CO INC",
      suite: "",
      street: "3462 JEROME AVE.",
      city: "BRONX",
      state: "NY",
      postcode: "10467",
    },
    {
      name: "AHG OF NEW YORK, INC",
      suite: "",
      street: "125 CLEARBROOK RD STE 122",
      city: "ELMSFORD",
      state: "NY",
      postcode: "10523",
    },
    {
      name: "WALGREEN EASTERN CO INC",
      suite: "",
      street: "19 BRADHURST AVENUE SUITE L1",
      city: "HAWTHORNE",
      state: "NY",
      postcode: "10532",
    },
    {
      name: "WALGREEN EASTERN CO., INC.",
      suite: "1ST FLOOR",
      street: "66 E. POST RD",
      city: "WHITE PLAINS",
      state: "NY",
      postcode: "10601",
    },
    {
      name: "KROGER SPECIALTY PHARMACY CA",
      suite: "",
      street: "7373 LINCOLN WAY",
      city: "GARDEN GROVE",
      state: "CA",
      postcode: "92841",
    },
    {
      name: "OPTUM PHARMACY 706, INC.",
      suite: "",
      street: "30-30   47TH AVENUE",
      city: "LONG ISLAND CITY",
      state: "NY",
      postcode: "11101",
    },
    {
      name: "WALGREEN EASTERN CO., INC.",
      suite: "",
      street: "55 PARK ST STE 1A",
      city: "NEW HAVEN",
      state: "CT",
      postcode: "6511",
    },
    {
      name: "ACARIAHEALTH PHARMACY #12, INC.",
      suite: "",
      street: "5 SKYLINE DR STE 240",
      city: "HAWTHORNE",
      state: "NY",
      postcode: "10532",
    },
    {
      name: "PROCARE PHARMACY DIRECT, L.L.C.",
      suite: "",
      street: "105 MALL BOULEVARD",
      city: "MONROEVILLE",
      state: "PA",
      postcode: "15146",
    },
    {
      name: "ACCREDO HEALTH GROUP INC",
      suite: "",
      street: "3000 ERICSSON DRIVE, SUITE 100",
      city: "WARRENDALE",
      state: "PA",
      postcode: "15086",
    },
    {
      name: "BIOPARTNERS IN CARE INC",
      suite: "",
      street: "11411 STRANG LINE RD",
      city: "LENEXA",
      state: "KS",
      postcode: "66215",
    },
    {
      name: "PROCARE PHARMACY, L.L.C.",
      suite: "",
      street: "1117 WALNUT ST.",
      city: "PHILADELPHIA",
      state: "PA",
      postcode: "19107",
    },
    {
      name: "WALGREEN CO",
      suite: "",
      street: "1325 14TH STREET NW",
      city: "WASHINGTON",
      state: "DC",
      postcode: "20005",
    },
    {
      name: "PROCARE PHARMACY, L.L.C.",
      suite: "",
      street: "1601 17TH STREET NW",
      city: "WASHINGTON",
      state: "DC",
      postcode: "20009",
    },
    {
      name: "WALGREEN CO.",
      suite: "",
      street: "6 N HOWARD STREET",
      city: "BALTIMORE",
      state: "MD",
      postcode: "21201",
    },
    {
      name: "ACCREDO HEALTH GROUP INC",
      suite: "",
      street: "422 GALLIMORE DAIRY RD",
      city: "GREENSBORO",
      state: "NC",
      postcode: "27409",
    },
    {
      name: "CAREMARK NORTH CAROLINA SPECIALTY PHARMA",
      suite: "",
      street: "10700 WORLD TRADE BLVD STE 110",
      city: "RALEIGH",
      state: "NC",
      postcode: "27617",
    },
    {
      name: "WALGREEN ARIZONA DRUG CO.",
      suite: "",
      street: "3627 N CAMPBELL AVE",
      city: "TUCSON",
      state: "AZ",
      postcode: "85719",
    },
    {
      name: "PROCARE PHARMACY, LLC",
      suite: "",
      street: "601 S. KINGS DR.",
      city: "CHARLOTTE",
      state: "NC",
      postcode: "28204",
    },
    {
      name: "PROCARE PHARMACY, L.L.C.",
      suite: "",
      street: "3250 HARDEN ST. EXT. SUITE #300",
      city: "COLUMBIA",
      state: "SC",
      postcode: "29203",
    },
    {
      name: "WALGREEN CO",
      suite: "",
      street: "201 E. HURON STREET, SUITE 01-210",
      city: "CHICAGO",
      state: "IL",
      postcode: "60611",
    },
    {
      name: "ACCREDO HEALTH GROUP INC",
      suite: "",
      street: "190 TECHNOLOGY PKWY STE 120",
      city: "NORCROSS",
      state: "GA",
      postcode: "30092",
    },
    {
      name: "ENCOMPASS RX, LLC",
      suite: "",
      street: "2700 NORTHEAST EXPRESSWAY",
      city: "ATLANTA",
      state: "GA",
      postcode: "30345",
    },
    {
      name: "PROCARE PHARMACY, L.L.C.",
      suite: "",
      street: "560 AMSTERDAM AVE, STE #E",
      city: "ATLANTA",
      state: "GA",
      postcode: "30306",
    },
    {
      name: "WALGREEN CO.",
      suite: "SUITE 505",
      street: "2835 N SHEFFIELD AVE",
      city: "CHICAGO",
      state: "IL",
      postcode: "60657",
    },
    {
      name: "KROGER SPECIALTY PHARMACY INC.",
      suite: "",
      street: "3200 LAKE EMMA RD UNIT 1000",
      city: "LAKE MARY",
      state: "FL",
      postcode: "32746",
    },
    {
      name: "DIPLOMAT SPECIALTY PHARMACY",
      suite: "",
      street: "4100 S SAGINAW ST",
      city: "FLINT",
      state: "MI",
      postcode: "48507",
    },
    {
      name: "WALGREEN CO.",
      suite: "",
      street: "1200 KUHL AVE",
      city: "ORLANDO",
      state: "FL",
      postcode: "32806",
    },
    {
      name: "ADVANCED CARE SCRIPTS, INC",
      suite: "SUITE 101",
      street: "6251 CHANCELLOR DRIVE",
      city: "ORLANDO",
      state: "FL",
      postcode: "32809",
    },
    {
      name: "ACCREDO HEALTH GROUP, INC.",
      suite: "",
      street: "6272 LEE VISTA BLVD STE 100",
      city: "ORLANDO",
      state: "FL",
      postcode: "32822",
    },
    {
      name: "OPTUM INFUSION SERVICES 201, INC.",
      suite: "",
      street: "5700 DOT COM CT STE 1030",
      city: "OVIEDO",
      state: "FL",
      postcode: "32765",
    },
    {
      name: "PUBLIX SUPER MARKETS",
      suite: "",
      street: "3200 LAKE WASHINGTON ROAD",
      city: "MELBOURNE",
      state: "FL",
      postcode: "32934",
    },
    {
      name: "WALGREEN CO.",
      suite: "",
      street: "1250 NW 7TH STREET SUITE 205",
      city: "MIAMI",
      state: "FL",
      postcode: "33125",
    },
    {
      name: "BIOSCRIP PHARMACY",
      suite: "",
      street: "21-23 STANHOPE STREET",
      city: "BOSTON",
      state: "MA",
      postcode: "2116",
    },
    {
      name: "WALGREEN CO.",
      suite: "",
      street: "1515 N. FLAGLER DRIVE SUITE 120",
      city: "WEST PALM BEACH",
      state: "FL",
      postcode: "33401",
    },
    {
      name: "WALGREEN CO.",
      suite: "",
      street: "3401 PGA BLVD STE-110",
      city: "PALM BEACH GARDENS",
      state: "FL",
      postcode: "33410",
    },
    {
      name: "CAREMARK FLORIDA SPECIALTY PHARMACY LLC",
      suite: "",
      street: "7930 WOODLAND CENTER BLVD STE 500",
      city: "TAMPA",
      state: "FL",
      postcode: "33614",
    },
    {
      name: "ACARIAHEALTH PHARMACY #26, INC",
      suite: "",
      street: "8715 HENDERSON RD",
      city: "TAMPA",
      state: "FL",
      postcode: "33634",
    },
    {
      name: "OPTION CARE ENTERPRISES, INC.",
      suite: "SUITE 430",
      street: "2100 RIVERCHASE CENTER",
      city: "HOOVER",
      state: "AL",
      postcode: "35244",
    },
    {
      name: "PROCARE PHARMACY, L.L.C.",
      suite: "",
      street: "1521 4TH AVE., SOUTH",
      city: "BIRMINGHAM",
      state: "AL",
      postcode: "35233",
    },
    {
      name: "OPTUM PHARMACY 705, LLC",
      suite: "",
      street: "1100 LEE BRANCH LN",
      city: "BIRMINGHAM",
      state: "AL",
      postcode: "35242",
    },
    {
      name: "ACCREDO HEALTH GROUP INC",
      suite: "",
      street: "2100 RIVERCHASE CTR STE 405",
      city: "HOOVER",
      state: "AL",
      postcode: "35244",
    },
    {
      name: "HOMECHOICE PARTNERS, INC.",
      suite: "",
      street: "2100 RIVERCHASE CENTER STE 314",
      city: "BIRMINGHAM",
      state: "AL",
      postcode: "35244",
    },
    {
      name: "KROGER SPECIALTY INFUSION AL LLC",
      suite: "",
      street: "2511 ROSS CLARK CIR",
      city: "DOTHAN",
      state: "AL",
      postcode: "36301",
    },
    {
      name: "OPTUM PHARMACY 705, LLC",
      suite: "",
      street: "1893 GENERAL GEORGE PATTON DR",
      city: "FRANKLIN",
      state: "TN",
      postcode: "37067",
    },
    {
      name: "ERLANGER PHARMACIES",
      suite: "",
      street: "979 EAST THIRD ST. SUITE 120",
      city: "CHATTANOOGA",
      state: "TN",
      postcode: "37403",
    },
    {
      name: "CAREMARK TENNESSEE SPECIALTY PHARMACY, L",
      suite: "SUITE 107",
      street: "8370 WOLF LAKE DRIVE",
      city: "BARTLETT",
      state: "TN",
      postcode: "38133",
    },
    {
      name: "ACCREDO HEALTH GROUP INC",
      suite: "",
      street: "1620 CENTURY CENTER PKWY",
      city: "MEMPHIS",
      state: "TN",
      postcode: "38134",
    },
    {
      name: "OPTUM PHARMACY 705, LLC",
      suite: "",
      street: "3539 BLUECUTT RD",
      city: "COLUMBUS",
      state: "MS",
      postcode: "39705",
    },
    {
      name: "WALGREENS SPECIALTY PHARMACY LLC",
      suite: "",
      street: "130 ENTERPRISE DRIVE",
      city: "PITTSBURGH",
      state: "PA",
      postcode: "15275",
    },
    {
      name: "WALGREENS SPECIALTY PHARMACY LLC",
      suite: "SUITE 100",
      street: "10530 JOHN W. ELLIOTT DRIVE",
      city: "FRISCO",
      state: "TX",
      postcode: "75033",
    },
    {
      name: "WALGREENS SPECIALTY PHARMACY, LLC",
      suite: "",
      street: "41460 HAGGERTY CIRCLE SOUTH",
      city: "CANTON",
      state: "MI",
      postcode: "48188",
    },
    {
      name: "WALGREEN CO.",
      suite: "",
      street: "912 W BELMONT AVENUE",
      city: "CHICAGO",
      state: "IL",
      postcode: "60657",
    },
    {
      name: "WALGREEN EASTERN CO., INC.",
      suite: "",
      street: "1 ROBERT WOOD JOHNSON PL.",
      city: "NEW BRUNSWICK",
      state: "NJ",
      postcode: "8901",
    },
    {
      name: "CENTERWELL PHARMACY, INC.",
      suite: "",
      street: "9843 WINDISCH RD",
      city: "WEST CHESTER",
      state: "OH",
      postcode: "45069",
    },
    {
      name: "MEDCO HEALTH SOLUTIONS OF INDIANA, LLC",
      suite: "",
      street: "4750 E. 450 S.",
      city: "WHITESTOWN",
      state: "IN",
      postcode: "46075",
    },
    {
      name: "ACCREDO HEALTH GROUP, INC.",
      suite: "",
      street: "2825 W PERIMETER RD STE 112",
      city: "INDIANAPOLIS",
      state: "IN",
      postcode: "46241",
    },
    {
      name: "WALGREEN CO.",
      suite: "",
      street: "9002 NORTH MERIDIAN SUITE 213",
      city: "INDIANAPOLIS",
      state: "IN",
      postcode: "46260",
    },
    {
      name: "WALGREEN EASTERN CO INC",
      suite: "",
      street: "1227 LOCUST STREET",
      city: "PHILADELPHIA",
      state: "PA",
      postcode: "19107",
    },
    {
      name: "WALGREEN CO.",
      suite: "",
      street: "1424 UNION AVENUE",
      city: "MEMPHIS",
      state: "TN",
      postcode: "38104",
    },
    {
      name: "CAREMARK MICHIGAN SPECIALTY PHARMACY, LL",
      suite: "",
      street: "1307 ALLEN DR STE H",
      city: "TROY",
      state: "MI",
      postcode: "48083",
    },
    {
      name: "HOMESCRIPTS",
      suite: "",
      street: "500 KIRTS BLVD",
      city: "TROY",
      state: "MI",
      postcode: "48084",
    },
    {
      name: "ACCREDO HEALTH GROUP INC",
      suite: "",
      street: "39625 LEWIS DR",
      city: "NOVI",
      state: "MI",
      postcode: "48377",
    },
    {
      name: "BIOSCRIP PHARMACY INC",
      suite: "",
      street: "826 N PLAKINTON AVE. SUITE 100",
      city: "MILWAUKEE",
      state: "WI",
      postcode: "53203",
    },
    {
      name: "ACCREDO HEALTH GROUP INC",
      suite: "",
      street: "2915 WATERS RD STE 109",
      city: "EAGAN",
      state: "MN",
      postcode: "55121",
    },
    {
      name: "WALGREEN CO.",
      suite: "",
      street: "2100 LYNDALE AVENUE SOUTH SUITE A",
      city: "MINNEAPOLIS",
      state: "MN",
      postcode: "55405",
    },
    {
      name: "CAREMARK ILLINOIS MAIL PHARMACY, LLC",
      suite: "SUITE A",
      street: "800 BIERMANN COURT",
      city: "MOUNT PROSPECT",
      state: "IL",
      postcode: "60056",
    },
    {
      name: "CAREMARK ILLINOIS SPECIALTY PHARMACY, LL",
      suite: "",
      street: "800 BIERMANN CT STE B",
      city: "MOUNT PROSPECT",
      state: "IL",
      postcode: "60056",
    },
    {
      name: "WALGREEN CO.",
      suite: "",
      street: "1775 W DEMPSTER ST STE T01116",
      city: "PARK RIDGE",
      state: "IL",
      postcode: "60068",
    },
    {
      name: "WALGREEN CO.",
      suite: "",
      street: "826 N PLANKINTON AVE SUITE 100",
      city: "MILWAUKEE",
      state: "WI",
      postcode: "53203",
    },
    {
      name: "WALGREEN CO.",
      suite: "STE 03110",
      street: "225 E CHICAGO AVE",
      city: "CHICAGO",
      state: "IL",
      postcode: "60611",
    },
    {
      name: "WALGREEN CO.",
      suite: "",
      street: "602 W. UNIVERSITY AVENUE",
      city: "URBANA",
      state: "IL",
      postcode: "61801",
    },
    {
      name: "CAREMARK,  L.L.C.",
      suite: "SUITE A",
      street: "1127 BRYN MAWR AVE",
      city: "REDLANDS",
      state: "CA",
      postcode: "92374",
    },
    {
      name: "FOUNDATION CARE LLC",
      suite: "",
      street: "4010 WEDGEWAY CT",
      city: "EARTH CITY",
      state: "MO",
      postcode: "63045",
    },
    {
      name: "WALGREEN CO.",
      suite: "STE A",
      street: "115 N EUCLID AVE",
      city: "SAINT LOUIS",
      state: "MO",
      postcode: "63108",
    },
    {
      name: "WALGREEN CO",
      suite: "",
      street: "3527 CHOUTEAU AVE.",
      city: "SAINT LOUIS",
      state: "MO",
      postcode: "63103",
    },
    {
      name: "WALGREEN CO",
      suite: "",
      street: "4545 EAST 9TH AVE SUITE 100",
      city: "DENVER",
      state: "CO",
      postcode: "80220",
    },
    {
      name: "OPTUMRX",
      suite: "",
      street: "6800 W 115TH ST STE 600",
      city: "OVERLAND PARK",
      state: "KS",
      postcode: "66211",
    },
    {
      name: "ACCREDO HEALTH GROUP, INC.",
      suite: "",
      street: "11411 STRANG LINE RD",
      city: "LENEXA",
      state: "KS",
      postcode: "66215",
    },
    {
      name: "CAREMARK KANSAS SPECIALTY PHARMACY",
      suite: "",
      street: "11162 RENNER BLVD",
      city: "LENEXA",
      state: "KS",
      postcode: "66219",
    },
    {
      name: "ACCREDO HEALTH GROUP INC",
      suite: "",
      street: "19060 Q ST STE 101",
      city: "OMAHA",
      state: "NE",
      postcode: "68135",
    },
    {
      name: "TOTAL LIFE CARE RX PHARMACY, LLC",
      suite: "SUITE B17",
      street: "2731 MANHATTAN BLVD",
      city: "HARVEY",
      state: "LA",
      postcode: "70058",
    },
    {
      name: "WALGREEN LOUISIANA CO., INC.",
      suite: "",
      street: "1415 TULANE AVE STE 2CW07",
      city: "NEW ORLEANS",
      state: "LA",
      postcode: "70112",
    },
    {
      name: "ACCREDO HEALTH GROUP INC",
      suite: "",
      street: "520 ELMWOOD PARK BLVD STE 100",
      city: "NEW ORLEANS",
      state: "LA",
      postcode: "70123",
    },
    {
      name: "ACCREDO HEALTH GROUP INC",
      suite: "",
      street: "4901 W RENO AVE STE 950",
      city: "OKLAHOMA CITY",
      state: "OK",
      postcode: "73127",
    },
    {
      name: "ACCREDO HEALTH GROUP INC",
      suite: "",
      street: "4343 W ROYAL LN STE 124",
      city: "IRVING",
      state: "TX",
      postcode: "75063",
    },
    {
      name: "KROGER SPECIALTY INFUSION TX",
      suite: "",
      street: "1748 N GREENVILLE AVE",
      city: "RICHARDSON",
      state: "TX",
      postcode: "75081",
    },
    {
      name: "SENDERRA RX PHARMACY",
      suite: "",
      street: "1301 E ARAPAHO RD STE 101",
      city: "RICHARDSON",
      state: "TX",
      postcode: "75081",
    },
    {
      name: "WALGREEN CO.",
      suite: "",
      street: "3826 CEDAR SPRINGS",
      city: "DALLAS",
      state: "TX",
      postcode: "75219",
    },
    {
      name: "PROCARE PHARMACY, L.L.C.",
      suite: "",
      street: "426 S. HENDERSON STREET",
      city: "FORT WORTH",
      state: "TX",
      postcode: "76104",
    },
    {
      name: "ACARIAHEALTH PHARMACY #11, INC",
      suite: "",
      street: "1311 W SAM HOUSTON PKWY N STE 130",
      city: "HOUSTON",
      state: "TX",
      postcode: "77043",
    },
    {
      name: "WALGREEN CO.",
      suite: "SUITE 120",
      street: "6624 FANNIN STREET",
      city: "HOUSTON",
      state: "TX",
      postcode: "77030",
    },
    {
      name: "WALGREEN CO.",
      suite: "",
      street: "6560 FANNIN STREET SUITE 260",
      city: "HOUSTON",
      state: "TX",
      postcode: "77030",
    },
    {
      name: "SPECIALTY THERAPEUTIC CARE",
      suite: "",
      street: "1311 W SAM HOUSTON PKWY N STE 150",
      city: "HOUSTON",
      state: "TX",
      postcode: "77043",
    },
    {
      name: "ACCREDO HEALTH GROUP INC",
      suite: "",
      street: "9307 KIRBY DR",
      city: "HOUSTON",
      state: "TX",
      postcode: "77054",
    },
    {
      name: "WALGREEN CO.",
      suite: "",
      street: "4101 GREENBRIAR SUITE 235",
      city: "HOUSTON",
      state: "TX",
      postcode: "77098",
    },
    {
      name: "PROCARE PHARMACY, L.L.C.",
      suite: "",
      street: "7950 FLOYD CURL DR. STE #106",
      city: "SAN ANTONIO",
      state: "TX",
      postcode: "78229",
    },
    {
      name: "WALGREEN CO",
      suite: "",
      street: "8333 N DAVIS HWY STE 50",
      city: "PENSACOLA",
      state: "FL",
      postcode: "32514",
    },
    {
      name: "OPTUM PHARMACY 704, INC.",
      suite: "",
      street: "5627 UNIVERSITY HEIGHTS STE 108",
      city: "SAN ANTONIO",
      state: "TX",
      postcode: "78249",
    },
    {
      name: "ACCREDO HEALTH GROUP INC",
      suite: "",
      street: "650 W GRAND AVE STE 102",
      city: "ELMHURST",
      state: "IL",
      postcode: "60126",
    },
    {
      name: "WALGREEN CO.",
      suite: "",
      street: "3537 BROADWAY BLVD.",
      city: "KANSAS CITY",
      state: "MO",
      postcode: "64111",
    },
    {
      name: "ACCREDO HEALTH GROUP INC",
      suite: "",
      street: "361 INVERNESS DR S STE F",
      city: "ENGLEWOOD",
      state: "CO",
      postcode: "80112",
    },
    {
      name: "PROCARE PHARMACY, L.L.C.",
      suite: "",
      street: "2101 N URSULA STREET UNIT 35",
      city: "AURORA",
      state: "CO",
      postcode: "80045",
    },
    {
      name: "WALGREEN CO.",
      suite: "",
      street: "1601 E 19TH AVE, STE 4650",
      city: "DENVER",
      state: "CO",
      postcode: "80218",
    },
    {
      name: "WALGREEN CO.",
      suite: "",
      street: "4745 ARAPAHOE AVE STE 120",
      city: "BOULDER",
      state: "CO",
      postcode: "80303",
    },
    {
      name: "ACCREDO HEALTH GROUP INC",
      suite: "",
      street: "3488 S MAIN ST",
      city: "SALT LAKE CITY",
      state: "UT",
      postcode: "84115",
    },
    {
      name: "PROCARE PHARMACY, L.L.C.",
      suite: "",
      street: "1002 EAST MCDOWELL ROAD",
      city: "PHOENIX",
      state: "AZ",
      postcode: "85006",
    },
    {
      name: "ACCREDO HEALTH GROUP INC",
      suite: "",
      street: "2040 W RIO SALADO PKWY STE 101B",
      city: "TEMPE",
      state: "AZ",
      postcode: "85281",
    },
    {
      name: "WALGREEN CO.",
      suite: "",
      street: "901 SOUTH RANCHO DRIVE #20",
      city: "LAS VEGAS",
      state: "NV",
      postcode: "89106",
    },
    {
      name: "ACCREDO HEALTH GROUP INC",
      suite: "",
      street: "212 CARPENTERS UNION WAY",
      city: "LAS VEGAS",
      state: "NV",
      postcode: "89119",
    },
    {
      name: "ACARIAHEALTH PHARMACY #13, INC",
      suite: "",
      street: "3302 GARFIELD AVE.",
      city: "COMMERCE",
      state: "CA",
      postcode: "90040",
    },
    {
      name: "BIOSCRIP PHARMACY",
      suite: "SUITE 1",
      street: "8490 SANTA MONICA BLVD",
      city: "WEST HOLLYWOOD",
      state: "CA",
      postcode: "90069",
    },
    {
      name: "PROCARE PHARMACY, L.L.C.",
      suite: "",
      street: "8607 SANTA MONICA BOULEVARD",
      city: "WEST HOLLYWOOD",
      state: "CA",
      postcode: "90069",
    },
    {
      name: "WALGREEN CO.",
      suite: "",
      street: "8490 SANTA MONICA BLVD SUITE 1",
      city: "WEST HOLLYWOOD",
      state: "CA",
      postcode: "90069",
    },
    {
      name: "KROGER SPECIALTY INFUSION CA",
      suite: "",
      street: "19110 VAN NESS AVE.",
      city: "TORRANCE",
      state: "CA",
      postcode: "90501",
    },
    {
      name: "PROCARE PHARMACY, L.L.C.",
      suite: "",
      street: "2 DUDLEY STREET SUITE 170",
      city: "PROVIDENCE",
      state: "RI",
      postcode: "2905",
    },
    {
      name: "WALGREEN CO.",
      suite: "",
      street: "4940 VAN NUYS BLVD SUITE 104",
      city: "SHERMAN OAKS",
      state: "CA",
      postcode: "91403",
    },
    {
      name: "PX DRUGSTORE",
      suite: "",
      street: "7373 LINCOLN WAY",
      city: "GARDEN GROVE",
      state: "CA",
      postcode: "92841",
    },
    {
      name: "PROCARE PHARMACY, L.L.C.",
      suite: "",
      street: "1010 UNIVERSITY AVENUE",
      city: "SAN DIEGO",
      state: "CA",
      postcode: "92103",
    },
    {
      name: "WALGREEN CO.",
      suite: "",
      street: "4339 DIPAOLO CENTER",
      city: "GLENVIEW",
      state: "IL",
      postcode: "60025",
    },
    {
      name: "ACCREDO HEALTH GROUP INC",
      suite: "",
      street: "2410 WARDLOW RD",
      city: "CORONA",
      state: "CA",
      postcode: "92878",
    },
    {
      name: "PROCARE PHARMACY, L.L.C.",
      suite: "",
      street: "445 CASTRO STREET",
      city: "SAN FRANCISCO",
      state: "CA",
      postcode: "94114",
    },
    {
      name: "WALGREEN CO.",
      suite: "",
      street: "35 COLLIER ROAD NW SUITE 100",
      city: "ATLANTA",
      state: "GA",
      postcode: "30309",
    },
    {
      name: "WALGREEN CO.",
      suite: "",
      street: "1874 PIEDMONT AVE NE STE 100A",
      city: "ATLANTA",
      state: "GA",
      postcode: "30324",
    },
    {
      name: "PROCARE PHARMACY, L.L.C.",
      suite: "",
      street: "1309 NW 23RD AVE.",
      city: "PORTLAND",
      state: "OR",
      postcode: "97210",
    },
    {
      name: "ACCREDO HEALTH GROUP INC",
      suite: "",
      street: "22623 68TH AVE S",
      city: "KENT",
      state: "WA",
      postcode: "98032",
    },
    {
      name: "PROCARE PHARMACY, L.L.C.",
      suite: "",
      street: "1001 MADISON ST.",
      city: "SEATTLE",
      state: "WA",
      postcode: "98104",
    },
    {
      name: "WALGREEN CO.",
      suite: "",
      street: "1409 11TH AVE.",
      city: "SEATTLE",
      state: "WA",
      postcode: "98122",
    },
    {
      name: "ACARIAHEALTH PHARMACY #14, INC.",
      suite: "",
      street: "4151 E COMMERCE WAY # 1041",
      city: "SACRAMENTO",
      state: "CA",
      postcode: "95834",
    },
    {
      name: "WALGREEN CO.",
      suite: "",
      street: "2301 HOLMES STREET",
      city: "KANSAS CITY",
      state: "MO",
      postcode: "64108",
    },
    {
      name: "OPTUM PHARMACY 702, LLC",
      suite: "",
      street: "1050 PATROL RD",
      city: "JEFFERSONVILLE",
      state: "IN",
      postcode: "47130",
    },
    {
      name: "EXPRESS SCRIPTS PHARMACY, INC.",
      suite: "",
      street: "4750 E. 450 S.",
      city: "WHITESTOWN",
      state: "IN",
      postcode: "46075",
    },
    {
      name: "WALGREEN CO.",
      suite: "LEVEL J, ROOM MU-145",
      street: "500 PARNASSUS AVENUE",
      city: "SAN FRANCISCO",
      state: "CA",
      postcode: "94143",
    },
    {
      name: "KROGER SPECIALTY PHARMACY LA",
      suite: "",
      street: "2731 MANHATTAN BLVD STE B17",
      city: "HARVEY",
      state: "LA",
      postcode: "70058",
    },
    {
      name: "PROCARE PHARMACY, L.L.C.",
      suite: "SUITE 104",
      street: "7205 CORPORATE CENTER DRIVE",
      city: "MIAMI",
      state: "FL",
      postcode: "33126",
    },
    {
      name: "ACARIAHEALTH PHARMACY, INC.",
      suite: "",
      street: "10409 W 84TH TERRACE",
      city: "LENEXA",
      state: "KS",
      postcode: "66214",
    },
    {
      name: "ACARIAHEALTH PHARMACY, INC.",
      suite: "",
      street: "1736 GAUSE BLVD E STE 24",
      city: "SLIDELL",
      state: "LA",
      postcode: "70461",
    },
    {
      name: "CYSTIC FIBROSIS SERVICES",
      suite: "",
      street: "10530 JOHN W ELLIOTT DR, STE 200",
      city: "FRISCO",
      state: "TX",
      postcode: "75033",
    },
    {
      name: "ACCREDO HEALTH GROUP, INC.",
      suite: "",
      street: "2 BOULDEN CIR STE 1",
      city: "NEW CASTLE",
      state: "DE",
      postcode: "19720",
    },
    {
      name: "CENTERWELL PHARMACY, INC.",
      suite: "",
      street: "10737 MARKS WAY",
      city: "MIRAMAR",
      state: "FL",
      postcode: "33025",
    },
    {
      name: "OPTUM PHARMACY 707, INC",
      suite: "",
      street: "4900 RIVERGRADE RD STE E110",
      city: "IRWINDALE",
      state: "CA",
      postcode: "91706",
    },
    {
      name: "WALGREEN CO.",
      suite: "",
      street: "6400 FANNIN ST., SUITE 102",
      city: "HOUSTON",
      state: "TX",
      postcode: "77030",
    },
    {
      name: "WALGREEN EASTERN CO INC",
      suite: "",
      street: "205 8TH AVENUE",
      city: "NEW YORK",
      state: "NY",
      postcode: "10011",
    },
  ];

  const benlysta_pharmacies = [
    {
      name: "BEVERLY SINAI MEDICAL PHARMACY, INC",
      suite: "",
      street: "2514 N ONTARIO ST, SUITE 101",
      city: "BURBANK",
      state: "CA",
      postcode: "91504",
    },
    {
      name: "RX BIOTECH PHARMACY, LLC",
      suite: "",
      street: "2514 N ONTARIO ST., SUITE 102",
      city: "BURBANK",
      state: "CA",
      postcode: "91504",
    },
    {
      name: "WALGREEN CO.",
      suite: "",
      street: "1020 29TH ST STE 140",
      city: "SACRAMENTO",
      state: "CA",
      postcode: "95816",
    },
    {
      name: "WALGREEN CO.",
      suite: "",
      street: "2100 WEBSTER STREET SUITE 105",
      city: "SAN FRANCISCO",
      state: "CA",
      postcode: "94115",
    },
    {
      name: "MIAMI COAST PHARMACY LLC",
      suite: "",
      street: "1250 NW 7TH ST UNIT 101-102",
      city: "MIAMI",
      state: "FL",
      postcode: "33125",
    },
    {
      name: "WALGREEN CO.",
      suite: "",
      street: "500 PARNASSUS, I LEVEL, RM MU-005",
      city: "SAN FRANCISCO",
      state: "CA",
      postcode: "94143",
    },
    {
      name: "WALGREEN CO",
      suite: "",
      street: "640 UNIVERSITY AVE",
      city: "SAN DIEGO",
      state: "CA",
      postcode: "92103",
    },
    {
      name: "WALGREEN ARIZONA DRUG CO",
      suite: "",
      street: "5149 W THUNDERBIRD RD.",
      city: "GLENDALE",
      state: "AZ",
      postcode: "85306",
    },
    {
      name: "WALGREEN CO.",
      suite: "",
      street: "747 N RUTLEDGE ST STE 100",
      city: "SPRINGFIELD",
      state: "IL",
      postcode: "62702",
    },
    {
      name: "WALGREEN ARIZONA DRUG CO.",
      suite: "SUITE 7",
      street: "2302 N. CENTRAL AVE.",
      city: "PHOENIX",
      state: "AZ",
      postcode: "85004",
    },
    {
      name: "WALGREEN CO.",
      suite: "SUITE 114",
      street: "114 W. 3RD AVE.",
      city: "COLUMBUS",
      state: "OH",
      postcode: "43201",
    },
    {
      name: "MEIJER SPECIALTY PHARMACY",
      suite: "",
      street: "8455 HAGGERTY ROAD",
      city: "BELLEVILLE",
      state: "MI",
      postcode: "48111",
    },
    {
      name: "AVELLA OF DEER VALLEY, INC. #38",
      suite: "",
      street: "24416 N. 19TH AVE",
      city: "PHOENIX",
      state: "AZ",
      postcode: "85085",
    },
    {
      name: "WALGREEN CO.",
      suite: "",
      street: "335 MASSACHUSETTS AVENUE",
      city: "INDIANAPOLIS",
      state: "IN",
      postcode: "46204",
    },
    {
      name: "WAL-MART PHARMACY 10-3039",
      suite: "",
      street: "225 W CHICAGO AVE",
      city: "CHICAGO",
      state: "IL",
      postcode: "60654",
    },
    {
      name: "AMBER SPECIALTY PHARMACY",
      suite: "",
      street: "8924 KIRBY DR",
      city: "HOUSTON",
      state: "TX",
      postcode: "77054",
    },
    {
      name: "AMBER ENTERPRISES, INC",
      suite: "UNIT 102 & 103",
      street: "E 328 COMMERCIAL RD",
      city: "SAN BERNARDINO",
      state: "CA",
      postcode: "92408",
    },
    {
      name: "CYSTIC FIBROSIS SERVICES, LLC",
      suite: "SUITE 200",
      street: "10530 JOHN W ELLIOTT DRIVE",
      city: "FRISCO",
      state: "TX",
      postcode: "75033",
    },
    {
      name: "AVELLA OF DEER VALLEY, INC. #38",
      suite: "",
      street: "24416 N. 19TH AVE",
      city: "PHOENIX",
      state: "AZ",
      postcode: "85085",
    },
    {
      name: "WALGREEN OF PUERTO RICO, INC.",
      suite: "",
      street: "576 CALLE CESAR GONZALEZ STE 206",
      city: "SAN JUAN",
      state: "PR",
      postcode: "918",
    },
    {
      name: "PROCARE PHARMACY, L.L.C.",
      suite: "",
      street: "979 EAST THIRD STREET",
      city: "CHATTANOOGA",
      state: "TN",
      postcode: "37403",
    },
    {
      name: "CVS/SPECIALTY OR CARELONRX SPECIALTY PHA",
      suite: "",
      street: "1001 SPINKS ROAD, STE 280",
      city: "FLOWER MOUND",
      state: "TX",
      postcode: "75028",
    },
    {
      name: "WALGREEN CO.",
      suite: "SUITE 101",
      street: "1 N. TACOMA AVE.",
      city: "TACOMA",
      state: "WA",
      postcode: "98403",
    },
    {
      name: "WALGREEN CO.",
      suite: "",
      street: "601 E KING ST",
      city: "KINGS MOUNTAIN",
      state: "NC",
      postcode: "28086",
    },
    {
      name: "HY-VEE PHARMACY SOLUTIONS",
      suite: "",
      street: "215 10TH ST STE 110",
      city: "DES MOINES",
      state: "IA",
      postcode: "50309",
    },
    {
      name: "WALGREEN CO.",
      suite: "SPC B",
      street: "565 PEACHTREE STREET NE",
      city: "ATLANTA",
      state: "GA",
      postcode: "30308",
    },
    {
      name: "AMBER SPECIALTY PHARMACY",
      suite: "",
      street: "10 MEDICAL PKWY, PLAZA 3, STE 107",
      city: "FARMERS BRANCH",
      state: "TX",
      postcode: "75234",
    },
    {
      name: "WALGREEN CO.",
      suite: "STE 112",
      street: "7000 INDIANA AVE.",
      city: "RIVERSIDE",
      state: "CA",
      postcode: "92506",
    },
    {
      name: "WALGREEN CO.",
      suite: "",
      street: "2262 MARKET STREET",
      city: "SAN FRANCISCO",
      state: "CA",
      postcode: "94114",
    },
    {
      name: "PROCARE PHARMACY, L.L.C.",
      suite: "",
      street: "10160 INTERNATIONAL BLVD.",
      city: "WEST CHESTER",
      state: "OH",
      postcode: "45246",
    },
    {
      name: "OPTUM PHARMACY 801, INC.",
      suite: "",
      street: "24416 N 19TH AVE",
      city: "PHOENIX",
      state: "AZ",
      postcode: "85085",
    },
    {
      name: "OPTUM PHARMACY 700, LLC",
      suite: "",
      street: "19 CHAPIN RD, BLDG D, STE D8",
      city: "PINE BROOK",
      state: "NJ",
      postcode: "7058",
    },
    {
      name: "ACCREDO HEALTH GROUP, INC.",
      suite: "",
      street: "677 ALA MOANA BLVD STE 404",
      city: "HONOLULU",
      state: "HI",
      postcode: "96813",
    },
    {
      name: "WALGREEN CO.",
      suite: "",
      street: "28311 MARGUERITE PKWY STE B-1",
      city: "MISSION VIEJO",
      state: "CA",
      postcode: "92692",
    },
    {
      name: "WALGREEN CO.",
      suite: "STE 120",
      street: "3217 MONTROSE BLVD.",
      city: "HOUSTON",
      state: "TX",
      postcode: "77006",
    },
    {
      name: "OPTUM PHARMACY 700, LLC",
      suite: "",
      street: "5700 DOT COM CT, STE 1000",
      city: "OVIEDO",
      state: "FL",
      postcode: "32765",
    },
    {
      name: "AVELLA OF DEER VALLEY, INC. #38",
      suite: "",
      street: "24416 N. 19TH AVE.",
      city: "PHOENIX",
      state: "AZ",
      postcode: "85085",
    },
    {
      name: "OPTUM PHARMACY 701, LLC",
      suite: "",
      street: "8131 W BOSTIAN RD STE A350",
      city: "WOODINVILLE",
      state: "WA",
      postcode: "98072",
    },
    {
      name: "CAREMARK, L.L.C.",
      suite: "",
      street: "7251 S. EASTERN AVE.",
      city: "LAS VEGAS",
      state: "NV",
      postcode: "89119",
    },
    {
      name: "PROCARE PHARMACY, L.L.C.",
      suite: "500 ALA MOANA BLVD., BLDG 1",
      street: "ONE WATERFRONT PLAZA",
      city: "HONOLULU",
      state: "HI",
      postcode: "96813",
    },
    {
      name: "OPTUM INFUSION SERVICES 501, INC.",
      suite: "",
      street: "8131 W BOSTIAN RD",
      city: "WOODINVILLE",
      state: "WA",
      postcode: "98072",
    },
    {
      name: "OPTUM PHARMACY 701, LLC",
      suite: "",
      street: "4015 SHOPTON RD STE 600",
      city: "CHARLOTTE",
      state: "NC",
      postcode: "28217",
    },
    {
      name: "CAREMARK PUERTO RICO SPECIALTY PHARMACY,",
      suite: "SUITE 2",
      street: "280 AVE JESUS T PINERO",
      city: "SAN JUAN",
      state: "PR",
      postcode: "927",
    },
    {
      name: "OPTUM PHARMACY 701, LLC",
      suite: "",
      street: "4100 S SAGINAW ST STE D",
      city: "FLINT",
      state: "MI",
      postcode: "48507",
    },
    {
      name: "ACCREDO HEALTH GROUP INC",
      suite: "",
      street: "261 CEDAR HILL ST",
      city: "MARLBOROUGH",
      state: "MA",
      postcode: "1752",
    },
    {
      name: "CAREMARK MASSACHUSETTS SPECIALTY PHARMAC",
      suite: "",
      street: "25 BIRCH STREET, BLDG B, SUITE 100",
      city: "MILFORD",
      state: "MA",
      postcode: "1757",
    },
    {
      name: "OPTUM INFUSION SERVICES 550, LLC",
      suite: "",
      street: "20 COMMERCE WAY",
      city: "WOBURN",
      state: "MA",
      postcode: "1801",
    },
    {
      name: "LYNNFIELD DRUG INC",
      suite: "",
      street: "12 KENT WAY STE 120F",
      city: "BYFIELD",
      state: "MA",
      postcode: "1922",
    },
    {
      name: "PROCARE PHARMACY, L.L.C.",
      suite: "",
      street: "35 KNEELAND STREET",
      city: "BOSTON",
      state: "MA",
      postcode: "2111",
    },
    {
      name: "CVS PHARMACY, INC.",
      suite: "",
      street: "350 LONGWOOD AVENUE",
      city: "BOSTON",
      state: "MA",
      postcode: "2115",
    },
    {
      name: "PROCARE PHARMACY, L.L.C.",
      suite: "",
      street: "350 LONGWOOD AVE., STE B",
      city: "BOSTON",
      state: "MA",
      postcode: "2115",
    },
    {
      name: "CAREMARK NEW JERSEY SPECIALTY PHCY, LLC",
      suite: "",
      street: "180 PASSAIC AVE UNIT B-5",
      city: "FAIRFIELD",
      state: "NJ",
      postcode: "7004",
    },
    {
      name: "ACCREDO HEALTH GROUP, INC.",
      suite: "",
      street: "45 ROUTE 46 EAST",
      city: "PINE BROOK",
      state: "NJ",
      postcode: "7058",
    },
    {
      name: "WALGREEN EASTERN CO., INC.",
      suite: "",
      street: "1 DIAMOND HILL RD.",
      city: "BERKELEY HEIGHTS",
      state: "NJ",
      postcode: "7922",
    },
    {
      name: "PROCARE PHARMACY, L.L.C.",
      suite: "",
      street: "126 EIGHTH AVE.",
      city: "NEW YORK",
      state: "NY",
      postcode: "10011",
    },
    {
      name: "WALGREEN EASTERN CO INC",
      suite: "",
      street: "3462 JEROME AVE.",
      city: "BRONX",
      state: "NY",
      postcode: "10467",
    },
    {
      name: "AHG OF NEW YORK, INC",
      suite: "",
      street: "125 CLEARBROOK RD STE 122",
      city: "ELMSFORD",
      state: "NY",
      postcode: "10523",
    },
    {
      name: "WALGREEN EASTERN CO INC",
      suite: "",
      street: "19 BRADHURST AVENUE SUITE L1",
      city: "HAWTHORNE",
      state: "NY",
      postcode: "10532",
    },
    {
      name: "WALGREEN EASTERN CO., INC.",
      suite: "1ST FLOOR",
      street: "66 E. POST RD",
      city: "WHITE PLAINS",
      state: "NY",
      postcode: "10601",
    },
    {
      name: "KROGER SPECIALTY PHARMACY CA",
      suite: "",
      street: "7373 LINCOLN WAY",
      city: "GARDEN GROVE",
      state: "CA",
      postcode: "92841",
    },
    {
      name: "OPTUM PHARMACY 706, INC.",
      suite: "",
      street: "30-30   47TH AVENUE",
      city: "LONG ISLAND CITY",
      state: "NY",
      postcode: "11101",
    },
    {
      name: "WALGREEN EASTERN CO., INC.",
      suite: "",
      street: "55 PARK ST STE 1A",
      city: "NEW HAVEN",
      state: "CT",
      postcode: "6511",
    },
    {
      name: "ACARIAHEALTH PHARMACY #12, INC.",
      suite: "",
      street: "5 SKYLINE DR STE 240",
      city: "HAWTHORNE",
      state: "NY",
      postcode: "10532",
    },
    {
      name: "TOWN TOTAL HEALTH, LLC",
      suite: "SUITE A",
      street: "415 CROSSWAYS PARK DRIVE",
      city: "WOODBURY",
      state: "NY",
      postcode: "11797",
    },
    {
      name: "TOWN TOTAL HEALTH, LLC",
      suite: "",
      street: "728 MADISON AVE.",
      city: "ALBANY",
      state: "NY",
      postcode: "12208",
    },
    {
      name: "SPECIALTY PHARMACY MANAGEMENT, LLC",
      suite: "",
      street: "15 EARHART DR STE 101",
      city: "AMHERST",
      state: "NY",
      postcode: "14221",
    },
    {
      name: "PROCARE PHARMACY DIRECT, L.L.C.",
      suite: "",
      street: "105 MALL BOULEVARD",
      city: "MONROEVILLE",
      state: "PA",
      postcode: "15146",
    },
    {
      name: "ACCREDO HEALTH GROUP INC",
      suite: "",
      street: "3000 ERICSSON DRIVE, SUITE 100",
      city: "WARRENDALE",
      state: "PA",
      postcode: "15086",
    },
    {
      name: "BIOPARTNERS IN CARE INC",
      suite: "",
      street: "11411 STRANG LINE RD",
      city: "LENEXA",
      state: "KS",
      postcode: "66215",
    },
    {
      name: "AMBER ENTERPRISES, INC",
      suite: "",
      street: "323 NORRISTOWN RD STE 100",
      city: "AMBLER",
      state: "PA",
      postcode: "19002",
    },
    {
      name: "PROCARE PHARMACY, L.L.C.",
      suite: "",
      street: "1117 WALNUT ST.",
      city: "PHILADELPHIA",
      state: "PA",
      postcode: "19107",
    },
    {
      name: "WALGREEN CO",
      suite: "",
      street: "1325 14TH STREET NW",
      city: "WASHINGTON",
      state: "DC",
      postcode: "20005",
    },
    {
      name: "PROCARE PHARMACY, L.L.C.",
      suite: "",
      street: "1601 17TH STREET NW",
      city: "WASHINGTON",
      state: "DC",
      postcode: "20009",
    },
    {
      name: "WALGREEN CO.",
      suite: "",
      street: "6 N HOWARD STREET",
      city: "BALTIMORE",
      state: "MD",
      postcode: "21201",
    },
    {
      name: "ACCREDO HEALTH GROUP INC",
      suite: "",
      street: "422 GALLIMORE DAIRY RD",
      city: "GREENSBORO",
      state: "NC",
      postcode: "27409",
    },
    {
      name: "CAREMARK NORTH CAROLINA SPECIALTY PHARMA",
      suite: "",
      street: "10700 WORLD TRADE BLVD STE 110",
      city: "RALEIGH",
      state: "NC",
      postcode: "27617",
    },
    {
      name: "WALGREEN ARIZONA DRUG CO.",
      suite: "",
      street: "3627 N CAMPBELL AVE",
      city: "TUCSON",
      state: "AZ",
      postcode: "85719",
    },
    {
      name: "PROCARE PHARMACY, LLC",
      suite: "",
      street: "601 S. KINGS DR.",
      city: "CHARLOTTE",
      state: "NC",
      postcode: "28204",
    },
    {
      name: "PROCARE PHARMACY, L.L.C.",
      suite: "",
      street: "3250 HARDEN ST. EXT. SUITE #300",
      city: "COLUMBIA",
      state: "SC",
      postcode: "29203",
    },
    {
      name: "WALGREEN CO",
      suite: "",
      street: "201 E. HURON STREET, SUITE 01-210",
      city: "CHICAGO",
      state: "IL",
      postcode: "60611",
    },
    {
      name: "ACCREDO HEALTH GROUP INC",
      suite: "",
      street: "190 TECHNOLOGY PKWY STE 120",
      city: "NORCROSS",
      state: "GA",
      postcode: "30092",
    },
    {
      name: "ENCOMPASS RX, LLC",
      suite: "",
      street: "2700 NORTHEAST EXPRESSWAY",
      city: "ATLANTA",
      state: "GA",
      postcode: "30345",
    },
    {
      name: "PROCARE PHARMACY, L.L.C.",
      suite: "",
      street: "560 AMSTERDAM AVE, STE #E",
      city: "ATLANTA",
      state: "GA",
      postcode: "30306",
    },
    {
      name: "WALGREEN CO.",
      suite: "SUITE 505",
      street: "2835 N SHEFFIELD AVE",
      city: "CHICAGO",
      state: "IL",
      postcode: "60657",
    },
    {
      name: "KROGER SPECIALTY PHARMACY INC.",
      suite: "",
      street: "3200 LAKE EMMA RD UNIT 1000",
      city: "LAKE MARY",
      state: "FL",
      postcode: "32746",
    },
    {
      name: "DIPLOMAT SPECIALTY PHARMACY",
      suite: "",
      street: "4100 S SAGINAW ST",
      city: "FLINT",
      state: "MI",
      postcode: "48507",
    },
    {
      name: "WALGREEN CO.",
      suite: "",
      street: "1200 KUHL AVE",
      city: "ORLANDO",
      state: "FL",
      postcode: "32806",
    },
    {
      name: "ADVANCED CARE SCRIPTS, INC",
      suite: "SUITE 101",
      street: "6251 CHANCELLOR DRIVE",
      city: "ORLANDO",
      state: "FL",
      postcode: "32809",
    },
    {
      name: "ACCREDO HEALTH GROUP, INC.",
      suite: "",
      street: "6272 LEE VISTA BLVD STE 100",
      city: "ORLANDO",
      state: "FL",
      postcode: "32822",
    },
    {
      name: "OPTUM INFUSION SERVICES 201, INC.",
      suite: "",
      street: "5700 DOT COM CT STE 1030",
      city: "OVIEDO",
      state: "FL",
      postcode: "32765",
    },
    {
      name: "PUBLIX SUPER MARKETS",
      suite: "",
      street: "3200 LAKE WASHINGTON ROAD",
      city: "MELBOURNE",
      state: "FL",
      postcode: "32934",
    },
    {
      name: "WALGREEN CO.",
      suite: "",
      street: "1250 NW 7TH STREET SUITE 205",
      city: "MIAMI",
      state: "FL",
      postcode: "33125",
    },
    {
      name: "BIOSCRIP PHARMACY",
      suite: "",
      street: "21-23 STANHOPE STREET",
      city: "BOSTON",
      state: "MA",
      postcode: "2116",
    },
    {
      name: "WALGREEN CO.",
      suite: "",
      street: "1515 N. FLAGLER DRIVE SUITE 120",
      city: "WEST PALM BEACH",
      state: "FL",
      postcode: "33401",
    },
    {
      name: "WALGREEN CO.",
      suite: "",
      street: "3401 PGA BLVD STE-110",
      city: "PALM BEACH GARDENS",
      state: "FL",
      postcode: "33410",
    },
    {
      name: "CAREMARK FLORIDA SPECIALTY PHARMACY LLC",
      suite: "",
      street: "7930 WOODLAND CENTER BLVD STE 500",
      city: "TAMPA",
      state: "FL",
      postcode: "33614",
    },
    {
      name: "ACARIAHEALTH PHARMACY #26, INC",
      suite: "",
      street: "8715 HENDERSON RD",
      city: "TAMPA",
      state: "FL",
      postcode: "33634",
    },
    {
      name: "OPTION CARE ENTERPRISES, INC.",
      suite: "SUITE 430",
      street: "2100 RIVERCHASE CENTER",
      city: "HOOVER",
      state: "AL",
      postcode: "35244",
    },
    {
      name: "PROCARE PHARMACY, L.L.C.",
      suite: "",
      street: "1521 4TH AVE., SOUTH",
      city: "BIRMINGHAM",
      state: "AL",
      postcode: "35233",
    },
    {
      name: "OPTUM PHARMACY 705, LLC",
      suite: "",
      street: "1100 LEE BRANCH LN",
      city: "BIRMINGHAM",
      state: "AL",
      postcode: "35242",
    },
    {
      name: "ACCREDO HEALTH GROUP INC",
      suite: "",
      street: "2100 RIVERCHASE CTR STE 405",
      city: "HOOVER",
      state: "AL",
      postcode: "35244",
    },
    {
      name: "HOMECHOICE PARTNERS, INC.",
      suite: "",
      street: "2100 RIVERCHASE CENTER STE 314",
      city: "BIRMINGHAM",
      state: "AL",
      postcode: "35244",
    },
    {
      name: "KROGER SPECIALTY INFUSION AL LLC",
      suite: "",
      street: "2511 ROSS CLARK CIR",
      city: "DOTHAN",
      state: "AL",
      postcode: "36301",
    },
    {
      name: "OPTUM PHARMACY 705, LLC",
      suite: "",
      street: "1893 GENERAL GEORGE PATTON DR",
      city: "FRANKLIN",
      state: "TN",
      postcode: "37067",
    },
    {
      name: "ERLANGER PHARMACIES",
      suite: "",
      street: "979 EAST THIRD ST. SUITE 120",
      city: "CHATTANOOGA",
      state: "TN",
      postcode: "37403",
    },
    {
      name: "CAREMARK TENNESSEE SPECIALTY PHARMACY, L",
      suite: "SUITE 107",
      street: "8370 WOLF LAKE DRIVE",
      city: "BARTLETT",
      state: "TN",
      postcode: "38133",
    },
    {
      name: "ACCREDO HEALTH GROUP INC",
      suite: "",
      street: "1620 CENTURY CENTER PKWY",
      city: "MEMPHIS",
      state: "TN",
      postcode: "38134",
    },
    {
      name: "OPTUM PHARMACY 705, LLC",
      suite: "",
      street: "3539 BLUECUTT RD",
      city: "COLUMBUS",
      state: "MS",
      postcode: "39705",
    },
    {
      name: "WALGREENS SPECIALTY PHARMACY LLC",
      suite: "",
      street: "130 ENTERPRISE DRIVE",
      city: "PITTSBURGH",
      state: "PA",
      postcode: "15275",
    },
    {
      name: "WALGREENS SPECIALTY PHARMACY LLC",
      suite: "SUITE 100",
      street: "10530 JOHN W. ELLIOTT DRIVE",
      city: "FRISCO",
      state: "TX",
      postcode: "75033",
    },
    {
      name: "WALGREENS SPECIALTY PHARMACY, LLC",
      suite: "",
      street: "41460 HAGGERTY CIRCLE SOUTH",
      city: "CANTON",
      state: "MI",
      postcode: "48188",
    },
    {
      name: "WALGREEN CO.",
      suite: "",
      street: "912 W BELMONT AVENUE",
      city: "CHICAGO",
      state: "IL",
      postcode: "60657",
    },
    {
      name: "WALGREEN EASTERN CO., INC.",
      suite: "",
      street: "1 ROBERT WOOD JOHNSON PL.",
      city: "NEW BRUNSWICK",
      state: "NJ",
      postcode: "8901",
    },
    {
      name: "CENTERWELL PHARMACY, INC.",
      suite: "",
      street: "9843 WINDISCH RD",
      city: "WEST CHESTER",
      state: "OH",
      postcode: "45069",
    },
    {
      name: "MEDCO HEALTH SOLUTIONS OF INDIANA, LLC",
      suite: "",
      street: "4750 E. 450 S.",
      city: "WHITESTOWN",
      state: "IN",
      postcode: "46075",
    },
    {
      name: "ACCREDO HEALTH GROUP, INC.",
      suite: "",
      street: "2825 W PERIMETER RD STE 112",
      city: "INDIANAPOLIS",
      state: "IN",
      postcode: "46241",
    },
    {
      name: "WALGREEN CO.",
      suite: "",
      street: "9002 NORTH MERIDIAN SUITE 213",
      city: "INDIANAPOLIS",
      state: "IN",
      postcode: "46260",
    },
    {
      name: "WALGREEN EASTERN CO INC",
      suite: "",
      street: "1227 LOCUST STREET",
      city: "PHILADELPHIA",
      state: "PA",
      postcode: "19107",
    },
    {
      name: "WALGREEN CO.",
      suite: "",
      street: "1424 UNION AVENUE",
      city: "MEMPHIS",
      state: "TN",
      postcode: "38104",
    },
    {
      name: "CAREMARK MICHIGAN SPECIALTY PHARMACY, LL",
      suite: "",
      street: "1307 ALLEN DR STE H",
      city: "TROY",
      state: "MI",
      postcode: "48083",
    },
    {
      name: "HOMESCRIPTS",
      suite: "",
      street: "500 KIRTS BLVD",
      city: "TROY",
      state: "MI",
      postcode: "48084",
    },
    {
      name: "ACCREDO HEALTH GROUP INC",
      suite: "",
      street: "39625 LEWIS DR",
      city: "NOVI",
      state: "MI",
      postcode: "48377",
    },
    {
      name: "BIOSCRIP PHARMACY INC",
      suite: "",
      street: "826 N PLAKINTON AVE. SUITE 100",
      city: "MILWAUKEE",
      state: "WI",
      postcode: "53203",
    },
    {
      name: "ACCREDO HEALTH GROUP INC",
      suite: "",
      street: "2915 WATERS RD STE 109",
      city: "EAGAN",
      state: "MN",
      postcode: "55121",
    },
    {
      name: "WALGREEN CO.",
      suite: "",
      street: "2100 LYNDALE AVENUE SOUTH SUITE A",
      city: "MINNEAPOLIS",
      state: "MN",
      postcode: "55405",
    },
    {
      name: "CAREMARK ILLINOIS MAIL PHARMACY, LLC",
      suite: "SUITE A",
      street: "800 BIERMANN COURT",
      city: "MOUNT PROSPECT",
      state: "IL",
      postcode: "60056",
    },
    {
      name: "CAREMARK ILLINOIS SPECIALTY PHARMACY, LL",
      suite: "",
      street: "800 BIERMANN CT STE B",
      city: "MOUNT PROSPECT",
      state: "IL",
      postcode: "60056",
    },
    {
      name: "WALGREEN CO.",
      suite: "",
      street: "1775 W DEMPSTER ST STE T01116",
      city: "PARK RIDGE",
      state: "IL",
      postcode: "60068",
    },
    {
      name: "MIDWEST PHARMACIES INC",
      suite: "",
      street: "1 ERIE CT",
      city: "OAK PARK",
      state: "IL",
      postcode: "60302",
    },
    {
      name: "WALGREEN CO.",
      suite: "",
      street: "826 N PLANKINTON AVE SUITE 100",
      city: "MILWAUKEE",
      state: "WI",
      postcode: "53203",
    },
    {
      name: "WALGREEN CO.",
      suite: "STE 03110",
      street: "225 E CHICAGO AVE",
      city: "CHICAGO",
      state: "IL",
      postcode: "60611",
    },
    {
      name: "WALGREEN CO.",
      suite: "",
      street: "602 W. UNIVERSITY AVENUE",
      city: "URBANA",
      state: "IL",
      postcode: "61801",
    },
    {
      name: "CAREMARK,  L.L.C.",
      suite: "SUITE A",
      street: "1127 BRYN MAWR AVE",
      city: "REDLANDS",
      state: "CA",
      postcode: "92374",
    },
    {
      name: "FOUNDATION CARE LLC",
      suite: "",
      street: "4010 WEDGEWAY CT",
      city: "EARTH CITY",
      state: "MO",
      postcode: "63045",
    },
    {
      name: "WALGREEN CO.",
      suite: "STE A",
      street: "115 N EUCLID AVE",
      city: "SAINT LOUIS",
      state: "MO",
      postcode: "63108",
    },
    {
      name: "WALGREEN CO",
      suite: "",
      street: "3527 CHOUTEAU AVE.",
      city: "SAINT LOUIS",
      state: "MO",
      postcode: "63103",
    },
    {
      name: "WALGREEN CO",
      suite: "",
      street: "4545 EAST 9TH AVE SUITE 100",
      city: "DENVER",
      state: "CO",
      postcode: "80220",
    },
    {
      name: "OPTUMRX",
      suite: "",
      street: "6800 W 115TH ST STE 600",
      city: "OVERLAND PARK",
      state: "KS",
      postcode: "66211",
    },
    {
      name: "ACCREDO HEALTH GROUP, INC.",
      suite: "",
      street: "11411 STRANG LINE RD",
      city: "LENEXA",
      state: "KS",
      postcode: "66215",
    },
    {
      name: "CAREMARK KANSAS SPECIALTY PHARMACY",
      suite: "",
      street: "11162 RENNER BLVD",
      city: "LENEXA",
      state: "KS",
      postcode: "66219",
    },
    {
      name: "ACCREDO HEALTH GROUP INC",
      suite: "",
      street: "19060 Q ST STE 101",
      city: "OMAHA",
      state: "NE",
      postcode: "68135",
    },
    {
      name: "HY-VEE PHARMACY SOLUTIONS",
      suite: "",
      street: "10004 S. 152ND ST., SUITE C",
      city: "OMAHA",
      state: "NE",
      postcode: "68138",
    },
    {
      name: "TOTAL LIFE CARE RX PHARMACY, LLC",
      suite: "SUITE B17",
      street: "2731 MANHATTAN BLVD",
      city: "HARVEY",
      state: "LA",
      postcode: "70058",
    },
    {
      name: "WALGREEN LOUISIANA CO., INC.",
      suite: "",
      street: "1415 TULANE AVE STE 2CW07",
      city: "NEW ORLEANS",
      state: "LA",
      postcode: "70112",
    },
    {
      name: "ACCREDO HEALTH GROUP INC",
      suite: "",
      street: "520 ELMWOOD PARK BLVD STE 100",
      city: "NEW ORLEANS",
      state: "LA",
      postcode: "70123",
    },
    {
      name: "ACCREDO HEALTH GROUP INC",
      suite: "",
      street: "4901 W RENO AVE STE 950",
      city: "OKLAHOMA CITY",
      state: "OK",
      postcode: "73127",
    },
    {
      name: "ACCREDO HEALTH GROUP INC",
      suite: "",
      street: "4343 W ROYAL LN STE 124",
      city: "IRVING",
      state: "TX",
      postcode: "75063",
    },
    {
      name: "AMBER SPECIALTY PHARMACY",
      suite: "",
      street: "1301 E ARAPAHO RD, STE 103",
      city: "RICHARDSON",
      state: "TX",
      postcode: "75081",
    },
    {
      name: "KROGER SPECIALTY INFUSION TX",
      suite: "",
      street: "1748 N GREENVILLE AVE",
      city: "RICHARDSON",
      state: "TX",
      postcode: "75081",
    },
    {
      name: "SENDERRA RX PHARMACY",
      suite: "",
      street: "1301 E ARAPAHO RD STE 101",
      city: "RICHARDSON",
      state: "TX",
      postcode: "75081",
    },
    {
      name: "WALGREEN CO.",
      suite: "",
      street: "3826 CEDAR SPRINGS",
      city: "DALLAS",
      state: "TX",
      postcode: "75219",
    },
    {
      name: "PROCARE PHARMACY, L.L.C.",
      suite: "",
      street: "426 S. HENDERSON STREET",
      city: "FORT WORTH",
      state: "TX",
      postcode: "76104",
    },
    {
      name: "ACARIAHEALTH PHARMACY #11, INC",
      suite: "",
      street: "1311 W SAM HOUSTON PKWY N STE 130",
      city: "HOUSTON",
      state: "TX",
      postcode: "77043",
    },
    {
      name: "WALGREEN CO.",
      suite: "SUITE 120",
      street: "6624 FANNIN STREET",
      city: "HOUSTON",
      state: "TX",
      postcode: "77030",
    },
    {
      name: "WALGREEN CO.",
      suite: "",
      street: "6560 FANNIN STREET SUITE 260",
      city: "HOUSTON",
      state: "TX",
      postcode: "77030",
    },
    {
      name: "SPECIALTY THERAPEUTIC CARE",
      suite: "",
      street: "1311 W SAM HOUSTON PKWY N STE 150",
      city: "HOUSTON",
      state: "TX",
      postcode: "77043",
    },
    {
      name: "ACCREDO HEALTH GROUP INC",
      suite: "",
      street: "9307 KIRBY DR",
      city: "HOUSTON",
      state: "TX",
      postcode: "77054",
    },
    {
      name: "WALGREEN CO.",
      suite: "",
      street: "4101 GREENBRIAR SUITE 235",
      city: "HOUSTON",
      state: "TX",
      postcode: "77098",
    },
    {
      name: "PROCARE PHARMACY, L.L.C.",
      suite: "",
      street: "7950 FLOYD CURL DR. STE #106",
      city: "SAN ANTONIO",
      state: "TX",
      postcode: "78229",
    },
    {
      name: "WALGREEN CO",
      suite: "",
      street: "8333 N DAVIS HWY STE 50",
      city: "PENSACOLA",
      state: "FL",
      postcode: "32514",
    },
    {
      name: "OPTUM PHARMACY 704, INC.",
      suite: "",
      street: "5627 UNIVERSITY HEIGHTS STE 108",
      city: "SAN ANTONIO",
      state: "TX",
      postcode: "78249",
    },
    {
      name: "ACCREDO HEALTH GROUP INC",
      suite: "",
      street: "650 W GRAND AVE STE 102",
      city: "ELMHURST",
      state: "IL",
      postcode: "60126",
    },
    {
      name: "WALGREEN CO.",
      suite: "",
      street: "3537 BROADWAY BLVD.",
      city: "KANSAS CITY",
      state: "MO",
      postcode: "64111",
    },
    {
      name: "ACCREDO HEALTH GROUP INC",
      suite: "",
      street: "361 INVERNESS DR S STE F",
      city: "ENGLEWOOD",
      state: "CO",
      postcode: "80112",
    },
    {
      name: "PROCARE PHARMACY, L.L.C.",
      suite: "",
      street: "2101 N URSULA STREET UNIT 35",
      city: "AURORA",
      state: "CO",
      postcode: "80045",
    },
    {
      name: "WALGREEN CO.",
      suite: "",
      street: "1601 E 19TH AVE, STE 4650",
      city: "DENVER",
      state: "CO",
      postcode: "80218",
    },
    {
      name: "WALGREEN CO.",
      suite: "",
      street: "4745 ARAPAHOE AVE STE 120",
      city: "BOULDER",
      state: "CO",
      postcode: "80303",
    },
    {
      name: "ACCREDO HEALTH GROUP INC",
      suite: "",
      street: "3488 S MAIN ST",
      city: "SALT LAKE CITY",
      state: "UT",
      postcode: "84115",
    },
    {
      name: "PROCARE PHARMACY, L.L.C.",
      suite: "",
      street: "1002 EAST MCDOWELL ROAD",
      city: "PHOENIX",
      state: "AZ",
      postcode: "85006",
    },
    {
      name: "ACCREDO HEALTH GROUP INC",
      suite: "",
      street: "2040 W RIO SALADO PKWY STE 101B",
      city: "TEMPE",
      state: "AZ",
      postcode: "85281",
    },
    {
      name: "WALGREEN CO.",
      suite: "",
      street: "901 SOUTH RANCHO DRIVE #20",
      city: "LAS VEGAS",
      state: "NV",
      postcode: "89106",
    },
    {
      name: "ACCREDO HEALTH GROUP INC",
      suite: "",
      street: "212 CARPENTERS UNION WAY",
      city: "LAS VEGAS",
      state: "NV",
      postcode: "89119",
    },
    {
      name: "ACARIAHEALTH PHARMACY #13, INC",
      suite: "",
      street: "3302 GARFIELD AVE.",
      city: "COMMERCE",
      state: "CA",
      postcode: "90040",
    },
    {
      name: "BIOSCRIP PHARMACY",
      suite: "SUITE 1",
      street: "8490 SANTA MONICA BLVD",
      city: "WEST HOLLYWOOD",
      state: "CA",
      postcode: "90069",
    },
    {
      name: "PROCARE PHARMACY, L.L.C.",
      suite: "",
      street: "8607 SANTA MONICA BOULEVARD",
      city: "WEST HOLLYWOOD",
      state: "CA",
      postcode: "90069",
    },
    {
      name: "WALGREEN CO.",
      suite: "",
      street: "8490 SANTA MONICA BLVD SUITE 1",
      city: "WEST HOLLYWOOD",
      state: "CA",
      postcode: "90069",
    },
    {
      name: "KROGER SPECIALTY INFUSION CA",
      suite: "",
      street: "19110 VAN NESS AVE.",
      city: "TORRANCE",
      state: "CA",
      postcode: "90501",
    },
    {
      name: "PROCARE PHARMACY, L.L.C.",
      suite: "",
      street: "2 DUDLEY STREET SUITE 170",
      city: "PROVIDENCE",
      state: "RI",
      postcode: "2905",
    },
    {
      name: "WALGREEN CO.",
      suite: "",
      street: "4940 VAN NUYS BLVD SUITE 104",
      city: "SHERMAN OAKS",
      state: "CA",
      postcode: "91403",
    },
    {
      name: "PX DRUGSTORE",
      suite: "",
      street: "7373 LINCOLN WAY",
      city: "GARDEN GROVE",
      state: "CA",
      postcode: "92841",
    },
    {
      name: "PROCARE PHARMACY, L.L.C.",
      suite: "",
      street: "1010 UNIVERSITY AVENUE",
      city: "SAN DIEGO",
      state: "CA",
      postcode: "92103",
    },
    {
      name: "WALGREEN CO.",
      suite: "",
      street: "4339 DIPAOLO CENTER",
      city: "GLENVIEW",
      state: "IL",
      postcode: "60025",
    },
    {
      name: "ACCREDO HEALTH GROUP INC",
      suite: "",
      street: "2410 WARDLOW RD",
      city: "CORONA",
      state: "CA",
      postcode: "92878",
    },
    {
      name: "PROCARE PHARMACY, L.L.C.",
      suite: "",
      street: "445 CASTRO STREET",
      city: "SAN FRANCISCO",
      state: "CA",
      postcode: "94114",
    },
    {
      name: "MAGELLAN RX PHARMACY, LLC",
      suite: "",
      street: "6870 SHADOWRIDGE DR.",
      city: "ORLANDO",
      state: "FL",
      postcode: "32812",
    },
    {
      name: "WALGREEN CO.",
      suite: "",
      street: "35 COLLIER ROAD NW SUITE 100",
      city: "ATLANTA",
      state: "GA",
      postcode: "30309",
    },
    {
      name: "WALGREEN CO.",
      suite: "",
      street: "1874 PIEDMONT AVE NE STE 100A",
      city: "ATLANTA",
      state: "GA",
      postcode: "30324",
    },
    {
      name: "PROCARE PHARMACY, L.L.C.",
      suite: "",
      street: "1309 NW 23RD AVE.",
      city: "PORTLAND",
      state: "OR",
      postcode: "97210",
    },
    {
      name: "ACCREDO HEALTH GROUP INC",
      suite: "",
      street: "22623 68TH AVE S",
      city: "KENT",
      state: "WA",
      postcode: "98032",
    },
    {
      name: "PROCARE PHARMACY, L.L.C.",
      suite: "",
      street: "1001 MADISON ST.",
      city: "SEATTLE",
      state: "WA",
      postcode: "98104",
    },
    {
      name: "WALGREEN CO.",
      suite: "",
      street: "1409 11TH AVE.",
      city: "SEATTLE",
      state: "WA",
      postcode: "98122",
    },
    {
      name: "ACARIAHEALTH PHARMACY #14, INC.",
      suite: "",
      street: "4151 E COMMERCE WAY # 1041",
      city: "SACRAMENTO",
      state: "CA",
      postcode: "95834",
    },
    {
      name: "AMBER PHARMACY",
      suite: "SUITE A",
      street: "10004 S 152ND STREET",
      city: "OMAHA",
      state: "NE",
      postcode: "68138",
    },
    {
      name: "WALGREEN CO.",
      suite: "",
      street: "2301 HOLMES STREET",
      city: "KANSAS CITY",
      state: "MO",
      postcode: "64108",
    },
    {
      name: "OPTUM PHARMACY 702, LLC",
      suite: "",
      street: "1050 PATROL RD",
      city: "JEFFERSONVILLE",
      state: "IN",
      postcode: "47130",
    },
    {
      name: "EXPRESS SCRIPTS PHARMACY, INC.",
      suite: "",
      street: "4750 E. 450 S.",
      city: "WHITESTOWN",
      state: "IN",
      postcode: "46075",
    },
    {
      name: "WALGREEN CO.",
      suite: "LEVEL J, ROOM MU-145",
      street: "500 PARNASSUS AVENUE",
      city: "SAN FRANCISCO",
      state: "CA",
      postcode: "94143",
    },
    {
      name: "KROGER SPECIALTY PHARMACY LA",
      suite: "",
      street: "2731 MANHATTAN BLVD STE B17",
      city: "HARVEY",
      state: "LA",
      postcode: "70058",
    },
    {
      name: "AMBER ENTERPRISES, INC",
      suite: "",
      street: "10004 S 152ND ST STE A",
      city: "OMAHA",
      state: "NE",
      postcode: "68138",
    },
    {
      name: "HY-VEE PHARMACY SOLUTIONS",
      suite: "",
      street: "10004 S 152ND ST STE C",
      city: "OMAHA",
      state: "NE",
      postcode: "68138",
    },
    {
      name: "AMBER ENTERPRISES, INC",
      suite: "",
      street: "1 E ERIE ST STE 630-640",
      city: "CHICAGO",
      state: "IL",
      postcode: "60611",
    },
    {
      name: "PROCARE PHARMACY, L.L.C.",
      suite: "SUITE 104",
      street: "7205 CORPORATE CENTER DRIVE",
      city: "MIAMI",
      state: "FL",
      postcode: "33126",
    },
    {
      name: "ACARIAHEALTH PHARMACY, INC.",
      suite: "",
      street: "10409 W 84TH TERRACE",
      city: "LENEXA",
      state: "KS",
      postcode: "66214",
    },
    {
      name: "ACARIAHEALTH PHARMACY, INC.",
      suite: "",
      street: "1736 GAUSE BLVD E STE 24",
      city: "SLIDELL",
      state: "LA",
      postcode: "70461",
    },
    {
      name: "CYSTIC FIBROSIS SERVICES",
      suite: "",
      street: "10530 JOHN W ELLIOTT DR, STE 200",
      city: "FRISCO",
      state: "TX",
      postcode: "75033",
    },
    {
      name: "ACCREDO HEALTH GROUP, INC.",
      suite: "",
      street: "2 BOULDEN CIR STE 1",
      city: "NEW CASTLE",
      state: "DE",
      postcode: "19720",
    },
    {
      name: "CENTERWELL PHARMACY, INC.",
      suite: "",
      street: "10737 MARKS WAY",
      city: "MIRAMAR",
      state: "FL",
      postcode: "33025",
    },
    {
      name: "OPTUM PHARMACY 707, INC",
      suite: "",
      street: "4900 RIVERGRADE RD STE E110",
      city: "IRWINDALE",
      state: "CA",
      postcode: "91706",
    },
    {
      name: "WALGREEN CO.",
      suite: "",
      street: "6400 FANNIN ST., SUITE 102",
      city: "HOUSTON",
      state: "TX",
      postcode: "77030",
    },
    {
      name: "WALGREEN EASTERN CO INC",
      suite: "",
      street: "205 8TH AVENUE",
      city: "NEW YORK",
      state: "NY",
      postcode: "10011",
    },
  ];

  const ojjaara_pharmacies = [
    {
      name: "SINA DRUG, LLC",
      suite: "",
      street: "7505 FANNIN ST.",
      city: "HOUSTON",
      state: "TX",
      postcode: "77054",
    },
    {
      name: "SINA DRUG, LLC",
      suite: "",
      street: "10204 WERCH DR.",
      city: "WOODRIDGE",
      state: "IL",
      postcode: "60517",
    },
    {
      name: "BIOLOGICS, INC.",
      suite: "",
      street: "11800 WESTON PARKWAY",
      city: "CARY",
      state: "NC",
      postcode: "27513",
    },
    {
      name: "SORKIN'S RX LTD",
      suite: "",
      street: "1985 MARCUS AVE STE 130",
      city: "NEW HYDE PARK",
      state: "NY",
      postcode: "11042",
    },
    {
      name: "SINA DRUG, LLC",
      suite: "",
      street: "1985 MARCUS AVE STE 120",
      city: "NEW HYDE PARK",
      state: "NY",
      postcode: "11042",
    },
    {
      name: "ONCOMED THE ONCOLOGY PHARMACY OF BUFFALO",
      suite: "",
      street: "1100 MAIN ST STE 120",
      city: "BUFFALO",
      state: "NY",
      postcode: "14209",
    },
    {
      name: "SINA DRUG, LLC",
      suite: "",
      street: "13410 EASTPOINT CENTRE DR",
      city: "LOUISVILLE",
      state: "KY",
      postcode: "40223",
    },
    {
      name: "ONCOMED PHARMACEUTICAL SERVICES OF MA, L",
      suite: "",
      street: "335 BEAR HILL RD",
      city: "WALTHAM",
      state: "MA",
      postcode: "2451",
    },
  ];

  const formatAddress = (pharmacy) => {
    if (pharmacy.suite.length > 0) {
      return `${pharmacy.suite}, ${pharmacy.street} ${pharmacy.city} ${pharmacy.state} ${pharmacy.postcode}`;
    } else {
      return `${pharmacy.street} ${pharmacy.city} ${pharmacy.state} ${pharmacy.postcode}`;
    }
  };

  const renderRows = () => {
    switch (props.kind) {
      case "benlysta":
        var pharmacies = benlysta_pharmacies;
        break;
      case "nucala":
        var pharmacies = nucala_pharmacies;
        break;
      case "zejula":
        var pharmacies = zejula_pharmacies;
        break;
      case "flolan":
        var pharmacies = flolan_pharmacies;
        break;
      case "ojjaara":
        var pharmacies = ojjaara_pharmacies;
        break;
    }

    return _.map(pharmacies, (pharmacy, index) => {
      return (
        <div key={index} style={{ marginBottom: 18 }}>
          <div style={{ fontSize: 12, fontWeight: 500 }}>{pharmacy.name}</div>
          <div style={{ fontSize: 12, color: "#718291", marginTop: 5 }}>{formatAddress(pharmacy)}</div>
        </div>
      );
    });
  };

  const renderModal = () => {
    return (
      <div className="modal__background">
        <div
          className="modal__body modal__body--no-side-padding animated fadeInDown"
          style={{ paddingTop: 30, paddingBottom: 30 }}
        >
          <div className="modal__body__header-container">
            <div className="modal__body__header-text-container">
              <div className="modal__body__title">Contract Pharmacies</div>
              <div className="modal__body__subtitle" style={{ marginBottom: 30 }}>
                Please refer below for a list of eligible contract pharmacies for GlaxoSmithKline products
              </div>
            </div>
            <div className="modal__body__header-image">
              <img src={verifyImage} width={150} />
            </div>
          </div>
          <div
            className="modal__body__form_container"
            style={{
              paddingLeft: 50,
              paddingRight: 50,
              paddingTop: 34,
              paddingBottom: 50,
              maxHeight: 450,
              overflowY: "scroll",
            }}
          >
            {renderRows()}
          </div>
          <div className="modal__body__footer">
            <div className="btn" style={{ flexGrow: 1 }} onClick={() => props.closeModal()}>
              Close
            </div>
          </div>
        </div>
      </div>
    );
  };

  return renderModal();
};

export default ListPharmaciesModal;
