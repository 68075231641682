import React from "react";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";

const CustomTextField = withStyles({
  root: {
    backgroundColor: "#ffffff",
    borderRadius: 8,
    "& label.Mui-focused": {
      color: "#3D71FF",
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: "green",
    },
    "& .MuiOutlinedInput-root": {
      borderRadius: 8,
      "& fieldset": {
        borderColor: "#DEE7EE",
      },
      "&:hover fieldset": {
        borderColor: "#DEE7EE",
      },
      "&.Mui-focused fieldset": {
        borderColor: "#3D71FF",
      },
    },
  },
})(TextField);

const VerifyForm = (props) => {
  return (
    <form method="POST" action="/verify">
      <input type="hidden" name="organization_id" value={props.organization} />
      <input type="hidden" name="user_id" value={props.user} />
      <CustomTextField
        variant="outlined"
        margin="normal"
        required
        fullWidth
        id="twofa"
        label="Authentication Code"
        name="code"
        autoFocus
      />
      <div className="register__form__footnote">
        Want to send your authentication code via email instead? Click the button below.
      </div>
      <div className="register__form__actions">
        <a href={`${window.location.origin}/organization/${props.organization}/users/${props.user}/email_2fa`}>
          <div className="btn btn--outline btn--white">Email Authentication Code</div>
        </a>
        <button className="btn" style={{ flexGrow: 1, marginLeft: 30 }} type="submit">
          Submit
        </button>
      </div>
    </form>
  );
};

export default VerifyForm;
