import React, { useState, useRef, useEffect } from "react";
import _ from "lodash";
import Takeda from "images/Takeda.png";

const AgreementTakeda = (props) => {
  const renderAgreement = () => {
    if (props.selectedManufacturer == "takeda") {
      return (
        <div>
          <div className="logo__container">
            <div className="logo">
              <img src={Takeda} />
            </div>
            <div className="logo__info">
              <div className="logo__info__title">Takeda</div>
              <div className="logo__info__subtitle">Wholly Owned Contract Pharmacy Agreement</div>
            </div>
          </div>
          <p>
            For orders placed on or after January 22nd, 2024 under the 340B Drug Pricing Program (“340B”), Takeda
            authorized wholesalers and distributors will not ship the Products identified below to covered entity
            contract pharmacies except as described in our 340B Contract Pharmacy Policy. Takeda's authorized
            wholesalers and distributors will continue to ship these Products to locations registered as a 340B covered
            entity or child site location affiliated with that covered entity.
          </p>
          <p>
            Products included in the 340B Contract Pharmacy Policy, effective January 22nd, 2024: Entyvio Pen, Entyvio
            IV, Trintellix, Motegrity, Mydayis, Dexilant, Adderall XR, Pentasa, Vyvanse
          </p>
          <p>Products included in this policy effective February 16th, 2024: Iclusig, Alunbrig, Fruzaqla</p>
          <p>Product included in this policy effective March 22nd, 2024: Ninlaro</p>
          <p>
            Covered entities remain eligible for Bill To/Ship To 340B arrangements for Takeda products that are not
            listed here.
          </p>
          <p>
            Federal grantees remain eligible for Bill To/Ship To arrangements for all Takeda products at the 340B
            discounted price.
          </p>
          <p>Under Takeda's 340B Contract Pharmacy Policy:</p>
          <ul>
            <li>
              Any covered entity that does not have an in-house pharmacy capable of dispensing Products purchased at the
              340B price to its patients may designate a single independent contract pharmacy location by visiting
              www.340Besp.com/designations, OR may designate its Wholly Owned Contract Pharmacy(ies) to receive bill
              to/ship to replenishment orders of 340B priced Products, as described in this policy.
            </li>
            <li>
              Any covered entity that has an in-house pharmacy capable of dispensing Products purchased at the 340B
              price to its patients may also designate its Wholly Owned Contract Pharmacy(ies) to receive bill to/ship
              to replenishment orders of 340B priced Products, as described in this policy.
            </li>
            <li>
              “Wholly Owned Contract Pharmacy(ies)” include contract pharmacies that are wholly owned by the covered
              entity (or have common ownership with the entity) and are registered with HRSA as a contract pharmacy for
              that covered entity.
            </li>
            <li>
              Any designated single contract pharmacy location or Wholly Owned Contract Pharmacies must be within 40
              miles of the covered entity parent site, except as described below for Entyvio Pen.
            </li>
            <li>
              For Entyvio Pen, Iclusig, Alunbrig, Fruzaqla, and Ninlaro only pharmacies in Takeda's limited distribution
              network with access to a specific Product may be designated for the same Product, so the requirement that
              the designated single contract pharmacy or Wholly Owned Contract Pharmacy(ies) be within 40 miles of the
              covered entity's parent site may be waived by Takeda.
            </li>
            <li>
              The covered entity must also provide claims data through 340B ESP™ for the designated single contract
              pharmacy location or Wholly Owned Contract Pharmacy(ies) to receive 340B discounted pricing.
            </li>
          </ul>
          <p>
            If a covered entity or health system is granted an exception from Takeda's 340B Contract Pharmacy Policy for
            its Wholly Owned Contract Pharmacy(ies), it may not also designate an independent contract pharmacy.
          </p>
          <p>
            Covered entities must complete the Wholly Owned Contract Pharmacy Exception request form on the 340B ESP
            website to apply for this exception. Proof of ownership documents are required to submit your request.
            Examples of proof of ownership documents include:
          </p>
          <ul>
            <li>Certificate of liability insurance</li>
            <li>State business licenses</li>
          </ul>
          <p>
            Wholly Owned Contract Pharmacy Exception requests will be processed within five (5) business days. Please
            note that failure to populate all required fields will delay the processing of Wholly Owned Contract
            Pharmacy Exception requests. Exceptions will be valid for twelve (12) months from approval date and after
            the initial 12-month period, Takeda may require covered entities to resubmit proof of ownership documents to
            validate the continued eligibility of existing exceptions.
          </p>
          <p>
            By signing this Exception request the authorized representative of the covered entity certifies that the
            covered entity wholly owns the identified contract pharmacy(ies), or the identified contract pharmacy(ies)
            have the same corporate ownership, and the identified pharmacies meet the requirements for this Exception.
            If there are any changes to the ownership structure of any of the contract pharmacies identified in this
            request form, the covered entity agrees to notify Takeda at support@340besp.com within five (5) business
            days of such change of ownership.
          </p>

          <div className="register__divider" />
        </div>
      );
    } else {
      return null;
    }
  };

  return renderAgreement();
};

export default AgreementTakeda;
