import React, { Component } from "react";
import verifyImage from "images/data_illustration.png";
import { render } from "react-dom";
import _ from "lodash";

const SuperAdminDetails = (props) => {


  const render = () => {
    return (
      <div className="draw__entity-card draw__entity-card--pending-transfer">
        <div className="draw__entity-card__title">
          {props.entity.entity_name_brg}
        </div>
        <div className="draw__entity-card__info-value">
          {props.entity.id_340b}
        </div>
        <div className="draw__entity-card__info-label">340B ID</div>
      </div>
    );
  };

  return render();
};

export default SuperAdminDetails;
