import React, { useRef } from "react";
import useOutsideClick from "../../Hooks/useOutsideClick";
import StatusTag from "./StatusTag";

const AttestationsSummary = ({ setDisplay, attestations, entity }) => {
  const modalRef = useRef();
  useOutsideClick(modalRef, closeModal);

  function closeModal() {
    setDisplay(false);
  }

  const renderPharmacies = () => {
    return attestations.map((pharmacy, i) => {
      return (
        <tr key={pharmacy.pharmacy_id} className={`sm`}>
          <td className="t--md">
            <div className="t--sm t--500">{pharmacy.pharmacy_name}</div>
            <div className="t--sm t--subtitle">{pharmacy.pharmacy_address}</div>
          </td>
          <td className="t--md">{pharmacy.npi || "--"}</td>
          <td className="t--md">
            <StatusTag status={pharmacy.status} />
          </td>
        </tr>
      );
    });
  };

  const renderTable = () => {
    return (
      <div className="attestation_modal__table-container">
        <table className="primary">
          <thead>
            <tr className="sm">
              <th>Pharmacy Name</th>
              <th>NPI</th>
              <th>Status</th>
            </tr>
          </thead>

          <tbody>{renderPharmacies()}</tbody>
        </table>
      </div>
    );
  };

  const renderEntityTag = () => {
    return <div>{entity.id_340b}</div>;
  };

  function renderHeader() {
    return (
      <div className="attestation_modal__header">
        {renderEntityTag()}
        <div className="attestation_modal__header__title">Sanofi Contract Pharmacy Anti-Diversion Policy</div>
        <div className="attestation_modal__header__subtitle">Submission summary</div>
      </div>
    );
  }

  function renderBody() {
    return (
      <>
        <div>
          <div className="t--500 mb--8">
            Below is a list of all pharmacies for which you have either attested, or submitted (a) pharmacy agreement(s)
          </div>
          <div className="t--md">
            Need to change or make an update to an attestation or document submission? Please{" "}
            <a
              href="https://help.340besp.com/en/articles/8808065-frequently-asked-questions-faqs"
              target="_blank"
              className="t--blue t--anchor"
            >
              click here
            </a>{" "}
            to chat with our support team or email us at{" "}
            <a href="mailto:support@340besp.com" className="t--blue t--anchor">
              support@340Besp.com
            </a>
            .
          </div>
        </div>
        {renderTable()}
      </>
    );
  }

  return (
    <div className="attestation_modal__background">
      <div className="attestation_modal__container" ref={modalRef}>
        {renderHeader()}

        <div className="attestation_modal__body">{renderBody()}</div>

        <div className="attestation_modal__footer">
          <div></div>
          <div className="flex gap--16">
            <button className="button button--lg button--white t--500" onClick={closeModal}>
              Close
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AttestationsSummary;
