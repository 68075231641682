import React, { useState, useEffect } from "react";
import { createPortal } from "react-dom";
import { CloseRounded } from "@mui/icons-material";
import useMultiPageNavigation from "../Hooks/useMultiPageNavigation";
import useConfirm from "../Hooks/useConfirm";
import IntroSlide from "./modal_slides/IntroSlide";
import MainSlide from "./modal_slides/MainSlide";
import AlertModal from "../AlertModal";

const Modal = ({
  organizationId,
  entities,
  attestations,
  setAttestations,
  hasCompletedAllAttestations,
  handleCloseModal,
  showSuccessModal,
  stats,
  setStats,
  setNotice,
}) => {
  const [shouldConfirmRef, setShouldConfirmRef] = useState(false);

  useEffect(() => {
    document.body.classList.add("overflow--hidden");

    return () => document.body.classList.remove("overflow--hidden");
  }, []);

  const { displayConfirmModal, getConfirmation, handleConfirm, handleCancel } = useConfirm();

  const { nextPage, currPage } = useMultiPageNavigation([
    <IntroSlide showMainSlide={() => nextPage()} handleCloseModal={handleCloseModal} />,
    <MainSlide
      organizationId={organizationId}
      entities={entities}
      stats={stats}
      setStats={setStats}
      attestations={attestations}
      setAttestations={setAttestations}
      hasCompletedAllAttestations={hasCompletedAllAttestations}
      showSuccessModal={showSuccessModal}
      handleCloseModal={handleCloseModal}
      setShouldConfirmRef={setShouldConfirmRef}
      getConfirmation={getConfirmation}
      getConfirmationIfNeeded={getConfirmationIfNeeded}
      setNotice={setNotice}
    />,
  ]);

  async function getConfirmationIfNeeded(callback) {
    if (shouldConfirmRef) {
      const isConfirmed = await getConfirmation();
      if (!isConfirmed) return;

      setShouldConfirmRef(false);
    }

    callback();
  }

  return createPortal(
    <>
      <div
        className="pharm_cert_modal__background flex align-end position--fixed"
        onClick={() => getConfirmationIfNeeded(handleCloseModal)}
      >
        <div
          className="pharm_cert_modal animated fadeInUp flex--col align-center position--rel bg--white pt--64 pb--16"
          onClick={(e) => e.stopPropagation()}
        >
          <div className="pharm_cert_modal__content container col-md-8 flex--col flex-grow">
            <div className="pb--24">
              <div className="t--xl t--500 t--title mb--8">Pharmacy Certification</div>
              <div className="t--subtitle">Complete the steps below for each covered entity listed</div>
            </div>
            <div className="pharm_cert_modal__body flex--col">{currPage}</div>
          </div>
          <CloseRounded
            className="pharm_cert_modal__close_icon position--abs"
            onClick={() => getConfirmationIfNeeded(handleCloseModal)}
          />
        </div>
      </div>
      <AlertModal
        isOpen={displayConfirmModal}
        title="Are you sure?"
        message="Are you sure? You may have unsaved progress. If you click Continue, you will lose any progress you may have made in this section. If you are okay with losing any potential progress in this section, click Continue. Otherwise click Cancel."
        confirmText="Continue"
        handleConfirm={handleConfirm}
        cancelText="Cancel"
        handleCancel={handleCancel}
      />
    </>,
    document.body
  );
};

export default Modal;
