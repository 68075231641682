import React from "react";
import brainstormIllustration from "../../../../assets/images/brainstorm_illustration.png";

const STEPS = [
  {
    heading: "Select Your Covered Entity",
    description: "Pharmacy Certification is required for each covered entity listed.",
  },
  {
    heading: "Certify the In-House Status",
    description: "Complete the Pharmacy Certification steps requested.",
  },
  {
    heading: "Submit the Certification",
    description: "Once complete, submit the Pharmacy Certification for your covered entity.",
  },
];

const IntroSlide = ({ handleCloseModal, showMainSlide }) => {
  return (
    <div className="flex--col flex-grow border br--8" style={{ minHeight: 0 }}>
      <div className="flex flex-grow pa--24 overflow--auto">
        <div className="flex--col align-center flex-grow" style={{ minWidth: 880, margin: "auto" }}>
          <img className="mb--24" style={{ width: 172 }} src={brainstormIllustration} alt="brainstorm_illustration" />
          <div className="flex--col align-center t--center gap--32">
            <div className="flex--col gap--8 t--title" style={{ maxWidth: 750 }}>
              <div className="t--700">How to complete your Pharmacy Certification</div>
              <div>
                340B ESP requires users certify the in-house status of their covered entity. To get started,
                submit the Pharmacy Certification for each covered entity.
              </div>
            </div>
            <div className="flex justify-center gap--80">
              {STEPS.map(({ heading, description }, i) => (
                <div key={i} className="pharm_cert_modal__intro_step flex--col align-center position--rel">
                  <div className="pharm_cert_modal__intro_step__number flex align-center justify-center position--rel t--700 bg--blue br--round mb--12">
                    {i + 1}
                  </div>
                  <div className="t--md t--700 t--blue mb--8">{heading}</div>
                  <div className="t--sm t--title">{description}</div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
      <div className="pharm_cert_modal__action_bar">
        <button className="btn btn--lg flex align-center btn--white border" onClick={handleCloseModal}>
          Cancel
        </button>
        <button className="btn btn--lg flex align-center" onClick={showMainSlide}>
          Get Started
        </button>
      </div>
    </div>
  );
};

export default IntroSlide;
