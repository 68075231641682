import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Snackbar from "@material-ui/core/Snackbar";
import Alert from "@material-ui/lab/Alert";

const useStyles = makeStyles({
  root: {
    maxWidth: "50%",
  },
});

export default function Notice(props) {
  const [state, setState] = React.useState(props.details);

  const classes = useStyles();

  useEffect(() => {
    setState(props.details);
  }, [props.details]);

  const handleClose = () => {
    setState({ ...state, open: false });
  };

  function getClassName() {
    if (props.width === "half") {
      return classes.root;
    }
    return null;
  }

  return (
    <Snackbar
      className={getClassName()}
      anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
      open={state.open}
      autoHideDuration={5000}
      onClose={handleClose}
    >
      <Alert elevation={5} variant="filled" onClose={handleClose} severity={state.kind}>
        {state.message}
      </Alert>
    </Snackbar>
  );
}
