import React from "react";
import InHouseRadioGroup from "./InHouseRadioGroup";
import PharmacyList from "./PharmacyList";
import SignAndAttest from "./SignAndAttest";
import ArrowRight from "../../../Icons/ArrowRight";

const HEADER_VARIANTS = {
  DESIGNATION_REMOVAL_WARNING: "Designation Removal Warning",
  NO_ADDITIONAL_DOC_REQUESTS: "No Additional Documentation Requests",
};

const FirstPage = ({
  pharmacies,
  activeId340B,
  attestation,
  setAttestation,
  showUploadPage,
  showSubmissionPage,
  allAttestationsCompleted,
  allAttestationsInitiallyCompleted,
  handleNextAttestation,
  handleSubmitAttestation,
  showSuccessModal,
  handleCloseModal,
  getConfirmation,
}) => {
  if (!activeId340B) return null;

  const completedAttestation = attestation.status == "complete";

  function renderButton() {
    function getButtonProps() {
      if (allAttestationsInitiallyCompleted) {
        return { text: "Close", onClick: handleCloseModal, disabled: false, renderArrowIcon: false };
      }

      if (allAttestationsCompleted) {
        return { text: "Finish", onClick: showSuccessModal, disabled: false, renderArrowIcon: true };
      }

      if (completedAttestation) {
        return { text: "Next Covered Entity", onClick: handleNextAttestation, disabled: false, renderArrowIcon: true };
      }

      if (attestation.in_house === false && attestation.documents_required) {
        return {
          text: "Continue",
          onClick: showSubmissionPage,
          disabled: attestation.in_house_certifications?.some(
            (certification) => certification.status === "not_started"
          ),
          renderArrowIcon: true,
        };
      }

      return {
        text: "Submit",
        onClick: handleSubmitAttestation,
        disabled: !attestation.signed_by,
        renderArrowIcon: true,
      };
    }

    const { text, onClick, disabled, renderArrowIcon } = getButtonProps();

    return (
      <button className="btn btn--lg flex align-center gap--8" onClick={onClick} disabled={disabled}>
        <span>{text}</span>
        {renderArrowIcon && <ArrowRight />}
      </button>
    );
  }

  function renderSignAndAttestOrPharmacyList() {
    if (attestation.in_house == false && attestation.documents_required) {
      return (
        <div className="pt--24 border--top">
          <PharmacyList pharmacies={pharmacies} showUploadPage={showUploadPage} />
        </div>
      );
    }

    if (attestation.in_house !== null) {
      function renderHeader() {
        if (!completedAttestation && attestation.in_house == true) {
          return (
            <div className="t--title">
              <div className="t--700 mb--8">Attention: Existing Designations May Be Removed</div>
              <div className="pharm_cert_modal__warning t--sm br--4 pa--12 gap--8">
                You have indicated that {attestation.id_340b} has an in-house pharmacy. Existing designations may be
                removed depending on the criteria of any pharmaceutical manufacturer's policy.
              </div>
            </div>
          );
        }

        if (!attestation.documents_required || attestation.in_house == true) {
          return (
            <div className="t--title">
              <div className="t--700 mb--8">No Additional Documentation Requests</div>
              <div className="t--md">Sign and attest below to submit.</div>
            </div>
          );
        }

        return null;
      }

      return (
        <div className="pt--24 border--top">
          <div className="flex--col gap--24">
            {renderHeader()}
            <SignAndAttest
              attestation={attestation}
              setAttestation={setAttestation}
              activeId340B={activeId340B}
              readOnly={completedAttestation}
            />
          </div>
        </div>
      );
    }

    return null;
  }

  return (
    <>
      <div className="pa--24 flex-grow overflow--y-auto">
        <div className="pb--24">
          <div className="t--700 t--title mb--8">
            Does {activeId340B} have an in-house pharmacy capable of retail dispensing for a patient's subsequent
            self-administration?
          </div>
          <div className="t--md t--title mb--24">
            To proceed, select <strong>Yes</strong> or <strong>No</strong>.
          </div>
          <InHouseRadioGroup
            setAttestation={setAttestation}
            attestation={attestation}
            disabled={completedAttestation}
            getConfirmation={getConfirmation}
          />
        </div>
        {renderSignAndAttestOrPharmacyList()}
      </div>
      <div className="pharm_cert_modal__action_bar">{renderButton()}</div>
    </>
  );
};

export default FirstPage;
