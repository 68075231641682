import React, { useState, useEffect } from "react";
import _ from "lodash";
import XLSX from "xlsx";
import DataOutput from "./DataOutput";
import FieldMappings from "./FieldMappings";
import Notice from "../Notice";
import TabBar from "../TabBar";
import DataInput from "./DataInput";
import { DragDropContext } from "react-beautiful-dnd";
import { fetchWrapper } from "./SubmissionHttp";
import { selectMapping, formatData, filesWithValidationErrors } from "./DataProcessing";

const DataSubmission = (props) => {
  const [state, setState] = useState({
    attachments: [],
    selectedFile: 0,
    selectedMapping: {},
  });

  const [salt, setSalt] = useState();
  const [filesWithErrors, setFilesWithErrors] = useState([]);
  const [displayTabBarTags, setDisplayTabBarTags] = useState(false);
  const [notice, setNotice] = useState({ kind: "error", open: false, message: "" });
  const [activeTab, setActiveTab] = useState(["Data"]);
  const [fieldMappingGroups, setFieldMappingGroups] = useState(props.fieldMapping);

  const ndcList = JSON.parse(props.ndcList);
  const tabs = ["Data", "Column Mappings"];
  const requiredColumns = [
    "date_of_service",
    "date_prescribed",
    "rx_number",
    "ndc",
    "quantity",
    "prescriber_id",
    "prescriber_id_qualifier",
    "service_provider_id",
    "service_provider_id_qualifier",
    "wholesaler_invoice_number",
    "contracted_entity_id",
    "claim_conforms_flag",
    "formatted_rx_number",
    "payer_bin",
    "payer_pcn",
    "ship_to_location",
    "ship_to_date",
    "account_340b",
    "product_serialization_number",
    "fill_number",
  ];

  useEffect(() => {
    fetchVault();
  }, []);

  // When field mappings change/update reprocess file data
  useEffect(() => {
    if (state.attachments.length) {
      processData();
    }
  }, [fieldMappingGroups]);

  // check file raw and processed data on change/update for errors
  useEffect(() => {
    setFilesWithErrors(filesWithValidationErrors(state.attachments, fieldMappingGroups, ndcList));
  }, [state]);

  // process data when salt state is updated
  useEffect(() => {
    processData();
  }, [salt]);

  const processData = () => {
    var clonedAttachments = _.cloneDeep(state.attachments);
    var mapping = state.selectedMapping;

    _.forEach(clonedAttachments, (file, index) => {
      var mappingObj = selectMapping(fieldMappingGroups, file.data);
      if (index == file.position) {
        mapping = mappingObj.mappings;
      }

      file.mappingName = mappingObj.name;
      file.processedData = formatData(mappingObj.mappings, file.data, ndcList, salt, filesWithErrors, file.position);
    });

    setState({ selectedFile: state.selectedFile, selectedMapping: mapping, attachments: clonedAttachments });
  };

  const fetchVault = () => {
    if (salt && salt.length == 64) return;

    fetchWrapper
      .vault(props.organization)
      .then((res) => setSalt(res.value))
      .catch((error) =>
        setNotice({ kind: "error", open: true, message: "Oh no - it looks like something went wrong" })
      );
  };

  const createFieldMapping = (name) => {
    fetchWrapper
      .createFieldMapping(props.organization, name)
      .then((res) => setFieldMappingGroups(res.mappings))
      .catch((error) =>
        setNotice({ kind: "error", open: true, message: "Oh no - it looks like something went wrong" })
      );
  };

  const updateFieldMapping = (id, fieldName, mappingName) => {
    fetchWrapper
      .updateFieldMapping(props.organization, id, fieldName, mappingName)
      .then((res) => setFieldMappingGroups(res.mappings))
      .catch((error) =>
        setNotice({ kind: "error", open: true, message: "Oh no - it looks like something went wrong" })
      );
  };

  // Deletes all mapggins in a given field_mapping record
  const deleteAllFieldMappings = (id) => {
    fetchWrapper
      .deleteAllFieldMappings(props.organization, id)
      .then((res) => setFieldMappingGroups(res.mappings))
      .catch((error) =>
        setNotice({ kind: "error", open: true, message: "Oh no - it looks like something went wrong" })
      );
  };

  // Delete single mapping attr in given field_mapping record
  const deleteFieldMapping = (id, fieldName, mappingName) => {
    fetchWrapper
      .deleteFieldMapping(props.organization, id, fieldName, mappingName)
      .then((res) => setFieldMappingGroups(res.mappings))
      .catch((error) =>
        setNotice({ kind: "error", open: true, message: "Oh no - it looks like something went wrong" })
      );
  };

  const reset = () => {
    setState({ attachments: [], selectedFile: 0 });
  };

  const manualSetSelectedMapping = (selectedMapping) => {
    var attachments = _.cloneDeep(state.attachments);
    var attachment = _.find(attachments, (attached) => attached.position == state.selectedFile);

    if (attachment) {
      attachment.mappingName = selectedMapping.name;
      attachment.processedData = formatData(
        selectedMapping.mappings,
        attachment.data,
        ndcList,
        salt,
        filesWithErrors,
        attachment.position
      );
      setState({
        selectedFile: state.selectedFile,
        selectedMapping: selectedMapping.mappings,
        attachments: attachments,
      });
    }
  };

  const removeSubmissionError = (value) => {
    var cloneFilesWithErrors = _.cloneDeep(filesWithErrors);
    var errors = _.pull(cloneFilesWithErrors, value);
    setFilesWithErrors(errors);
  };

  const setSubmissionErrors = () => {
    if (!filesWithErrors.includes(state.selectedFile)) {
      setFilesWithErrors([...filesWithErrors, state.selectedFile]);
    }
  };

  const enableTab = (tab) => {
    setActiveTab(tab);

    document.querySelector(".tabs__view--active").classList.remove("tabs__view--active");
    document.querySelector(`[data-tab-name="${tab}"]`).classList.add("tabs__view--active");
  };

  const removeSelectedFile = (position, selectedFilePosition) => {
    var cloneAttachments = _.cloneDeep(state.attachments);
    var attachments = _.reject(cloneAttachments, ["position", position]);
    var attachment = _.find(attachments, (attached) => attached.position == selectedFilePosition);

    setState({
      selectedFile: selectedFilePosition,
      selectedMapping: selectMapping(fieldMappingGroups, attachment.data).mappings,
      attachments: attachments,
    });
  };

  const handleSelectedFile = (position) => {
    var clonedAttachments = _.cloneDeep(state.attachments);
    var attachment = _.find(clonedAttachments, (attached) => attached.position == position);
    var mappingObj = _.find(fieldMappingGroups, ["name", attachment.mappingName]);

    setState({ selectedFile: position, selectedMapping: mappingObj.mappings, attachments: [...state.attachments] });
  };

  const handleFile = (file, position) => {
    const reader = new FileReader();
    const rABS = !!reader.readAsBinaryString;

    reader.onload = (e) => {
      const bstr = e.target.result;
      const wb = XLSX.read(bstr, {
        type: rABS ? "binary" : "array",
      });

      const wsname = wb.SheetNames[0];
      const ws = wb.Sheets[wsname];

      const data = XLSX.utils.sheet_to_json(ws, {
        raw: false,
        blankrows: false,
        defval: "",
      });

      const selectedMapping = selectMapping(fieldMappingGroups, data);

      setState({
        selectedFile: position,
        selectedMapping: selectedMapping.mappings,
        attachments: [
          ...state.attachments,
          {
            position: position,
            mappingName: selectedMapping.name,
            data: data,
            processedData: formatData(selectedMapping.mappings, data, ndcList, salt, filesWithErrors, position),
          },
        ],
      });
    };

    if (rABS) {
      reader.readAsBinaryString(file);
    } else {
      reader.readAsArrayBuffer(file);
    }
  };

  // TODO: Do we need this?
  const sortRequiredShape = () => {
    var shape = JSON.parse(props.requiredShape);
    var obj = {};

    _.map(requiredColumns, (item) => {
      obj[item] = shape[item];
    });

    return obj;
  };

  const getSelectedFileData = (position) => {
    const attachment = _.find(state.attachments, (attachment) => attachment.position == position);

    return attachment.data;
  };

  const getSelectedFileDataColumnNames = () => {
    const data = getSelectedFileData(state.selectedFile);
    return _.keys(data[0]);
  };

  const dataView = (tab) => {
    return (
      <div key={tab} className="tabs__view tabs__view--active" data-tab-name={tab}>
        <DataOutput
          salt={salt}
          setSubmissionErrors={setSubmissionErrors}
          organizationID={props.organization}
          data={state.attachments.length ? getSelectedFileData(state.selectedFile) : []}
          selectedFile={state.selectedFile}
          attachments={state.attachments}
          mappings={fieldMappingGroups}
          ndcList={ndcList}
          columns={state.attachments.length && getSelectedFileDataColumnNames()}
          requiredShape={sortRequiredShape()}
          attachedCount={state.attachments.length}
          fieldMapping={state.selectedMapping}
          setSelectedMapping={manualSetSelectedMapping}
          displayTabBarTags={setTabBarTag}
        />
      </div>
    );
  };

  const renderFileNotice = () => {
    if (state.attachments.length == 0) {
      return (
        <div className="notify__banner" style={{ marginBottom: 20 }}>
          <div className="notify__banner__icon">
            <i className="solid solid-budicon-notification"> </i>
          </div>
          <div className="notify__banner__notice__title">
            <strong>Attach a file - </strong>
          </div>
          <div className="notify__banner__notice">
            In order to update or create column mappings you must attach a data file.
          </div>
        </div>
      );
    }
  };

  const mappingView = (tab) => {
    return (
      <div key={tab} className="tabs__view" data-tab-name={tab}>
        {renderFileNotice()}
        <FieldMappings
          organization={props.organization}
          createMapping={createFieldMapping}
          deleteAllFieldMappings={deleteAllFieldMappings}
          deleteFieldMapping={deleteFieldMapping}
          updateFieldMapping={updateFieldMapping}
          fields={state.data && _.keys(state.data[0])}
          requiredShape={sortRequiredShape()}
          mappings={fieldMappingGroups}
        />
      </div>
    );
  };

  const renderTabViews = () => {
    return tabs.map((tab) => {
      switch (tab) {
        case "Data":
          return dataView(tab);
        case "Column Mappings":
          return mappingView(tab);
      }
    });
  };

  const onDragEnd = (result) => {
    const { source, destination } = result;

    // dropped outside the list
    if (!destination) {
      return;
    }

    var dropZoneDetails = destination.droppableId.split("#");

    if (dropZoneDetails[0] === "existing") {
      updateFieldMapping(dropZoneDetails[2], dropZoneDetails[1], result.draggableId);
    }
  };

  const setTabBarTag = (display) => {
    setDisplayTabBarTags(display);
  };

  return (
    <div style={{ display: "flex" }}>
      <DragDropContext onDragEnd={onDragEnd}>
        <div className="content__container">
          <div className="page-details__container">
            <div className="page-details__container__info">
              <div className="page-details__title">Submit 340B Claims Data</div>
              <div className="breadcrumbs__container">
                <div className="breadcrumbs__crumb">{"Claims Data >"}</div>
                <div className="breadcrumbs__crumb breadcrumbs__crumb__active">New</div>
              </div>
              <div className="page-details__helpdesk-article__container">
                <a href="http://help.340besp.com/en/articles/8900671-how-to-submit-claims-to-340b-esp" target="_blank">
                  <div className="page-details__helpdesk-article">Learn How to Submit Claims</div>
                </a>
                <a href={props.ndcDownloadPath}>
                  <div className="page-details__helpdesk-article">Download NDC list</div>
                </a>
              </div>
            </div>
          </div>
          <Notice details={notice} />
          <TabBar
            tabs={tabs}
            activeTab={activeTab}
            setActiveTab={enableTab}
            displayTagsFor={"Column Mappings"}
            displayTabBarTags={displayTabBarTags}
          />
          {renderTabViews()}
        </div>
        <div className="draw__container">
          <DataInput
            organization={props.organization}
            ndcList={ndcList}
            reset={reset}
            submissionPath={props.submissionPath}
            fileHeaders={
              state.attachments.length && getSelectedFileData(state.selectedFile)[0]
                ? Object.keys(getSelectedFileData(state.selectedFile)[0])
                : null
            }
            fieldMappingGroups={fieldMappingGroups}
            requiredShape={props.requiredShape}
            attachments={state.attachments}
            filesWithErrors={filesWithErrors}
            removeSubmissionError={removeSubmissionError}
            removeSelectedFile={removeSelectedFile}
            handleFile={handleFile}
            handleSelectedFile={handleSelectedFile}
          />
        </div>
      </DragDropContext>
    </div>
  );
};

export default DataSubmission;
