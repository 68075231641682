import React, { useEffect } from "react";
import { createPortal } from "react-dom";
import { CloseRounded } from "@mui/icons-material";
import plantsIllustration from "../../assets/images/plants_illustration.png";

const AlertModal = ({
  isOpen,
  title,
  message,
  confirmText,
  handleConfirm,
  cancelText,
  handleCancel,
}) => {
  useEffect(() => {
    if (isOpen) document.body.classList.add("overflow--hidden");

    return () => document.body.classList.remove("overflow--hidden");
  }, [isOpen]);

  function renderActionBar() {
    if (!cancelText && !confirmText) return null;

    return (
      <div className="alert_modal__action_bar mt--24">
        {cancelText && (
          <button
            className="btn btn--lg flex align-center btn--white border"
            onClick={handleCancel}
          >
            {cancelText}
          </button>
        )}
        {confirmText && (
          <button className="btn btn--lg flex align-center" onClick={handleConfirm}>
            {confirmText}
          </button>
        )}
      </div>
    );
  }

  if (!isOpen) return null;

  return createPortal(
    <div className="alert_modal__background position--fixed" onClick={handleCancel}>
      <div
        className="alert_modal flex--col bg--white br--8 position--fixed overflow--hidden"
        onClick={(e) => e.stopPropagation()}
      >
        <div className="flex--col flex-grow">
          <div className="bg">
            <img
              className="position--rel mt--32"
              style={{ width: 145, left: "45%", userSelect: "none" }}
              src={plantsIllustration}
              alt="plants_illustration"
            />
          </div>
          <div className="pa--32">
            <div className="t--xl t--500 mb--16" style={{ letterSpacing: "normal" }}>
              {title}
            </div>
            <div className="t--md t--subtitle">{message}</div>
            {renderActionBar()}
          </div>
        </div>
        <CloseRounded className="alert_modal__close_icon position--abs" onClick={handleCancel} />
      </div>
    </div>,
    document.body
  );
};

export default AlertModal;
