import { useState } from "react";

const useConfirm = () => {
  const [displayConfirmModal, setDisplayConfirmModal] = useState(false);
  const [resolve, setResolve] = useState(null);

  function getConfirmation() {
    return new Promise((res) => {
      setDisplayConfirmModal(true);
      setResolve(() => res);
    });
  }

  function resolveConfirmation(res) {
    setDisplayConfirmModal(false);
    resolve(res);
  }

  return {
    displayConfirmModal,
    getConfirmation,
    handleConfirm: () => resolveConfirmation(true),
    handleCancel: () => resolveConfirmation(false),
  };
};

export default useConfirm;
