import React, { useState, useRef, useEffect } from "react";
import _ from "lodash";
import PharmacySelect from "./PharmacySelect";

const RetailDesignationsForm = (props) => {
  const [singlePharmacyDesignation, setSinglePharmacyDesignation] = useState(false);
  const [confirmedPolicy, setConfirmedPolicy] = useState(false);
  const [hasSelectedPharmacy, setHasSelectedPharmacy] = useState(false);
  const [selectedPharmacy, setSelectedPharmacy] = useState(false);

  useEffect(() => {
    var formDetails = _.cloneDeep(props.manufacturerFormDetails);

    // set form details for completed status - obj name as class
    formDetails[props.manufacturer].singlePharmacyDesignationForm = {
      open: singlePharmacyDesignation,
      completed: hasSelectedPharmacy,
    };
    props.setManufacturerFormDetails(formDetails);
  }, [singlePharmacyDesignation, hasSelectedPharmacy]);

  const policies = () => {
    return (
      <div
        className={
          confirmedPolicy
            ? "designation_form__section__group__step__toggle_container designation_form__section__group__step__toggle_container--closed"
            : "designation_form__section__group__step__toggle_container"
        }
      >
        <div className="stat-cards__confirmation stat-cards__confirmation--disabled">
          <span>
            Sanofi's 340B integrity initiative permits any 340B covered entity that does not have the ability to dispense 340B purchased drugs from an in-house pharmacy to its patients to designate a single contract pharmacy for this purpose. By designating a contract pharmacy in this web form, you are acknowledging that your 340B covered entity does not have an in-house pharmacy that is capable of dispensing 340B purchased medicines to eligible patients. For hospital covered entity types subject to Sanofi's 340B integrity initiative, you are also acknowledging that you must submit claims data through the 340B ESP™ platform to access 340B pricing at your one designated contract pharmacy. Covered entities within the Consolidated Health Center Program are not required to submit claims data to access 340B pricing at their one designated contract pharmacy.
            <br />
            <br />
            Please note that Sanofi considers all sites together as one covered entity for purposes of Sanofi's 340B Integrity Initiative, inclusive of the covered entity's Parent Site, Child Sites, and Associated Sites.
          </span>
          {renderConfirmation()}
        </div>
      </div>
    );
  };

  const renderChildSiteNotice = (entity) => {
    return (
      <div
        className="designation_form__section__group__step__toggle_container"
      >
        <div className="stat-cards__confirmation stat-cards__confirmation--disabled">
          <div className="stat-cards__form__fieldset__details__title">{entity.entity_name}</div>
          <div className="stat-cards__form__fieldset__details__subtitle">{entity.id_340b}</div>
          <span>The selected entity is registered with HRSA as a Child or Associated Site. Per Sanofi's policy, contract pharmacy designations are made for the parent entity.</span>
          {renderParent(entity)}
        </div>
      </div>
    );
  };

  const renderParent = (entity) => {
    return (
      <div className="stat-cards__confirmation__footer">
        <span>
          <div className="indicator indicator--red indicator--pulse" style={{ marginTop: 3 }}></div>
          Please make designation for parent entity: {entity.parent_id_340b}
        </span>
      </div>
    );
  };

  const renderDesignationQuestion = () => {
    return `Would you like to make a single contract pharmacy designation for ${props.manufacturerName}'s products?`;
  };

  const renderDesignationSubtitle = () => {
    return "Contract Pharmacy Designation";
  };

  const renderSinglePharmacyDesignationForm = () => {
    return (
      <div className="designation_form__section__group">
        <div className="designation_form__section__group__row">
          <div className="designation_form__section__group__question">
            <div className="designation_form__section__group__question__subtitle">{renderDesignationSubtitle()}</div>
            {renderDesignationQuestion()}
          </div>
          <div className="designation_form__section__group__value">
            <div className="btn__option__group">
              <div
                className={singlePharmacyDesignation ? "btn btn--small btn--selected" : "btn btn--small"}
                onClick={() => setSinglePharmacyDesignation(true)}
              >
                yes
              </div>
              <div
                className={singlePharmacyDesignation ? "btn btn--small" : "btn btn--small btn--selected"}
                onClick={() => setSinglePharmacyDesignation(false)}
              >
                no
              </div>
            </div>
          </div>
        </div>
        {renderPolicy()}
        {renderStep()}
      </div>
    );
  };

  const renderConfirmation = () => {
    return (
      <div className="stat-cards__confirmation__footer">
        <span>
          <div className="indicator indicator--red indicator--pulse" style={{ marginTop: 3 }}></div>
          Please confirm policy before assigning designation
        </span>
        <span>
          <div className="btn btn--ib btn--tiny" onClick={() => setConfirmedPolicy(true)}>
            Confirm
          </div>
        </span>
      </div>
    );
  };

  const renderStepIndicator = (step) => {
    if (step) {
      return (
        <div className="designation_form__section__group__step__indicator designation_form__section__group__step__indicator--complete">
          <i className="solid solid-budicon-check-ui" />
        </div>
      );
    } else {
      return <div className="designation_form__section__group__step__indicator"></div>;
    }
  };

  const renderPolicy = () => {
    if (singlePharmacyDesignation) {
      return (
        <div className="designation_form__section__group__step">
          {renderStepIndicator(confirmedPolicy)}
          <div className="designation_form__section__group__step__title">Policy Confirmation</div>
          <div className="designation_form__section__group__step__subtitle">
            Please confirm manufacturers policy to continue.
          </div>
          {policies()}
        </div>
      );
    }
  };

  const filterPharmacies = (entity) => {
    return entity.pharmacies.details;
  };

  const renderPharmacySelect = () => {
    return _.map(props.entities, (entity, index) => {
      if (entity.child_site) {
        return (
          <div key={index}>
            {renderChildSiteNotice(entity)}
          </div>
        )
      } else {
        return (<div key={index}>
          <PharmacySelect
            kind={"retail"}
            entity={entity}
            pharmacies={filterPharmacies(entity)}
            manufacturer={props.manufacturer}
            disableGranteeNotice={false}
            disableWhollyOwnedNotice={true}
            disableInHouseSpecialtyNotice={true}
            disableInHouseNotice={false}
            selectedPharmacy={selectedPharmacy}
            setSelectedPharmacy={setSelectedPharmacy}
            setHasSelectedPharmacy={setHasSelectedPharmacy}
            setRetailSelectedSpecialtyPharmacy={props.setRetailSelectedSpecialtyPharmacy}
            setRetailHasSelectedSpecialtyPharmacy={props.setRetailHasSelectedSpecialtyPharmacy}
          />
        </div>)
      }
  });
  };

  const renderStep = () => {
    if (singlePharmacyDesignation && confirmedPolicy) {
      return (
        <div className="designation_form__section__group__step">
          {renderStepIndicator(hasSelectedPharmacy)}
          <div className="designation_form__section__group__step__title">Select Contract Pharmacy</div>
          <div className="designation_form__section__group__step__subtitle">
            Please select a contract pharmacy to designate.
          </div>
          {renderPharmacySelect()}
        </div>
      );
    }
  };

  return renderSinglePharmacyDesignationForm();
};

export default RetailDesignationsForm;
