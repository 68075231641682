import React, { useState, useEffect } from "react";
import Switch from "@material-ui/core/Switch";
import { withStyles } from "@material-ui/core/styles";

const Toggle = withStyles((theme) => ({
  root: {
    width: 40,
    height: 20,
    padding: 0,
    marginRight: 10,
  },
  switchBase: {
    padding: 4,
    "&$checked": {
      transform: "translateX(20px)",
      color: theme.palette.common.white,
      "& + $track": {
        backgroundColor: "#3246D3",
        opacity: 1,
        border: "none",
      },
    },
    "&$focusVisible $thumb": {
      color: "#3246D3",
      border: "6px solid #fff",
    },
  },
  thumb: {
    width: 12,
    height: 12,
    boxShadow: "none",
  },
  track: {
    borderRadius: 20,
    backgroundColor: "#B7C0C8",
    opacity: 1,
    transition: theme.transitions.create(["background-color", "border"]),
  },
  checked: {},
  focusVisible: {},
}))(({ classes, ...props }) => {
  return (
    <Switch
      focusVisibleClassName={classes.focusVisible}
      disableRipple
      onChange={(e) => {
        e.target.checked ? props.handleSetValue(props.enabled_value) : props.handleSetValue(props.disabled_value);
      }}
      classes={{
        root: classes.root,
        switchBase: classes.switchBase,
        thumb: classes.thumb,
        track: classes.track,
        checked: classes.checked,
      }}
    />
  );
});

export default function CustomToggle(props) {
  const renderLabel = () => {
    if (props.label) {
      return <span className="t--sm t--subtitle">{props.label}</span>;
    }
  };

  return (
    <div>
      <Toggle
        handleSetValue={props.setValue}
        enabled_value={props.enabled_value}
        disabled_value={props.disabled_value}
      />
      {renderLabel()}
    </div>
  );
}
