import React, { useRef, useState, useEffect } from "react";
import _ from "lodash";

const DropdownSelectMapping = (props) => {
  const [displayDropdown, setDisplayDropdown] = useState(false);

  // should rename position everywhere to ID - ID alias for position
  const getAttachmentByID = (id) => {
    return _.find(props.attachments, (data) => data.position == id)
  }

  const getMappingDetails = (id) => {
    var attachment = getAttachmentByID(id)

    if (attachment) {
      return attachment.mappingName
    } else {
      return "No Mapping Selected"
    }
  }

  const renderDropDown = () => {
    if (displayDropdown) {
      return <div className="dropdown__container" style={{ top: 50 }}>
        <div className="dropdown__header">
          <div className="dropdown__header__title">Select Mappings</div>
          <div className="dropdown__header__close">
            <div className="solid solid-budicon-cross-ui" />
          </div>
        </div>
        {_.map(props.mappings, (mapping, index) => {
          return <div key={index} className="dropdown__item" onClick={() => props.setSelectedMapping(mapping)}>{mapping.name}</div>
        })}
      </div>
    }
  }

  return (
    <div className="btn btn--outline btn--align-end btn--white"
      style={{ marginLeft: 20, position: 'relative' }}
      onClick={() => {
        setDisplayDropdown(!displayDropdown);
      }}
    >
      {getMappingDetails(props.selectedFile)}
      <i className="solid solid-budicon-chevron-bottom" style={{ fontWeight: 800, marginLeft: 10, marginRight: 0 }} />
      {renderDropDown()}
    </div>
  )
};

export default DropdownSelectMapping;