import React from "react";
import { CloseRounded } from "@mui/icons-material";

const Header = ({ variant = "", id340B }) => {
  switch (variant) {
    case "No Additional Documentation Requests":
      return (
        <div className="t--title">
          <div className="t--700 mb--8">No Additional Documentation Requests</div>
          <div className="t--md">Sign and attest below to submit.</div>
        </div>
      );
    case "Designation Removal Warning":
      return (
        <div className="t--title">
          <div className="t--700 mb--8">Attention: Existing Designations May Be Removed</div>
          <div className="pharm_cert_modal__warning t--sm br--4 pa--12 gap--8">
            You have indicated that {id340B} has an in-house pharmacy. Existing designations may be removed depending on
            the criteria of any pharmaceutical manufacturer's policy.
          </div>
        </div>
      );
    default:
      return null;
  }
};

const SignAndAttest = ({ activeId340B, attestation, setAttestation, readOnly = false }) => {
  const conditionalDisabledClass = readOnly ? "pharm_cert_modal__signature--disabled" : "";

  function renderClearInputButton() {
    function handleClick() {
      if (readOnly || attestation.signed_by == "") return;

      setAttestation({ signed_by: "" });
    }

    return (
      <CloseRounded className={`pharm_cert_modal__signature__icon ${conditionalDisabledClass}`} onClick={handleClick} />
    );
  }

  return (
    <div>
      <div className="t--700 t--title mb--16">Sign and Attest</div>
      <div className={`pharm_cert_modal__signature  flex align-center bg br--8 gap--8`}>
        {renderClearInputButton()}
        <input
          type="text"
          className={`pharm_cert_modal__signature__input ${conditionalDisabledClass} t--sm t--title`}
          placeholder="Your name here"
          value={attestation.signed_by}
          onChange={(e) => setAttestation({ signed_by: e.target.value })}
          readOnly={readOnly}
        />
      </div>
    </div>
  );
};

export default SignAndAttest;
