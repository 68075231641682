import React from "react";
import classnames from "classnames";

const availableVariants = ["primary", "secondary", "tertiary", "link", "tab", "danger", "warning"];

const Button = ({ variant = "primary", className = "", isDisabled = false, onClick, children }) => {
  if (!availableVariants.includes(variant)) {
    throw new Error(
      `The <Button /> component must include one of the following variants 
      ${availableVariants.map((v) => " " + v)} or leave out for default variant`
    );
  }

  const buttonClasses = classnames({
    button: true,
    [`button--variant-${variant}`]: true,
    [`${className}`]: className,
  });

  return (
    <button onClick={onClick} className={buttonClasses} disabled={isDisabled}>
      {children}
    </button>
  );
};

export default Button;
