import React, { useState, useRef, useEffect } from "react";
import _ from "lodash";
import RetailDesignationForm from "./RetailDesignationsForm";
import DonDesignationForm from "./DonDesignationForm";
import VyndaDesignationForm from "./VyndaDesignationForm";

import Pfizer from "images/Pfizer.png";

const SanofiDesignations = (props) => {
  const manufacturer_name = "pfizer";
  const vanity_manufacturer_name = "Pfizer";
  const [retailHasSelectedSpecialtyPharmacy, setRetailHasSelectedSpecialtyPharmacy] = useState(false);
  const [retailSelectedSpecialtyPharmacy, setRetailSelectedSpecialtyPharmacy] = useState();
  const [manufacturerFormDetails, setManufacturerFormDetails] = useState({
    pfizer: {},
  });

  useEffect(() => {
    props.setCompletedDesignations(isComplete());
  }, [manufacturerFormDetails]);

  const isComplete = () => {
    var completed = true;

    var openForms = _.map(manufacturerFormDetails, (manufacturer) => {
      return _.filter(manufacturer, (form) => {
        return form.open;
      });
    });

    // remove empty arrays and flatten
    var filteredForms = openForms.filter((e) => e.length).flat();

    if (filteredForms.length) {
      completed = _.every(filteredForms, ["completed", true]);
    }

    return completed;
  };

  const renderRetail = () => {
    return (
      <RetailDesignationForm
        entities={props.entities}
        kind={"retail"}
        manufacturerFormDetails={manufacturerFormDetails}
        setManufacturerFormDetails={setManufacturerFormDetails}
        manufacturer={manufacturer_name}
        manufacturerName={vanity_manufacturer_name}
        setRetailSelectedSpecialtyPharmacy={setRetailSelectedSpecialtyPharmacy}
        setRetailHasSelectedSpecialtyPharmacy={setRetailHasSelectedSpecialtyPharmacy}
      />
    );
  };

  const renderDon = () => {
    return (
      <DonDesignationForm
        entities={props.entities}
        specialtyKeyName={"don"}
        manufacturerFormDetails={manufacturerFormDetails}
        setManufacturerFormDetails={setManufacturerFormDetails}
        manufacturer={manufacturer_name}
        manufacturerName={vanity_manufacturer_name}
        retailSelectedSpecialtyPharmacy={retailSelectedSpecialtyPharmacy}
        retailHasSelectedSpecialtyPharmacy={retailHasSelectedSpecialtyPharmacy}
        isBlocked={_.some(props.entities, (e) => e.blocked.includes("don"))}
      />
    );
  };

  const renderVynda = () => {
    return (
      <VyndaDesignationForm
        entities={props.entities}
        kind={"vynda"}
        manufacturerFormDetails={manufacturerFormDetails}
        setManufacturerFormDetails={setManufacturerFormDetails}
        manufacturer={manufacturer_name}
        manufacturerName={vanity_manufacturer_name}
        retailSelectedSpecialtyPharmacy={retailSelectedSpecialtyPharmacy}
        retailHasSelectedSpecialtyPharmacy={retailHasSelectedSpecialtyPharmacy}
        isBlocked={_.some(props.entities, (e) => e.blocked.includes("vynda"))}
      />
    );
  };

  return (
    <div>
      <div className="designation_form__section__header">
        <div className="designation_form__section__header__logo">
          <img src={Pfizer} />
        </div>
        <div className="designation_form__section__header__title">{vanity_manufacturer_name}</div>
      </div>
      {renderRetail()}
      {renderDon()}
      {renderVynda()}
    </div>
  );
};

export default SanofiDesignations;
