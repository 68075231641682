import React from "react";

const FilePresent = ({ size = "medium" }) => {
  const sizes = {
    large: { height: 24, width: 24 },
    medium: { height: 20, width: 20 },
  };

  return (
    <svg style={sizes[size]} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M5 18.333c-.458 0-.85-.163-1.176-.489a1.606 1.606 0 0 1-.49-1.178V3.333c0-.458.163-.85.49-1.177.326-.326.718-.49 1.176-.49h6.813a1.649 1.649 0 0 1 1.166.48l3.209 3.208a1.647 1.647 0 0 1 .48 1.167v10.145c-.001.459-.164.851-.49 1.178-.327.326-.72.49-1.178.49H5zm0-1.666h10v-10h-2.5a.806.806 0 0 1-.593-.24.807.807 0 0 1-.24-.594v-2.5H5v13.334zm5-.834c.93 0 1.72-.326 2.365-.979a3.228 3.228 0 0 0 .969-2.354V10a.808.808 0 0 0-.24-.594.808.808 0 0 0-.594-.24.806.806 0 0 0-.593.24.807.807 0 0 0-.24.594v2.5c0 .458-.16.85-.48 1.177-.319.326-.715.49-1.187.49-.458 0-.85-.164-1.177-.49a1.605 1.605 0 0 1-.49-1.177V7.917c0-.125.042-.226.126-.303A.419.419 0 0 1 8.75 7.5a.41.41 0 0 1 .303.114.41.41 0 0 1 .114.303v3.75c0 .236.08.433.24.593.16.16.357.24.593.24s.434-.08.594-.24c.16-.16.24-.357.24-.594v-3.75c0-.583-.202-1.076-.605-1.479a2.012 2.012 0 0 0-1.479-.604c-.583 0-1.076.202-1.479.604a2.012 2.012 0 0 0-.604 1.48V12.5c0 .916.326 1.701.979 2.354a3.21 3.21 0 0 0 2.354.98z"
        fill="currentColor"
      />
    </svg>
  );
};

export default FilePresent;
