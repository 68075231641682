import React, { useRef } from "react";
import FilePresent from "../../Icons/FilePresent";

const FileRow = ({ id, fileName, type, handleFileUpload, handleFileDelete, hasFileAttached, index }) => {
  const inputRef = useRef(null);

  const DEFAULT_TEXT_BY_TYPE = {
    required: "Pharmacy License (Required)",
    additional: "Additional Documents (Optional)",
  };

  const conditionalColorClass = fileName ? "color--blue" : "";

  function handleDelete(e) {
    if (!hasFileAttached) return;

    e.preventDefault();

    handleFileDelete(type, index);
    inputRef.current.value = "";
  }

  function handleChange(e) {
    if (!e.target.files.length) return;

    handleFileUpload(type, e.target.files[0]);

    if (type == "additional") {
      inputRef.current.value = "";
    }
  }

  function renderButton() {
    if (hasFileAttached) {
      return (
        <button className="btn btn--lg flex align-center btn--white border" onClick={(e) => handleDelete(e)}>
          Delete
        </button>
      );
    }
    return (
      <button
        className="btn btn--lg flex align-center btn--white border"
        onClick={(e) => {
          e.preventDefault();
          inputRef.current.click();
        }}
      >
        Attach
      </button>
    );
  }

  return (
    <div className="pharm_cert_modal__upload__file_row flex align-center justify-space-between pt--12 pb--12 gap--8">
      <div className=" flex align-center gap--8">
        <FilePresent />
        <span
          className={`pharm_cert_modal__upload__file_text t--md t--title ${conditionalColorClass} overflow--x-hidden`}
          title={fileName || null}
        >
          {fileName || DEFAULT_TEXT_BY_TYPE[type]}
        </span>
      </div>

      {renderButton()}
      <input
        id={id}
        ref={inputRef}
        type="file"
        accept="image/*,.pdf"
        style={{ display: "none" }}
        onChange={handleChange}
      />
    </div>
  );
};

export default FileRow;
