import { Subject } from "rxjs";

const subject = new Subject();
const validationSubject = new Subject();
const fileSubject = new Subject();


const submissionStore = {
  subscribe: (setState) => {
    subject.subscribe(setState);
  },
  attachedFiles: (files) => {
    subject.next(files);
  },
  subscribeValidations: (setState) => {
    validationSubject.subscribe(setState);
  },
  validationErrors: (isValid) => {
    validationSubject.next(isValid);
  },
  subscribeSelectedFile: (file) => {
    fileSubject.subscribe(file)
  },
  selectedFile: (file) => {
    fileSubject.next(file)
  }
};

export default submissionStore;
