import React from "react";

export default class AuthenticateForm extends React.Component {
  constructor() {
    super();

    this.state = {
      email: "",
      password: "",
    };
  }

  componentWillUnmount() {
    this.props.onUnload();
  }

  handleEmailChange = (value) => {
    this.setState({
      email: value,
    });
  };

  handlePasswordChange = (value) => {
    this.setState({
      email: value,
    });
  };

  render() {
    return (
      <div className="auth-page">
        <div className="container page">
          <div className="row">
            <div className="col-md-6 offset-md-3 col-xs-12">
              <h1 className="text-xs-center">Two Factor Authentication</h1>
              <p className="text-xs-center"></p>

              <form method="POST" action="/login">
                <fieldset>
                  <fieldset className="form-group">
                    <input
                      className="form-control form-control-lg"
                      type="email"
                      name="session[email]"
                      placeholder="Email"
                      value={this.props.email}
                      onChange={(event) =>
                        this.handleEmailChange(event.target.value)
                      }
                    />
                  </fieldset>

                  <fieldset className="form-group">
                    <input
                      className="form-control form-control-lg"
                      type="password"
                      name="session[password]"
                      placeholder="Password"
                      value={this.props.password}
                      onChange={(event) =>
                        this.handleEmailChange(event.target.value)
                      }
                    />
                  </fieldset>

                  <input type="submit" value="Login" />
                </fieldset>
              </form>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
