import React, { Component } from "react";
import DayJS from "react-dayjs";
import _ from "lodash";
import moment from "moment";

const SubmissionDetailsCard = (props) => {
  const renderSubmissionNoticeCard = () => {
    return (
      <div className="draw__submission_card draw__submission_card--notice-orange draw__submission_card--fluid">
        <div className="draw__submission_card__content_container draw__submission_card__content_container--sm-pb">
          <div
            className="indicator indicator--lg indicator--white indicator--pulse--white"
            style={{ marginBottom: 8 }}
          ></div>
          <div>
            <div className="draw__submission_card__title">Incomplete Submissions</div>
            <div className="draw__submission_card__subtitle draw__submission_card__subtitle--lg">
              Based on a comparison of your 340B purchase volume and 340B claims submissions, your entity has been
              identified as having incomplete 340B claims submissions. To ensure your continued participation with
              pharmaceutical manufacturers' data integrity initiatives, please review each Entity Profile module.
              <br />
              <br />
              Please note that you may need to submit additional 340B claims data within the next two submission cycles.
            </div>
          </div>
        </div>
        <div className="draw__submission_card__footer">
          <a href={`/organization/${props.organization}/entities/${props.entity.id}`}>
            <div
              className="btn btn--ib btn--small btn--white btn--b-dark-orange btn--default-align"
              style={{ marginTop: 20 }}
            >
              View Details
            </div>
          </a>
          <div className="draw__submission_card__shape__container draw__submission_card__shape__container--small">
            <div className="draw__submission_card--half-cir-shape-2 draw__submission_card--half-cir-shape-2--orange draw__submission_card--half-cir-shape-2--move-up" />
            <div className="draw__submission_card--half-cir-shape-3 draw__submission_card--half-cir-shape-3--light-orange draw__submission_card--half-cir-shape-3--move-up" />
            <div className="draw__submission_card--half-cir-shape-4 draw__submission_card--half-cir-shape-4--orange draw__submission_card--half-cir-shape-4--move-up" />
            <div className="draw__submission_card--half-cir-shape-5 draw__submission_card--half-cir-shape-5--orange draw__submission_card--half-cir-shape-5--move-up" />
          </div>
        </div>
      </div>
    );
  };

  const renderEligibilityDownload = () => {
    return (
      <a href={`/organization/${props.organization}/eligibility.xlsx`}>
        <div className="draw__tile" style={{ marginTop: 20 }}>
          <div className="draw__tile__icon">
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="31" viewBox="0 0 24 31">
              <g transform="translate(-0.223)">
                <path
                  d="M1.068,0H16.646l7.375,7.481V32.562a1.068,1.068,0,0,1-1.068,1.068H1.068A1.068,1.068,0,0,1,0,32.562V1.068A1.068,1.068,0,0,1,1.068,0Z"
                  transform="translate(2.541)"
                  fill="#E2EAFF"
                />
                <path d="M0,0,7.473,7.473h-5.9A1.573,1.573,0,0,1,0,5.9Z" transform="translate(19.089)" fill="#9DB7FF" />
                <g transform="translate(0 14.401)">
                  <rect width="21" height="10" rx="2" transform="translate(0.223 0.344)" fill="#3360D9" />
                  <text
                    transform="translate(5 7)"
                    fill="#ffffff"
                    fontSize="6"
                    fontFamily="Roboto-Bold, Roboto"
                    fontWeight="700"
                    letterSpacing="0.007em"
                  >
                    <tspan x="0" y="0">
                      FILE
                    </tspan>
                  </text>
                </g>
              </g>
            </svg>
          </div>
          <div className="draw__tile__details">
            <div className="draw__tile__details__title">Download Eligibility File</div>
            <div className="draw__tile__details__subtitle">Download latest</div>
          </div>
        </div>
      </a>
    );
  };

  const renderSubmissionCard = () => {
    return (
      <div className="draw__submission_card">
        <div className="draw__submission_card--half-cir-shape-1" />
        <div className="draw__submission_card--half-cir-shape-2" />
        <div className="draw__submission_card--half-cir-shape-3" />
        <div className="draw__submission_card--half-cir-shape-4" />
        <div className="draw__submission_card--half-cir-shape-5" />
        <div className="draw__submission_card__content_container">
          <div>
            <div className="draw__submission_card__title">Claims Submission</div>
            <div className="draw__submission_card__subtitle">Date</div>
          </div>
          <div className="draw__submission_card__date_icon">
            <div className="draw__submission_card__date_icon__date">{moment().format("DD")}</div>
            <div className="draw__submission_card__date_icon__month">{moment().format("MMM")}</div>
          </div>
          <a href={`/organization/${props.organization}/submissions/new`}>
            <div className="btn btn--ib btn--small btn--white btn--default-align">Start Submission</div>
          </a>
        </div>
      </div>
    );
  };

  const renderCard = () => {
    if (props.atRisk) {
      return renderSubmissionNoticeCard();
    } else {
      return renderSubmissionCard();
    }
  };

  const render = () => {
    return (
      <div>
        {renderCard()}
        {renderEligibilityDownload()}
      </div>
    );
  };

  return render();
};

export default SubmissionDetailsCard;
