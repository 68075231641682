import React, { useState, useRef } from "react";
import useOutsideClick from "../../Hooks/useOutsideClick";

import OptionsRadio from "./Slides/OptionsRadio";
import AttestationList from "./Slides/AttestationList";
import SignAndAttest from "./Slides/SignAndAttest";
import UploadList from "./Slides/UploadList";
import Http from "./http";
import AttestationsSummary from "./AttestationsSummary";
import Confirmation from "./Slides/Confirmation";

const options = [
  {
    label: "Provide only an attestation of legal title for your contract pharmacy arrangement(s)",
    value: "attest",
  },
  {
    label: "Provide only your contract pharmacy agreement(s) for your contract pharmacy arrangement(s)",
    value: "upload",
  },
  {
    label:
      "Provide a combination of your contract pharmacy agreement(s) and an attestation of legal title for your contract pharmacy arrangement(s)",
    value: "attest_and_upload",
  },
];

const Modal = ({
  statsComplete,
  setDisplay,
  entity,
  id_340b,
  attestations,
  setAttestations,
  setNotice,
  page,
  userEmail,
  firstName,
  lastName
}) => {
  const [currentSlide, setCurrentSlide] = useState("initial");
  const [attestingPharmacies, setAttestingPharmacies] = useState({});
  const [pharmacyAgreements, setPharmacyAgreements] = useState({});
  const [title, setTitle] = useState("");
  const [entityName, setEntityName] = useState("");
  const [signature, setSignature] = useState("");
  const [signaturePNG, setSignaturePNG] = useState("");
  const [acceptedTerms, setAcceptedTerms] = useState(false);
  const [selectedOption, setSelectedOption] = useState();
  const modalRef = useRef();
  const [attestationSummaryUrl, setAttestationSummaryUrl] = useState("");
  const [pharmacyUploads, setPharmacyUploads] = useState({});

  function closeModal() {
    setDisplay(false);
  }

  useOutsideClick(modalRef, closeModal);
  if (statsComplete && currentSlide == "initial" && page == "entities") {
    return <AttestationsSummary attestations={attestations} setDisplay={setDisplay} id_340b={id_340b} />;
  }

  const slides = {
    initial: <OptionsRadio options={options} selectedOption={selectedOption} setSelectedOption={setSelectedOption} />,
    attest: (
      <AttestationList
        attestations={attestations}
        attestingPharmacies={attestingPharmacies}
        setAttestingPharmacies={setAttestingPharmacies}
      />
    ),
    upload: (
      <UploadList
        pharmacyUploads={pharmacyUploads}
        setPharmacyUploads={setPharmacyUploads}
        attestations={attestations}
        setAttestations={setAttestations}
        pharmacyAgreements={pharmacyAgreements}
        setPharmacyAgreements={setPharmacyAgreements}
      />
    ),
    sign_for_attest: (() => {
      const attestingPharmaciesArr = attestations.filter((a) => attestingPharmacies[a.pharmacy_id]);

      return (
        <SignAndAttest
          signature={signature}
          setSignature={setSignature}
          acceptedTerms={acceptedTerms}
          setAcceptedTerms={setAcceptedTerms}
          attestingPharmacies={attestingPharmaciesArr}
          entityName={entityName}
          setEntityName={setEntityName}
          signaturePNG={signaturePNG}
          setSignaturePNG={setSignaturePNG}
          title={title}
          setTitle={setTitle}
        />
      );
    })(),
    confirmation: <Confirmation attestationSummaryUrl={attestationSummaryUrl} />,
  };

  const renderCurrentSlide = () => {
    return slides[currentSlide];
  };

  function handleLastSlide() {
    switch (currentSlide) {
      case "attest":
        // clearState();
        setCurrentSlide("initial");
        return;

      case "sign_for_attest":
        setCurrentSlide("attest");
        return;
      case "upload":
        if (selectedOption === "attest_and_upload") {
          setCurrentSlide("attest");
          return;
        }

        setCurrentSlide("initial");
        return;
    }
  }

  const handleNextSlide = () => {
    if (currentSlide === "initial" && selectedOption === "attest_and_upload") {
      setCurrentSlide("attest");
      return;
    }

    if (currentSlide == "confirmation" && selectedOption == "attest_and_upload") {
      setCurrentSlide("upload");
      return;
    }

    if (currentSlide === "sign_for_attest" && selectedOption === "attest_and_upload") {
      Http.submitStateAttestations(
        attestations,
        setAttestations,
        signature,
        title,
        entityName,
        signaturePNG,
        attestingPharmacies,
        setAttestingPharmacies,
        setNotice,
        setAttestationSummaryUrl,
        userEmail,
        firstName,
        lastName
      );
      setSignature("");
      setAcceptedTerms(false);
      setCurrentSlide("confirmation");
      return;
    }

    if (currentSlide === "initial" && selectedOption === "attest") {
      setCurrentSlide("attest");
      return;
    }

    if (currentSlide === "initial" && selectedOption === "upload") {
      setCurrentSlide("upload");
      return;
    }

    if (currentSlide === "attest") {
      setCurrentSlide("sign_for_attest");
      return;
    }

    if (currentSlide === "sign_for_attest") {
      Http.submitStateAttestations(
        attestations,
        setAttestations,
        signature,
        title,
        entityName,
        signaturePNG,
        attestingPharmacies,
        setAttestingPharmacies,
        setNotice,
        setAttestationSummaryUrl,
        userEmail,
        firstName,
        lastName
      );
      setCurrentSlide("confirmation");
      return;
    }

    if (currentSlide === "upload") {
      Http.submitPharmacyAgreements(
        pharmacyUploads,
        setPharmacyUploads,
        attestations,
        setAttestations,
        setNotice,
        userEmail,
        firstName,
        lastName
      );
      closeModal();
      return;
    }
  };

  // TODO: create separate router function
  const nextSlideDisabled = () => {
    if (currentSlide === "initial" && !!selectedOption) {
      return false;
    }

    if (currentSlide == "confirmation" && selectedOption == "attest_and_upload") {
      return false;
    }

    if (currentSlide === "attest" && Object.keys(attestingPharmacies).length > 0) {
      return false;
    }

    // TODO: clean up this conditional logic
    if (
      currentSlide.startsWith("sign") &&
      acceptedTerms &&
      signature.length > 0 &&
      title.length > 0 &&
      entityName.length > 0 &&
      signaturePNG !== ""
    ) {
      return false;
    }

    if (currentSlide === "upload" && Object.keys(pharmacyUploads).length > 0) {
      return false;
    }

    return true;
  };

  const renderButtonContents = () => {
    if (currentSlide.includes("sign") || currentSlide == "upload") {
      return "Submit";
    }

    return "Continue";
  };

  const renderEntityTag = () => {
    return <div>{id_340b}</div>;
  };

  function renderHeader() {
    switch (currentSlide) {
      case "initial":
        return (
          <div className="attestation_modal__header">
            {renderEntityTag()}
            <div className="attestation_modal__header__title">Sanofi Contract Pharmacy Anti-Diversion Policy</div>
            <div className="attestation_modal__header__subtitle">
              Please provide your contract pharmacy agreement(s) evidencing that you retain legal title to Sanofi
              340B-priced drugs at your Arkansas-based contract pharmacies or, alternatively, an attestation that you
              retain legal title to Sanofi 340B-priced drugs at your Arkansas-based contract pharmacies. Proof of legal
              title is required for each Arkansas-based contract pharmacy at which you request access to 340B pricing
              from Sanofi. Please refer to Sanofi’s Policy Document at{" "}
              <a className="t--anchor t--blue" href="/resources" target="_blank">
                340Besp.com/resources
              </a>{" "}
              for additional information.
            </div>
          </div>
        );
      case "attest":
        if (selectedOption == "attest_and_upload") {
          return (
            <div className="attestation_modal__header">
              {renderEntityTag()}
              <div className="attestation_modal__header__title">Contract Pharmacy Anti-Diversion Attestation</div>
              <div className="attestation_modal__header__subtitle">
                You have opted to attest for certain contract pharmacy arrangements and to submit your contract pharmacy
                agreement(s) for other contract pharmacy arrangements as proof that you retain legal title to Sanofi
                340B-priced drugs at your Arkansas-based contract pharmacies. Please follow the instructions below to
                complete the attestation process first.
              </div>
            </div>
          );
        }

        return (
          <div className="attestation_modal__header">
            {renderEntityTag()}
            <div className="attestation_modal__header__title">Contract Pharmacy Anti-Diversion Attestation</div>
            <div className="attestation_modal__header__subtitle">
              You have opted to attest that you retain title to Sanofi 340B priced drugs at your Arkansas-based contract
              pharmacies. Please follow the instructions below to complete this process.
            </div>
          </div>
        );
      case "upload":
        if (selectedOption === "attest_and_upload") {
          return (
            <div className="attestation_modal__header">
              {renderEntityTag()}
              <div className="attestation_modal__header__title">
                Contract Pharmacy Anti-Diversion Documentation Submission
              </div>
              <div className="attestation_modal__header__subtitle">
                You have opted to attest for certain contract pharmacy arrangements and to submit your contract pharmacy
                agreement(s) for other contract pharmacy arrangements as proof that you retain legal title to Sanofi
                340B-priced drugs at Arkansas-based contract pharmacies. Please follow the instructions below to
                complete the contract pharmacy agreement submission process.
              </div>
            </div>
          );
        }

        return (
          <div className="attestation_modal__header">
            {renderEntityTag()}
            <div className="attestation_modal__header__title">
              Contract Pharmacy Anti-Diversion Documentation Submission
            </div>
            <div className="attestation_modal__header__subtitle">
              You have opted to submit your contract pharmacy agreement(s) as proof that you retain legal title to
              Sanofi 340B-priced drugs at your Arkansas-based contract pharmacies. Please follow the instructions below
              to complete this process.
            </div>
          </div>
        );
      case "sign_for_attest":
        return (
          <div className="attestation_modal__header">
            {renderEntityTag()}
            <div className="attestation_modal__header__title">Contract Pharmacy Anti-Diversion Attestation</div>
            <div className="attestation_modal__header__subtitle">
              You have opted to attest that you retain title to Sanofi 340B priced drugs at your Arkansas-based contract
              pharmacies. Please follow the instructions below to complete this process.
            </div>
          </div>
        );

      case "confirmation":
        return (
          <div className="attestation_modal__header">
            {renderEntityTag()}
            <div className="attestation_modal__header__title">Contract Pharmacy Anti-Diversion Attestation</div>
            {/* <div className="attestation_modal__header__subtitle">Attestations Submitted</div> */}
          </div>
        );
    }
  }

  const renderFooter = () => {
    if (currentSlide == "confirmation" && selectedOption == "attest_and_upload") {
      return (
        <div className="attestation_modal__footer">
          <div>
            <button className="button button--lg button--white t--500" onClick={closeModal}>
              Cancel
            </button>
          </div>
          <div className="flex gap--16">
            <button
              className="button button--lg button--white button--inline-flex t--500"
              onClick={handleLastSlide}
              disabled={currentSlide == "initial"}
            >
              Back
            </button>
            <button
              className="button button--lg button--blue button--inline-flex t--500"
              onClick={handleNextSlide}
              disabled={nextSlideDisabled()}
            >
              {renderButtonContents()}
            </button>
          </div>
        </div>
      );
    }
    if (currentSlide == "confirmation") {
      return (
        <div className="attestation_modal__footer" style={{ justifyContent: "flex-end" }}>
          <button className="button button--lg button--white t--500" onClick={closeModal}>
            Close
          </button>
        </div>
      );
    }
    return (
      <div className="attestation_modal__footer">
        <div>
          <button className="button button--lg button--white t--500" onClick={closeModal}>
            Cancel
          </button>
        </div>
        <div className="flex gap--16">
          <button
            className="button button--lg button--white button--inline-flex t--500"
            onClick={handleLastSlide}
            disabled={currentSlide == "initial"}
          >
            Back
          </button>
          <button
            className="button button--lg button--blue button--inline-flex t--500"
            onClick={handleNextSlide}
            disabled={nextSlideDisabled()}
          >
            {renderButtonContents()}
          </button>
        </div>
      </div>
    );
  };

  return (
    <div className="attestation_modal__background">
      <div className="attestation_modal__container" ref={modalRef}>
        {renderHeader()}

        <div className="attestation_modal__body">{renderCurrentSlide()}</div>

        {renderFooter()}
      </div>
    </div>
  );
};

export default Modal;
