import React, { useRef, useEffect } from "react";
import ProgressBar from "../components/ProgressBar";
import CheckCircle from "../../Icons/CheckCircle";
import { numberToPercent } from "../../utils/NumberFormatter";

const CoveredEntitiesList = ({
  attestations,
  activeId340B,
  setActiveId340B,
  showFirstPage,
  getConfirmationIfNeeded,
}) => {
  const selectedRowRef = useRef();

  useEffect(() => {
    if (selectedRowRef.current) {
      selectedRowRef.current.scrollIntoView({
        behavior: "smooth",
        block: "nearest",
      });
    }
  });

  function getAttestationProgressText(datum) {
    switch (datum.status) {
      case "complete":
      case "in_review":
        return { status: "Certification Submitted", progress: null, progressPercentage: "100%" };
      case "in_progress":
        if (!datum?.documents_required || datum.in_house) {
          return { status: "In Progress", progress: "Not Submitted", progressPercentage: "0%" };
        }

        const numOfAllPharmacies = datum?.in_house_certifications?.length;
        const numOfCompleted = datum?.in_house_certifications?.filter((p) => p.has_license_attachment).length;

        return {
          status: `${numOfCompleted}/${numOfAllPharmacies} Pharmacies`,
          progress: numOfCompleted === numOfAllPharmacies ? "Ready to Submit" : "Not Submitted",
          progressPercentage: numberToPercent(numOfCompleted / numOfAllPharmacies),
        };
      case "not_started":
      default:
        return { status: "Begin Certification", progress: "Not Started", progressPercentage: "0%" };
    }
  }

  function handleRowClick(id340B) {
    if (activeId340B === id340B) return;

    getConfirmationIfNeeded(() => {
      setActiveId340B(id340B);
      showFirstPage();
    });
  }

  function renderEntityRow(entityData) {
    if (!entityData) return;

    const isActiveCe = entityData.id_340b === activeId340B;
    const conditionalActiveClass = isActiveCe ? "pharm_cert_modal__main__ce_list__item--active bg--white" : "";
    const isProgressComplete = entityData.status === "in_review" || entityData.status === "complete";
    const progressBarColor = isProgressComplete ? "green" : "";
    const { status, progress, progressPercentage } = getAttestationProgressText(entityData) || {};

    function getRef() {
      if (isActiveCe) return selectedRowRef;

      return null;
    }

    return (
      <div
        key={entityData.id_340b}
        className={`pharm_cert_modal__main__ce_list__item flex justify-space-between align-center pa--16 position--rel ${conditionalActiveClass}`}
        onClick={() => handleRowClick(entityData.id_340b)}
        ref={getRef()}
      >
        <div>
          <div className="t--700 t--title mb--4" style={{ fontSize: 18 }}>
            {entityData.id_340b}
          </div>
          <div className="t--md t--subtitle">{entityData.entity_name}</div>
        </div>
        <div className="pharm_cert_modal__main__ce_list__progress_box flex--col justify-center t--sm border br--4 gap--4">
          <div className="t--700 t--title">{status}</div>
          {progress && <div className="t--subtitle">{progress}</div>}
          <div className="flex align-center gap--4">
            {isProgressComplete && (
              <div className="pharm_cert_modal__main__ce_list__progress_box__check_circle">
                <CheckCircle size="small" />
              </div>
            )}
            <ProgressBar percent={progressPercentage} color={progressBarColor} />
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="pharm_cert_modal__main__ce_list bg">
      {attestations?.map((entityData) => renderEntityRow(entityData))}
    </div>
  );
};

export default CoveredEntitiesList;
