import React, { useEffect, useState } from "react";
import _ from "lodash";

import Http from "../PharmacyCertification/http";
import ActionRequired from "../PharmacyCertification/ActionRequired";
import AlertModal from "../AlertModal";
import Modal from "./Modal";

const PharmCertIndex = (props) => {
  const [attestations, setAttestations] = useState([]);
  const [stats, setStats] = useState({});
  const [displayModal, setDisplayModal] = useState(false);
  const [displaySuccessModal, setDisplaySuccessModal] = useState(false);

  function showSuccessModal() {
    setDisplayModal(false);
    setDisplaySuccessModal(true);
  }

  const hasCompletedAllAttestations = stats.entities_remaining === 0;

  useEffect(() => {
    Http.getData(props.organizationId, setAttestations, setStats, props.setNotice);
  }, []);

  const renderPharmCertSuccessMessage = () => {
    return (
      <>
        <div className="mb--16">
          <b>Pharmacy Certification Submitted</b>
        </div>
        <div className="mb--16">
          Thank you for completing the Pharmacy Certification. Please allow time for 340B ESP to review the provided documentation.
        </div>
        <div>
          Please reach out to <span className="t--blue"><a href="mailto:support@340besp.com">support@340besp.com</a></span> if you have any questions.
        </div>
      </>
    );
  };

  const renderActionRequired = () => {
    if (_.isEmpty(stats)) {
      return null;
    }

    return (
      <div className="draw__item__container">
        <ActionRequired
          organization={props.organizationId}
          entities={props.entities}
          setDisplayModal={setDisplayModal}
          stats={stats}
          setStats={setStats}
          ceData={attestations}
          setCeData={setAttestations}
          hasCompletedAllAttestations={hasCompletedAllAttestations}
          setDisplaySuccessModal={setDisplaySuccessModal}
          setNotice={props.setNotice}
        />
      </div>
    );
  };

  const renderModal = () => {
    if (!displayModal) {
      return null;
    }

    return (
      <Modal
        handleCloseModal={() => setDisplayModal(false)}
        showSuccessModal={showSuccessModal}
        organizationId={props.organizationId}
        entities={props.entities}
        stats={stats}
        setStats={setStats}
        attestations={attestations}
        setAttestations={setAttestations}
        hasCompletedAllAttestations={hasCompletedAllAttestations}
        setNotice={props.setNotice}
      />
    );
  };

  return (
    <>
      {renderActionRequired()}

      {renderModal()}

      <AlertModal
        isOpen={displaySuccessModal}
        title="Success!"
        message={renderPharmCertSuccessMessage()}
        handleCancel={() => setDisplaySuccessModal(false)}
      />
    </>
  );
};

export default PharmCertIndex;
