import React from "react";

const ArrowLeft = ({ size = "medium" }) => {
  const sizes = {
    large: { height: 24, width: 24 },
    medium: { height: 20, width: 20 },
  };

  return (
    <svg style={sizes[size]} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="m8.25 12.9 2 2a.875.875 0 0 1 .275.637.894.894 0 0 1-.263.638.915.915 0 0 1-.65.275.875.875 0 0 1-.637-.275l-3.55-3.55a.837.837 0 0 1-.275-.62c0-.237.092-.447.275-.63l3.55-3.55a.875.875 0 0 1 .638-.275c.242 0 .458.091.65.275a.894.894 0 0 1 .262.637.875.875 0 0 1-.275.638l-2 2H18.3c.255 0 .469.085.641.257.173.171.26.384.26.638a.88.88 0 0 1-.26.642.866.866 0 0 1-.642.263H8.25z"
        fill="currentColor"
      />
    </svg>
  );
};

export default ArrowLeft;
