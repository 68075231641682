import React, { useState, useRef, useEffect } from "react";
import _ from "lodash";
import Biogen from "images/Biogen.png";

const AgreementBiogen = (props) => {
  const renderAgreement = () => {
    if (props.selectedManufacturer == "biogen") {
      return (
        <div>
          <div className="logo__container">
            <div className="logo">
              <img src={Biogen} />
            </div>
            <div className="logo__info">
              <div className="logo__info__title">Biogen</div>
              <div className="logo__info__subtitle">Wholly Owned Contract Pharmacy Agreement</div>
            </div>
          </div>
          <p>
            For orders placed on or after February 1, 2023, wholesalers, on behalf of Biogen, will distribute AVONEX®
            and PLEGRIDY® purchased at the 340B price by hospital covered entities only to 340B covered entities
            registered on the 340B OPAIS database as a parent or child, as explained further below. Subject to the
            exceptions described below, pharmacies registered within the 340B OPAIS database as a contract pharmacy will
            no longer be eligible for 'Bill-To / Ship-To' orders. Any hospital covered entity that does not have an
            in-house pharmacy capable of dispensing drugs purchased at the 340B price to its patients may designate a
            single contract pharmacy location by visiting https://www.340besp.com/designations.
          </p>
          <p>
            Contract pharmacies that are wholly owned by a 340B hospital covered entity or have common ownership with a
            340B-eligible health system, will remain eligible to receive 'Bill-To / Ship-To' orders of drugs purchased
            at the 340B price. These pharmacies must be registered within the 340B OPAIS database as a contract pharmacy
            of the 340B covered entity. If a hospital covered entity is granted an exemption for its wholly or commonly
            owned contract pharmacy(ies), it may not also designate an independent contract pharmacy.
          </p>
          <p>
            Covered entities must complete the Wholly Owned Contract Pharmacy Exemption form on the 340B ESP website to
            apply for this exemption. Proof of ownership documents are required to submit your request. Examples of
            proof of ownership documents may include:
          </p>
          <ul>
            <li>Certificate of liability insurance</li>
            <li>State business licenses</li>
          </ul>
          <p>
            Wholly Owned Contract Pharmacy Exemption requests will be processed within five (5) business days. Please
            note that failure to populate all required fields may delay the processing of Wholly Owned Contract Pharmacy
            Exemption requests. Biogen may require covered entities to resubmit proof of ownership documents to validate
            the continued eligibility of existing exemptions after 12 months from initial approval of the Exemption.
          </p>
          <p>
            By signing this form, the authorized representative of the covered entity certifies that the covered entity
            and contract pharmacy(ies) included in this Exemption request: (i) have the same corporate ownership, OR
            (ii) that the covered entity wholly owns the identified contract pharmacy(ies); AND (iii) the covered entity
            qualifies for the exemption as described in this form. If there are any changes to the ownership structure
            of any of the contract pharmacies identified in this form, the covered entity agrees to notify Biogen at
            support@340besp.com within five (5) business days of such change of ownership. Failure to notify Biogen of
            such change may result in a loss of contract pharmacy exemption.
          </p>
          <div className="register__divider" />
        </div>
      );
    } else {
      return null;
    }
  };

  return renderAgreement();
};

export default AgreementBiogen;
