import React, { Component } from "react";
import verifyImage from "images/data_illustration.png";
import DayJS from "react-dayjs";
import _ from "lodash";

const EntityDetails = (props) => {
  const renderPendingRegistrations = () => {
    return (
      <div className="draw__entity-card draw__entity-card__pending-transfer">
        <div className="draw__entity-card__title draw__entity-card__title__lg">
          Pending Registrations!
        </div>
        <div className="draw__entity-card__note">
          You have
          <strong
            style={{
              fontSize: 13,
              textDecoration: "underline",
              padding: "0px 3px",
            }}
          >
            {props.pendingRegistrationIDs.length}
          </strong>
          more pending Entity registration(s) to verify.
          <span
            style={{
              fontSize: 13,
              textDecoration: "underline",
              padding: "0px 3px",
            }}
          >
            {_.map(
              props.pendingRegistrationIDs,
              (entity) => `${entity.id_340b} `
            )}
          </span>
        </div>
        <div className="draw__entity-card__image__container">
          <a
            href={`/organization/${props.organization}/onboarding/verify?entity=${props.pendingRegistrationIDs[0].id}`}
          >
            <div className="btn btn--ib btn--small btn--white btn--default-align">
              Verify
            </div>
          </a>
          <div className="draw__entity-card__image">
            <img src={verifyImage} />
          </div>
        </div>
      </div>
    );
  };

  const renderPendingTransfer = () => {
    return (
      <div className="draw__entity-card draw__entity-card__pending-transfer">
        <div className="draw__entity-card__title draw__entity-card__title__lg">
          Pending Transfer
        </div>
        <div className="draw__entity-card__note">
          You requested to transfer your entites to
          <strong
            style={{
              fontSize: 13,
              textDecoration: "underline",
              padding: "0px 3px",
            }}
          >
            {props.transferDetails.receiver}
          </strong>
          to submit on your behalf.
        </div>
        <div className="draw__entity-card__image__container">
          <div className="btn btn--ib btn--small btn--white btn--default-align">
            Revoke
          </div>
          <div className="draw__entity-card__image">
            <img src={verifyImage} />
          </div>
        </div>
      </div>
    );
  };

  const renderSubmissionCard = () => {
    return (
      <div className="draw__submission_card">
        <div className="draw__submission_card--half-cir-shape-1" />
        <div className="draw__submission_card--half-cir-shape-2" />
        <div className="draw__submission_card--half-cir-shape-3" />
        <div className="draw__submission_card--half-cir-shape-4" />
        <div className="draw__submission_card--half-cir-shape-5" />
        <div className="draw__submission_card__content_container">
          <div>
            <div className="draw__submission_card__title">
              Claims Submission
            </div>
            <div className="draw__submission_card__subtitle">
              Due Date
            </div>
          </div>
          <div className="draw__submission_card__date_icon">
            <div className="draw__submission_card__date_icon__date">
              <DayJS format="DD">{props.nextSubmission.ts}</DayJS>
            </div>
            <div className="draw__submission_card__date_icon__month">
              <DayJS format="MMM">{props.nextSubmission.ts}</DayJS>
            </div>
          </div>
          <a href={`/organization/${props.organization}/submissions/new?tid=${props.nextSubmission.tid}`}>
            <div className="btn btn--ib btn--small btn--white btn--default-align">
              Start Submission
            </div>
          </a>
        </div>
      </div>
    );
  }

  const render = () => {
    return renderSubmissionCard();
  };

  return render();
};

export default EntityDetails;
