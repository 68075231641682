import React, { useState, useEffect, useRef } from "react";
import _ from "lodash";
import debounce from "debounce-promise";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import AsyncSelect from "react-select/async";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";

const CustomTextField = withStyles({
  root: {
    backgroundColor: "#ffffff",
    borderRadius: 8,
    "& label.Mui-focused": {
      color: "#3D71FF",
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: "green",
    },
    "& .MuiOutlinedInput-root": {
      borderRadius: 8,
      "& fieldset": {
        borderColor: "#DEE7EE",
      },
      "&:hover fieldset": {
        borderColor: "#DEE7EE",
      },
      "&.Mui-focused fieldset": {
        borderColor: "#3D71FF",
      },
    },
  },
})(TextField);

const customSelectStyles = {
  container: (base) => ({
    ...base,
    margin: "16px 0 8px",
  }),
  control: (base) => ({
    ...base,
    minHeight: 56,
    borderColor: "#DEE7EE",
  }),
};

const SelectCoveredEntity = (props) => {
  const [enableSecondaryEntities, setEnableSecondaryEntities] = useState(false);
  const [secondaryEntities, setSecondaryEntities] = useState([0]);

  const loadOptions = (query) => fetchSearchTerm(query);
  const debouncedLoadOptions = debounce(loadOptions, 500, {
    leading: true,
  });

  const handleSelectedPrimaryEntity = (selection) => {
    selection ? props.completeSelection(true) : props.completeSelection(false);
  };

  const fetchSearchTerm = async (query) => {
    let url = new URL("/api/entities", window.location.origin);
    url.search = new URLSearchParams({ query: query }).toString();

    const results = await fetch(url)
      .then((response) => response.json())
      .then((data) => data)
      .catch((error) => {
        console.error("Error:", error);
      });

    return results;
  };

  const secondaryContactDetailForm = () => {
    return _.map(secondaryEntities, (position, i) => (
      <div key={position} id={position}>
        <div className="register__form__dual-row register__form__dual-row--pad-right">
          <CustomTextField label="First Name" margin="normal" variant="outlined" id={`firstName_${position}`} name="secondary_entity[users][][first_name]" autoFocus fullWidth required />
        </div>
        <div className="register__form__dual-row register__form__dual-row--pad-left">
          <CustomTextField label="Last Name" margin="normal" variant="outlined" id={`lastName_${position}`} name="secondary_entity[users][][last_name]" fullWidth required />
        </div>
        <div className="register__form__requirements">
          <p>
            <span className="bold">Note: </span>
            Please provide your
            <span className="bold"> Work Email Address.</span>
            <br />
            Accounts are verified by authenticating the 340B covered entity domain with the email address provided.
          </p>
        </div>
        <CustomTextField label="Work Email Address" margin="normal" variant="outlined" id={`email_${position}`} name="secondary_entity[users][][email]" fullWidth required />
        <AsyncSelect
          components={{ DropdownIndicator: null, IndicatorSeparator: null }}
          openMenuOnFocus={false}
          openMenuOnClick={false}
          defaultOptions={false}
          name="secondary_entity[users][][entity_id]"
          placeholder={"Search Covered Entity *"}
          styles={customSelectStyles}
          isClearable={true}
          loadOptions={(query) => debouncedLoadOptions(query)}
        />
        <div
          style={{
            margin: "15px 0",
            textDecoration: "underline",
            color: "#718291",
            cursor: "pointer",
          }}
          onClick={() => {
            setSecondaryEntities(_.without(secondaryEntities, position));
          }}
        >
          remove
        </div>
        <div className="register__divider" />
      </div>
    ));
  };

  const renderSecondaryEntityForm = () => {
    if (enableSecondaryEntities) {
      return (
        <div>
          <div className="register__divider" />
          <div className="register__form__requirements">
            <p>
              Users can elect to submit 340B claims data on behalf of additional 340B covered entities. Verification for this process is managed by comparing the domain of the work email address provided with the domain of the 340B covered
              entity.
              <br />
              <br />
              If you are the primary contact for the additional covered entity(s), simply include your name and work email address for the additional covered entity(s). If the covered entity is already registered, please enter the name and
              work email address of a registered user of the covered entity. You can also add covered entities to your account following completion of the registration process.
            </p>
          </div>
          <div className="register__divider" />
          <div id={"secondaryEntityForm"}>{secondaryContactDetailForm()}</div>
          <div
            style={{
              margin: "15px 0",
              textDecoration: "underline",
              color: "#3D71FF",
              cursor: "pointer",
              textAlign: "right",
            }}
            onClick={() => {
              setSecondaryEntities(secondaryEntities.concat(secondaryEntities[secondaryEntities.length - 1] + 1));
            }}
          >
            + Add Covered Entity
          </div>
        </div>
      );
    }
  };

  return (
    <div>
      <div className="register__step__title">2. Select your covered entity.</div>
      <div className="register__form" style={{ paddingTop: 30, paddingBottom: 40 }}>
        <div id={"primaryEntityInput"} />
        <p>Search for your covered entity name or 340B ID by typing in the search field below.</p>
        <AsyncSelect
          components={{ DropdownIndicator: null, IndicatorSeparator: null }}
          openMenuOnFocus={false}
          openMenuOnClick={false}
          name="primary_entity_id"
          placeholder={"Search Covered Entity *"}
          styles={customSelectStyles}
          onChange={handleSelectedPrimaryEntity}
          defaultOptions={false}
          isClearable={true}
          loadOptions={(query) => debouncedLoadOptions(query)}
        />
      </div>
      <div className="register__step__title">3. Submit data on behalf of additional covered entities.</div>
      <div className="register__form" style={{ paddingTop: 30, paddingBottom: 40 }}>
        <div id={"secondaryEntityInputs"} />
        <p>Will you submit 340B claims data for other covered entities?</p>

        <FormControlLabel
          control={
            <Checkbox
              checked={enableSecondaryEntities}
              onChange={() => {
                setEnableSecondaryEntities(true);
              }}
              color="primary"
            />
          }
          label="Yes"
        />

        <FormControlLabel
          control={
            <Checkbox
              checked={!enableSecondaryEntities}
              onChange={() => {
                setEnableSecondaryEntities(false);
              }}
              color="primary"
            />
          }
          label="No"
        />

        {renderSecondaryEntityForm()}
      </div>
    </div>
  );
};

export default SelectCoveredEntity;
