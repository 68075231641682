import React, { useState } from "react";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import inviteImage from "images/invite_or_find_illustration.png";
import InputAdornment from "@material-ui/core/InputAdornment";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";

const Attestation = (props) => {
  const [exempt, setExempt] = React.useState(false);
  const [insured, setInsured] = React.useState(true);

  const renderForm = () => {
    if (!insured) {
      return (
        <form method="POST" action={props.submitPath} id="attestationForm">
          <input name="_method" type="hidden" value="put" />
          <input type="hidden" name="authenticity_token" value={document.querySelector('meta[name="csrf-token"]').getAttribute("content")} />
          <div className="register__divider" style={{ margin: "20px 0" }} />
          <p>
            Covered entities may complete the attestation below to indicate that 340B contract pharmacy utilization is exclusively for uninsured patients. Pharmaceutical manufacturers may elect to exempt covered entities from use of 340B
            ESP™ based on this attestation.
            <br />
            <br />
            Each attestation will be validated through analysis of rebate utilization data originating from each 340B covered entity's own pharmacies. Following validation, manufacturers will notify covered entities of their exemption
            status. In the interim, please complete your registration with 340B ESP™.
          </p>
          <p style={{ color: "#0A162F", marginTop: 30 }}>
            By checking the ‘Attest’ box below, I <strong>{props.user} </strong>
            certify that the covered entity for which I am registering does not fill prescriptions for patients with any form of Medicaid, Medicare Part D or commercial insurance through any of its 340B contract pharmacies.
          </p>

          <FormControlLabel
            control={
              <Checkbox
                checked={exempt}
                onChange={() => {
                  setExempt(!exempt);
                }}
                color="primary"
              />
            }
            label="I Attest"
          />
          <input type="hidden" value={exempt} name="organization[exempt]" />
        </form>
      );
    }
  };

  const enableSubmit = () => {
    if (insured) {
      return true;
    } else if (!insured && exempt) {
      return true;
    } else false;
  };

  const renderButton = () => {
    if (insured) {
      return (
        <a href={enableSubmit() ? props.path : null} style={{ flexGrow: 1 }}>
          <div className={enableSubmit() ? "btn" : "btn btn--disabled"} style={{ flexGrow: 1, marginLeft: 30 }}>
            Continue
          </div>
        </a>
      );
    } else {
      return (
        <button className={enableSubmit() ? "btn" : "btn btn--disabled"} disabled={enableSubmit() ? false : true} style={{ flexGrow: 1, marginLeft: 30 }} type="submit" form="attestationForm">
          Continue
        </button>
      );
    }
  };

  return (
    <div>
      <div className="onboarding__content__form">
        <div className="onboarding__content__form__image">
          <img src={inviteImage} />
        </div>
        <div className="onboarding__content__form__title">Patient Insurance Status</div>
        <p>Do your contract pharmacies fill prescriptions for patients insured by Medicaid, Medicare Part D or commercial health plans?</p>
        <div className="btn__option__group" style={{ width: "50%" }}>
          <div
            onClick={() => {
              setInsured(true);
            }}
            className={insured ? "btn btn--small btn__option__group--pad-child-r btn--selected" : "btn btn--small btn__option__group--pad-child-r"}
          >
            yes
          </div>
          <div
            onClick={() => {
              setInsured(false);
            }}
            className={insured ? "btn btn--small btn__option__group--pad-child-r" : "btn btn--small btn__option__group--pad-child-r btn--selected"}
          >
            no
          </div>
        </div>
        <div className="onboarding__content__form__actionbar onboarding__content__form__actionbar--space-between">
          <div className="onboarding__content__form__actionbar__note">This can be updated or completed by you or another admin within your account settings.</div>
        </div>
        {renderForm()}
        <div className="onboarding__content__form__actions">
          <a href="/logout">
            <div className="btn btn--outline btn--white">Cancel and Log Out</div>
          </a>

          {renderButton()}
        </div>
      </div>
    </div>
  );
};

export default Attestation;
