import React from "react";

const CheckCircleOutline = ({ size = "medium" }) => {
  const sizes = {
    large: { height: 24, width: 24 },
    medium: { height: 20, width: 20 },
    small: { height: 16, width: 16 },
  };

  return (
    <svg style={sizes[size]} xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
      <path
        d="m424-408-86-86q-11-11-28-11t-28 11q-11 11-11 28t11 28l114 114q12 12 28 12t28-12l226-226q11-11 11-28t-11-28q-11-11-28-11t-28 11L424-408Zm56 328q-83 0-156-31.5T197-197q-54-54-85.5-127T80-480q0-83 31.5-156T197-763q54-54 127-85.5T480-880q83 0 156 31.5T763-763q54 54 85.5 127T880-480q0 83-31.5 156T763-197q-54 54-127 85.5T480-80Zm0-80q134 0 227-93t93-227q0-134-93-227t-227-93q-134 0-227 93t-93 227q0 134 93 227t227 93Zm0-320Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default CheckCircleOutline;
