import React, { useState, useRef, useEffect } from "react";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import _ from "lodash";
import { data } from "jquery";

const CustomTextField = withStyles({
  root: {
    backgroundColor: "transparent",
    borderRadius: 8,
    "& label.Mui-focused": {
      color: "#3D71FF",
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: "green",
    },
    "& .MuiOutlinedInput-root": {
      borderRadius: 8,
      backgroundColor: "#ffffff",
      "& fieldset": {
        borderColor: "#DEE7EE",
      },
      "&:hover fieldset": {
        borderColor: "#DEE7EE",
      },
      "&.Mui-focused fieldset": {
        borderColor: "#3D71FF",
      },
    },
  },
})(TextField);

const WholesalerInputs = (props) => {
  const findWholesaler = (obj) => {
    return _.find(obj, ['id', props.id])
  }

  const renderInputs = () => {
    return (
      <div>
        <CustomTextField
          label="Wholesaler Name"
          margin="normal"
          variant="outlined"
          name={`application[wholesalers_attributes][][name]`}
          fullWidth
          required
          value={props.name ? props.name : ""}
          onChange={(event) => {
            let dataClone = _.cloneDeep(props.wholesalers);

            findWholesaler(dataClone).name = event.target.value;
            props.setWholesalers(dataClone);
          }}
        />
        {props.wholesalers.length > 1 ? (<div
          style={{
            margin: "15px 0",
            textDecoration: "underline",
            color: "#718291",
            cursor: "pointer",
          }}
          onClick={() => {
            let dataClone = _.cloneDeep(props.wholesalers);
            _.remove(dataClone, (obj) => obj === findWholesaler(dataClone))

            props.setWholesalers(dataClone);
          }}
        >
          remove
        </div>) : (null)}
        <div className="register__divider" />
      </div>
    );
  };

  return (
    renderInputs()
  )

};

export default WholesalerInputs;
