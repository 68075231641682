import React from "react";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import dataImage from "images/data_illustration.png";

const CustomTextField = withStyles({
  root: {
    backgroundColor: "#ffffff",
    borderRadius: 8,
    "& label.Mui-focused": {
      color: "#3D71FF",
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: "green",
    },
    "& .MuiOutlinedInput-root": {
      borderRadius: 8,
      "& fieldset": {
        borderColor: "#DEE7EE",
      },
      "&:hover fieldset": {
        borderColor: "#DEE7EE",
      },
      "&.Mui-focused fieldset": {
        borderColor: "#3D71FF",
      },
    },
  },
})(TextField);

const ManagersForm = (props) => {
  const [selectedOption, setSelectedOption] = React.useState("");

  const handleChange = (event) => {
    setSelectedOption(event.target.value);
  };

  const postForm = (event) => {
    event.preventDefault();
    // do we need to do any validations first???

    document.getElementById("managers_form").submit();
  };

  return (
    <form method="POST" action={props.submit_path} id="managers_form" onSubmit={postForm}>
      <input type="hidden" name="authenticity_token" value={document.querySelector('meta[name="csrf-token"]').getAttribute("content")} />
      <div className="onboarding__content__form">
        <div className="onboarding__content__form__image">
          <img src={dataImage} />
        </div>
        <div className="onboarding__content__form__title">Do you use a Contract Pharmacy Administrator?</div>
        <p>Please select a value from the list below.</p>

        <CustomTextField
          select
          label="TPA"
          margin="normal"
          variant="outlined"
          id="role"
          name="third_party_admin"
          fullWidth
          required
          SelectProps={{
            native: true,
          }}
          value={selectedOption}
          onChange={handleChange}
        >
          <option key="No" value="No">
            No - We don't use a TPA
          </option>
          340Basics
          <option key="340Basics" value="340Basics">
            340Basics
          </option>
          <option key="340BComplete" value="340B Complete">
            340B Complete (Walgreens)
          </option>
          <option key="AmericanHealthCare" value="American Health Care">
            American Health Care
          </option>
          <option key="Atria" value="Atria">
            Atria
          </option>
          <option key="CaptureRx" value="CaptureRx">
            CaptureRx
          </option>
          <option key="Cervey" value="Cervey">
            Cervey
          </option>
          <option key="CirrusPharmacySystems" value="Cirrus Pharmacy Systems">
            Cirrus Pharmacy Systems
          </option>
          <option key="PDMI" value="PDMI">
            PDMI
          </option>
          <option key="Omnicell 340B" value="Omnicell 340B">
            Omnicell 340B
          </option>
          <option key="MacroHelix" value="MacroHelix">
            MacroHelix
          </option>
          <option key="Maxor Pharmacies" value="Maxor Pharmacies">
            Maxor Pharmacies
          </option>
          <option key="Nuvem" value="Nuvem">
            Nuvem
          </option>
          <option key="SentryDataSystems" value="Sentry Data Systems">
            Sentry Data Systems
          </option>
          <option key="SunRx" value="Sun Rx">
            Sun Rx (MedImpact)
          </option>
          <option key="VeritySolutions" value="Verity Solutions">
            Verity Solutions
          </option>
          <option key="Wellpartner" value="Wellpartner">
            Wellpartner
          </option>
          <option key="Other" value="Other">
            Other
          </option>
        </CustomTextField>

        <div className="onboarding__content__form__actions">
          <a href={props.path}>
            <div className="btn btn--outline btn--white">Skip and do this later</div>
          </a>
          <button className="btn" style={{ flexGrow: 1, marginLeft: 30 }} type="submit">
            Continue
          </button>
        </div>
      </div>

      <div className="onboarding__content__footer">
        <div className="onboarding__content__footer__note">
          <a href="/logout">Cancel and return home</a>
        </div>
      </div>
    </form>
  );
};

export default ManagersForm;
