import React, { useState, useRef, useEffect } from "react";
import _ from "lodash";
import PharmacySelect from "./PharmacySelect";

const OfevDesignationForm = (props) => {
  const [specialtyDesignation, setSpecialtyDesignation] = useState(false);
  const [confirmedPolicy, setConfirmedPolicy] = useState(false);
  const [hasSelectedPharmacy, setHasSelectedPharmacy] = useState(false);
  const [selectedPharmacy, setSelectedPharmacy] = useState(false);

  useEffect(() => {
    var formDetails = _.cloneDeep(props.manufacturerFormDetails);

    // set form details for completed status - obj name as class
    formDetails[props.manufacturer].specialtyDesignationForm = {
      open: specialtyDesignation,
      completed: hasSelectedPharmacy,
    };
    props.setManufacturerFormDetails(formDetails);
  }, [specialtyDesignation, hasSelectedPharmacy]);

  const policies = () => {
    if (props.isBlocked) {
      return notPermitted();
    }

    return (
      <div
        className={
          confirmedPolicy
            ? "designation_form__section__group__step__toggle_container designation_form__section__group__step__toggle_container--closed"
            : "designation_form__section__group__step__toggle_container"
        }
      >
        <div className="stat-cards__confirmation stat-cards__confirmation--disabled">
          <span>
            Effective December 1, 2021, Boehringer Ingelheim's (BI) contract pharmacy policy permits any 340B covered
            entity that does not have the ability to dispense OFEV to eligible patients to designate a single specialty
            pharmacy within BI's limited distribution network for this purpose. A wholly owned pharmacy may instead be
            designated as a single contract pharmacy location to dispense OFEV provided it meets the contract pharmacy
            requirements as registered on the HRSA OPAIS database as a contract pharmacy and is not a central fill
            pharmacy. By designating a specialty pharmacy in this web form, you are acknowledging that your 340B covered
            entity is not capable of dispensing OFEV to eligible patients through either an in-house pharmacy or
            previously designated contract pharmacy. Additionally, you agree to utilize the specialty pharmacy solely
            for the purpose of distributing OFEV to your patients.
            <br />
            <br />
            Your contract pharmacy designation will be valid for 12 months from the date of your first designation. You
            must resubmit your designation after the 12 - month period has expired for your designation to remain in
            effect. You may only change your designation within a 12 - month period if the designated contract pharmacy
            relationship is terminated from the HRSA OPAIS database. If you have any questions regarding designation of
            a wholly owned contract pharmacy, please reach out to support@340besp.com.
          </span>
          {renderConfirmation()}
        </div>
      </div>
    );
  };

  const renderDesignationQuestion = () => {
    return `Would you like to make a single contract pharmacy designation for ${props.manufacturerName}'s specialty product, OFEV?`;
  };

  const renderDesignationSubtitle = () => {
    return "Designation for Specialty Products";
  };

  const renderSpecialtyDesignationForm = () => {
    return (
      <div className="designation_form__section__group">
        <div className="designation_form__section__group__row">
          <div className="designation_form__section__group__question">
            <div className="designation_form__section__group__question__subtitle">{renderDesignationSubtitle()}</div>
            {renderDesignationQuestion()}
          </div>
          <div className="designation_form__section__group__value">
            <div className="btn__option__group">
              <div
                className={specialtyDesignation ? "btn btn--small btn--selected" : "btn btn--small"}
                onClick={() => setSpecialtyDesignation(true)}
              >
                yes
              </div>
              <div
                className={specialtyDesignation ? "btn btn--small" : "btn btn--small btn--selected"}
                onClick={() => setSpecialtyDesignation(false)}
              >
                no
              </div>
            </div>
          </div>
        </div>
        {renderPolicy()}
        {renderStep()}
      </div>
    );
  };

  const renderConfirmation = () => {
    return (
      <div className="stat-cards__confirmation__footer">
        <span>
          <div className="indicator indicator--red indicator--pulse" style={{ marginTop: 3 }}></div>
          Please confirm policy before assigning designation
        </span>
        <span>
          <div className="btn btn--ib btn--tiny" onClick={() => setConfirmedPolicy(true)}>
            Confirm
          </div>
        </span>
      </div>
    );
  };

  const renderStepIndicator = (step) => {
    if (step) {
      return (
        <div className="designation_form__section__group__step__indicator designation_form__section__group__step__indicator--complete">
          <i className="solid solid-budicon-check-ui" />
        </div>
      );
    } else {
      return <div className="designation_form__section__group__step__indicator"></div>;
    }
  };

  const notPermitted = () => {
    return (
      <div
        className={
          confirmedPolicy
            ? "designation_form__section__group__step__toggle_container designation_form__section__group__step__toggle_container--closed"
            : "designation_form__section__group__step__toggle_container"
        }
      >
        <div className="stat-cards__confirmation stat-cards__confirmation--disabled">
          <span>Please reach out to support at support@340besp.com for assistance.</span>
          {renderNotPermitted()}
        </div>
      </div>
    );
  };

  const renderNotPermitted = () => {
    return (
      <div className="stat-cards__confirmation__footer">
        <span>
          <div className="indicator indicator--red indicator--pulse" style={{ marginTop: 3 }}></div>
          Contact support
        </span>
      </div>
    );
  };

  const renderPolicy = () => {
    if (specialtyDesignation) {
      return (
        <div className="designation_form__section__group__step">
          {renderStepIndicator(confirmedPolicy)}
          <div className="designation_form__section__group__step__title">Policy Confirmation</div>
          <div className="designation_form__section__group__step__subtitle">
            Please confirm manufacturers policy to continue.
          </div>
          {policies()}
        </div>
      );
    }
  };

  const filterPharmacies = (entity) => {
    return _.filter(entity.pharmacies.details, { [props.manufacturer]: [props.specialtyKeyName] });
  };

  const renderPharmacySelect = () => {
    // hasSelectedSpecialtyPharmacy is passed in to render notice
    // You can't select two specialty pharmacies across designation kinds per manufacturer

    // retailSelectedSpecialtyPharmacy for duplicate input values if single designation has selected specialty CP

    return _.map(props.entities, (entity, index) => (
      <div key={index}>
        <PharmacySelect
          kind={props.specialtyKeyName}
          entity={entity}
          pharmacies={
            props.retailHasSelectedSpecialtyPharmacy
              ? [props.retailSelectedSpecialtyPharmacy]
              : filterPharmacies(entity)
          }
          specialtyPharmacies={filterPharmacies(entity)}
          manufacturer={props.manufacturer}
          disableGranteeNotice={true}
          disableWhollyOwnedNotice={true}
          disableInHouseSpecialtyNotice={true}
          disableInHouseNotice={true}
          selectedPharmacy={selectedPharmacy}
          setSelectedPharmacy={setSelectedPharmacy}
          setHasSelectedPharmacy={setHasSelectedPharmacy}
          retailSelectedSpecialtyPharmacy={props.retailSelectedSpecialtyPharmacy}
          retailHasSelectedSpecialtyPharmacy={props.retailHasSelectedSpecialtyPharmacy}
          setRetailSelectedSpecialtyPharmacy={props.setRetailSelectedSpecialtyPharmacy}
          setRetailHasSelectedSpecialtyPharmacy={props.setRetailHasSelectedSpecialtyPharmacy}
          setOfevHasSelectedPharmacy={props.setOfevHasSelectedPharmacy}
        />
      </div>
    ));
  };

  const renderStep = () => {
    if (specialtyDesignation && confirmedPolicy) {
      return (
        <div className="designation_form__section__group__step">
          {renderStepIndicator(hasSelectedPharmacy)}
          <div className="designation_form__section__group__step__title">Select Contract Pharmacy</div>
          <div className="designation_form__section__group__step__subtitle">
            Please select a contract pharmacy to designate.
          </div>
          {renderPharmacySelect()}
        </div>
      );
    }
  };

  return renderSpecialtyDesignationForm();
};

export default OfevDesignationForm;
