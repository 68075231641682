import React, { useState, useRef, useEffect } from "react";
import _ from "lodash";
import PharmacySelect from "./PharmacySelect";

const RetailDesignationsForm = (props) => {
  const [singlePharmacyDesignation, setSinglePharmacyDesignation] = useState(false);
  const [confirmedPolicy, setConfirmedPolicy] = useState(false);
  const [hasSelectedPharmacy, setHasSelectedPharmacy] = useState(false);
  const [selectedPharmacy, setSelectedPharmacy] = useState(false);

  useEffect(() => {
    var formDetails = _.cloneDeep(props.manufacturerFormDetails);

    // set form details for completed status - obj name as class
    formDetails[props.manufacturer].singlePharmacyDesignationForm = {
      open: singlePharmacyDesignation,
      completed: hasSelectedPharmacy,
    };
    props.setManufacturerFormDetails(formDetails);
  }, [singlePharmacyDesignation, hasSelectedPharmacy]);

  const policies = () => {
    return (
      <div
        className={
          confirmedPolicy
            ? "designation_form__section__group__step__toggle_container designation_form__section__group__step__toggle_container--closed"
            : "designation_form__section__group__step__toggle_container"
        }
      >
        <div className="stat-cards__confirmation stat-cards__confirmation--disabled">
          <span>
            Effective May 1st, 2024, Sumitomo's policy provides an exception that allows any hospital Covered Entity
            without an in-house pharmacy to designate a single contract pharmacy location within 40 miles of the covered
            entity parent site for the delivery of 340B-priced drugs. By designating a single contract pharmacy
            location, you are representing that your 340B covered entity does not have an in-house pharmacy that is
            capable of dispensing 340B purchased drugs to eligible patients.
          </span>
          {renderConfirmation()}
        </div>
      </div>
    );
  };

  const renderDesignationQuestion = () => {
    return `Would you like to make a single contract pharmacy designation for ${props.manufacturerName}'s products?`;
  };

  const renderDesignationSubtitle = () => {
    return "Contract Pharmacy Designation";
  };

  const renderSinglePharmacyDesignationForm = () => {
    return (
      <div className="designation_form__section__group">
        <div className="designation_form__section__group__row">
          <div className="designation_form__section__group__question">
            <div className="designation_form__section__group__question__subtitle">{renderDesignationSubtitle()}</div>
            {renderDesignationQuestion()}
          </div>
          <div className="designation_form__section__group__value">
            <div className="btn__option__group">
              <div
                className={singlePharmacyDesignation ? "btn btn--small btn--selected" : "btn btn--small"}
                onClick={() => setSinglePharmacyDesignation(true)}
              >
                yes
              </div>
              <div
                className={singlePharmacyDesignation ? "btn btn--small" : "btn btn--small btn--selected"}
                onClick={() => setSinglePharmacyDesignation(false)}
              >
                no
              </div>
            </div>
          </div>
        </div>
        {renderPolicy()}
        {renderStep()}
      </div>
    );
  };

  const renderConfirmation = () => {
    return (
      <div className="stat-cards__confirmation__footer">
        <span>
          <div className="indicator indicator--red indicator--pulse" style={{ marginTop: 3 }}></div>
          Please confirm policy before assigning designation
        </span>
        <span>
          <div className="btn btn--ib btn--tiny" onClick={() => setConfirmedPolicy(true)}>
            Confirm
          </div>
        </span>
      </div>
    );
  };

  const renderStepIndicator = (step) => {
    if (step) {
      return (
        <div className="designation_form__section__group__step__indicator designation_form__section__group__step__indicator--complete">
          <i className="solid solid-budicon-check-ui" />
        </div>
      );
    } else {
      return <div className="designation_form__section__group__step__indicator"></div>;
    }
  };

  const renderPolicy = () => {
    if (singlePharmacyDesignation) {
      return (
        <div className="designation_form__section__group__step">
          {renderStepIndicator(confirmedPolicy)}
          <div className="designation_form__section__group__step__title">Policy Confirmation</div>
          <div className="designation_form__section__group__step__subtitle">
            Please confirm manufacturers policy to continue.
          </div>
          {policies()}
        </div>
      );
    }
  };

  const filterPharmacies = (entity) => {
    return _.filter(
      entity.pharmacies.details,
      (cp) => entity.pharmacies.in_range.includes(cp.value) && !entity.omit.includes(cp.pid)
    );
  };

  const renderPharmacySelect = () => {
    return _.map(props.entities, (entity, index) => (
      <div key={index}>
        <PharmacySelect
          kind={"retail"}
          renderMap={true}
          entity={entity}
          pharmacies={filterPharmacies(entity)}
          manufacturer={props.manufacturer}
          disableGranteeNotice={true}
          disableWhollyOwnedNotice={true}
          disableInHouseSpecialtyNotice={true}
          disableInHouseNotice={false}
          selectedPharmacy={selectedPharmacy}
          setSelectedPharmacy={setSelectedPharmacy}
          setHasSelectedPharmacy={setHasSelectedPharmacy}
          setRetailSelectedSpecialtyPharmacy={props.setRetailSelectedSpecialtyPharmacy}
          setRetailHasSelectedSpecialtyPharmacy={props.setRetailHasSelectedSpecialtyPharmacy}
        />
      </div>
    ));
  };

  const renderStep = () => {
    if (singlePharmacyDesignation && confirmedPolicy) {
      return (
        <div className="designation_form__section__group__step">
          {renderStepIndicator(hasSelectedPharmacy)}
          <div className="designation_form__section__group__step__title">Select Contract Pharmacy</div>
          <div className="designation_form__section__group__step__subtitle">
            Please select a contract pharmacy to designate.
          </div>
          {renderPharmacySelect()}
        </div>
      );
    }
  };

  return renderSinglePharmacyDesignationForm();
};

export default RetailDesignationsForm;
