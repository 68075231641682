import React, { useState, useEffect } from "react";

export default function Notifications(props) {
  const [displayNotifications, setDisplayNotifications] = useState(false)
  const [displayIndicator, setDisplayIndicator] = useState(props.hasNotifications)


  const markSeen = () => {
    if (!displayIndicator) return;

    fetch(`/organization/${props.organization}/notifications`, {
      method: "PUT",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "X-CSRF-Token": document.querySelector('meta[name="csrf-token"]').getAttribute("content"),
      },
    })
      .then((res) => res.json())
      .then((data) => {
        setDisplayIndicator(false)
      })
      .catch((error) => {
        console.warn("failed to mark notification")
      });
  };

  const renderIndicator = () => {
    if (displayIndicator) {
      return <div className="header__notifications__icon__indicator header__notifications__icon__indicator--pulse" />
    }
  }

  const renderNotificationsTray = () => {
    if (displayNotifications) {
      return <div className="header__notifications__container animated delay-1 fadeInDown--small">
        <div className="header__notifications__container__title">
          Notifications
        </div>
        {renderNotification()}
      </div>
    }
  }

  const renderNotification = () => {
    return props.notifications.map((notification, index) => (
      <div key={index} className="header__notifications__container__item">
        <div className="header__notifications__container__item__icon">
          <i className="solid solid-budicon-newspaper" />
        </div>
        <div className="header__notifications__container__item__content">
          <div className="header__notifications__container__item__content__info__container">
            <div className="header__notifications__container__item__content__info__subtitle">{notification.title}</div>
            <div className="header__notifications__container__item__content__info__date">{notification.created_at}</div>
          </div>
          <div className="header__notifications__container__item__content__info">{notification.message}</div>
        </div>
      </div>
    ))
  }

  return (
    <div className="header__notifications__icon" onMouseEnter={() => setDisplayNotifications(true)} onMouseLeave={() => setTimeout(() => { markSeen(); setDisplayNotifications(false) }, 500)}>
      {renderIndicator()}
      <i className="solid solid-budicon-alert" />
      {renderNotificationsTray()}
    </div>
  );
}
