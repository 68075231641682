import React, { useState, useRef, useEffect } from "react";
import _ from "lodash";
import DocumentBillboard from "./DocumentBillboard";
import Attachments from "./Attachments";

const PublicWhollyOwnedExemptionsDocuments = (props) => {
  const [completedAttachedDocuments, setCompletedAttachedDocuments] = useState(false);
  const [attachments, setAttachments] = useState([{ id: 1, filename: null, filesize: null, file: null }]);

  useEffect(() => {
    if (hasEmptyValues(attachments)) {
      setCompletedAttachedDocuments(false);
    } else {
      setCompletedAttachedDocuments(true);
    }
  }, [attachments]);

  const hasEmptyValues = (data) => {
    let values = _.flatten(data.map((obj) => _.values(obj)));

    return values.includes(null) || values.includes("");
  };

  const enableSubmit = () => {
    return completedAttachedDocuments;
  };

  const renderAttachments = () => {
    return attachments.map((item, i) => {
      return (
        <div key={item.id}>
          <Attachments
            id={item.id}
            filename={item.filename}
            filesize={item.filesize}
            file={item.file}
            attachments={attachments}
            setAttachments={setAttachments}
          />
        </div>
      );
    });
  };

  const renderAttachmentNote = () => {
    return (
      <p>
        <span className="bold">Note: </span>
        In order to submit an exemption request, please provide proof of ownership documents. Examples of proof of
        ownership documents include:
        <span className="bold"> Certificate of liability insurance </span>
        or
        <span className="bold"> State business licenses</span>
      </p>
    );
  };

  return (
    <div className="register__wrapper register__wrapper--large">
      <form
        method="POST"
        action="/wholly_owned_application"
        id="wholly_owned_application_form"
        encType="multipart/form-data"
      >
        <input name="_method" type="hidden" value="patch" />
        <input name="token" type="hidden" value={props.paramsToken} />
        <div className="register__form__container register__form__container--large">
          <div className="register__step__title">1. Upload Documents</div>
          <div className="register__form register__form--large">
            <div className="register__form__requirements" style={{ marginTop: 0 }}>
              {renderAttachmentNote()}
            </div>
            {renderAttachments()}
            <div
              style={{
                margin: "15px 0",
                textDecoration: "underline",
                color: "#3246D3",
                cursor: "pointer",
                textAlign: "right",
              }}
              onClick={() => {
                setAttachments([
                  ...attachments,
                  { id: _.maxBy(attachments, "id").id + 1, filename: null, filesize: null, file: null },
                ]);
              }}
            >
              + Add Another File
            </div>
          </div>
        </div>
        <DocumentBillboard completedAttachedDocuments={completedAttachedDocuments} enableSubmit={enableSubmit} />
      </form>
    </div>
  );
};

export default PublicWhollyOwnedExemptionsDocuments;
