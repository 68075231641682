import React from "react";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";

const CustomTextField = withStyles({
  root: {
    backgroundColor: "#ffffff",
    borderRadius: 8,
    "& label.Mui-focused": {
      color: "#3D71FF",
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: "green",
    },
    "& .MuiOutlinedInput-root": {
      borderRadius: 8,
      "& fieldset": {
        borderColor: "#DEE7EE",
      },
      "&:hover fieldset": {
        borderColor: "#DEE7EE",
      },
      "&.Mui-focused fieldset": {
        borderColor: "#3D71FF",
      },
    },
  },
})(TextField);

const ContactsForm = (props) => {
  return (
    <form method="POST" action="/contacts">
      <div className="row">
        <div className="col-6">
          <CustomTextField
            label="First Name"
            margin="normal"
            variant="outlined"
            id="firstName"
            name="contact[firstname]"
            fullWidth
            required
          />
        </div>
        <div className="col-6">
          <CustomTextField
            label="Last Name"
            margin="normal"
            variant="outlined"
            id="lastName"
            name="contact[lastname]"
            fullWidth
            required
          />
        </div>
      </div>
      <div className="row">
        <div className="col-6">
          <CustomTextField
            label="Email Address"
            margin="normal"
            variant="outlined"
            id="email"
            name="contact[email]"
            fullWidth
            required
          />
        </div>
        <div className="col-6">
          <CustomTextField
            label="Covered Entity Name"
            margin="normal"
            variant="outlined"
            id="coveredEntity"
            name="contact[entity]"
            fullWidth
            required
          />
        </div>
      </div>
      <CustomTextField
        label="Message"
        margin="normal"
        variant="outlined"
        id="message"
        fullWidth
        required
        name="contact[message]"
        type="text"
        multiline={true}
        rows={10}
      />
      <div className="row">
        <div className="col-12 mt-3 d-flex justify-content-end">
          <button className="btn" type="submit">
            <i className="solid solid-budicon-sending-message" />
            Send Message
          </button>
        </div>
      </div>
    </form>
  );
};

export default ContactsForm;
