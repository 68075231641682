import React, { useState } from "react";

const OptionsRadio = ({ options, selectedOption, setSelectedOption }) => {
  const renderOptions = () => {
    return options.map((option, i) => {
      const checked = selectedOption === option.value;

      function getClassName() {
        if (checked) {
          return "attestation_modal__option--selected";
        }
        return "";
      }

      return (
        <div
          className={`attestation_modal__option ${getClassName()}`}
          key={i}
          onClick={() => setSelectedOption(option.value)}
        >
          <input className="radio__input" type="radio" value={option.value} checked={checked} readOnly />
          <span className="radio__styled-input" />
          <div>{option.label}</div>
        </div>
      );
    });
  };

  return (
    <>
      <div className="t--500">Please select one of the options below.</div>
      {renderOptions()}
    </>
  );
};

export default OptionsRadio;
