import React from "react";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import dayjs from "dayjs";

const CustomTextField = withStyles({
  
  root: {
    backgroundColor: "#ffffff",
    borderRadius: 8,
    "& label.Mui-focused": {
      color: "#3D71FF",
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: "green",
    },
    "& .MuiOutlinedInput-root": {
      borderRadius: 8,
      "& fieldset": {
        borderColor: "#DEE7EE",
      },
      "&:hover fieldset": {
        borderColor: "#DEE7EE",
      },
      "&.Mui-focused fieldset": {
        borderColor: "#3D71FF",
      },
    },
  },
})(TextField);

const CustomSelect = withStyles({
  
  root: {
    backgroundColor: "#ffffff",
    borderRadius: 8,
    "& label.Mui-focused": {
      color: "#3D71FF",
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: "green",
    },
    "& .MuiOutlinedInput-root": {
      borderRadius: 8,
      "& fieldset": {
        borderColor: "#DEE7EE",
      },
      "&:hover fieldset": {
        borderColor: "#DEE7EE",
      },
      "&.Mui-focused fieldset": {
        borderColor: "#3D71FF",
      },
    },
  },
})(Select);

const DataDispute = (props) => {
  const [reason, setReason] = React.useState('');
  const [value, setValue] = React.useState(dayjs('2014-08-18T21:11:54'));

  const handleChange = (newValue) => {
    setValue(newValue);
  };

  const handleReasonChange = (event) => {
    setReason(event.target.value);
  };

  return (
    <form method="POST" action="/supports">
      <div className="row">
        <div className="col-6">
          <CustomTextField
            label="Entity 340B ID"
            margin="normal"
            variant="outlined"
            id="id340b"
            name="support[id340b]"
            defaultValue={props.id340b}
            readOnly
            fullWidth
            required
          />
        </div>
        <div className="col-6">
          <CustomTextField
            label="Pharmacy Name"
            margin="normal"
            variant="outlined"
            id="pharmacy_name"
            name="support[pharmacy_name]"
            fullWidth
            required
          />
        </div>
      </div>
      <div className="row">
        <div className="col-6">
          <CustomTextField
            label="Pharmacy Address"
            margin="normal"
            variant="outlined"
            id="pharmacy_address"
            name="support[pharmacy_address]"
            fullWidth
            required
          />
        </div>
        <div className="col-6">
          <CustomTextField
            label="Pharmacy DEA"
            margin="normal"
            variant="outlined"
            id="pharmacy_dea"
            name="support[pharmacy_dea]"
            fullWidth
            required
          />
        </div>
        <div className="col-6">
          <CustomTextField
            label="HRSA Pharmacy ID"
            margin="normal"
            variant="outlined"
            id="hrsa_pharmacy_id"
            name="support[hrsa_pharmacy_id]"
            fullWidth
            required
          />
        </div>
        <div className="col-6">
          <CustomTextField
            label="Manufacturer"
            margin="normal"
            variant="outlined"
            id="manufacturer"
            name="support[manufacturer]"
            fullWidth
            required
          />
        </div>
        <div className="col-6">
          <CustomTextField
            label="Product Name"
            margin="normal"
            variant="outlined"
            id="product_name"
            name="support[product_name]"
            fullWidth
            required
          />
        </div>
        <div className="col-6">
          <CustomTextField
            label="NDC"
            margin="normal"
            variant="outlined"
            id="ndc"
            name="support[ndc]"
            fullWidth
            required
          />
        </div>
        <div className="col-6">
          <CustomTextField
            label="Start Date"
            margin="normal"
            variant="outlined"
            id="start_date"
            name="support[start_date]"
            type="date"
            fullWidth
            required
          />
        </div>
        <div className="col-6">
          <CustomTextField
            label="End Date"
            margin="normal"
            variant="outlined"
            id="end_date"
            name="support[end_date]"
            type="date"
            fullWidth
            required
          />
        </div>
        <div className="col-12">
          <InputLabel id="reason-label">Reason</InputLabel>
          <Select
            labelId="reason-label"
            id="reason"
            name="support[reason]"
            value={reason}
            label="Reason"
            onChange={handleReasonChange}
            fullWidth
            required
          >
            <MenuItem value={"Missing or incorrect submissions"}>Missing or incorrect submissions</MenuItem>
            <MenuItem value={"Incorrect chargebacks"}>Incorrect chargebacks</MenuItem>
            <MenuItem value={"Incorrect pharmacy ID"}>Incorrect pharmacy ID</MenuItem>
            <MenuItem value={"Incorrect entity ID"}>Incorrect entity ID</MenuItem>
            <MenuItem value={"Incorrect product"}>Incorrect product</MenuItem>
          </Select>
        </div>
      </div>
      <div className="row">
        <div className="col-12 mt-3 d-flex justify-content-end">
          <button className="btn" type="submit">
            <i className="solid solid-budicon-sending-message" />
            Send Form
          </button>
        </div>
      </div>
    </form>
  );
};

export default DataDispute;
