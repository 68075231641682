import React from "react";
import { GroupsTwoTone, ChevronRight, VerifiedUser, BusinessTwoTone } from "@mui/icons-material";
import StatCards from "./StatCards";
import OverdrawnAccountsWidget from "./OverdrawnAccountsWidget";
import AccountStatusWidget from "./AccountStatusWidget";
import ClaimsWidget from "./ClaimsWidget";

const Index = (props) => {
  return (
    <div className="dashboard__grid">
      <StatCards
        user_name={props.user_name}
        user_role={props.user_role}
        user_email={props.user_email}
        user_count={props.user_count}
        recordCount={props.recordCount}
        organization={props.organization}
        user_initials={props.user_initials}
        primary_ce={props.primary_ce}
        primary_id_340b={props.primary_id_340b}
        entities_count={props.entities_count}
        conforming_claims_count={props.conforming_claims_count}
      />
      <OverdrawnAccountsWidget
        organization={props.organization}
        overdrawn_status_history_path={props.overdrawn_status_history_path}
      />
      <ClaimsWidget data={props.data.data} />
      <AccountStatusWidget organization={props.organization} ledger_activity_path={props.ledger_activity_path} />
    </div>
  );
};

export default Index;
