import React from "react";

const ProgressBar = ({ percent = "0%", color = "" }) => {
  const colorClass = color ? `pharm_cert_modal__progress_bar--${color}` : "";

  return (
    <div className="pharm_cert_modal__progress_bar__container">
      <div className={`pharm_cert_modal__progress_bar ${colorClass}`} style={{ width: percent }} />
    </div>
  );
};

export default ProgressBar;
