import React from "react";

const ArrowRight = ({ size = "medium" }) => {
  const sizes = {
    large: { height: 24, width: 24 },
    medium: { height: 20, width: 20 },
  };

  return (
    <svg style={sizes[size]} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M13.125 10.75H4.751a.73.73 0 0 1-.535-.214.719.719 0 0 1-.216-.532c0-.21.072-.39.216-.535a.722.722 0 0 1 .535-.219h8.374l-1.667-1.667a.745.745 0 0 1-.01-1.063c.16-.152.34-.228.542-.228.201 0 .378.076.53.229l2.966 2.965a.667.667 0 0 1 .15.229.756.756 0 0 1 .052.28.756.756 0 0 1-.209.526l-2.958 2.958a.681.681 0 0 1-.521.219.794.794 0 0 1-.53-.24.746.746 0 0 1 .01-1.062l1.645-1.646z"
        fill="currentColor"
      />
    </svg>
  );
};

export default ArrowRight;
