import React from "react";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";

const CustomTextField = withStyles({
  root: {
    backgroundColor: "#ffffff",
    borderRadius: 8,
    "& label.Mui-focused": {
      color: "#3D71FF",
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: "green",
    },
    "& .MuiOutlinedInput-root": {
      borderRadius: 8,
      "& fieldset": {
        borderColor: "#DEE7EE",
      },
      "&:hover fieldset": {
        borderColor: "#DEE7EE",
      },
      "&.Mui-focused fieldset": {
        borderColor: "#3D71FF",
      },
    },
  },
})(TextField);

const ManualRegistrationForm = (props) => {
  return (
    <form method="POST" action="/verify">
      <div className="onboarding__content__form">
        <div className="onboarding__content__form__title">
          We were unable to automatically verify your registration.
        </div>
        <p>
          Lorem, ipsum dolor sit amet consectetur adipisicing elit. Ratione
          deleniti nam, minus debitis sapiente tenetur saepe placeat eaque
          vitae.
        </p>

        {/* <CustomTextField
          variant="outlined"
          margin="normal"
          required
          fullWidth
          id="twofa"
          label="Verification Code"
          name="code"
          autoFocus
        />
        <div className="register__form__footnote">
          By clicking ‘Verify’ you agree to Platform Terms of Service and
          Privacy Policy.
        </div>
        <div className="register__form__actions">
          <a href={props.path}>
            <div className="btn btn--outline btn--white">
              Re-send verification email
            </div>
          </a>
          <button
            className="btn"
            style={{ flexGrow: 1, marginLeft: 30 }}
            type="submit"
          >
            Verify and Continue
          </button>
        </div> */}
      </div>

      <div className="onboarding__content__footer">
        <div className="onboarding__content__footer__note">
          <a href="/logout">Cancel - return home</a>
        </div>

        {/* <div className="onboarding__content__footer__actions">
          <a href="/resend_2fa">
            <div className="btn btn--outline btn--white">
              Re-send verification email
            </div>
          </a>
          <div className="btn" style={{ flexGrow: 1, marginLeft: 30 }}>
            Verify and Continue
          </div>
        </div> */}
      </div>
    </form>
  );
};

export default ManualRegistrationForm;
