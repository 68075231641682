import React from "react";

const StatusTag = ({ status }) => {
  switch (status) {
    case null:
    case "not_started":
    case "in_progress":
      return (
        <div className="attestation_modal__status">
          <i className="attestation_modal__status__icon attestation_modal__status__icon--blue" />
          Not Submitted
        </div>
      );
    case "in_review":
      return (
        <div className="attestation_modal__status">
          <i className="attestation_modal__status__icon attestation_modal__status__icon--purple" />
          Under Review
        </div>
      );
    case "denied":
      return (
        <div className="attestation_modal__status">
          <i className="attestation_modal__status__icon attestation_modal__status__icon--red" />
          Denied
        </div>
      );
    case "complete":
      return (
        <div className="attestation_modal__status">
          <i className="attestation_modal__status__icon attestation_modal__status__icon--green" />
          Approved
        </div>
      );
    default:
      return null;
  }
};

export default StatusTag;
