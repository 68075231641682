import React, { useState, useRef, useEffect } from "react";
import _ from "lodash";

const Attachments = (props) => {

  const findAttachment = (obj) => {
    return _.find(obj, ['id', props.id])
  }

  const handleChange = (e) => {
    const file = e.target.files[0];

    let dataClone = _.cloneDeep(props.attachments);
    let attachment = findAttachment(dataClone)

    attachment.filename = file ? file.name : null
    attachment.filesize = file ? file.size : null
    attachment.file = file ? file : null

    props.setAttachments(dataClone);
  };

  const handleRemovefile = (e) => {
    let dataClone = _.cloneDeep(props.attachments);
    _.remove(dataClone, (obj) => obj === findAttachment(dataClone))

    props.setAttachments(dataClone);
  };

  const renderRemoveBtn = () => {
    if (props.attachments.length > 1) {
      return (
        <div className="attachments__tile__remove__container">
          <div className="attachments__tile__remove" onClick={handleRemovefile}>Remove</div>
        </div>
      )
    } else {
      return null
    }
  }

  const renderFile = () => {
    return <div className={props.file ? "attachments__tile attachments__tile--space attachments__tile--selected" : "attachments__tile attachments__tile--space"}>
      <div className="attachments__tile__icon attachments__tile__icon--push-bottom">
        <svg xmlns="http://www.w3.org/2000/svg" width="26.339" height="33.63" viewBox="0 0 26.339 33.63">
          <g transform="translate(-0.223)">
            <path d="M1.068,0H16.646l7.375,7.481V32.562a1.068,1.068,0,0,1-1.068,1.068H1.068A1.068,1.068,0,0,1,0,32.562V1.068A1.068,1.068,0,0,1,1.068,0Z" transform="translate(2.541)" fill={props.file ? "#def8ea" : "#F2F5F8"} />
            <path d="M0,0,7.473,7.473h-5.9A1.573,1.573,0,0,1,0,5.9Z" transform="translate(19.089)" fill={props.file ? "#2bd67b" : "#718291"} />
            <g transform="translate(0 14.401)">
              <rect width="23" height="10" rx="2" transform="translate(0.223 0.344)" fill={props.file ? "#24b668" : "#718291"} />
              <text transform="translate(6.223 7.344)" fill="#ffffff" fontSize="6" fontFamily="Roboto-Bold, Roboto" fontWeight="700" letterSpacing="0.007em">
                <tspan x="0" y="0">
                  FILE
                </tspan>
              </text>
            </g>
          </g>
        </svg>
      </div>
      {renderRemoveBtn()}
      <div className="attachments__tile__details attachments__tile__details--block">
        <div className="attachments__tile__details__filename">{props.filename ? props.filename : "No file selected"}</div>
        <div className="attachments__tile__details__mapping-name">
          <span style={{ color: "#718291" }}>File size - </span>
          <span style={{ color: "#718291" }}>{props.filesize ? props.filesize : "No file selected"}</span>
        </div>
      </div>
      <input type="file" name="application[documents][]" style={{ display: "none" }} className="form-control" id={`fileAttachments-${props.id}`} onChange={handleChange} />
      <div className="btn" style={{ marginTop: 30 }} onClick={() => {
        document.getElementById(`fileAttachments-${props.id}`).click();
      }}>Select File</div>
    </div>
  }

  return (
    renderFile()
  )

};

export default Attachments;
