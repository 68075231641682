import React from 'react';
import AsyncSelect from 'react-select/async';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { InputLabel, FormControl } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  formControl: {
    minWidth: 120,
    width: '100%',
  },
  inputLabel: {
    ...theme.typography.subtitle1,
    color: theme.palette.text.primary,
    transform: 'translate(14px, 20px) scale(1)',
    '&.MuiInputLabel-shrink': {
      transform: 'translate(14px, -6px) scale(0.75)',
    },
  },
}));

const CustomAsyncSelect = ({
  label,
  loadOptions,
  onChange,
  value,
  ...props
}) => {
  const classes = useStyles();
  const theme = useTheme();

  return (
    <FormControl className={classes.formControl}>
      <InputLabel shrink={!!value} className={classes.inputLabel}>
        {label}
      </InputLabel>
      <AsyncSelect
        {...props}
        value={value}
        loadOptions={loadOptions}
        onChange={onChange}
        styles={{
          control: (base, state) => ({
            ...base,
            '&:hover': {
              borderColor: state.isFocused
                ? theme.palette.primary.main
                : theme.palette.grey[400],
            },
            borderColor: state.isFocused
              ? theme.palette.primary.main
              : theme.palette.grey[400],
            boxShadow: state.isFocused
              ? `0 0 0 1px ${theme.palette.primary.main}`
              : 'none',
            borderRadius: theme.shape.borderRadius,
          }),
          input: base => ({
            ...base,
            ...theme.typography.subtitle1,
            color: theme.palette.text.primary,
          }),
          menu: base => ({
            ...base,
            borderRadius: theme.shape.borderRadius,
          }),
          option: (base, state) => ({
            ...base,
            ...theme.typography.subtitle1,
            color: theme.palette.text.primary,
            backgroundColor: state.isFocused
              ? theme.palette.grey[200]
              : 'transparent',
          }),
        }}
      />
    </FormControl>
  );
};

export default CustomAsyncSelect;