import React, { useState, useEffect, useRef } from "react";
import Notice from "./Notice";
import _ from "lodash";
import { search, filterByStatus } from "./Pharmacies/utils";
import PharmacySearch from "./Pharmacies/PharmacySearch";

export default function LillyPharmacyTable(props) {
  const [editing, setEditing] = React.useState(false);
  const [displayConfirmation, setDisplayConfirmation] = React.useState(false);
  const [attestingPharmacies, setAttestingPharmacies] = React.useState([]);
  const [pharmacies, setPharmacies] = React.useState(props.pharmacies);
  const [notice, setNotice] = React.useState({
    kind: "error",
    open: false,
    message: "",
  });
  const [eligibility, setEligibility] = useState(props.eligibility);
  const [searchTerms, setSearchTerms] = useState({ searchQuery: "", filterQuery: [] });

  useEffect(() => {
    filterPharmacies();
  }, [searchTerms]);

  const filterPharmacies = () => {
    if (searchTerms.filterQuery.length > 0) {
      return filterByStatus(pharmacies, searchTerms, eligibility, ["retail"]);
    } else {
      return search(pharmacies, searchTerms);
    }
  };

  const renderEligibilityTag = (status, date) => {
    switch (status) {
      case "ELIGIBLE":
        return (
          <div className="tag tag--small tag--with-tooltip tag--square tag--green" style={{ width: 88 }}>
            <div className="tag__info">
              <div className="tooltip tooltip--fast tooltip--info-icon tooltip--pointer-events">
                <div className="tooltip__container__title">ELIGIBLE</div>
                <div className="tooltip__container__subtitle">
                  Reported to wholesalers as eligible to receive 340B pricing for the specific manufacturer
                </div>
              </div>
            </div>
            ELIGIBLE
          </div>
        );
      case "INELIGIBLE":
        return (
          <div className="tag tag--small tag--with-tooltip tag--square tag--solid-red" style={{ width: 88 }}>
            <div className="tag__info">
              <div className="tooltip tooltip--fast tooltip--info-icon tooltip--pointer-events">
                <div className="tooltip__container__title">INELIGIBLE</div>
                <div className="tooltip__container__subtitle">
                  Contract pharmacy arrangement does not currently have access to 340B pricing
                </div>
              </div>
            </div>
            INELIGIBLE
          </div>
        );
      case "IN_PROCESS":
        return (
          <div className="tag tag--small tag--with-tooltip tag--square tag--green" style={{ width: 88 }}>
            <div className="tag__info">
              <div className="tooltip tooltip--fast tooltip--info-icon tooltip--pointer-events">
                <div className="tooltip__container__title">IN PROCESS</div>
                <div className="tooltip__container__subtitle">
                  Pricing access should be reflected with wholesalers within the next 10 business days
                </div>
              </div>
            </div>
            IN PROCESS
          </div>
        );
      case "AT_RISK":
        return (
          <div className="tag tag--small tag--with-tooltip tag--square tag--orange" style={{ width: 88 }}>
            <div className="tag__info">
              <div className="tooltip tooltip--fast tooltip--info-icon tooltip--pointer-events">
                <div className="tooltip__container__title">AT RISK</div>
                <div className="tooltip__container__subtitle">
                  {`Currently maintains access to 340B pricing but pricing access may be restricted if
                  complete claims data is not submitted by ${date}.`}
                </div>
              </div>
            </div>
            AT RISK
          </div>
        );
    }
  };

  const renderPharmacyStatus = (pharmacy) => {
    return renderEligibilityTag(
      props.eligibility[pharmacy.pharmacy_id].status,
      props.eligibility[pharmacy.pharmacy_id].risk_expiration_date
    );
  };

  const renderPharmacies = () => {
    return filterPharmacies().map((pharmacy, i) => {
      return (
        <tr key={i} className="sm">
          <td className="t--md">
            <div className="t--sm t--500">{pharmacy.pharmacy_name}</div>
            <div className="t--sm t--subtitle">{pharmacy.address}</div>
          </td>
          <td className="t--md">{pharmacy.dea}</td>
          <td className="t--md">{renderPharmacyStatus(pharmacy)}</td>
          <td className="t--md" style={{ fontSize: 12 }}>
            {pharmacy.health_industry_number}
          </td>
        </tr>
      );
    });
  };
  return (
    <div>
      <div className="table__actionbar table__actionbar--left-align table__actionbar--sticky">
        <div className="table__actionbar__vertical_stack">
          <div className="section__title">Contracted Pharmacies</div>
          <PharmacySearch searchTerms={searchTerms} setSearchTerms={setSearchTerms} filterable={true} />
        </div>
      </div>

      <div className="table__container" style={{ padding: "0 5px", marginBottom: 50 }}>
        <table className="primary">
          <thead>
            <tr className="sm">
              <th>Pharmacy</th>
              <th>DEA</th>
              <th>Status</th>
              <th>HIN</th>
            </tr>
          </thead>

          <tbody>{renderPharmacies()}</tbody>
        </table>
      </div>
      <Notice details={notice} />
    </div>
  );
}
