import React from "react";

const CheckCircle = ({ size = "medium" }) => {
  const sizes = {
    large: { height: 24, width: 24 },
    medium: { height: 20, width: 20 },
    small: { height: 16, width: 16 },
  };

  return (
    <svg style={sizes[size]} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="m7.067 9.2-1.45-1.45a.612.612 0 0 0-.45-.183.64.64 0 0 0-.467.2.632.632 0 0 0-.183.466c0 .19.06.345.183.467l1.9 1.9a.632.632 0 0 0 .467.183.632.632 0 0 0 .466-.183l3.784-3.783a.612.612 0 0 0 .183-.45.64.64 0 0 0-.2-.467.632.632 0 0 0-.467-.183.632.632 0 0 0-.466.183l-3.3 3.3zM8 14.667a6.489 6.489 0 0 1-2.6-.526 6.728 6.728 0 0 1-2.117-1.424A6.728 6.728 0 0 1 1.86 10.6 6.488 6.488 0 0 1 1.333 8c0-.922.175-1.789.526-2.6a6.728 6.728 0 0 1 1.424-2.117c.6-.6 1.306-1.075 2.117-1.425A6.495 6.495 0 0 1 8 1.333c.922 0 1.789.175 2.6.525.811.35 1.517.825 2.117 1.425.6.6 1.075 1.306 1.424 2.117.35.811.526 1.678.526 2.6 0 .922-.175 1.789-.526 2.6a6.728 6.728 0 0 1-1.424 2.117c-.6.6-1.306 1.074-2.117 1.424a6.488 6.488 0 0 1-2.6.526z"
        fill="currentColor"
      />
    </svg>
  );
};

export default CheckCircle;
