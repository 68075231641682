import React, { useState, useEffect } from "react";
import _ from "lodash";

const ModalFieldMappings = (props) => {
  const [selectedColumn, setSelectedColumn] = useState(props.displayModal.selectedItem);
  const [mappings, setMappings] = useState(props.mappings ? props.mappings : props.columnsNeeded);
  const keys = _.keys(props.columnsNeeded);

  const requiredColumns = ["date_of_service", "date_prescribed", "rx_number", "ndc", "quantity", "service_provider_id", "contracted_entity_id"];

  useEffect(() => {
    setSelectedColumn(props.displayModal.selectedItem);
  }, [props]);

  const canSubmit = () => {
    return !_.some(_.pick(mappings, requiredColumns), _.isEmpty);
  };

  const submitMappings = () => {
    if (!canSubmit()) return;

    fetch(props.submitPath, {
      method: props.mappings ? "PATCH" : "POST",
      headers: {
        "Content-Type": "application/json",
        "X-CSRF-Token": document.querySelector('meta[name="csrf-token"]').getAttribute("content"),
      },
      body: JSON.stringify({ field_mappings: mappings }),
    })
      .then((res) => res.json())
      .then(
        (data) => {
          location.reload();
        },
        (error) => {
          location.reload();
        }
      );
  };

  const buildMappings = (field) => {
    setMappings({
      ...mappings,
      [selectedColumn]: field,
    });

    var position = keys.indexOf(selectedColumn);

    if (keys.length == position + 1) {
      setSelectedColumn(keys[0]);
    } else {
      setSelectedColumn(keys[position + 1]);
    }
  };

  const removeMapping = (field) => {
    _.findKey(mappings, (val) => val == field);

    setMappings({
      ...mappings,
      [_.findKey(mappings, (val) => val == field)]: null,
    });
  };

  const renderMessage = () => {
    if (!props.fieldNames.length) {
      return <div className="modal__body__fields__container__message">No file attached, Please attach a file to map required columns.</div>;
    }
  };

  const renderRequiredColumns = (column) => {
    return requiredColumns.includes(column) ? (
      <span>
        <span>{column}</span>
        <span style={{ color: "#f50057" }}>*</span>
      </span>
    ) : (
      column
    );
  };

  const renderModal = () => {
    return (
      <div className="modal__background">
        <div className="modal__body modal__body--large modal__body--no-side-padding" style={{ paddingTop: 30, paddingBottom: 30 }}>
          <div className="modal__body__title">Assign and select columns</div>
          <div className="modal__body__subtitle" style={{ marginBottom: 30 }}>
            Confirm and save your fields to map to required columns for submissions.
          </div>

          <div className="modal__body__form_container">
            <div className="modal__body__columns__container">
              {_.keys(props.columnsNeeded)
                .sort()
                .map((column, i) => (
                  <div
                    key={i}
                    className={selectedColumn == column ? "modal__body__columns__item__container modal__body__columns__item__container--selected" : "modal__body__columns__item__container"}
                    onClick={() => {
                      setSelectedColumn(column);
                    }}
                  >
                    <div className="modal__body__columns__item">{renderRequiredColumns(column)}</div>

                    {mappings[column] && (
                      <div
                        className="tag tag--square tag--light-blue"
                        onClick={() => {
                          removeMapping(mappings[column]);
                        }}
                      >
                        <div className="tag__with_icon_text">{mappings[column]}</div>
                        <i className="solid solid-budicon-cross-sign" />
                      </div>
                    )}
                  </div>
                ))}
            </div>
            <div className="modal__body__fields__container">
              {renderMessage()}
              {props.fieldNames &&
                _.difference(props.fieldNames.sort(), _.values(mappings)).map((field, i) => (
                  <div
                    key={i}
                    className="modal__body__fields__item__container"
                    onClick={() => {
                      buildMappings(field);
                    }}
                  >
                    {field}
                  </div>
                ))}
            </div>
          </div>
          <div className="modal__body__footer">
            <div
              className="btn btn--white btn--outline"
              onClick={() => {
                props.dismissModal();
              }}
            >
              Cancel Selection
            </div>
            <div
              className={canSubmit() ? "btn" : "btn btn--disabled"}
              disabled={canSubmit() ? false : true}
              style={{ flexGrow: 1, marginLeft: 30 }}
              onClick={() => {
                submitMappings();
              }}
            >
              Save Assigned Columns
            </div>
          </div>
        </div>
      </div>
    );
  };

  return props.displayModal.display ? renderModal() : null;
};

export default ModalFieldMappings;
