import React, { useState, useEffect } from "react";
import _ from "lodash";

const SubmissionRows = (props) => {
  const renderRows = () => {
    return _.map(
      _.sortBy(props.rows, (r) => r.manufacturer),
      (row, i) => {
        return (
          <tr key={i} className="sm">
            <td className="t--md">{row.manufacturer}</td>
            <td className="t--md">{row.ndc}</td>
            <td className="t--md">
              <div className="t--sm t--500">{row.pharmacy_name}</div>
              <div className="t--sm t--subtitle">{row.pharmacy_address1}</div>
            </td>
            <td className="t--md">{row.service_provider_id}</td>
            <td className="t--md">{row.claim_conforms_flag.toString()}</td>
            <td className="t--md">{row.created_at.split("T")[0]}</td>
          </tr>
        );
      }
    );
  };

  return (
    <div className="table__container" style={{ padding: "0 5px", marginBottom: 50 }}>
      <table className="primary">
        <thead>
          <tr className="sm">
            <th>Manufacturer</th>
            <th>Ndc</th>
            <th>Pharmacy</th>
            <th>Service Provider Id</th>
            <th>Conforms</th>
            <th>Submitted</th>
          </tr>
        </thead>

        <tbody>{renderRows()}</tbody>
      </table>
    </div>
  );
};

export default SubmissionRows;
