export default class Landing {
  constructor() {
    var animationLoaded = false;

    if (window.innerWidth > 991) {
      this.loadAnimations();
    } else {
      this.dataAnimation();
    }

    // check and change animation state
    const self = this;
    window.addEventListener("resize", function (event) {
      if (window.innerWidth > 991 && animationLoaded == false) {
        animationLoaded = true;
        self.heroAnimation(true);
      }
    });
  }

  loadAnimations() {
    const self = this;
    const heroAnimation = this.heroAnimation();

    heroAnimation.addEventListener("DOMLoaded", function (e) {
      document.getElementById("heroAnimation").classList.add("animated", "delay", "slow-2", "fadeIn");

      setTimeout(function () {
        heroAnimation.play();
        self.dataAnimation();
      }, 500);
    });
  }

  heroAnimation(autoplay = false) {
    return bodymovin.loadAnimation({
      container: document.getElementById("heroAnimation"),
      renderer: "svg",
      loop: true,
      autoplay: autoplay,
      assetsPath: "/dist/hero/images/",
      path: "/dist/hero/hero_data.json",
    });
  }

  dataAnimation() {
    return bodymovin.loadAnimation({
      container: document.getElementById("dataPanelAnimation"),
      renderer: "svg",
      loop: true,
      autoplay: true,
      assetsPath: "/dist/images/",
      path: "/dist/data.json",
    });
  }
}
