import React, { useMemo, Fragment, useRef } from "react";
import {
  createColumnHelper,
  getCoreRowModel,
  useReactTable,
  flexRender,
  getExpandedRowModel,
} from "@tanstack/react-table";
import CheckCircle from "./Icons/CheckCircle";
import CheckCircleOutline from "./Icons/CheckCircleOutline";
import FilePresent from "../components/Icons/FilePresent";

const FileRow = ({ id, fileName, type, handleFileUpload, handleFileDelete, hasFileAttached, index }) => {
  const inputRef = useRef(null);

  const DEFAULT_TEXT_BY_TYPE = {
    required: "Pharmacy License (Required)",
    additional: "Additional Documents (Optional)",
  };

  const conditionalColorClass = fileName ? "color--blue" : "";

  function handleDelete(e) {
    if (!hasFileAttached) return;

    e.preventDefault();

    handleFileDelete(type, index);
    inputRef.current.value = "";
  }

  function handleChange(e) {
    if (!e.target.files.length) return;

    handleFileUpload(type, e.target.files[0]);

    if (type == "additional") {
      inputRef.current.value = "";
    }
  }

  function renderButton() {
    if (hasFileAttached) {
      return (
        <button className="button button--md button--white" onClick={(e) => handleDelete(e)}>
          Delete
        </button>
      );
    }
    return (
      <button
        className="button button--md button--white"
        onClick={(e) => {
          e.preventDefault();
          inputRef.current.click();
        }}
      >
        Attach
      </button>
    );
  }

  return (
    <div className="pharm_cert_modal__upload__file_row flex align-center justify-space-between pt--12 pb--12 gap--8">
      <div className=" flex align-center gap--8">
        <FilePresent />
        <span
          className={`pharm_cert_modal__upload__file_text t--md t--title ${conditionalColorClass} overflow--x-hidden`}
          title={fileName || null}
        >
          {fileName || DEFAULT_TEXT_BY_TYPE[type]}
        </span>
      </div>

      {renderButton()}
      <input
        id={id}
        ref={inputRef}
        type="file"
        accept="image/*,.pdf"
        style={{ display: "none" }}
        onChange={handleChange}
      />
    </div>
  );
};

const ExpandedRow = ({ row, attachments, setAttachments }) => {
  const pharmacy = row.original;
  const attachment = attachments.find((a) => a.npi == pharmacy.npi);

  function handleFileUpload(type, file) {
    setAttachments((prevAttachments) => {
      return prevAttachments.map((attachment) => {
        if (attachment.npi !== pharmacy.npi) {
          return attachment;
        }

        if (type === "required") {
          attachment.pharmacy_license = file;
        } else {
          attachment.additional_documents = [...attachment.additional_documents, file];
        }

        return attachment;
      });
    });
  }

  function handleFileDelete(type, index) {
    setAttachments((prevAttachments) => {
      return prevAttachments.map((attachment) => {
        if (attachment.npi !== pharmacy.npi) {
          return attachment;
        }

        if (type === "required") {
          attachment.pharmacy_license = null;
        } else {
          attachment.additional_documents = attachment.additional_documents.filter((_, i) => i !== index);
        }

        return attachment;
      });
    });
  }

  return (
    <div className="border br--8 pl--12 pr--12 pharm_cert_form__pharmacy_table__npi_frame">
      <FileRow
        id={`required-file-${pharmacy.id}`}
        fileName={attachment.pharmacy_license?.name}
        hasFileAttached={Boolean(attachment.pharmacy_license)}
        type="required"
        handleFileUpload={handleFileUpload}
        handleFileDelete={handleFileDelete}
      />
      <div className="pharm_cert_form__separator"></div>
      {attachment.additional_documents?.map((file, i) => (
        <Fragment>
          <FileRow
            key={`${file}-${pharmacy.id}-${i}`}
            id={`${file}-${pharmacy.id}-${i}`}
            fileName={file.name}
            hasFileAttached={true}
            index={i}
            type="additional"
            handleFileUpload={handleFileUpload}
            handleFileDelete={handleFileDelete}
          />
          <div className="pharm_cert_form__separator"></div>
        </Fragment>
      ))}
      <FileRow
        id={`additional-file-${pharmacy.id}`}
        type="additional"
        handleFileUpload={handleFileUpload}
        handleFileDelete={handleFileDelete}
      />
    </div>
  );
};

const PharmacyColumnCell = ({ pharmacy, attachments }) => {
  const pharmacy_attachments = attachments.find((a) => a.npi == pharmacy.npi);
  const { npi, pharmacy_address, status } = pharmacy || {};

  const [icon, colorClass] = pharmacy_attachments.pharmacy_license
    ? [<CheckCircle />, "color--blue"]
    : [<CheckCircleOutline />, "color--gray4"];

  return (
    <div className="flex gap--8 align-start">
      <div className={`${colorClass} flex align-center`}>{icon}</div>
      <div className="table__text__stack">
        <div className="t--title t--700 mb--4">{npi}</div>
        <div className="t--subtitle">{pharmacy_address}</div>
      </div>
    </div>
  );
};

const DocumentsColumnCell = ({ pharmacy, attachments, row, expandRow }) => {
  const attachment = attachments.find((a) => a.npi == pharmacy.npi);
  const hasDocumentsAttached = attachment.pharmacy_license || attachment.additional_documents.length;

  const totalNumOfDocs = (attachment.pharmacy_license ? 1 : 0) + attachment.additional_documents.length;
  const hasUploadedDoc = totalNumOfDocs > 0;
  const conditionalEditBtnClass = hasUploadedDoc || row.getIsExpanded() ? "button--white" : "button--blue";

  function handleClick(e) {
    e.preventDefault();
    expandRow();
  }

  function renderButton() {
    if (pharmacy.status !== "not_started") return null;

    function renderText() {
      if (row.getIsExpanded()) {
        return "Close";
      }

      if (hasDocumentsAttached) {
        return "Edit";
      }

      return "Start";
    }

    return (
      <button className={`button button--lg ${conditionalEditBtnClass}`} onClick={handleClick}>
        {renderText()}
      </button>
    );
  }

  return (
    <div className="flex justify-space-between align-center">
      <span>{totalNumOfDocs} Total</span>
      {renderButton()}
    </div>
  );
};

const InhouseCertificationsTable = ({ pharmacies, attachments, setAttachments }) => {
  const columnHelper = createColumnHelper();

  const columns = useMemo(() => {
    return [
      columnHelper.accessor("name", {
        header: <div className="pl--32 pharm_cert_form__pharmacy_table__thead_pharmacy">Pharmacy</div>,
        cell: ({ row }) => <PharmacyColumnCell pharmacy={row.original} attachments={attachments} />,
        size: 304,
      }),
      columnHelper.accessor("documents", {
        header: <div className="pharm_cert_form__pharmacy_table__thead_documents">Documents</div>,
        cell: ({ row }) => (
          <DocumentsColumnCell
            row={row}
            pharmacy={row.original}
            attachments={attachments}
            expandRow={() => row.toggleExpanded()}
          />
        ),
        size: 242,
      }),
    ];
  }, [pharmacies]);

  const table = useReactTable({
    data: pharmacies,
    columns,
    getExpandedRowModel: getExpandedRowModel(),
    getCoreRowModel: getCoreRowModel(),
  });

  const renderSubComponent = (row) => {
    return <ExpandedRow row={row} attachments={attachments} setAttachments={setAttachments} />;
  };
  return (
    <table cellPadding={0} cellSpacing={0} width={"100%"}>
      <thead>
        {table.getHeaderGroups().map((headerGroup) => (
          <tr key={headerGroup.id}>
            {headerGroup.headers.map((header) => (
              <th key={header.id} style={{ width: `${header.getSize()}px` }}>
                {flexRender(header.column.columnDef.header, header.getContext())}
              </th>
            ))}
          </tr>
        ))}
      </thead>
      <tbody>
        {table.getRowModel().rows.map((row) => {
          return (
            <Fragment key={row.id}>
              <tr key={row.id}>
                <td colSpan={2}>
                  <table cellPadding={0} cellSpacing={0} className="pharm_cert_form__pharmacy_table">
                    <tbody>
                      <tr>
                        {row.getVisibleCells().map((cell) => (
                          <td key={cell.id} className="t--sm t--title">
                            {flexRender(cell.column.columnDef.cell, cell.getContext())}
                          </td>
                        ))}
                      </tr>
                      {row.getIsExpanded() && (
                        <tr>
                          <td colSpan={row.getVisibleCells().length}>{renderSubComponent(row)}</td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </td>
              </tr>
            </Fragment>
          );
        })}
      </tbody>
    </table>
  );
};

export default InhouseCertificationsTable;
