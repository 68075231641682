import React from "react";
import CheckBox from "../../../Icons/CheckBox";
import CheckBoxOutline from "../../../Icons/CheckBoxOutline";

const RADIO_OPTIONS = [
  { label: "Yes", value: true },
  { label: "No", value: false },
];

const CheckBoxIcon = ({ isSelected, conditionalDisabledClass }) => {
  const [icon, conditionalColorClass] = isSelected ? [<CheckBox />, "color--blue"] : [<CheckBoxOutline />, ""];

  return <div className={`${conditionalColorClass} ${conditionalDisabledClass}`}>{icon}</div>;
};

const RadioOption = ({ handleSelect, option, attestation, disabled }) => {
  const isSelected = option.value === attestation.in_house;
  const conditionalDisabledClass = disabled ? "pharm_cert_modal__checkbox--disabled" : "";

  return (
    <div className="flex align-center gap--8">
      <div className="pharm_cert_modal__checkbox" onClick={() => handleSelect(option.value)}>
        <CheckBoxIcon isSelected={isSelected} conditionalDisabledClass={conditionalDisabledClass} />
      </div>
      <span className={`t--md t--title ${conditionalDisabledClass}`}>{option.label}</span>
    </div>
  );
};

const InHouseRadioGroup = ({ setAttestation, attestation, disabled = false, getConfirmation }) => {
  const clearedDataObj = {
    signed_by: null,
    pharmacies: attestation.pharmacies?.map((pharmacy) => {
      return {
        ...pharmacy,
        has_license_attachment: false,
        license_attachment_filename: null,
        has_additional_attachments: false,
        additional_attachments_filenames: [],
        status: "not_started",
      };
    }),
  };

  async function handleSelect(value) {
    if (disabled) return;

    const hasSavedData =
      attestation.signed_by ||
      attestation.in_house_certifications.some((p) => p.has_license_attachment || p.has_additional_attachments);

    if (attestation.in_house !== null && hasSavedData) {
      const isConfirmed = await getConfirmation();
      if (!isConfirmed) return;
    }

    const isSelected = value === attestation.in_house;
    const newStatusAndInHouseValues = isSelected
      ? { status: "not_started", in_house: null }
      : { status: "in_progress", in_house: value };
    const newProps =
      attestation.in_house !== null && hasSavedData
        ? { ...clearedDataObj, ...newStatusAndInHouseValues }
        : newStatusAndInHouseValues;
    setAttestation(newProps);
  }

  return (
    <>
      <div className="flex gap--24">
        {RADIO_OPTIONS.map((option) => (
          <RadioOption
            key={option.label}
            handleSelect={handleSelect}
            option={option}
            attestation={attestation}
            disabled={disabled}
          />
        ))}
      </div>
    </>
  );
};

export default InHouseRadioGroup;
