import React, { useState, useRef, useEffect } from "react";
import AsyncSelect from "react-select/async";
import debounce from "debounce-promise";
import _ from "lodash";

const customSelectStyles = {
  container: (base) => ({
    ...base,
    margin: "16px 0 8px",
  }),
  control: (base) => ({
    ...base,
    minHeight: 56,
    borderColor: "#DEE7EE",
  }),
};

const SelectEntity = (props) => {
  const loadOptions = (query) => fetchSearchTerm(query);
  const debouncedLoadOptions = debounce(loadOptions, 500, {
    leading: true,
  });

  const fetchSearchTerm = async (query) => {
    let url = new URL("/api/entities", window.location.origin);
    url.search = new URLSearchParams({ query: query, fetch_all: true }).toString();

    const results = await fetch(url)
      .then((response) => response.json())
      .then((data) => data)
      .catch((error) => {
        console.error("Error:", error);
      });

    return results;
  };

  const renderEntitySelect = () => {
    return (
      <AsyncSelect
        components={{ DropdownIndicator: null, IndicatorSeparator: null }}
        openMenuOnFocus={false}
        openMenuOnClick={false}
        defaultOptions={false}
        name="application[entity_id]"
        placeholder={"Search Covered Entity *"}
        styles={customSelectStyles}
        isClearable={true}
        loadOptions={(query) => debouncedLoadOptions(query)}
        onChange={(selection) => {
          if (selection && selection.value) {
            props.setCompletedSelectedEntity(true)
          } else {
            props.setCompletedSelectedEntity(false)
          }
        }}
      />
    )
  };

  return (
    <div>
      <div className="register__form__requirements register__form__requirements--no-mt">
        <p>
          <span className="bold">Note: </span>
          Select your Covered Entity below, you can search by 340B ID or Entity Name
        </p>
      </div>
      <div>{renderEntitySelect()}</div>
    </div>
  )

};

export default SelectEntity;
