import React, { useState, useEffect, useRef } from "react";
import Switch from "@material-ui/core/Switch";
import { withStyles } from "@material-ui/core/styles";
import Notice from "./Notice";

const CustomSwitch = withStyles((theme) => ({
  root: {
    width: 40,
    height: 20,
    padding: 0,
  },
  switchBase: {
    padding: 4,
    "&$checked": {
      transform: "translateX(20px)",
      color: theme.palette.common.white,
      "& + $track": {
        backgroundColor: "#3246D3",
        opacity: 1,
        border: "none",
      },
    },
    "&$focusVisible $thumb": {
      color: "#3246D3",
      border: "6px solid #fff",
    },
  },
  thumb: {
    width: 12,
    height: 12,
    boxShadow: "none",
  },
  track: {
    borderRadius: 20,
    backgroundColor: "#B7C0C8",
    opacity: 1,
    transition: theme.transitions.create(["background-color", "border"]),
  },
  checked: {},
  focusVisible: {},
}))(({ classes, ...props }) => {
  return (
    <Switch
      focusVisibleClassName={classes.focusVisible}
      disableRipple
      classes={{
        root: classes.root,
        switchBase: classes.switchBase,
        thumb: classes.thumb,
        track: classes.track,
        checked: classes.checked,
      }}
      {...props}
    />
  );
});

export default function SwitchForm(props) {
  const completedMount = useRef(true);
  const [state, setState] = React.useState({
    checked: props.checked,
    path: props.path,
    name: props.name,
  });
  const [notice, setNotice] = React.useState({
    kind: "error",
    open: false,
    message: "",
  });

  useEffect(() => {
    if (completedMount.current) {
      completedMount.current = false;
      return;
    }

    submitForm();
  }, [state]);

  const submitForm = () => {
    fetch(props.path, {
      method: "PUT",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "X-CSRF-Token": document.querySelector('meta[name="csrf-token"]').getAttribute("content"),
      },
      body: JSON.stringify({
        user: { [props.name]: props.inverse ? !state.checked : state.checked },
      }),
    })
      .then((res) => {
        if (res.status >= 200 && res.status <= 299) {
          return res.json();
        } else {
          throw Error(res.statusText);
        }
      })
      .then((data) => {
        setNotice({
          kind: "success",
          open: true,
          message: data.notice,
        });
      })
      .catch((error) => {
        setNotice({
          kind: "error",
          open: true,
          message: "Oh no - it looks like something went wrong, please try again.",
        });
      });
  };

  const handleChange = () => {
    setState({ ...state, checked: event.target.checked });
  };

  return (
    <div>
      <CustomSwitch checked={state.checked} onChange={handleChange} name={props.name} />
      <Notice details={notice} />
    </div>
  );
}
