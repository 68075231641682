import React, { useState } from "react";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import dataImage from "images/data_illustration.png";
import AsyncSelect from "react-select/async";
import debounce from "debounce-promise";

const CustomTextField = withStyles({
  root: {
    backgroundColor: "#ffffff",
    borderRadius: 8,
    "& label.Mui-focused": {
      color: "#3D71FF",
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: "green",
    },
    "& .MuiOutlinedInput-root": {
      borderRadius: 8,
      "& fieldset": {
        borderColor: "#DEE7EE",
      },
      "&:hover fieldset": {
        borderColor: "#DEE7EE",
      },
      "&.Mui-focused fieldset": {
        borderColor: "#3D71FF",
      },
    },
  },
})(TextField);

const customSelectStyles = {
  container: (base) => ({
    ...base,
    margin: "16px 0 8px",
  }),
  control: (base) => ({
    ...base,
    minHeight: 56,
    borderColor: "#DEE7EE",
  }),
};

const TransferOrganization = (props) => {
  const [entityRegistered, setEntityRegistered] = useState();
  const loadOptions = (query) => fetchSearchTerm(query);
  const debouncedLoadOptions = debounce(loadOptions, 800, {
    leading: true,
  });

  const fetchSearchTerm = async (query) => {
    let url = new URL("/api/entities", window.location.origin);
    url.search = new URLSearchParams({
      query: query,
      fetch_all: true,
    }).toString();

    const results = await fetch(url)
      .then((response) => response.json())
      .then((data) => data)
      .catch((error) => {
        console.error("Error:", error);
      });

    return results;
  };

  const renderInvitation = () => {
    if (entityRegistered == false) {
      return (
        <div className="notify__block" style={{ marginTop: 20, marginBottom: 20 }}>
          <div className="notify__block__message" style={{ width: "80%", marginBottom: 20 }}>
            The selected covered entity has not yet registered - send a registration invite.
          </div>

          <div>
            <div className="register__form__dual-row register__form__dual-row--pad-right">
              <CustomTextField label="First Name" margin="normal" variant="outlined" id="firstName" name="user[first_name]" autoFocus fullWidth required />
            </div>
            <div className="register__form__dual-row register__form__dual-row--pad-left">
              <CustomTextField label="Last Name" margin="normal" variant="outlined" id="lastName" name="user[last_name]" fullWidth required />
            </div>
          </div>
          <CustomTextField label="Work Email Address" margin="normal" variant="outlined" id="email" name="user[email]" fullWidth required />
        </div>
      );
    }
  };

  return (
    <form method="POST" action={props.submission_path}>
      <input type="hidden" name="authenticity_token" value={document.querySelector('meta[name="csrf-token"]').getAttribute("content")} />
      <div className="onboarding__content__form">
        <div className="onboarding__content__form__image">
          <img src={dataImage} />
        </div>
        <div className="onboarding__content__form__title">Transfer to Covered Entity</div>
        <p>The selected covered entity will receive a request to submit data on your behalf</p>

        <AsyncSelect
          components={{ DropdownIndicator: null, IndicatorSeparator: null }}
          openMenuOnFocus={false}
          openMenuOnClick={false}
          name="entity_id"
          placeholder={"Search Covered Entity *"}
          styles={customSelectStyles}
          defaultOptions={false}
          isClearable={true}
          onChange={(event) => {
            if (event) {
              setEntityRegistered(event.registered);
            } else {
              setEntityRegistered();
            }
          }}
          loadOptions={(query) => debouncedLoadOptions(query)}
        />
        {renderInvitation()}
        <div className="onboarding__content__form__actions onboarding__content__form__actions--margin-sm">
          <a href={props.return_path}>
            <div className="btn btn--outline btn--white">Cancel</div>
          </a>
          <button className={entityRegistered != undefined ? "btn" : "btn btn--disabled"} style={{ flexGrow: 1, marginLeft: 30 }} disabled={entityRegistered != undefined ? false : true} type="submit">
            Send Request
          </button>
        </div>
      </div>
    </form>
  );
};

export default TransferOrganization;
