import React, { useState, useEffect } from "react";
import verifyImage from "images/verify_account_illustration.png";

const TransferOrgModal = (props) => {
  const [displayModal, setDisplayModal] = useState(false);

  const renderModal = () => {
    if (displayModal) {
      return (
        <div className="modal__background">
          <div className="modal__body modal__body--no-side-padding" style={{ paddingTop: 30, paddingBottom: 30 }}>
            <div className="modal__body__title">Are you sure?</div>
            <div className="modal__body__subtitle" style={{ marginBottom: 30 }}>
              We need you to confirm before continuing
            </div>

            <div className="modal__body__spotlight">
              <img src={verifyImage} width={220} />
            </div>
            <div className="modal__body__container">
              <div className="modal__body__container__note__title">Confirmation need</div>
              <div className="modal__body__container__note">Would you like another 340B covered entity to submit 340B claims data on your behalf?</div>
            </div>
            <div className="modal__body__footer">
              <div
                className="btn btn--white btn--outline"
                onClick={() => {
                  setDisplayModal(false);
                }}
              >
                Cancel
              </div>
              <div
                className="btn"
                style={{ flexGrow: 1, marginLeft: 30 }}
                onClick={() => {
                  location.href = props.transferPath;
                }}
              >
                Continue
              </div>
            </div>
          </div>
        </div>
      );
    }
  };

  return (
    <div>
      <div
        className="btn btn--ib btn--outline btn--white"
        onClick={() => {
          setDisplayModal(true);
        }}
      >
        Transfer Entities
      </div>
      {renderModal()}
    </div>
  );
};
export default TransferOrgModal;
