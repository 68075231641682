import React, { useState, useEffect } from "react";
import { Refresh } from "@material-ui/icons";
import "react-datepicker/dist/react-datepicker.css";
import _ from "lodash";
import ChipSelector from "../../Draw/ChipSelector";

const ReportsDraw = (props) => {
  const [selectedEntity, setSelectedEntity] = useState(props.searchQuery.id_340b);
  const [selectedManufacturer, setSelectedManufacturer] = useState(props.searchQuery.manufacturer);

  useEffect(() => {
    props.handleSearch("id_340b", selectedEntity);
  }, [selectedEntity]);

  useEffect(() => {
    props.handleSearch("manufacturer", selectedManufacturer);
  }, [selectedManufacturer]);

  return (
    <div>
      <div className="draw__tools__container">
        <div className="btn btn--sqr">
          <Refresh fontSize="small" />
        </div>
      </div>
      <div className="draw__item">
        <div className="draw__item__title draw__item__title--mb-sm t--upcase t--sm">Covered Entity</div>
        <div className="chip__well">
          <ChipSelector
            items={props.entities}
            selected={selectedEntity}
            setSelected={setSelectedEntity}
            required={true}
          />
        </div>
      </div>
      <div className="draw__divider" />
      <div className="draw__item">
        <div className="draw__item__title draw__item__title--mb-sm t--upcase t--sm">Manufacturers</div>
        <div className="chip__well">
          <ChipSelector
            items={props.manufacturers}
            selected={selectedManufacturer}
            setSelected={setSelectedManufacturer}
          />
        </div>
      </div>
    </div>
  );
};

export default ReportsDraw;
