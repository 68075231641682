import React, { useState, useEffect, useRef, Fragment } from "react";

import { ExpandMore, GetApp, ChevronRight } from "@material-ui/icons";
import EligibilityStatusDetails from "./Entities/EligibilityStatusDetails";

import Notice from "./Notice";
import _ from "lodash";
import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  useReactTable,
  getExpandedRowModel,
} from "@tanstack/react-table";

export default function AllPharmacyTable(props) {
  const [data, setData] = React.useState(() => props.pharmacies);

  const [editing, setEditing] = React.useState(false);
  const [displayConfirmation, setDisplayConfirmation] = React.useState(false);
  const [attestingPharmacies, setAttestingPharmacies] = React.useState([]);
  const [designations, setDesignations] = React.useState(props.designations);
  const [pharmacies, setPharmacies] = React.useState(props.pharmacies);
  const [notice, setNotice] = React.useState({
    kind: "error",
    open: false,
    message: "",
  });

  const columnHelper = createColumnHelper();

  const columns = [
    columnHelper.accessor((row) => row, {
      header: "Pharmacy",
      cell: ({ row, getValue }) => {
        return (
          <>
            <div className="btn btn--sqr" style={{ marginRight: 18 }} onClick={() => row.toggleExpanded()}>
              <ExpandMore fontSize="small" />
            </div>
            <div className="table__text__stack">
              <div className="t--sm t--500">{getValue().pharmacy_name}</div>
              <div className="t--sm t--subtitle">{getValue().address}</div>
            </div>
          </>
        );
      },
    }),
    columnHelper.accessor((row) => row, {
      header: "Exceptions",
      cell: ({ row, getValue }) => {
        return renderInfoBadge(row.original.pharmacy_id);
      },
    }),
    columnHelper.accessor((row) => row.dea, {
      header: "Status",
      cell: ({ row, getValue }) => {
        return (
          <div className="badge">
            <div className="badge__indicator badge__indicator--orange" />
            <div className="badge__item badge__item--t-lg">{countAtRisk(row)}</div>
            <div className="badge__indicator badge__indicator--green" />
            <div className="badge__item badge__item--t-lg">{countEligible(row)}</div>
            <div className="badge__indicator badge__indicator--red" />
            <div className="badge__item badge__item--t-lg badge__item--last">{countIneligible(row)}</div>
          </div>
        );
      },
    }),
    columnHelper.accessor((row) => row.claims_count, {
      header: "Conforming Claims",
      cell: ({ row, getValue }) => (
        <div className="t--500">
          {props.claims_count[row.original.pharmacy_id] ? props.claims_count[row.original.pharmacy_id] : "0"}
        </div>
      ),
    }),
    columnHelper.accessor((row) => row.health_industry_number, {
      header: "HIN",
    }),
    columnHelper.accessor((row) => row.dea, {
      header: "DEA",
    }),
  ];

  const table = useReactTable({
    data,
    columns,
    getExpandedRowModel: getExpandedRowModel(),
    getCoreRowModel: getCoreRowModel(),
  });

  const renderInfoBadge = (pharmacy_id) => {
    var des = props.mfr_designations[pharmacy_id];

    if (des) {
      return (
        <div className="badge">
          <div className="badge__indicator" />
          EXCEPTIONS
        </div>
      );
    } else {
      return "";
    }
  };

  const renderSubComponent = (row) => {
    return (
      <EligibilityStatusDetails row={row} eligibility={props.eligibility} mfr_designations={props.mfr_designations} />
    );
  };

  const countEligible = (row) => {
    let count = 0;

    _.each(props.eligibility, (val, key) => {
      _.each(val[row.original.pharmacy_id].networks, (network) => {
        if (
          val[row.original.pharmacy_id][network] == "ELIGIBLE" ||
          val[row.original.pharmacy_id][network] == "IN_PROCESS"
        ) {
          count += 1;
        }
      });
    });

    return count;
  };

  const countIneligible = (row) => {
    let count = 0;

    _.each(props.eligibility, (val, key) => {
      _.each(val[row.original.pharmacy_id].networks, (network) => {
        if (
          val[row.original.pharmacy_id][network] == "INELIGIBLE" ||
          val[row.original.pharmacy_id][network] == "INELIGIBLE_MISSING_CLAIMS"
        ) {
          count += 1;
        }
      });
    });

    return count;
  };

  const countAtRisk = (row) => {
    let count = 0;

    _.each(props.eligibility, (val, key) => {
      _.each(val[row.original.pharmacy_id].networks, (network) => {
        if (val[row.original.pharmacy_id][network] == "AT_RISK") {
          count += 1;
        }
      });
    });

    return count;
  };

  const renderRows = () => {
    return table.getRowModel().rows.map((row) => {
      return (
        <Fragment key={row.id}>
          <tr className="sm">
            {row.getVisibleCells().map((cell) => (
              <td key={cell.id} className="t--md">
                {flexRender(cell.column.columnDef.cell, cell.getContext())}
              </td>
            ))}
          </tr>
          {row.getIsExpanded() && (
            <tr>
              <td colSpan={row.getVisibleCells().length}>{renderSubComponent(row)}</td>
            </tr>
          )}
        </Fragment>
      );
    });
  };

  return (
    <div>
      <div className="table__actionbar table__actionbar--spacebetween">
        <div className="section__title">Contracted Pharmacies</div>
      </div>
      <table className="primary">
        <thead>
          {table.getHeaderGroups().map((headerGroup) => (
            <tr key={headerGroup.id} className="sm">
              {headerGroup.headers.map((header) => (
                <th key={header.id}>
                  {header.isPlaceholder ? null : flexRender(header.column.columnDef.header, header.getContext())}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody>{renderRows()}</tbody>
      </table>
    </div>
  );
}
