import React from "react";

const CheckBoxOutline = ({ size = "medium" }) => {
  const sizes = {
    large: { height: 24, width: 24 },
    medium: { height: 20, width: 20 },
    small: { height: 16, width: 16 },
  };

  return (
    <svg style={sizes[size]} xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
      <path
        d="M200-120q-33 0-56.5-23.5T120-200v-560q0-33 23.5-56.5T200-840h560q33 0 56.5 23.5T840-760v560q0 33-23.5 56.5T760-120H200Zm0-80h560v-560H200v560Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default CheckBoxOutline;
