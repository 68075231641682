import React from "react";

const GrantTpaAccessInfo = (props) => {
  return (
    <div
      className="card__item card__item--small card__item--light"
      style={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
      }}
    >
      <div>
        <div className="card__item__label">{"Access granted to: "}</div>
        <div className="card__item__value" style={{ marginLeft: 10 }}>
          {props.name}
        </div>
      </div>
      <div
        className="btn btn--sq btn--white btn--ib"
        onClick={() => {
          props.handleDelete(props.access_key);
        }}
      >
        <div className="solid solid-budicon-trash"></div>
      </div>
    </div>
  );
};

export default GrantTpaAccessInfo;
