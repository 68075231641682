import React from "react";
import { ResponsiveLine } from "@nivo/line";

const MyResponsiveLine = (props) => (
  <div style={{ height: 258, backgroundColor: "#ffffff", borderRadius: 8 }}>
    <ResponsiveLine
      data={props.data}
      margin={{ top: 20, right: 20, bottom: 50, left: 80 }}
      xScale={{ type: "point" }}
      yScale={{
        type: "linear",
        min: "auto",
        max: "auto",
        stacked: true,
        reverse: false,
      }}
      curve="natural"
      axisBottom={{
        orient: "bottom",
        tickSize: 5,
        tickPadding: 5,
        tickRotation: 0,
      }}
      axisLeft={{
        orient: "left",
        tickSize: 5,
        tickValues: 5,
        tickPadding: 5,
        tickRotation: 0,
      }}
      enableGridX={false}
      colors={["#3D71FF"]}
      pointSize={8}
      pointColor={{ theme: "background" }}
      pointBorderWidth={2}
      pointBorderColor={{ from: "serieColor" }}
      pointLabel="y"
      pointLabelYOffset={-12}
      useMesh={true}
    />
  </div>
);

export default MyResponsiveLine;
