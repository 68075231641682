import React, { useMemo } from "react";
import { createColumnHelper, getCoreRowModel, useReactTable, flexRender } from "@tanstack/react-table";
import CheckCircle from "../../../Icons/CheckCircle";
import CheckCircleOutline from "../../../Icons/CheckCircleOutline";
import classnames from "classnames";

const PharmacyColumnCell = ({ pharmacy }) => {
  const { npi, pharmacy_address, status } = pharmacy || {};

  const [icon, colorClass] =
    status !== "not_started" ? [<CheckCircle />, "color--blue"] : [<CheckCircleOutline />, "color--gray4"];

  return (
    <div className="flex gap--8">
      <div className={`${colorClass} flex align-center`}>{icon}</div>
      <div className="table__text__stack">
        <div className="t--title t--700 mb--4">{npi}</div>
        <div className="t--subtitle">{pharmacy_address}</div>
      </div>
    </div>
  );
};

const StatusesColumnCell = ({ pharmacy, showUploadPage }) => {
  const { status, npi } = pharmacy || {};

  if (status === "not_started") {
    return (
      <button className={"btn btn--lg flex align-center"} onClick={() => showUploadPage(npi)}>
        Start
      </button>
    )
  }

  const statusText = (status) => {
    const displayObject = {
      text: '',
      variant: ''
    }
    switch (status) {
      case "complete":
        displayObject.text = "Not In-House"
        displayObject.variant = "blue"
        break;
      case "denied":
        displayObject.text = "In-House"
        displayObject.variant = "orange"
        break;
      default: 
        displayObject.text = "Under Review"
        displayObject.variant = "bright-purple"
    }

    return displayObject
  }

  const object = statusText(status)

  const badgeClasses = classnames({
    'badge__indicator': true,
    [`badge__indicator--${object.variant}`]: true,
  });

  return (
    <div className="flex justify-space-between align-center">
      <div className="badge">
        <div className={badgeClasses} />
        {object.text}
      </div>
    </div>
  )
}

const DocumentsColumnCell = ({ pharmacy, showUploadPage }) => {
  const { has_license_attachment, additional_attachments_filenames } = pharmacy || {};
  const documentCount = (has_license_attachment ? 1 : 0) + additional_attachments_filenames.length;

  return (
    <div className="flex justify-space-between align-center">
      <span>{documentCount} Total</span>
    </div>
  );
};

const PharmacyList = ({ pharmacies, showUploadPage }) => {
  const columnHelper = createColumnHelper();

  const columns = useMemo(() => {
    return [
      columnHelper.accessor("name", {
        header: <div className="pl--32">Pharmacy</div>,
        cell: ({ row }) => <PharmacyColumnCell pharmacy={row.original} />,
        size: 304,
      }),
      columnHelper.accessor("documents", {
        header: "Documents",
        cell: ({ row }) => <DocumentsColumnCell pharmacy={row.original}  />,
        size: 305
      }),
      columnHelper.accessor("status", {
        header: "Status",
        cell: ({ row }) => <StatusesColumnCell pharmacy={row.original} showUploadPage={showUploadPage} />,
        size: 242,
      }),
    ];
  }, [pharmacies]);

  const table = useReactTable({
    data: pharmacies,
    columns,
    getCoreRowModel: getCoreRowModel(),
  });

  return (
    <>
      <div className="t--700 t--title mb--8">Provide the Pharmacy License</div>
      <div className="t--md t--title mb--16">
        Please provide a pharmacy license for each listed pharmacy. You can save your progress along the 
        way as you attach and upload the requested documentation for each NPI.  Should you need to revisit 
        this page later, the documentation uploaded will be saved. Once documentation is uploaded for each 
        listed pharmacy, click Continue. The review status of the provided documentation will not be made available 
        until the requested documentation for all NPIs under this covered entity are submitted. Need to change or 
        make an update to a document submission? Email <span className="t--blue"><a href="mailto:support@340besp.com">support@340besp.com</a></span>.
      </div>
      <table className="primary pharm_cert_modal__table">
        <thead>
          {table.getHeaderGroups().map((headerGroup) => (
            <tr key={headerGroup.id}>
              {headerGroup.headers.map((header) => (
                <th key={header.id} style={{ width: `${header.getSize()}px` }}>
                  {flexRender(header.column.columnDef.header, header.getContext())}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody>
          {table.getRowModel().rows.map((row) => (
            <tr key={row.id}>
              {row.getVisibleCells().map((cell) => (
                <td key={cell.id} className="t--sm t--title">
                  {flexRender(cell.column.columnDef.cell, cell.getContext())}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </>
  );
};

export default PharmacyList;
