import React from "react";
import { ResponsiveBar } from "@nivo/bar";

const SubmissionsChart = (props) => (
  <ResponsiveBar
    data={props.data}
    groupMode="stacked"
    keys={["non_conforming", "conforming"]}
    indexBy="timestamp"
    margin={{ top: 32, right: 8, bottom: 32, left: 8 }}
    padding={0.5}
    layout={"vertical"}
    colors={["#FF681F", "#3246D3"]}
    borderColor={{
      from: "color",
      modifiers: [["darker", 1.6]],
    }}
    axisTop={null}
    axisRight={null}
    axisBottom={{
      orient: "bottom",
      tickSize: 0,
      tickPadding: 9,
      tickRotation: 0,
    }}
    axisLeft={null}
    enableGridY={false}
    enableLabel={false}
    tooltip={(bar) => {
      return (
        <div
          style={{
            padding: 2,
            color: "#ffffff",
            background: "#222222",
          }}
        >
          <strong>
            {bar.id}: {bar.value}
          </strong>
        </div>
      );
    }}
  />
);

export default SubmissionsChart;
