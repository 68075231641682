var descriptions = [
  {
    title: "Date of Service",
    description: "Date on which the patient filled their prescription.",
    kind: "error",
    display: false,
  },
  {
    title: "Date Prescribed",
    description: "Date the prescriber wrote the prescription.",
    kind: "error",
    display: false,
  },
  {
    title: "Rx Number",
    description: "The native (unmodified) prescription number for the prescription as generated by the pharmacy.",
    kind: "error",
    display: false,
  },
  {
    title: "NDC",
    description:
      "The 11-digit National Drug Code which indicates the manufacturer, product, and the commercial package size.",
    kind: "error",
    display: false,
  },
  {
    title: "Quantity",
    description: "The number of units in the prescription.",
    kind: "error",
    display: false,
  },
  {
    title: "Service Provider ID",
    description:
      "A unique, public ID for the dispensing pharmacy. Accepted IDs include the NPI, DEA, NCPDP, and Medicaid ID.",
    kind: "error",
    display: false,
  },
  {
    title: "Contracted Entity ID",
    description:
      "The 340B ID of the covered entity where the prescription originated. This is usually the 340B ID of the covered entity under which you registered.",
    kind: "error",
    display: false,
  },
  {
    title: "Prescriber ID",
    description: "A unique, public ID for the prescribing physician. Accepted IDs include the NPI and DEA ID.",
    // TODO: should the kind be set to 'error' on prescriber_id, prescriber_id_qualifier, service_provider_id_qualifier if the existing code has them set to warnings?
    kind: "warning",
    display: false,
  },
  {
    title: "Prescriber ID Qualifier",
    description: "Indicates the type of unique ID provided. A value of “01” indicates NPI, “12” indicates DEA.",
    kind: "warning",
    display: false,
  },
  {
    title: "Service Provider ID Qualifier",
    description:
      "Indicates the type of unique ID provider. “01” for NPI, “05” for Medicaid”, “07” for NCPDP, and “12” for DEA.",
    kind: "warning",
    display: false,
  },
  {
    title: "Wholesaler Invoice Number",
    description:
      "The invoice number assigned by the wholesaler for the replenishment order made by the 340B covered entity.",
    kind: "error",
    display: false,
  },
  {
    title: "Product Serialization Number",
    description: "Unique ID assigned to the package shipped from the manufacturer to the wholesaler.",
    kind: "error",
    display: false,
  },
  {
    title: "340B Account",
    description: "Account number assigned by the wholesaler and used for the purchase.",
    kind: "error",
    display: false,
  },
  {
    title: "Ship to Date",
    description: "Date when the drug was shipped to the Ship To location.",
    kind: "error",
    display: false,
  },
  {
    title: "Ship to Location",
    description: "NPI, DEA, or NCPDP of the pharmacy where the drug was physically shipped.",
    kind: "error",
    display: false,
  },
  {
    title: "Payer PCN",
    description:
      "Processor Control Number. Identifier used to determine which processor will handle a prescription drug claim.",
    kind: "error",
    display: false,
  },
  {
    title: "Payer BIN",
    description: "The bank identification number of the primary payer on the prescription.",
    kind: "error",
    display: false,
  },
  {
    title: "Fill Number",
    description:
      "Indicates the number of times the prescription has been filled as of the current fill. For example, a value of 2 indicates that the prescription has been filled twice and the current fill is the second one.",
    kind: "error",
    display: false,
  },
];

var warnings = [
  {
    title: "Date of Service",
    name: "date_of_service",
    description: "This is just a warning - make sure you are happy with this mapping",
    kind: "warning",
    display: false,
  },
  {
    title: "Date Prescribed",
    name: "date_prescribed",
    description: "This is just a warning - make sure you are happy with this mapping",
    kind: "warning",
    display: false,
  },
  {
    title: "Rx Number",
    name: "rx_number",
    description: "This is just a warning - make sure you are happy with this mapping",
    kind: "warning",
    display: false,
  },
  {
    title: "NDC",
    name: "ndc",
    description: "This is just a warning - make sure you are happy with this mapping",
    kind: "warning",
    display: false,
  },
  {
    title: "Quantity",
    name: "quantity",
    description: "This is just a warning - make sure you are happy with this mapping",
    kind: "warning",
    display: false,
  },
  {
    title: "Service Provider ID",
    name: "service_provider_id",
    description: "This is just a warning - make sure you are happy with this mapping",
    kind: "warning",
    display: false,
  },
  {
    title: "Contracted Entity ID",
    name: "contracted_entity_id",
    description: "This is just a warning - make sure you are happy with this mapping",
    kind: "warning",
    display: false,
  },
  {
    title: "Prescriber ID",
    name: "prescriber_id",
    description: "This is just a warning - make sure you are happy with this mapping",
    kind: "warning",
    display: false,
  },
  {
    title: "Prescriber ID Qualifier",
    name: "prescriber_id_qualifier",
    description: "This is just a warning - make sure you are happy with this mapping",
    kind: "warning",
    display: false,
  },
  {
    title: "Service Provider ID Qualifier",
    name: "service_provider_id_qualifier",
    description: "This is just a warning - make sure you are happy with this mapping",
    kind: "warning",
    display: false,
  },
  {
    title: "Wholesaler Invoice Number",
    name: "wholesaler_invoice_number",
    description: "This is just a warning - make sure you are happy with this mapping",
    kind: "warning",
    display: false,
  },
];

export const validationDescriptions = () => {
  return descriptions;
};

export const mappingWarnings = () => {
  return warnings;
};
