import React, { useRef, useEffect, useState } from "react";
import _ from "lodash";
import debounce from "debounce-promise";
import moment from "moment";
import Draw from "./ClaimDetailsDraw";
import ClaimDetailsTable from "./ClaimsDetailsTable";
import { wholeNumberWithCommas } from "../../utils/NumberFormatter";
import Notice from "../../Notice";
import Http from "../http";

// import RightArrow from "../../../public/placeholders/icon-arrow-right.svg";

const ReportsIndex = (props) => {
  const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
  const currentMonth = new Date().getMonth();
  const selectMonth = currentMonth == 0 ? 11 : currentMonth - 1;
  const month = monthNames[selectMonth];
  const [notice, setNotice] = React.useState({
    kind: "error",
    open: false,
    message: "",
  });

  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const entityID = urlParams.get("id");

  const [filterQuery, setFilterQuery] = useState({
    product_name: null,
    status: null,
  });
  const [searchQuery, setSearchQuery] = useState({
    id_340b: entityID ? entityID : props.entities[0].value,
    manufacturer: props.manufacturers[0].value,
    date_range: { start: moment().startOf("month").format("YYYY-MM-DD"), end: moment().format("YYYY-MM-DD") },
  });
  const [claims, setClaims] = useState([]);
  const [isClaimsLoading, setIsClaimsLoading] = useState(false);

  const isMounted = useRef(false);

  useEffect(() => {
    // debouncedFetchHandler();
    fetch();
  }, [searchQuery]);

  useEffect(() => {
    if ((isMounted.current && !claims) || (isMounted.current && claims.length == 0)) {
      setNotice({
        kind: "success",
        open: true,
        message: "The current selection and filters returned no results",
      });
    }

    isMounted.current = true;
  }, [claims]);

  const fetch = async () => {
    setIsClaimsLoading(true);
    await Http.fetchQuery(props.query_path, searchQuery).then((res) => {
      setClaims(res.claims);
    });
    setIsClaimsLoading(false);
  };

  const debouncedFetchHandler = debounce(fetch, 300);

  const handleFilter = (name, value) => {
    setFilterQuery({ ...filterQuery, [name]: value });
  };

  const handleSearch = (name, value) => {
    setSearchQuery({ ...searchQuery, [name]: value });
  };

  const getFilters = () => {
    var filters = {};

    _.each(filterQuery, (value, key) => {
      if (value && value.length) _.assign(filters, { [key]: value });
    });

    return filters;
  };

  const filteredClaims = () => {
    return _.filter(claims, getFilters());
  };

  const renderAvgChart = () => {
    return _.map(props.claim_details.monthly_non_conforming_claims, (mth) => {
      return (
        <div className="progress_bar__wrapper" style={{ margin: "8px 0" }} key={mth.month}>
          <div className="progress_bar__container progress_bar__container--t-fixed">
            <div className="progress_bar__fill progress_bar__fill--blue" style={{ width: `${mth.total}%` }} />
          </div>
          <div className="progress_bar__value">{mth.month}</div>
        </div>
      );
    });
  };

  return (
    <div style={{ display: "flex" }}>
      <div className="content__container">
        <div className="page-details__container">
          <div className="page-details__container__info">
            <div className="page-details__title">Claims Details</div>
            <div className="breadcrumbs__container">
              <div className="breadcrumbs__crumb breadcrumbs__crumb__active">Reports</div>
            </div>
          </div>
          <div className="reports__grid" style={{ marginTop: 20 }}>
            <div className="div1">
              <div className="tile__well" style={{ height: "100%" }}>
                <div className="tile">
                  <div className="tile__header tile__header--spread">
                    <div className="t--md">Conforming Claims</div>
                  </div>
                  <div className="flex__container flex__container--align-center">
                    <div className="t-500 t--xl">
                      {wholeNumberWithCommas(_.sumBy(filteredClaims(), (c) => c.status == "CONFORMING"))}
                    </div>
                    <div className="t--sm ml-2">Average</div>
                  </div>
                </div>
              </div>
            </div>
            <div className="div2">
              <div className="tile__well" style={{ height: "100%" }}>
                <div className="tile">
                  <div className="tile__header tile__header--spread">
                    <div className="t--md">% Conforming Claims</div>
                  </div>
                  <div className="flex__container flex__container--align-center">
                    <div className="t-500 t--xl">
                      {`${
                        filteredClaims()?.length > 0
                          ? _.round(
                              (_.sumBy(filteredClaims(), (c) => c.status == "CONFORMING") /
                                (_.sumBy(filteredClaims(), (c) => c.status == "CONFORMING") +
                                  _.sumBy(filteredClaims(), (c) => c.status != "CONFORMING"))) *
                                100,
                              1
                            )
                          : 0
                      }%`}
                    </div>
                    <div className="t--sm ml-2">Average</div>
                  </div>
                </div>
              </div>
            </div>
            <div className="div3">
              <div className="tile__well" style={{ height: "100%" }}>
                <div className="tile">
                  <div className="tile__header tile__header--spread">
                    <div className="t--md">Non Conforming Claims</div>
                  </div>
                  <div className="flex__container flex__container--align-center">
                    <div className="t-500 t--xl">
                      {wholeNumberWithCommas(_.sumBy(filteredClaims(), (c) => c.status != "CONFORMING"))}
                    </div>
                    <div className="t--sm ml-2">This Month</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <ClaimDetailsTable claims={filteredClaims()} isLoading={isClaimsLoading} />
      </div>
      <div className="draw__container">
        <Draw
          entities={props.entities}
          setSearchQuery={setSearchQuery}
          searchQuery={searchQuery}
          handleSearch={handleSearch}
          handleFilter={handleFilter}
          manufacturers={props.manufacturers}
          products={props.products}
        />
      </div>
      <Notice details={notice} />
    </div>
  );
};

export default ReportsIndex;
