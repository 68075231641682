import React from "react";
import { ResponsiveBar } from "@nivo/bar";

const NonConformingClaimsChart = (props) => (
  <ResponsiveBar
    data={props.data}
    keys={["total"]}
    indexBy="month"
    margin={{ bottom: 25 }}
    padding={0.5}
    layout={"vertical"}
    colors={(item) => {
      if (item.index === 2) {
        return "#3246D3";
      } else {
        return "#dee5eb";
      }
    }}
    borderRadius={4}
    borderColor={{
      from: "color",
      modifiers: [["darker", 1.6]],
    }}
    axisTop={null}
    axisRight={null}
    axisBottom={{
      format: (v) => v.slice(0, 3),
      orient: "bottom",
      tickSize: 0,
      tickPadding: 9,
      tickRotation: 0,
    }}
    axisLeft={null}
    enableGridY={false}
    enableLabel={false}
    labelSkipHeight={12}
    labelTextColor={{
      from: "color",
      modifiers: [["darker", 1.6]],
    }}
  />
);

export default NonConformingClaimsChart;
