import React, { useState } from "react";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import dataImage from "images/data_illustration.png";
import AsyncSelect from "react-select/async";
import debounce from "debounce-promise";

const customSelectStyles = {
  container: (base) => ({
    ...base,
    margin: "16px 0 8px",
  }),
  control: (base) => ({
    ...base,
    minHeight: 56,
    borderColor: "#DEE7EE",
  }),
};

const RegisterEntity = (props) => {
  const loadOptions = (query) => fetchSearchTerm(query);
  const [selectedEntityID, setSelectedEntityID] = useState();

  const debouncedLoadOptions = debounce(loadOptions, 800, {
    leading: true,
  });

  const fetchSearchTerm = async (query) => {
    let url = new URL("/api/entities", window.location.origin);
    url.search = new URLSearchParams({
      query: query,
    }).toString();

    const results = await fetch(url)
      .then((response) => response.json())
      .then((data) => data)
      .catch((error) => {
        console.error("Error:", error);
      });

    return results;
  };

  return (
    <form method="POST" action={props.submission_path}>
      <input type="hidden" name="authenticity_token" value={document.querySelector('meta[name="csrf-token"]').getAttribute("content")} />
      <input type="hidden" name="entity_id" value={selectedEntityID} />
      <div className="onboarding__content__form">
        <div className="onboarding__content__form__image">
          <img src={dataImage} />
        </div>
        <div className="onboarding__content__form__title">Add a Covered Entity</div>
        <p>Seclect a covered entity to be added to your account</p>

        <AsyncSelect
          components={{ DropdownIndicator: null, IndicatorSeparator: null }}
          openMenuOnFocus={false}
          openMenuOnClick={false}
          name="entity_id"
          placeholder={"Search Covered Entity *"}
          styles={customSelectStyles}
          defaultOptions={false}
          isClearable={true}
          onChange={(event) => {
            if (event) {
              setSelectedEntityID(event.value);
            } else {
              setSelectedEntityID();
            }
          }}
          loadOptions={(query) => debouncedLoadOptions(query)}
        />

        <div className="onboarding__content__form__actions onboarding__content__form__actions--margin-sm">
          <a href={props.return_path}>
            <div className="btn btn--outline btn--white">Cancel</div>
          </a>
          <button className={"btn"} style={{ flexGrow: 1, marginLeft: 30 }} type="submit">
            {"Add entity"}
          </button>
        </div>
      </div>
    </form>
  );
};

export default RegisterEntity;
