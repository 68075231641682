import React, { useRef, useState, useEffect } from "react";
import _ from "lodash";
import XLSX from "xlsx";
import { SHA3 } from "sha3";
import Notice from "../Notice";

const TransformDataFiles = (props) => {
  const [notice, setNotice] = React.useState({
    kind: "error",
    open: false,
    message: "",
  });

  const hash = new SHA3(256);
  var salt = useRef(null);
  var workbookSample = XLSX.utils.book_new();
  var requiredShape = JSON.parse(props.requiredShape);
  var fieldMapping = JSON.parse(props.fieldMapping);

  var csvRows = [_.values(fieldMapping)];

  const workbookSheet = XLSX.utils.aoa_to_sheet([_.keys(requiredShape)]);

  useEffect(() => {
    fetchVault();
  }, []);

  const fetchVault = () => {
    if (salt.current) return;

    fetch(`/api/organization/${props.organization}/vault`, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "X-CSRF-Token": document
          .querySelector('meta[name="csrf-token"]')
          .getAttribute("content"),
      },
    })
      .then((res) => res.json())
      .then((data) => {
        salt.current = data.value;
      })
      .catch((error) => {
        setNotice({
          kind: "error",
          open: true,
          message:
            "Oh no - it looks like something went wrong, please try again.",
        });
      });
  };

  const handleFiles = async (file) => {
    var reader = new FileReader();

    reader.onload = function (e) {
      const fileType = file.name.split(".").pop();

      // If file type is txt force pipe as seperator
      const data = csvToJson(e.target.result, fileType === "txt" ? "|" : ",");

      downloadFile(filterData(data), "SecondSightSolutions.csv");
    };

    reader.readAsBinaryString(file);
  };

  const csvToJson = (file, delimiter) => {
    var lines = file.split("\n");
    var result = [];

    var headers = lines[0].split(delimiter);

    for (var i = 1; i < lines.length; i++) {
      var obj = {};
      var currentline = lines[i].split(delimiter);

      for (var j = 0; j < headers.length; j++) {
        obj[headers[j]] = currentline[j];
      }

      result.push(obj);
    }

    return result;
  };

  const downloadFile = (data, fileName) => {
    var csvData = data.join("\r\n");
    var blob = new Blob([csvData], {
      type: "application/csv;charset=utf-8;",
    });

    if (window.navigator.msSaveBlob) {
      // FOR IE BROWSER
      navigator.msSaveBlob(blob, fileName);
    } else {
      // FOR OTHER BROWSERS
      var link = document.createElement("a");
      var csvUrl = URL.createObjectURL(blob);
      link.href = csvUrl;
      link.style = "visibility:hidden";
      link.download = fileName;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }

    // reset for new files
    csvRows = [_.values(fieldMapping)];
    document.getElementById("dataFileAttachment").value = "";
  };

  const filterData = (data) => {
    console.log("starting data");
    var schemaContent = csvRows;
    for (let i = 0, l = data.length; i < l; i++) {
      // const schema = _.cloneDeep(requiredShape);
      const schema = Object.assign({}, requiredShape);

      if (i % 100000 == 0) {
        console.log(i);
      }

      // TRY MUTATING DATA OBJECT INSTEAD
      _.map(requiredShape, (value, key) => {
        schema[key] = formatValue(key, data[i][fieldMapping[key]]);
      });

      schemaContent.push(_.values(schema));
    }

    return schemaContent;
  };

  const formatValue = (schemaColumnName, value) => {
    if (["date_of_service", "date_prescribed"].includes(schemaColumnName)) {
      value = formatDate(value);
    }

    if (
      ["date_of_service", "date_prescribed", "rx_number"].includes(
        schemaColumnName
      )
    ) {
      return hashValue(value);
    } else {
      return value;
    }
  };

  const hashValue = (value) => {
    return generateSecureHash(value);
  };

  const formatDate = (value) => {
    // return early if not a date
    if (isNaN(Date.parse(value))) return;

    var date = new Date(value);

    return date.toISOString().split("T")[0];
  };

  const generateSecureHash = (value) => {
    hash.reset();

    if (!value) return;
    const saltedValue = value + salt.current;

    hash.update(saltedValue);

    return hash.digest("hex");
  };

  const downloadWorkbook = async (e) => {
    const files = e.target.files;

    handleFiles(files[0]);
  };

  return (
    <div>
      <Notice details={notice} />
      <input
        type="file"
        style={{ display: "none" }}
        className="form-control"
        id="dataFileAttachment"
        onChange={downloadWorkbook}
      />

      <div
        className={"btn"}
        style={{ marginTop: 30, marginBottom: 30 }}
        onClick={() => {
          document.getElementById("dataFileAttachment").click();
        }}
      >
        Attach Data File
      </div>
    </div>
  );
};

export default TransformDataFiles;
