import React, { useState, useEffect, Fragment } from "react";
import { PieChart } from "react-minimal-pie-chart";
import { ExpandMore, GetApp, ChevronRight } from "@material-ui/icons";
import _ from "lodash";

export default function EligibilityStatusDetails(props) {
  const renderEligibleBadge = (status) => {
    return (
      <div className="badge">
        <div className="badge__indicator badge__indicator--green" />
        {status}
      </div>
    );
  };

  const renderAtRiskBadge = (status) => {
    return (
      <div className="badge">
        <div className="badge__indicator badge__indicator--orange" />
        {status}
      </div>
    );
  };

  const renderIneligibleBadge = (status) => {
    return (
      <div className="badge">
        <div className="badge__indicator badge__indicator--red" />
        {status}
      </div>
    );
  };

  const renderStatusBadge = (status) => {
    switch (status) {
      case "ELIGIBLE":
        return renderEligibleBadge(status);
      case "IN_PROCESS":
        return renderEligibleBadge(status);
      case "AT_RISK":
        return renderAtRiskBadge(status);
      case "INELIGIBLE_MISSING_CLAIMS":
        return renderIneligibleBadge(status);
      case "INELIGIBLE":
        return renderIneligibleBadge(status);
    }
  };

  const renderInfoBadge = (mfr, network, pharmacy_id) => {
    var des = props.mfr_designations[pharmacy_id];

    if (des) {
      var mfr = _.find(des, (d) => d.manufacturer == mfr && d.kind == network);
      if (!mfr) {
        return;
      }

      if (["has_wholly_owned_exemption", "exception"].includes(mfr.flag)) {
        return (
          <div className="badge">
            <div className="badge__indicator" />
            EXEMPTION
          </div>
        );
      } else {
        return (
          <div className="badge">
            <div className="badge__indicator" />
            DESIGNATION
          </div>
        );
      }
    }
  };

  const renderStatus = () => {
    return _.map(props.eligibility, (val, key) => {
      return _.map(val[props.row.original.pharmacy_id].networks, (network) => {
        return (
          <tr className="tny">
            <td className="t--md">{key}</td>
            <td className="t--md">{network}</td>
            <td className="t--md">{renderStatusBadge(val[props.row.original.pharmacy_id][network])}</td>
            <td className="t--md">{renderInfoBadge(key, network, props.row.original.pharmacy_id)}</td>
          </tr>
        );
      });
    });
  };

  return (
    <table className="secondary" style={{ textAlign: "left" }}>
      <thead>
        <tr className="sm">
          <th className="rnd-l">Manufacturer</th>
          <th>Category</th>
          <th>Status</th>
          <th className="rnd-r">Exception</th>
        </tr>
      </thead>
      <tbody>{renderStatus()}</tbody>
    </table>
  );
}
