import React from "react";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";
import { findRenderedComponentWithType } from "react-dom/test-utils";
import { forEach } from "lodash";
import GrantTpaAccessInfo from "./GrantTpaAccessInfo";

const CustomTextField = withStyles({
  root: {
    backgroundColor: "transparent",
    borderRadius: 8,
    "& label.Mui-focused": {
      color: "#3D71FF",
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: "green",
    },
    "& .MuiOutlinedInput-root": {
      borderRadius: 8,
      backgroundColor: "#ffffff",
      "& fieldset": {
        borderColor: "#DEE7EE",
      },
      "&:hover fieldset": {
        borderColor: "#DEE7EE",
      },
      "&.Mui-focused fieldset": {
        borderColor: "#3D71FF",
      },
    },
  },
})(TextField);

const GrantTpaAccessForm = (props) => {
  const [state, setState] = React.useState({
    edit: false,
    allowSave: true,
  });

  const handleChange = (event) => {
    setState({
      ...state,
      allowSave: true,
      token: event.target.value,
    });
  };

  const handleSave = () => {
    props.submit(props.entity.id_340b, state.token);
    setState({ ...state, edit: false, token: null });
  };

  const handleDelete = (access_key) => {
    props.delete(props.entity.id_340b, access_key);
  };

  const handleEdit = () => {
    setState({ ...state, edit: true });
  };

  const handleCancel = () => {
    setState({ ...state, edit: false });
  };

  const renderAddBtn = () => {
    if (!state.edit) {
      return (
        <div className="btn btn--ib btn--small btn--grey" onClick={handleEdit}>
          Add TPA
        </div>
      );
    }
  };

  const renderForm = () => {
    if (state.edit) {
      return (
        <>
          <CustomTextField
            label={props.entity.id_340b + " Link Token"}
            margin="none"
            fullWidth
            variant="outlined"
            onChange={handleChange}
          />

          <div style={{ display: "flex", justifyContent: "flex-end" }}>
            <div
              className={"btn btn--small btn--blue btn--ib"}
              style={{
                alignSelf: "center",
                marginLeft: 30,
              }}
              onClick={handleSave}
            >
              Save
            </div>
            <div
              className={"btn btn--small btn--navy btn--ib"}
              style={{
                alignSelf: "center",
                marginLeft: 10,
              }}
              onClick={handleCancel}
            >
              Cancel
            </div>
          </div>
        </>
      );
    }
  };

  const renderInfo = () => {
    return props.tpas.map((tpa) => {
      return (
        <GrantTpaAccessInfo
          key={tpa.access_key}
          name={tpa.company_name}
          access_key={tpa.access_key}
          handleDelete={handleDelete}
        />
      );
    });
  };

  return (
    <>
      <div className="flex__container flex__container--space-between flex__container--align-end">
        <div className="card__details__section__title">Grant TPA Access</div>
      </div>
      {renderInfo()}
      <div className="flex__container flex__container--end" style={{ marginTop: 20 }}>
        {renderAddBtn()}
        {renderForm()}
      </div>
    </>
  );
};

export default GrantTpaAccessForm;
