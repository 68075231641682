import React, { useRef, useState, useEffect } from "react";

const Errors = (props) => {
  const renderWarning = (warning, index) => {
    return (
      <div key={index} className="notify__error notify__error--warning animated fadeInUp">
        <div className="notify__error__col">
          <div className="notify__error__error-title notify__error__error-title--warning">Warning</div>
        </div>

        <div className="notify__error__col notify__error__col--spread">
          <div className="notify__error__description-title">{warning.title}</div>
          <div className="notify__error__description">{warning.description}</div>
        </div>
      </div>
    );
  };

  const renderError = (error, index) => {
    return (
      <div key={index} className="notify__error animated fadeInUp">
        <div className="notify__error__col">
          <div className="notify__error__error-title">Validation Error</div>
        </div>

        <div className="notify__error__col notify__error__col--spread">
          <div className="notify__error__description-title">{error.title}</div>
          <div className="notify__error__description">{error.description}</div>
        </div>
      </div>
    );
  };

  const renderErrors = () => {
    return props.errors.map((error, index) => {
      if (error.display) {
        return error.kind == "warning" ? renderWarning(error, index) : renderError(error, index);
      }
    });
  };

  return <div className="notify__error__container">{renderErrors()}</div>;
};

export default Errors;
