import React, { useState, useRef, useEffect } from "react";
import _ from "lodash";
import PharmacySelect from "./PharmacySelect";

const ImbruvicaDesignationForm = (props) => {
  const [specialtyDesignation, setSpecialtyDesignation] = useState(false);
  const [confirmedPolicy, setConfirmedPolicy] = useState(false);
  const [hasSelectedPharmacy, setHasSelectedPharmacy] = useState(false);
  const [selectedPharmacy, setSelectedPharmacy] = useState(false);

  useEffect(() => {
    var formDetails = _.cloneDeep(props.manufacturerFormDetails);

    // set form details for completed status - obj name as class
    formDetails[props.manufacturer].specialtyDesignationForm = {
      open: specialtyDesignation,
      completed: hasSelectedPharmacy,
    };
    props.setManufacturerFormDetails(formDetails);
  }, [specialtyDesignation, hasSelectedPharmacy]);

  const notPermitted = () => {
    return (
      <div
        className={
          confirmedPolicy
            ? "designation_form__section__group__step__toggle_container designation_form__section__group__step__toggle_container--closed"
            : "designation_form__section__group__step__toggle_container"
        }
      >
        <div className="stat-cards__confirmation stat-cards__confirmation--disabled">
          <span>Please reach out to support at support@340besp.com for assistance.</span>
          {renderNotPermitted()}
        </div>
      </div>
    );
  };

  const renderNotPermitted = () => {
    return (
      <div className="stat-cards__confirmation__footer">
        <span>
          <div className="indicator indicator--red indicator--pulse" style={{ marginTop: 3 }}></div>
          Contact support
        </span>
      </div>
    );
  };

  const policies = () => {
    if (props.isBlocked) {
      return notPermitted();
    }

    return (
      <div
        className={
          confirmedPolicy
            ? "designation_form__section__group__step__toggle_container designation_form__section__group__step__toggle_container--closed"
            : "designation_form__section__group__step__toggle_container"
        }
      >
        <div className="stat-cards__confirmation stat-cards__confirmation--disabled">
          AbbVie's contract pharmacy policy permits a 340B covered entity that does not have an in-house pharmacy
          eligible for the IMBRUVICA® distribution network and is not an excluded entity under 42 U.S.C. § 256b(e) to
          designate a single specialty contract pharmacy from the list of eligible specialty pharmacies in
          Pharmacyclics', an AbbVie Company, limited distribution network for this purpose.
          {renderConfirmation()}
        </div>
      </div>
    );
  };

  const renderImbruvicaDesignationForm = () => {
    return (
      <div className="designation_form__section__group">
        <div className="designation_form__section__group__row">
          <div className="designation_form__section__group__question">
            <div className="designation_form__section__group__question__subtitle">
              Contract Pharmacy designation for Imbruvica
            </div>
            Would you like to make a single contract pharmacy designation for AbbVie's specialty limited distribution
            product, IMBRUVICA®?
          </div>
          <div className="designation_form__section__group__value">
            <div className="btn__option__group">
              <div
                className={specialtyDesignation ? "btn btn--small btn--selected" : "btn btn--small"}
                onClick={() => setSpecialtyDesignation(true)}
              >
                yes
              </div>
              <div
                className={specialtyDesignation ? "btn btn--small" : "btn btn--small btn--selected"}
                onClick={() => setSpecialtyDesignation(false)}
              >
                no
              </div>
            </div>
          </div>
        </div>
        {renderPolicy()}
        {renderStep()}
      </div>
    );
  };

  const renderConfirmation = () => {
    return (
      <div className="stat-cards__confirmation__footer">
        <span>
          <div className="indicator indicator--red indicator--pulse" style={{ marginTop: 3 }}></div>
          Please confirm policy before assigning designation
        </span>
        <span>
          <div className="btn btn--ib btn--tiny" onClick={() => setConfirmedPolicy(true)}>
            Confirm
          </div>
        </span>
      </div>
    );
  };

  const renderStepIndicator = (step) => {
    if (step) {
      return (
        <div className="designation_form__section__group__step__indicator designation_form__section__group__step__indicator--complete">
          <i className="solid solid-budicon-check-ui" />
        </div>
      );
    } else {
      return <div className="designation_form__section__group__step__indicator"></div>;
    }
  };

  const renderPolicy = () => {
    if (specialtyDesignation) {
      return (
        <div className="designation_form__section__group__step">
          {renderStepIndicator(confirmedPolicy)}
          <div className="designation_form__section__group__step__title">Policy Confirmation</div>
          <div className="designation_form__section__group__step__subtitle">
            Please confirm manufacturers policy to continue.
          </div>
          {policies()}
        </div>
      );
    }
  };

  const renderNoticeHasExistingDesignation = (entity) => {
    var designation = entity.designations_count[props.manufacturer];

    if (designation && designation[props.specialtyKeyName] > 0) {
      return (
        <div className="stat-cards__notice stat-cards__notice--blue stat-cards__notice--mb" style={{ lineHeight: 1.5 }}>
          The selected covered entity already made a contract pharmacy designation, please login to or register an
          account with 340B ESP to view the details of this designation. If you have questions, please reach out to
          support@340besp.com
        </div>
      );
    }
  };

  const filterPharmacies = (entity) => {
    return _.filter(entity.pharmacies.details, { [props.manufacturer]: [props.specialtyKeyName] });
  };

  const renderPharmacySelect = () => {
    return _.map(props.entities, (entity, index) => (
      <div key={index}>
        {renderNoticeHasExistingDesignation(entity)}
        <PharmacySelect
          kind={"imbruvica"}
          manufacturer={props.manufacturer}
          entity={entity}
          pharmacies={filterPharmacies(entity)}
          disableGranteeNotice={false}
          disableWhollyOwnedNotice={true}
          disableInHouseSpecialtyNotice={true}
          disableInHouseNotice={true}
          selectedPharmacy={selectedPharmacy}
          setSelectedPharmacy={setSelectedPharmacy}
          setHasSelectedPharmacy={setHasSelectedPharmacy}
          retailSelectedSpecialtyPharmacy={props.retailSelectedSpecialtyPharmacy}
          retailHasSelectedSpecialtyPharmacy={props.retailHasSelectedSpecialtyPharmacy}
        />
      </div>
    ));
  };

  const renderStep = () => {
    if (specialtyDesignation && confirmedPolicy) {
      return (
        <div className="designation_form__section__group__step">
          {renderStepIndicator(hasSelectedPharmacy)}
          <div className="designation_form__section__group__step__title">Select Contract Pharmacy</div>
          <div className="designation_form__section__group__step__subtitle">
            Please select a contract pharmacy to designate.
          </div>
          {renderPharmacySelect()}
        </div>
      );
    }
  };

  return renderImbruvicaDesignationForm();
};

export default ImbruvicaDesignationForm;
