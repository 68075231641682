import React from "react";
import ArrowRight from "../../Icons/ArrowRight";

const ActionButton = () => {
  return (
    <button className="btn btn--lg flex align-center gap--8" onClick={onClick} disabled={disabled}>
      <span>{text}</span>
      <ArrowRight />
    </button>
  );
};

export default ActionButton;
