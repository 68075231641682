import React, { useState, useRef, useEffect } from "react";
import _ from "lodash";
import Gsk from "images/GSK.png";

const AgreementGsk = (props) => {

  const renderAgreement = () => {
    if (props.selectedManufacturer == "glaxosmithkline") {
      return (
        <div>
          <div className="logo__container">
            <div className="logo">
              <img src={Gsk} />
            </div>
            <div className="logo__info">
              <div className="logo__info__title">GlaxoSmithKline</div>
              <div className="logo__info__subtitle">Wholly Owned Contract Pharmacy Agreement</div>
            </div>
          </div>
          <p>
            For orders placed on or after April 1, 2022 wholesalers, on behalf of GlaxoSmithKline (GSK), will only ship GSK products purchased at the 340B price exclusively to locations which are registered within the Health Resources and Services Administration's (HRSA) database as: (1) a 340B covered entity; or (2) a child site of a registered 340B covered entity location. Pharmacies registered within the HRSA database as a contract pharmacy will no longer be eligible for Bill To / Ship To replenishment orders. To ensure all 340B covered entities can access GSK products at the 340B price, any covered entity that does not have an in-house pharmacy capable of dispensing 340B purchased drugs to its patients may designate a single contract pharmacy location by visiting www.340Besp.com/designations. Covered entities may also elect to submit 340B claims through 340B ESP™ for all utilization dispensed through contract pharmacies. Any covered entity that elects to submit its 340B claims will be allowed to continue Bill To / Ship To replenishment orders for its contract pharmacies. Covered entities that are interested in submitting 340B claims will need to register an account with 340B ESP™ and must submit the 340B claims within 45 days of the dispense date.
          </p>
          <p>
            Under GSK's 340B distribution policy, contract pharmacies that are wholly owned by a 340B covered entity or have common ownership with a health system, will remain eligible to receive Bill To / Ship To replenishment orders of 340B priced drugs. These pharmacies must be registered within the HRSA database as a contract pharmacy of the 340B covered entity. If a covered entity or health system is granted an exemption from GSK's 340B distribution policy for its wholly owned contract pharmacy(ies), it may not also designate an independent (i.e., third party) contract pharmacy to dispense its primary care products.
          </p>
          <p>
            Covered entities must complete the wholly owned contract pharmacy exemption form on the 340B ESP website to apply for this exemption. Proof of ownership documents are required to complete your request. Examples of proof of ownership documents include:
          </p>
          <ul>
            <li>
              Certificate of liability insurance
            </li>
            <li>
              State business licenses
            </li>
          </ul>
          <p>
            Wholly Owned Contract Pharmacy Exemption requests will be processed within five (5) business days. Please note that failure to populate all required fields will delay the processing of Wholly Owned Contract Pharmacy Exemption requests. Exemptions will be valid for twelve (12) months from approval date and after the initial 12-month period, GSK may require covered entities to resubmit proof of ownership documents to validate the continued eligibility of existing exemptions.
          </p>
          <p>
            By signing this form the authorized representative of the covered entity certifies that the covered entity and contract pharmacy(ies) included in this Exemption request: (i) have the same corporate ownership, OR (ii) that the covered entity wholly owns the identified contract pharmacy(ies); AND (iii) the covered entity qualifies for the exemption as described in this form. If there are any changes to the ownership structure of any of the contract pharmacies identified in this form, the covered entity agrees to notify GSK at support@340besp.com within five (5) business days of such change of ownership.
          </p>
          <div className="register__divider" />
        </div>
      )
    } else {
      return null
    }
  }

  return (
    renderAgreement()
  )

};

export default AgreementGsk;
