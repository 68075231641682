import React, { useState, useEffect } from "react";
import Snackbar from "@material-ui/core/Snackbar";
import Alert from "@material-ui/lab/Alert";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles({
  root: {
    whiteSpace: "pre-wrap",
  },
});

export default function OverlayMessage(props) {
  const classes = useStyles();

  useEffect(() => {
    document.body.classList.add("overlay__no-scroll");
  }, []);

  return (
    <div className="overlay">
      <Snackbar className={classes.root} anchorOrigin={{ vertical: "bottom", horizontal: "left" }} open={true}>
        <Alert elevation={5} variant="filled" severity="error">
          {props.message}
        </Alert>
      </Snackbar>
    </div>
  );
}
