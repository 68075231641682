import React, { useEffect, useState } from "react";
import _ from "lodash";
import { ResponsiveLine } from "@nivo/line";
import { linearGradientDef } from "@nivo/core";
import { wholeNumberWithCommas } from "../utils/NumberFormatter";
import moment from "moment";
import axios from "axios";

export default function OverdrawnAccountsWidget(props) {
  const [viewPolicyToDate, setViewPolicyToDate] = useState(false);
  const [statusHistory, setStatusHistory] = useState({
    id: "OverdrawnAccountDetails",
    data: [
      { x: "Jul", y: 0 },
      { x: "Aug", y: 0 },
      { x: "Sep", y: 0 },
      { x: "Oct", y: 0 },
      { x: "Nov", y: 0 },
      { x: "Dec", y: 0 },
    ],
  });

  const [searchQuery, setSearchQuery] = useState({
    date_range: { start: moment().subtract(3, "months").format("YYYY-MM-DD"), end: moment().format("YYYY-MM-DD") },
  });

  useEffect(() => {
    fetchStatusHistory();
  }, [searchQuery]);

  const handleDateRangeSelect = (months, state) => {
    setViewPolicyToDate(state);

    setSearchQuery({
      date_range: {
        start: moment().subtract(months, "months").format("YYYY-MM-DD"),
        end: moment().format("YYYY-MM-DD"),
      },
    });
  };

  const fetchStatusHistory = () => {
    setStatusHistory({
      id: "OverdrawnAccountDetails",
      data: [
        { x: "Jul", y: 0 },
        { x: "Aug", y: 0 },
        { x: "Sep", y: 0 },
        { x: "Oct", y: 0 },
        { x: "Nov", y: 0 },
        { x: "Dec", y: 0 },
      ],
    });

    axios
      .get(props.overdrawn_status_history_path, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "X-CSRF-Token": document.querySelector('meta[name="csrf-token"]').getAttribute("content"),
        },
        params: {
          ...searchQuery,
        },
      })
      .then(function (response) {
        setStatusHistory(response.data.data);
      })
      .catch(function (error) {});
  };

  const renderTable = () => {
    return (
      <table className="secondary secondary--light">
        <thead>
          <tr className="sm">
            <th className="rnd-l t--sm">Manufacturer</th>
            <th className="rnd-l t--sm">Entity</th>
            <th className="rnd-r t--sm">Accounts</th>
          </tr>
        </thead>
        <tbody>{renderMfrDetails()}</tbody>
      </table>
    );
  };

  const renderMfrDetails = () => {
    return _.map(_.orderBy(statusHistory.mfrs, ["total"], ["desc"]), (mfr, i) => {
      return (
        <tr className="sm" key={i}>
          <td className="t--sm">{mfr.name}</td>
          <td className="t--sm">{mfr.entity}</td>
          <td className="t--sm">{mfr.total}</td>
        </tr>
      );
    });
  };

  return (
    <div className="div5">
      <div className="tile" style={{ width: "100%", height: "100%" }}>
        <div className="tile__header tile__header--spread">
          <div className="tile__header__details">
            <div className="tile__header__details__title">Negative Balance Accounts</div>
            <div className="tile__header__details__subtitle">
              Updated - {moment().startOf("month").format("MM-DD-YYYY")}
            </div>
          </div>
          <a href={`/organization/${props.organization}/accounts/`}>
            <div className="btn btn--light">View Details</div>
          </a>
        </div>
        <div className="flex__container flex__container--space-between flex__container--align-center">
          <div className="t--500 t--xl">
            {statusHistory ? wholeNumberWithCommas(statusHistory.data[statusHistory.data.length - 1].y) : 0}
          </div>
          <div className="btn__option__group">
            <div
              onClick={() => handleDateRangeSelect(12, true)}
              className={viewPolicyToDate ? "btn btn--tiny btn--selected" : "btn btn--tiny"}
            >
              Last 12 Months
            </div>
            <div
              onClick={() => handleDateRangeSelect(3, false)}
              className={viewPolicyToDate ? "btn btn--tiny" : "btn btn--tiny btn--selected"}
            >
              Last 3 Months
            </div>
          </div>
        </div>
        <div style={{ width: "100%", height: 258, margin: "20px 0", backgroundColor: "#ffffff", borderRadius: 8 }}>
          <ResponsiveLine
            data={[
              {
                id: statusHistory.id,
                data: viewPolicyToDate ? statusHistory.data : statusHistory.data.slice(-3),
              },
            ]}
            curve="cardinal"
            enableArea={true}
            enablePoints={false}
            areaOpacity={0.2}
            enableCrosshair={false}
            defs={[
              {
                id: "gradientFill",
                type: "linearGradient",
                colors: [
                  { offset: 0, color: "#FF681F" },
                  { offset: 100, color: "#FFFFFF" },
                ],
              },
            ]}
            theme={{
              grid: {
                line: {
                  stroke: "#F2F5F8",
                },
              },
            }}
            margin={{ top: 20, right: 10, bottom: 25, left: 45 }}
            xScale={{ type: "point" }}
            yScale={{
              type: "linear",
              min: 0,
              max: "auto",
              stacked: true,
              reverse: false,
            }}
            axisBottom={{
              orient: "bottom",
              tickSize: 5,
              tickPadding: 5,
              tickRotation: 0,
            }}
            axisLeft={{
              orient: "left",
              tickSize: 5,
              tickValues: 5,
              tickPadding: 5,
              tickRotation: 0,
            }}
            enableGridX={false}
            colors={["#FF681F"]}
            fill={[{ match: "*", id: "gradientFill" }]}
            pointColor={{ theme: "background" }}
            pointLabel="y"
            pointLabelYOffset={-12}
            useMesh={true}
          />
        </div>
        <div style={{ overflow: "scroll" }}>{renderTable()}</div>
      </div>
    </div>
  );
}
